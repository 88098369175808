import styled from 'styled-components';

const Overflow = styled.div`
  position: relative;
  overflow: scroll;

  max-height: calc(90vh - 200px);

  scrollbar-width: none;

  &::-webkit-scrollbar {
    width: 0;
    height: 0;
  }

  &::-webkit-scrollbar-track {
    -webkit-box-shadow: none;
    border-radius: 0;
  }

  &::-webkit-scrollbar-thumb {
    border: transparent 1px solid;
    background-color: transparent;
  }

  overflow: -moz-scrollbars-none;
`;

export default Overflow;
