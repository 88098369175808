import { fromJS } from 'immutable';
import { map } from 'config';
import debug from 'utils/debug';
import * as actions from './actions';

const { REDUX_EVENT_HANDLER } = debug('m:maps:index');

export const initialState = fromJS({
  commuteOffer: {},
  dataset: {},
  geofences: {},
  newGeofence: {},
  simulation: {}
})
  .setIn(['commuteOffer', 'viewport'], map.initialViewState)
  .setIn(['dataset', 'viewport'], map.initialViewStateDataset)
  .setIn(['geofences', 'viewport'], map.initialViewState)
  .setIn(['newGeofence', 'viewport'], map.initialViewState)
  .setIn(['simulation', 'viewport'], map.initialViewState);

const reducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case actions.CHANGE_VIEWPORT_COMMUTE_OFFER: {
      REDUX_EVENT_HANDLER(type, payload);

      const viewport = payload;
      return state.setIn(['commuteOffer', 'viewport'], viewport);
    }
    case actions.CHANGE_VIEWPORT_SIMULATION: {
      REDUX_EVENT_HANDLER(type, payload);

      const viewport = payload;
      return state.setIn(['simulation', 'viewport'], viewport);
    }
    case actions.CHANGE_VIEWPORT_DATASET: {
      REDUX_EVENT_HANDLER(type, payload);

      const viewport = payload;
      return state.setIn(['dataset', 'viewport'], viewport);
    }
    case actions.CHANGE_VIEWPORT_GEOFENCES: {
      REDUX_EVENT_HANDLER(type, payload);

      const viewport = payload;
      return state.setIn(['geofences', 'viewport'], viewport);
    }
    case actions.CHANGE_VIEWPORT_NEW_GEOFENCE: {
      REDUX_EVENT_HANDLER(type, payload);

      const viewport = payload;
      return state.setIn(['newGeofence', 'viewport'], viewport);
    }
    case actions.CHANGE_VIEWPORT_ALL_MAPS: {
      REDUX_EVENT_HANDLER(type, payload);

      const { latitude, longitude } = payload;
      return state
        .setIn(['commuteOffer', 'viewport', 'latitude'], latitude)
        .setIn(['commuteOffer', 'viewport', 'longitude'], longitude)
        .setIn(['dataset', 'viewport', 'latitude'], latitude)
        .setIn(['dataset', 'viewport', 'longitude'], longitude)
        .setIn(['simulation', 'viewport', 'latitude'], latitude)
        .setIn(['simulation', 'viewport', 'longitude'], longitude)
        .setIn(['geofences', 'viewport', 'latitude'], latitude)
        .setIn(['geofences', 'viewport', 'longitude'], longitude)
        .setIn(['newGeofence', 'viewport', 'latitude'], latitude)
        .setIn(['newGeofence', 'viewport', 'longitude'], longitude);
    }
    default: {
      return state;
    }
  }
};

export default reducer;
