import { connect } from 'react-redux';
import createImmutableSelector from 'create-immutable-selector';

import { activeBookingIndexSelector } from 'modules/commuteOffer/selectors';
import { bookingsSelector } from 'modules/simulations/selectors';
import { isBookingsHiddenSelector } from 'modules/ui/selectors';
import { stopsSelector } from 'modules/entities/selectors';

import { toggleBookingsHidden } from 'modules/ui/actions';

import Bookings from './Bookings';

const mapStateToProps = createImmutableSelector(
  bookingsSelector,
  isBookingsHiddenSelector,
  activeBookingIndexSelector,
  stopsSelector,
  (bookings, isHidden, activeBookingIndex, stops) => ({
    bookings,
    isHidden: false,
    activeBookingIndex,
    stops
  })
);

const mapDispatchToProps = {
  toggleBookingsHidden
};

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps
);

export default withConnect(Bookings);
