import { connect } from 'react-redux';
import createImmutableSelector from 'create-immutable-selector';

import { updateDataset } from 'modules/datasets/actions';
import { datasetSelector } from 'modules/datasets/selectors';

import HeaderButtons from './HeaderButtons';

const mapDispatchToProps = {
  updateDataset
};

const mapStateToProps = createImmutableSelector(datasetSelector, dataset => ({
  bookmarked: dataset.get('bookmarked'),
  id: dataset.get('id')
}));

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps
);

export default withConnect(HeaderButtons);
