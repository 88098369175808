export const SIMULATIONS_FETCH_REQUEST =
  '@@simulations/SIMULATIONS_FETCH_REQUEST';
export const SIMULATIONS_FETCH_SUCCESS =
  '@@simulations/SIMULATIONS_FETCH_SUCCESS';
export const SIMULATIONS_FETCH_FAILURE =
  '@@simulations/SIMULATIONS_FETCH_FAILURE';
export const fetchSimulations = () => ({ type: SIMULATIONS_FETCH_REQUEST });

export const SIMULATION_FETCH_REQUEST =
  '@@simulations/SIMULATION_FETCH_REQUEST';
export const SIMULATION_FETCH_SUCCESS =
  '@@simulations/SIMULATION_FETCH_SUCCESS';
export const SIMULATION_FETCH_FAILURE =
  '@@simulations/SIMULATION_FETCH_FAILURE';
export const fetchSimulation = id => ({
  type: SIMULATION_FETCH_REQUEST,
  payload: id
});

export const DATA_FETCH_REQUEST = '@@simulations/DATA_FETCH_REQUEST';
export const DATA_FETCH_SUCCESS = '@@simulations/DATA_FETCH_SUCCESS';
export const DATA_FETCH_FAILURE = '@@simulations/DATA_FETCH_FAILURE';

export const fetchSimulationData = (simulation, id, offset, limit) => ({
  type: DATA_FETCH_REQUEST,
  payload: { simulation, id, offset, limit }
});

export const fetchSimulationDataSuccess = (
  simulation,
  meta,
  objects,
  progress
) => ({
  type: DATA_FETCH_SUCCESS,
  payload: { simulation, meta, objects, progress }
});

export const DATA_FETCH_START = '@@simulations/DATA_FETCH_START';
export const DATA_FETCH_STOP = '@@simulations/DATA_FETCH_STOP';
export const DATA_FETCH_FINISH = '@@simulations/DATA_FETCH_FINISH';
export const fetchSimulationDataStart = id => ({
  type: DATA_FETCH_START,
  payload: id
});

export const fetchSimulationDataStop = () => ({
  type: DATA_FETCH_STOP
});

export const ADD_SIMULATION_REQUEST = '@@simulations/ADD_SIMULATION_REQUEST';
export const ADD_SIMULATION_SUCCESS = '@@simulations/ADD_SIMULATION_SUCCESS';
export const ADD_SIMULATION_FAILURE = '@@simulations/ADD_SIMULATION_FAILURE';
export const addSimulation = body => ({
  type: ADD_SIMULATION_REQUEST,
  payload: { body }
});

export const UPDATE_SIMULATION_REQUEST =
  '@@simulations/UPDATE_SIMULATION_REQUEST';
export const UPDATE_SIMULATION_SUCCESS =
  '@@simulations/UPDATE_SIMULATION_SUCCESS';
export const UPDATE_SIMULATION_FAILURE =
  '@@simulations/UPDATE_SIMULATION_FAILURE';
export const updateSimulation = (id, body) => ({
  type: UPDATE_SIMULATION_REQUEST,
  payload: { id, body }
});

export const DELETE_SIMULATION_REQUEST =
  '@@simulations/DELETE_SIMULATION_REQUEST';
export const DELETE_SIMULATION_SUCCESS =
  '@@simulations/DELETE_SIMULATION_SUCCESS';
export const DELETE_SIMULATION_FAILURE =
  '@@simulations/DELETE_SIMULATION_FAILURE';
export const deleteSimulation = id => ({
  type: DELETE_SIMULATION_REQUEST,
  payload: id
});

export const CLEAR_SIMULATION = '@@simulations/CLEAR_SIMULATION';
export const clearSimulation = () => ({ type: CLEAR_SIMULATION });

export const CLEAR_LIST = '@@simulations/CLEAR_LIST';
export const clearList = () => ({ type: CLEAR_LIST });

// Events

export const WEBSOCKET_CONNECTED = '@@simulations/WEBSOCKET_CONNECTED';
export const websocketConnected = () => ({ type: WEBSOCKET_CONNECTED });

export const WEBSOCKET_DISCONNECTED = '@@simulations/WEBSOCKET_DISCONNECTED';
export const websocketDisconnected = () => ({ type: WEBSOCKET_DISCONNECTED });

export const SIMULATION_EVENT = '@@simulation/SIMULATION_EVENT';
export const simulationEvent = data => ({
  type: SIMULATION_EVENT,
  payload: data
});

// Player

export const SET_SIMULATION_TIME = '@@ui/SET_SIMULATION_TIME';
export const setSimulationTime = (time, maxTime) => ({
  type: SET_SIMULATION_TIME,
  payload: { time, maxTime }
});

export const CHANGE_SIMULATION_TIME = '@@ui/CHANGE_SIMULATION_TIME';
export const changeSimulationTime = time => ({
  type: CHANGE_SIMULATION_TIME,
  payload: time
});

export const SIMULATION_PLAY = '@@ui/SIMULATION_PLAY';
export const simulationPlay = () => ({ type: SIMULATION_PLAY });

export const SIMULATION_STOP = '@@ui/SIMULATION_STOP';
export const simulationStop = () => ({ type: SIMULATION_STOP });

export const SIMULATION_NEXT = '@@ui/SIMULATION_NEXT';

export const SET_SIMULATION_SPEED = '@@ui/SET_SIMULATION_SPEED';
export const setSimulationSpeed = speed => ({
  type: SET_SIMULATION_SPEED,
  payload: speed
});
