import styled from 'styled-components';

export default styled.div`
  position: relative;
  padding: 15px;
  margin-bottom: 5px;
  font-weight: 600;

  ${({ isHidden }) =>
    isHidden &&
    `
    padding: 10px 6px;
    writing-mode: tb-rl;
    width: 32px;
    height: 200px;
    text-align: right;
    transform: rotate(180deg);
  `}
`;
