import { connect } from 'react-redux';
import { createSelector } from 'reselect';

import { routingEngineSelector } from 'modules/ui/selectors';

import { toggleRoutingEngine } from 'modules/ui/actions';

import RoutingButtons from './RoutingButtons';

const mapStateToProps = createSelector(
  routingEngineSelector,
  routingEngine => ({ routingEngine })
);

const mapDispatchToProps = {
  toggleRoutingEngine
};

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps
);

export default withConnect(RoutingButtons);
