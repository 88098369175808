// @flow

import createImmutableSelector from 'create-immutable-selector';
import { connect } from 'react-redux';
import {
  vehiclesFilteredBySearchSelector,
  bookingsFilteredBySearchSelector
} from 'modules/commuteOffer/selectors';
import { changeVehiclesFilter } from 'modules/ui/actions';

import Filter from './Filter';

const mapStateToProps = createImmutableSelector(
  vehiclesFilteredBySearchSelector,
  bookingsFilteredBySearchSelector,
  (vehicles, bookings) => ({ vehicles, bookings })
);
const mapDispatchToProps = {
  changeVehiclesFilter
};

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps
);

export default withConnect(Filter);
