import React from 'react';
import Button from 'components/Button';

import Container from './Container';
import ButtonsWrap from './ButtonsWrap';
import Search from './Search';
import Title from './Title';
import Selected from './Selected';

const Panel = (props) => {
  const {
    count,
    selectedIds,
    toggleCompareMod,
    clearSelectedSimulations
  } = props;

  return (
    <Container>
      <Title>{`${count || 0} simulations`}</Title>
      <Selected
        count={selectedIds.size}
        clearSelectedSimulations={clearSelectedSimulations}
      />
      <ButtonsWrap>
        <Button
          isDisable={selectedIds.size < 2 || selectedIds.size > 5}
          onClick={toggleCompareMod}
        >
          Compare
        </Button>
      </ButtonsWrap>
      <Search placeholder='Search simulations' />
    </Container>
  );
};

export default Panel;
