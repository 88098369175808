import React from 'react';
import { InputRange } from '@urbica/ui-kit';
import { ThemeProvider } from 'styled-components';

import Settings from './Settings';
import Time from './Time';

import theme from './theme';
import Container from './Container';
import Play from './Play';

const Slider = (props) => {
  const {
    time,
    isRunning,
    onChange,
    minMax = { minTime: 0, maxTime: 0 },
    onStop,
    onPlay,
    isAllDay,
    step = 1
  } = props;

  const { minTime, maxTime } = minMax;

  const onChangeHandler = (e) => {
    onChange(e.target.value);
  };

  const onPlayStop = () => {
    if (isRunning) {
      onStop();
    } else {
      onPlay();
    }
  };

  return (
    <Container>
      <Settings {...props} />
      <Play onClick={onPlayStop} isRunning={isRunning} isDisable={isAllDay} />
      <ThemeProvider theme={theme}>
        <InputRange
          onChange={onChangeHandler}
          isDisable={isAllDay}
          value={time}
          max={maxTime}
          min={minTime}
          step={step}
        />
      </ThemeProvider>
      <Time {...props} />
    </Container>
  );
};

export default Slider;
