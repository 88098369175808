import moment from 'moment';
import debug from 'utils/debug';

import { emptyCommuteOfferJSON } from 'utils/CommuteOffer';

const { METHOD } = debug('m:CommuteSchedule:GeneratePhysicalVehicles');

// eslint-disable-next-line import/prefer-default-export
export const commuteSchedule$GeneratePhysicalVehicles = async (
  offers,
  currentDate,
  timezone,
  opts
) => {
  METHOD('Request', { offers, currentDate, timezone, opts });

  try {
    const options = opts || {};

    const { max_slack_ph } = options;

    const tags = [
      `#${currentDate.format('YYYY-MM-DD')}`,
      `#${currentDate.format('ddd')}`
    ];

    const mergedOffer = offers.reduce((dst, src) => {
      METHOD('Merge', { dst, src });

      const src_result_assigned_bookings_map = src.result.assigned_bookings.reduce(
        (acc, booking) => ({
          ...acc,
          [booking.uid]: booking
        }),
        {}
      );
      METHOD('Merge:src_result_assigned_bookings_map', {
        src,
        src_result_assigned_bookings_map
      });

      const src_stateless_api_request_data_nodes = src.stateless_api_request_data.nodes.map(
        (node) => {
          METHOD('Merge:src_stateless_api_request_data_nodes:node', {
            node
          });
          const booking = src_result_assigned_bookings_map[node.booking_uid];
          METHOD('Merge:src_stateless_api_request_data_nodes:booking', {
            booking
          });

          const { scheduled_pickup_time, scheduled_dropoff_time } = booking;

          const timewindow_sub =
            typeof node.max_timewindow_sub !== 'undefined'
              ? node.max_timewindow_sub
              : 300;
          const timewindow_add =
            typeof node.max_timewindow_add !== 'undefined'
              ? node.max_timewindow_add
              : 300;
          METHOD('Merge:src_stateless_api_request_data_nodes:timewindow', {
            timewindow_sub,
            timewindow_add
          });

          switch (node.node_type) {
            case 'pickup': {
              return {
                ...node,
                open_time_ts: moment(scheduled_pickup_time)
                  .subtract(timewindow_sub, 'seconds')
                  .tz(global.GEODISC_TIMEZONE)
                  .format(),
                close_time_ts: moment(scheduled_pickup_time)
                  .add(timewindow_add, 'seconds')
                  .tz(global.GEODISC_TIMEZONE)
                  .format(),
                close_time_ts_dynamic: moment(scheduled_pickup_time)
                  .add(timewindow_add, 'seconds')
                  .tz(global.GEODISC_TIMEZONE)
                  .format(),
                original_open_time_ts: node.open_time_ts,
                original_close_time_ts: node.close_time_ts,
                original_close_time_ts_dynamic: node.close_time_ts_dynamic
              };
            }
            case 'dropoff': {
              return {
                ...node,
                open_time_ts: moment(scheduled_dropoff_time)
                  .subtract(timewindow_sub, 'seconds')
                  .tz(global.GEODISC_TIMEZONE)
                  .format(),
                close_time_ts: moment(scheduled_dropoff_time)
                  .add(timewindow_add, 'seconds')
                  .tz(global.GEODISC_TIMEZONE)
                  .format(),
                close_time_ts_dynamic: moment(scheduled_dropoff_time)
                  .add(timewindow_add, 'seconds')
                  .tz(global.GEODISC_TIMEZONE)
                  .format(),
                original_open_time_ts: node.open_time_ts,
                original_close_time_ts: node.close_time_ts,
                original_close_time_ts_dynamic: node.close_time_ts_dynamic
              };
            }
            default:
              return node;
          }
        }
      );
      METHOD('Merge:src_stateless_api_request_data_nodes', {
        src,
        src_stateless_api_request_data_nodes
      });

      const src_stateless_api_request_data_vehicles = src.stateless_api_request_data.vehicles.map(
        vehicle => ({
          ...vehicle,
          tags,
          start_time: undefined,
          end_time: undefined,
          readonly: true
        })
      );

      const engineSettingsBase =
        options.engineSettingsBase ||
        src.stateless_api_request_data.engine_settings;

      return {
        ...dst,
        stateless_api_request_data: {
          ...dst.stateless_api_request_data,
          inbound: {
            sources: offers.map(offer => offer.name)
          },
          bookings: {
            ...dst.stateless_api_request_data.bookings,
            ...src.stateless_api_request_data.bookings
          },
          nodes: [
            ...dst.stateless_api_request_data.nodes,
            ...src_stateless_api_request_data_nodes
          ],
          vehicles: [
            ...dst.stateless_api_request_data.vehicles,
            ...src_stateless_api_request_data_vehicles
          ],
          engine_settings: {
            ...engineSettingsBase,
            model_parameters: {
              ...engineSettingsBase.model_parameters,
              max_slack: max_slack_ph
            }
          },
          current_time: currentDate.tz(global.GEODISC_TIMEZONE).format()
        },
        result: {
          ...dst.result,
          vehicles: {
            ...dst.result.vehicles,
            ...src.result.vehicles
          },
          assigned_bookings: [
            ...dst.result.assigned_bookings,
            ...src.result.assigned_bookings
          ],
          rejected_bookings: [
            ...dst.result.rejected_bookings,
            ...src.result.rejected_bookings
          ]
        },
        tags,
        name: tags.join(' ')
      };
    }, JSON.parse(emptyCommuteOfferJSON));
    METHOD('mergedOffer', { mergedOffer });

    METHOD('Success', { mergedOffer });
    return mergedOffer;
  } catch (error) {
    METHOD('Failure', { error });
    // return JSON.parse(emptyCommuteOfferJSON);
    throw error;
  }
};
