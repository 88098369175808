import React from 'react';
import debug from 'utils/debug';

import Loading from 'components/Loading';

import ModalContainer from './ModalContainer';
import NewDataset from './NewDataset';
import NewGeofence from './NewGeofence';
import NewSimulation from './NewSimulation';
import NewCommuteOffer from './NewCommuteOffer';
import DuplicateCommuteOffer from './DuplicateCommuteOffer';
import CommuteOfferVehicleEditor from './CommuteOfferVehicleEditor';
import CommuteOfferVehicleUpdates from './CommuteOfferVehicleUpdates';
import CommuteScheduleImportPassengers from './CommuteScheduleImportPassengers';
import ProgressWindow from './ProgressWindow';
import TableView from './TableView';

const { METHOD } = debug('c:ModalWindow:Modal');

const Component = ({ modalWindow, ...props }) => {
  METHOD('Component', { modalWindow, ...props });

  switch (modalWindow) {
    case 'dataset':
      return <NewDataset {...props} />;
    case 'geofence':
      return <NewGeofence {...props} />;
    case 'simulation':
      return <NewSimulation {...props} />;
    case 'commuteoffer':
      return <NewCommuteOffer {...props} />;
    case 'duplicateCommuteOffer':
      return <DuplicateCommuteOffer {...props} />;
    case 'CommuteOfferVehicleEditor':
      return <CommuteOfferVehicleEditor {...props} />;
    case 'CommuteOfferVehicleUpdates':
      return <CommuteOfferVehicleUpdates {...props} />;
    case 'CommuteScheduleImportPassengers':
      return <CommuteScheduleImportPassengers {...props} />;
    case 'ProgressWindow':
      return <ProgressWindow {...props} />;
    case 'TableView':
      return <TableView {...props} />;
    default:
      return null;
  }
};

const ModalWindow = ({ modalWindow, closePopup, ...props }) => {
  const { id, opts } = modalWindow;
  return (
    <ModalContainer
      title={opts.title || `New ${id}`}
      isCloseDisabled={opts.isCloseDisabled || false}
      onClose={closePopup}
    >
      <React.Suspense fallback={<Loading />}>
        <Component modalWindow={id} {...props} {...opts} />
      </React.Suspense>
    </ModalContainer>
  );
};

export default ModalWindow;
