import { fromJS } from 'immutable';
import moment from 'moment';
import debug from 'utils/debug';
import { getColorByKey } from 'utils/simulations';
import {
  SIMULATIONS_FETCH_SUCCESS,
  SIMULATION_FETCH_SUCCESS,
  DATA_FETCH_START,
  DATA_FETCH_STOP,
  DATA_FETCH_SUCCESS,
  DATA_FETCH_FINISH,
  CLEAR_SIMULATION,
  CLEAR_LIST,
  WEBSOCKET_CONNECTED,
  WEBSOCKET_DISCONNECTED,
  SIMULATION_EVENT,
  SET_SIMULATION_TIME,
  CHANGE_SIMULATION_TIME,
  SIMULATION_PLAY,
  SIMULATION_STOP,
  SET_SIMULATION_SPEED,
  SIMULATION_NEXT
} from './actions';
import { AUTHORIZATION_SUCCESS } from '../user/actions';

const { DEBUG, TRACE, PROFILER, REDUX_EVENT_HANDLER } = debug(
  'm:simulations:index'
);

export const initialState = fromJS({
  count: null,
  list: null,
  simulation: null,
  simulationData: null,
  progress: 0,
  player: {
    time: 0,
    minTime: 0,
    maxTime: 0,
    running: false,
    speed: 1
  }
});

const reducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case AUTHORIZATION_SUCCESS: {
      REDUX_EVENT_HANDLER(type, payload);

      const { token } = payload;

      const wsToken = token.get('token');
      DEBUG('Token:', wsToken);

      return state.set('wsToken', wsToken);
    }
    case SIMULATIONS_FETCH_SUCCESS: {
      REDUX_EVENT_HANDLER(type, payload);

      const objects = payload.get('objects');
      const count = payload.getIn(['meta', 'total_count']);

      return state.set('list', objects).set('count', count);
    }
    case SIMULATION_FETCH_SUCCESS: {
      REDUX_EVENT_HANDLER(type, payload);

      const { data, bookings, vehicles, nodes } = payload;

      TRACE($ => $('Simulation:', data));
      TRACE($ => $('Bookings:', bookings));
      TRACE($ => $('Vehicles:', vehicles));
      TRACE($ => $('Nodes:', nodes));

      global.bookingsByUID = {};
      bookings.forEach((booking) => {
        global.bookingsByUID[booking.uid] = booking;
      });

      return state
        .set('simulation', fromJS(data))
        .set('simulationBookings', bookings)
        .set('simulationVehicles', vehicles)
        .set('simulationNodes', nodes);
    }
    case DATA_FETCH_START: {
      REDUX_EVENT_HANDLER(type, payload);

      global.SIMULATION_DATA_RAW = [];
      global.SIMULATION_DATA_TIMELINE = {
        minTime: 0,
        maxTime: 0,
        data: {}
      };

      return state.set('simulationDataCount', 0).set('progress', 0);
    }
    case DATA_FETCH_SUCCESS: {
      REDUX_EVENT_HANDLER(type, payload);

      const { simulation, meta, objects, progress } = payload;

      DEBUG('Objects:', objects);

      PROFILER('UPDATE_SIMULATION_DATA', () => {
        global.SIMULATION_DATA_RAW = [
          ...global.SIMULATION_DATA_RAW,
          ...objects
        ];

        objects.forEach((item) => {
          const unixtime =
            Math.ceil(moment(item.current_sim_ts).unix() / 10) * 10;

          global.SIMULATION_DATA_TIMELINE.minTime =
            global.SIMULATION_DATA_TIMELINE.minTime > 0
              ? Math.min(global.SIMULATION_DATA_TIMELINE.minTime, unixtime)
              : unixtime;
          global.SIMULATION_DATA_TIMELINE.maxTime =
            global.SIMULATION_DATA_TIMELINE.maxTime > 0
              ? Math.max(global.SIMULATION_DATA_TIMELINE.minTime, unixtime)
              : unixtime;

          if (!global.SIMULATION_DATA_TIMELINE.data[unixtime]) {
            global.SIMULATION_DATA_TIMELINE.data[unixtime] = {};
          }

          global.SIMULATION_DATA_TIMELINE.data[unixtime][item.agent_id] = item;
        });
      });

      const { minTime, maxTime } = global.SIMULATION_DATA_TIMELINE;

      return state
        .setIn(['player', 'time'], minTime)
        .setIn(['player', 'minTime'], minTime)
        .setIn(['player', 'maxTime'], maxTime)
        .set('simulation', fromJS(simulation))
        .set('simulationDataCount', global.SIMULATION_DATA_RAW.length)
        .set('total_count', meta.total_count)
        .set('progress', progress);
    }
    case DATA_FETCH_FINISH: {
      REDUX_EVENT_HANDLER(type, payload);

      const { minTime } = global.SIMULATION_DATA_TIMELINE;

      return state.setIn(['player', 'time'], minTime).set('progress', 1);
    }
    case DATA_FETCH_STOP: {
      REDUX_EVENT_HANDLER(type, payload);

      return state
        .set('simulation', null)
        .set('simulationBookings', null)
        .set('simulationVehicles', null)
        .set('simulationData', null)
        .set('total_count', 0)
        .set('progress', 0);
    }
    case CLEAR_SIMULATION: {
      REDUX_EVENT_HANDLER(type, payload);

      return state
        .set('simulation', null)
        .set('simulationBookings', null)
        .set('simulationVehicles', null)
        .set('simulationData', null)
        .set('total_count', 0)
        .set('progress', 0);
    }
    case CLEAR_LIST: {
      REDUX_EVENT_HANDLER(type, payload);

      return state.set('list', null);
    }
    case WEBSOCKET_CONNECTED: {
      REDUX_EVENT_HANDLER(type, payload);

      return state
        .set('simulationData', null)
        .set('total_count', 0)
        .set('progress', 0);
    }
    case WEBSOCKET_DISCONNECTED: {
      REDUX_EVENT_HANDLER(type, payload);

      return state;
    }
    case SIMULATION_EVENT: {
      REDUX_EVENT_HANDLER(type, payload);

      const { kind, data } = payload;

      const simulation = state.get('simulation');

      if (!simulation) {
        return state;
      }

      if (!simulation.get('isLive')) {
        return state;
      }

      const simulationId = simulation.get('id');

      if (kind === 'simulation_event') {
        const event = data.data;

        if (data.simulation_id !== simulationId) {
          return state;
        }

        DEBUG('Event:', data);

        if (data.message_type === 'vehicle_states') {
          const { vehicle_states } = event;

          const objects = vehicle_states
            ? vehicle_states.reduce((acc, value) => {
                if (simulationId === value.simulation_id) {
                  acc.push({
                    ...value,
                    activeColor: getColorByKey(value.id),
                    current_sim_ts_unix: Math.ceil(
                      moment(value.current_sim_ts).unix()
                    )
                  });
                }
                return acc;
              }, [])
            : [];

          DEBUG('Vehicles:', objects);

          const cMaxTime = objects.length
            ? objects[objects.length - 1].current_sim_ts_unix
            : 0;

          window.lastSimulationVehicleStates = {
            objects
          };

          if (!state.get('simulationData')) {
            return state
              .setIn(['player', 'time'], cMaxTime)
              .setIn(['player', 'minTime'], cMaxTime)
              .setIn(['player', 'maxTime'], cMaxTime)
              .set('simulationData', objects)
              .set('simulationVehicles', objects);
          }

          return state
            .setIn(['player', 'time'], cMaxTime)
            .setIn(['player', 'minTime'], cMaxTime)
            .setIn(['player', 'maxTime'], cMaxTime)
            .update('simulationData', (simulationData) => {
              const cMinTime = cMaxTime;

              // DEBUG('Min Time:', cMinTime);
              // DEBUG('Max Time:', cMaxTime);

              return [...simulationData, ...objects].reduce((acc, item) => {
                if (
                  item.current_sim_ts_unix >= cMinTime &&
                  item.current_sim_ts_unix <= cMaxTime
                ) {
                  acc.push(item);
                }
                // else {
                //   DEBUG('Discarded:', item);
                // }
                return acc;
              }, []);
            });
        }
      }

      return state;
    }
    case SET_SIMULATION_TIME: {
      REDUX_EVENT_HANDLER(type, payload);

      const { minTime, maxTime } = payload;

      return state
        .setIn(['player', 'time'], maxTime)
        .setIn(['player', 'minTime'], minTime)
        .setIn(['player', 'maxTime'], maxTime);
    }
    case CHANGE_SIMULATION_TIME: {
      REDUX_EVENT_HANDLER(type, payload);

      const time = payload;

      return state.setIn(['player', 'time'], parseInt(time, 10));
    }
    case SIMULATION_PLAY: {
      REDUX_EVENT_HANDLER(type, payload);

      const time = state.get('player').get('time');
      const minTime = state.get('player').get('minTime');
      const maxTime = state.get('player').get('maxTime');
      return state
        .setIn(['player', 'time'], time === maxTime ? minTime : time)
        .setIn(['player', 'running'], true);
    }
    case SIMULATION_STOP: {
      REDUX_EVENT_HANDLER(type, payload);

      return state.setIn(['player', 'running'], false);
    }
    case SET_SIMULATION_SPEED: {
      REDUX_EVENT_HANDLER(type, payload);

      const speed = payload;

      return state.setIn(['player', 'speed'], speed);
    }
    case SIMULATION_NEXT: {
      REDUX_EVENT_HANDLER(type, payload);

      const time = payload;
      const maxTime = state.get('player').get('maxTime');

      return state
        .setIn(['player', 'running'], time < maxTime)
        .setIn(['player', 'time'], time > maxTime ? maxTime : time);
    }
    default:
      return state;
  }
};

export default reducer;
