import React from 'react';
import CheckBox from 'components/CheckBox';

import debug from 'utils/debug';

import Container from './Container';
import RowContainer from './RowContainer';
import Link from './Link';
import Column from './Column';
import LeftColumn from './LeftColumn';
import RightColumn from './RightColumn';
import MenuButton from './MenuButtom';

const { METHOD } = debug('p:CommuteOffers:Table:Body:Row');

const Row = ({ commuteOffer, selectedIds, ...props }) => {
  const isActive = false;
  const { id } = commuteOffer;

  const { deleteCommuteOffer } = props;

  const onSelected = () => {
    METHOD('onSelected', { id });
    props.toggleSelectedCommuteOffers(commuteOffer);
  };

  const onDuplicate = () => {
    METHOD('onDuplicate', { id });
    props.openPopup('duplicateCommuteOffer', {
      title: 'Duplicate a commute offer',
      id,
      commuteOffer
    });
  };

  const onExport = () => {
    METHOD('onExport', { id });
  };

  const onDelete = () => {
    METHOD('onDelete', { id });
    deleteCommuteOffer(id);
  };

  return (
    <Container isActive={isActive}>
      <LeftColumn style={{ paddingLeft: 20 }}>
        <CheckBox
          isActive={selectedIds.includes(id)}
          onClick={() => onSelected(id)}
          style={{ marginRight: 20 }}
        />
      </LeftColumn>
      <RowContainer>
        <Link to={`/commuteoffer/${commuteOffer.id}`}>
          <Column width='70px'>{commuteOffer.id}</Column>
          <Column width='100%'>{commuteOffer.name}</Column>
        </Link>
        <RightColumn>
          <MenuButton
            duplicateCommuteOffer={onDuplicate}
            exportCommuteOffer={onExport}
            deleteCommuteOffer={onDelete}
          />
        </RightColumn>
      </RowContainer>
    </Container>
  );
};

export default Row;
