// @flow

import * as React from 'react';
import { debounce } from 'debounce';

import Form from './Form';
import Input from './Input';

const Filter = (props) => {
  const { changeVehiclesFilter } = props;

  const delayedChangeFilterCriteria = debounce(changeVehiclesFilter, 300);

  const onChange = (event) => {
    event.persist();
    delayedChangeFilterCriteria(event.target.value.toLowerCase());
  };

  return (
    <Form>
      <Input
        type='text'
        onChange={onChange}
        defaultValue={window.GEODISC_DEBUG_DEFAULT_VEHICLES_PANEL_FILTER || ''}
        placeholder='Filter vehicles'
      />
    </Form>
  );
};

export default React.memo(Filter);
