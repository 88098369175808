import styled from 'styled-components';
import plus from './plus.svg';

const Button = styled.div`
  display: block;
  position: fixed;
  bottom: 20px;
  right: 30px;

  width: 45px;
  height: 45px;
  cursor: pointer;
  background: #f9603a url(${plus}) no-repeat center;
  margin-bottom: 5px;
  opacity: 1;
  border-radius: 50%;

  transition: transform 0.3s;

  &:hover {
    transform: rotate(90deg);
  }
`;

export default Button;
