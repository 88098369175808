import {
  call,
  put,
  takeLatest,
  takeEvery,
  select,
  all
} from 'redux-saga/effects';
import { api$CommuteOffer as api } from 'api';

import { fetchData } from 'api/net';
import nearestFeature from 'utils/nearest-feature';
import { point } from '@turf/helpers';
import { ActionCreators } from 'redux-undo';
import moment from 'moment';
import debug from 'utils/debug';
import { diff } from 'json-diff';
import deepmerge from 'deepmerge';

import { commuteSchedule$ImportPassengersSchedule } from 'utils/CommuteSchedule';

import routing from 'config/routing';
import {
  defaultVehicleRoutingEngineJSON,
  normalizeCommuteOffer,
  filterCommuteOfferByVehicle,
  diffCommuteOffers
} from 'utils/CommuteOffer';

import * as actions from './actions';
import * as uiActions from '../ui/actions';

import {
  allVehiclesSelector,
  filteredVehiclesSelector,
  commuteOfferRoutesSelector,
  commuteOfferOriginalDataSelector,
  commuteOfferCurrentDataSelector
} from './selectors';
import { routeSourceSelector } from '../maps/selectors';
import { stopsSelector } from '../entities/selectors';
import {
  currentProjectIdSelector,
  currentProjectSelector,
  projectsSelector
} from '../user/selectors';
import { routePageSelector } from '../router/selectors';
import {
  activeRouteStopSelector,
  activeVehicleIdsSelector,
  addStopModeSelector,
  addPointModeSelector,
  draggablePointSelector,
  routingEngineSelector
} from '../ui/selectors';

const { DEBUG, TRACE, METHOD, COPY } = debug('m:commuteOffer:saga');

function* fetchCommuteOffers() {
  METHOD('fetchCommuteOffers');

  try {
    const page = yield select(routePageSelector);
    const projectId = yield select(currentProjectIdSelector);
    TRACE($ => $('Page:', page));

    const data = yield call(api.getCommuteOffers, projectId);

    yield put({
      type: actions.COMMUTE_OFFERS_FETCH_SUCCESS,
      payload: data
    });
  } catch (error) {
    yield put({ type: actions.COMMUTE_OFFERS_FETCH_FAILURE, payload: error });
  }
}

function* fetchCommuteOffer({ payload }) {
  METHOD('fetchCommuteOffer:Enter', payload);

  try {
    const id = payload;

    yield put(ActionCreators.clearHistory());
    const data = id ? yield call(api.getCommuteOffer, id) : {};

    const result = yield normalizeCommuteOffer(data);

    if (global.GEODISC_DEBUG_FETCH_JSONDIFF_ENABLED) {
      console.log(`[${id}]: Calculating...`); // eslint-disable-line
      console.log(diffCommuteOffers(`---`, data, result)); // eslint-disable-line
      console.log(`[${id}]: Done.`); // eslint-disable-line
    }

    METHOD('fetchCommuteOffer:Success', COPY({ id, data, result }));
    yield put({
      type: actions.COMMUTE_OFFER_FETCH_SUCCESS,
      payload: result
    });
  } catch (error) {
    METHOD('fetchCommuteOffer:Failure', { payload, error });
    yield put({ type: actions.COMMUTE_OFFER_FETCH_FAILURE, payload: error });
  }
}

function* updateCommuteOffer({ payload }) {
  METHOD('updateCommuteOffer', payload);

  try {
    const { id, body } = payload;

    yield put(uiActions.setIsSaving(true));

    const data = yield call(api.updateCommuteOffer, id, body);

    yield put(uiActions.setIsSaving(false));
    yield put(uiActions.setIsChanged(false));
    yield put({
      type: actions.UPDATE_COMMUTE_OFFER_SUCCESS,
      payload: data
    });
  } catch (error) {
    yield put(uiActions.setIsSaving(false));
    yield put({ type: actions.UPDATE_COMMUTE_OFFER_FAILURE, payload: error });
  }
}

function* deleteCommuteOffer({ payload }) {
  METHOD('deleteCommuteOffer', payload);

  try {
    const id = payload;
    yield call(api.deleteCommuteOffer, id);
    yield put({
      type: actions.DELETE_COMMUTE_OFFER_SUCCESS,
      payload: id
    });
    yield put(actions.fetchCommuteOffers());
  } catch (error) {
    yield put({ type: actions.DELETE_COMMUTE_OFFER_FAILURE, payload: error });
  }
}

function* changeRouteStop({ payload }) {
  METHOD('changeRouteStop', payload);

  const vehicleId = payload.agent_id;
  const { id } = yield select(draggablePointSelector);

  if (!payload.newPoint) {
    const { lon, lat } = payload;
    yield put(actions.changeRoutePoint(lon, lat, id, vehicleId));
    return;
  }

  const { node_type } = payload;
  const stop = payload.newPoint;

  const { properties } = stop;
  const { coordinates } = stop.geometry;

  if (node_type === 'stop') {
    const newEmptyStop = {
      stop_id: String(properties.id),
      location_code: properties.code,
      location_name: `${properties.name} #${properties.code}`,
      lon: coordinates[0],
      lat: coordinates[1],
      node_type
    };

    yield put(actions.changeRouteEmptyStop(id, newEmptyStop, vehicleId));
    return;
  }

  const activeStopId = yield select(activeRouteStopSelector);
  const vehicles = yield select(filteredVehiclesSelector);

  const { route } = vehicles.find(item => item.agent_id === vehicleId);
  TRACE($ => $('Route:', route));

  const changedStop = route.find(item => item.id === activeStopId);
  TRACE($ => $('Stop:', changedStop));

  const newNodes = changedStop.bookings.map((booking) => {
    const { node } = booking;
    return {
      ...node,
      stop_id: String(properties.id),
      location_code: properties.code,
      location_name: `${properties.name} #${properties.code}`,
      lon: coordinates[0],
      lat: coordinates[1],
      node_type: node_type || 'pickup'
    };
  });

  TRACE($ => $('New Nodes:', newNodes));
  yield put(actions.setBookingNode(newNodes));
}

function* changeRouteOrder({ payload }) {
  METHOD('changeRouteOrder', payload);

  const { newIndex, vehicleId } = payload;

  const vehicles = yield select(filteredVehiclesSelector);
  const routingEngine = yield select(routingEngineSelector);
  const vehicle = vehicles.find(item => item.agent_id === vehicleId);
  const isActive = yield select(activeRouteStopSelector);

  yield put(actions.fetchRoute(vehicle, routingEngine));
  if (isActive) {
    yield put(uiActions.setActiveRouteStop(newIndex));
  }
}

function* fetchCurrentRoute() {
  METHOD('fetchCurrentRoute');

  const vehicleIds = yield select(activeVehicleIdsSelector);
  const vehicles = yield select(filteredVehiclesSelector);

  const routingEngine = yield select(routingEngineSelector);

  if (routingEngine !== 'euclidean') {
    yield all(
      vehicleIds.map((vehicleId) => {
        const vehicle = vehicles.find(item => item.agent_id === vehicleId);

        if (vehicle && vehicle.route.length >= 2) {
          return put(actions.fetchRoute(vehicle, routingEngine));
        }

        return null;
      })
    );
  }
}

function* addBookingToRoute({ payload }) {
  METHOD('addBookingToRoute', payload);

  yield put(uiActions.setIsChanged(true));

  return fetchCurrentRoute();
}

function* addPointToRoute({ payload }) {
  METHOD('addPointToRoute', payload);

  const { lon, lat } = payload;
  const currentPoint = point([lon, lat]);

  const vehicleId = yield select(addPointModeSelector);
  TRACE($ => $('Vehicle:', vehicleId));

  const segments = yield select(routeSourceSelector);
  const vehicles = yield select(filteredVehiclesSelector);
  const vehicle = vehicles.find(item => item.agent_id === vehicleId);

  const segment = nearestFeature(currentPoint, segments);
  TRACE($ => $('Segment:', segment));

  const { position } = segment.properties;
  const { route } = vehicle;

  yield put(actions.setPointToRoute(lon, lat, vehicleId, route, position + 1));
}

function* addStopToRoute({ payload }) {
  METHOD('addStopToRoute', payload);

  const stopId = payload;
  const vehicleId = yield select(addStopModeSelector);
  const stops = yield select(stopsSelector);
  const vehicles = yield select(filteredVehiclesSelector);
  const vehicle = vehicles.find(item => item.agent_id === vehicleId);
  const { route } = vehicle;

  const stop = stops[stopId];
  yield put(actions.setStopToRoute(stop, vehicleId, route));
}

const getRoute = async (
  routingEngine,
  route,
  opts,
  approaches,
  curb,
  start_time
) => {
  METHOD('getRoute:Request', {
    request: { routingEngine, route, opts, approaches, curb, start_time }
  });

  const { url } = routing[routingEngine];

  const res = await api.getRoute(
    url(route, { approaches, curb, start_time }, opts)
  );
  METHOD('getRoute:Result', {
    request: { routingEngine, route, opts, approaches, curb, start_time },
    result: res
  });
  return res;
};

const routingEngineDrivers = {
  dummy: {
    fetch: async (route, opts) => {
      METHOD('routing:dummy:fetch', { route, opts });

      // eslint-disable-next-line no-async-promise-executor
      return new Promise(async (resolve, reject) => {
        setImmediate(async () => {
          try {
            const data = {
              routes: [
                {
                  duration: 0,
                  geometry: {
                    coordinates: route.map(item => [item.lon, item.lat]),
                    type: 'LineString'
                  },
                  legs: route.map(() => ({ duration: 0 }))
                }
              ]
            };
            resolve(data);
          } catch (error) {
            reject(error);
          }
        });
      });
    }
  },
  mapbox: {
    fetch: async (route, opts) => {
      METHOD('routing:mapbox:fetch', { route, opts });

      return getRoute('mapbox', route, opts, false);
    }
  },
  osrme: {
    fetch: async (route, opts) => {
      METHOD('routing:osrme:fetch:req', { route, opts });

      const curb = typeof opts.curb !== 'undefined' ? opts.curb : true;
      const res = await getRoute(
        'osrme',
        route,
        opts,
        true,
        curb,
        route[0].scheduled_ts
      );
      METHOD('routing:osrme:fetch:res', { route, opts, result: res });
      return res;
    }
  },
  osrm: {
    fetch: async (route, opts) => {
      METHOD('routing:osrm:fetch:req', { route, opts });

      const curb = typeof opts.curb !== 'undefined' ? opts.curb : true;
      const res = await getRoute('osrm', route, opts, true, curb);
      METHOD('routing:osrm:fetch:res', { route, opts, result: res });
      return res;
    }
  },
  here: {
    fetch: async (route, opts) => {
      METHOD('routing:here:fetch', { route, opts });

      const hereUrl = window.GEODISC_HERE_ROUTE_URL;
      const hereAppId = window.GEODISC_HERE_APP_ID;
      const hereAppCode = window.GEODISC_HERE_APP_CODE;
      const hereMode = opts.mode || window.GEODISC_HERE_MODE;

      const coordinates = route
        .map((herePoint, index) => {
          return `waypoint${index}=geo!${herePoint.lat},${herePoint.lon}`;
        }, [])
        .join('&');

      // eslint-disable-next-line
      const url = `${hereUrl}/calculateroute.json?app_id=${hereAppId}&app_code=${hereAppCode}&${coordinates}&mode=${hereMode}`;

      const response = await fetchData(url);
      const responseJSON = await response.json();

      DEBUG('Response:', responseJSON);

      const responseRoute =
        responseJSON.response &&
        responseJSON.response.route &&
        responseJSON.response.route[0];

      const res =
        responseRoute && responseRoute.waypoint
          ? {
              routes: [
                {
                  duration: responseRoute.leg.reduce(
                    (acc, item) => acc + item.travelTime,
                    0
                  ),
                  distance: responseRoute.leg.reduce(
                    (acc, item) => acc + item.length,
                    0
                  ),
                  geometry: {
                    coordinates:
                      responseRoute &&
                      responseRoute.leg.reduce((legAcc, leg) => {
                        const maneuver = leg.maneuver.reduce(
                          (maneuverAcc, maneuverPoint) => {
                            maneuverAcc.push([
                              maneuverPoint.position.longitude,
                              maneuverPoint.position.latitude
                            ]);
                            return maneuverAcc;
                          },
                          []
                        );
                        return [...legAcc, ...maneuver];
                      }, []),
                    type: 'LineString'
                  },
                  legs: responseRoute.leg.map(leg => ({
                    duration: leg.travelTime,
                    distance: leg.length
                  }))
                }
              ]
            }
          : {
              routes: [
                {
                  duration: 0,
                  geometry: {
                    coordinates: route.map(item => [item.lon, item.lat]),
                    type: 'LineString'
                  },
                  legs: route.map(() => ({ duration: 0 }))
                }
              ]
            };

      METHOD('routing:here:fetch:result', {
        route,
        opts,
        responseJSON,
        data: res
      });
      return res;
    }
  },
  asteria: {
    fetch: async (route, opts) => {
      METHOD('routing:asteria:fetch', { route, opts });

      const payload = {
        locations: route.map(item => ({ lon: item.lon, lat: item.lat })),
        departure_delays: route.map(() => 0),
        departure_time: route[0].scheduled_ts
      };

      DEBUG('Request:', payload);

      // const asteriaUrl = opts.url || window.GEODISC_ASTERIA_URL;
      // const asteriaKey = opts.key || window.GEODISC_ASTERIA_TOKEN;
      const asteriaUrl = window.GEODISC_ASTERIA_URL;
      const asteriaKey = window.GEODISC_ASTERIA_TOKEN;
      const asteriaRoutingProfile =
        opts.road_network || window.GEODISC_ASTERIA_ROUTING_PROFILE;

      const url = `${asteriaUrl}/v1/asteria/edgegraph/${asteriaRoutingProfile}/route_through`;

      const response = await fetchData(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          Authorization: `Basic ${asteriaKey}`
        },
        body: JSON.stringify(payload)
      });

      const responseJSON = await response.json();

      DEBUG('Response:', responseJSON);

      return {
        routes: [
          {
            duration: responseJSON.costs[responseJSON.costs.length - 1],
            geometry: {
              coordinates: responseJSON.points.map(item => [
                item.lon,
                item.lat
              ]),
              type: 'LineString'
            },
            legs: responseJSON.distances
              .slice(1)
              .map(value => ({ duration: value }))
          }
        ]
      };
    }
  },
  defined_by_vehicle_settings: {
    fetch: async (route, defaults, opts) => {
      METHOD('routing:defined_by_vehicle_settings:fetch:req', { route, opts });

      const engine = routingEngineDrivers[opts.routing_engine_name];
      const res = await engine.fetch(route, opts);
      METHOD('routing:defined_by_vehicle_settings:fetch:res', {
        route,
        opts,
        result: res
      });
      return res;
    }
  }
};

if (global.GEODISC_HERE_DISABLE) {
  routingEngineDrivers.here = {
    fetch: async (route, opts) => {
      METHOD('routing:dummy:fetch', { route, opts });

      // eslint-disable-next-line no-async-promise-executor
      return new Promise(async (resolve, reject) => {
        setImmediate(async () => {
          try {
            const data = {
              routes: [
                {
                  duration: 0,
                  geometry: {
                    coordinates: route.map(item => [item.lon, item.lat]),
                    type: 'LineString'
                  },
                  legs: route.map(() => ({ duration: 0 }))
                }
              ]
            };
            resolve(data);
          } catch (error) {
            reject(error);
          }
        });
      });
    }
  };
}

function* fetchRoute({ payload }) {
  METHOD('fetchRoute:Request', payload);

  try {
    const { routingEngine, vehicle } = payload;
    const { route, agent_id, routing_engine } = vehicle;

    const engine = routing_engine || {
      routing_engine_name: 'osrme'
    };

    const driver = routingEngineDrivers[routingEngine];

    const data = yield call(driver.fetch, route, {}, engine);

    METHOD('fetchRoute:Success', { payload, data, agent_id });
    yield put({
      type: actions.ROUTE_FETCH_SUCCESS,
      payload: { data, agent_id }
    });
  } catch (error) {
    METHOD('fetchRoute:Failure', { error });
    yield put({ type: actions.ROUTE_FETCH_FAILURE, payload: error });
  }
}

function* fetchAllRoutes() {
  METHOD('fetchAllRoutes');

  const vehicles = yield select(allVehiclesSelector);
  METHOD('fetchAllRoutes:vehicles', { vehicles });
  const routingEngine = yield select(routingEngineSelector);
  METHOD('fetchAllRoutes:routingEngine', { routingEngine });

  if (routingEngine !== 'euclidean') {
    yield all(
      vehicles
        .filter(vehicle => vehicle.route.length >= 2)
        .map((vehicle) => {
          METHOD('fetchAllRoutes:vehicle', { vehicle, routingEngine });
          return put(actions.fetchRoute(vehicle, routingEngine));
        })
    );
  }
}

function* fetchAllWalkingRoutes() {
  DEBUG('fetchAllWalkingRoutes()');

  global.WALKING_ROUTES = {};
  global.WALKING_ROUTES_BY_IDS = {};

  yield;
}

function* recalculateVehicleTime(payload) {
  METHOD('recalculateVehicleTime:Request', payload);

  const routingEngine = yield select(routingEngineSelector);
  if (routingEngine === 'euclidean') {
    METHOD('recalculateVehicleTime:Success', {
      reason: 'Euclidean routing engine',
      payload
    });
    return;
  }

  const routes = yield select(commuteOfferRoutesSelector);

  const vehicleId = payload.agent_id || payload;
  const data = payload.data || routes.get(vehicleId);
  METHOD('recalculateVehicleTime:data', {
    data
  });

  if (!data) {
    return;
  }

  const vehicles = yield select(filteredVehiclesSelector);
  const vehicle = vehicles.find(item => item.agent_id === vehicleId);
  METHOD('recalculateVehicleTime:vehicle', {
    vehicle
  });

  if (!vehicle || vehicle.readonly) {
    return;
  }

  const { route } = vehicle;
  METHOD('recalculateVehicleTime:route', {
    route
  });

  if (!route.length) {
    return;
  }

  const firstPoint = route[0];
  firstPoint.scheduled_ts =
    firstPoint.open_time_ts &&
    firstPoint.open_time_ts !== firstPoint.scheduled_ts
      ? firstPoint.open_time_ts
      : firstPoint.scheduled_ts;

  const startTime = moment(firstPoint.open_time_ts || firstPoint.scheduled_ts);

  const legs = data.routes && data.routes.length && data.routes[0].legs;

  const time = startTime;

  const oldNodes = [].concat(
    ...route.map((routePoint) => {
      return routePoint.bookings.map(item => item.node);
    })
  );

  const calculatedNodes = [].concat(
    ...route.map((routePoint, i) => {
      const service_time = routePoint.service_time || 0;
      const slack = routePoint.slack || 0;
      if (i > 0 && legs && legs[i - 1]) {
        time.add(legs[i - 1].duration + service_time + slack, 's');
      }

      return routePoint.bookings.map((item) => {
        const scheduled_ts = time.tz(global.GEODISC_TIMEZONE).format();
        const calculated_scheduled_ts = item.node.calculated_scheduled_ts
          ? moment(item.node.calculated_scheduled_ts)
              .tz(global.GEODISC_TIMEZONE)
              .format()
          : moment(item.node.scheduled_ts)
              .tz(global.GEODISC_TIMEZONE)
              .format();
        return {
          ...item.node,
          calculated_scheduled_ts:
            calculated_scheduled_ts !== scheduled_ts
              ? calculated_scheduled_ts
              : undefined,
          scheduled_ts
        };
      });
    })
  );

  const lastOriginalNode = oldNodes[oldNodes.length - 1];
  const lastCalculatedNode = calculatedNodes[calculatedNodes.length - 1];

  const routing_engine = vehicle.routing_engine || {};
  const osrme_timestamp_mode =
    routing_engine.osrme_timestamp_mode || 'start_time';

  const timeDiff =
    osrme_timestamp_mode === 'end_time'
      ? moment(lastCalculatedNode.scheduled_ts).diff(
          moment(lastOriginalNode.scheduled_ts)
        )
      : 0;

  const newNodes =
    timeDiff > 0
      ? calculatedNodes.map((node) => {
          const scheduled_ts = moment(node.scheduled_ts)
            .subtract(timeDiff, 'ms')
            .tz(global.GEODISC_TIMEZONE)
            .format();
          return {
            ...node,
            scheduled_ts
          };
        })
      : calculatedNodes;

  const nodesDiff = diff(oldNodes, newNodes);

  METHOD('recalculateVehicleTime:Success', {
    payload,
    vehicle,
    oldNodes,
    newNodes,
    nodesDiff,
    timeDiff
  });
  if (nodesDiff) {
    yield put(actions.setResultVehicles(vehicleId, newNodes));
  }
}

function* setActiveVehicleId({ payload }) {
  METHOD('setActiveVehicleId', payload);

  return yield recalculateVehicleTime(payload);
}

function* routeFetchSuccess({ payload }) {
  METHOD('routeFetchSuccess', payload);

  return yield recalculateVehicleTime(payload);
}

function* deleteVehicle({ payload }) {
  METHOD('deleteVehicle', payload);

  const id = payload;
  yield put(uiActions.cleanActiveVehicleId(id));
}

function* addCommuteOffer({ payload }) {
  METHOD('addCommuteOffer', payload);

  try {
    const { params, data } = payload;
    const { name, project } = params;

    const { id } = yield call(api.addCommuteOffer, {
      ...data,
      name,
      project
    });

    yield put({ type: actions.ADD_COMMUTE_OFFER_SUCCESS, payload: { id } });
    yield put(actions.fetchCommuteOffers());
    yield put(uiActions.closePopup());
  } catch (error) {
    yield put({ type: actions.ADD_COMMUTE_OFFER_FAILURE, payload: error });
  }
}

function* duplicateCommuteOffer({ payload }) {
  METHOD('duplicateCommuteOffer:Request', payload);

  try {
    const { params } = payload;
    const { name } = params;

    const data = yield call(api.getCommuteOffer, payload.id);
    METHOD('duplicateCommuteOffer:Data', { data });

    const { project, result, stateless_api_request_data } = data;

    const { id } = yield call(api.addCommuteOffer, {
      project,
      result,
      stateless_api_request_data,
      name
    });

    METHOD('duplicateCommuteOffer:Success', { id });
    yield put({
      type: actions.DUPLICATE_COMMUTE_OFFER_SUCCESS,
      payload: { id }
    });
    yield put(actions.fetchCommuteOffers());
    yield put(uiActions.closePopup());
  } catch (error) {
    METHOD('duplicateCommuteOffer:Failure', { error });
    yield put({
      type: actions.DUPLICATE_COMMUTE_OFFER_FAILURE,
      payload: error
    });
  }
}

function* incSerialNumber() {
  METHOD('incSerialNumber');

  yield;
}

function* addVehicleRequest({ payload }) {
  METHOD('addVehicleRequest:Request', payload);

  try {
    const { data, values } = payload;

    const newData = { ...data };

    const newVehicle = {
      agent_id: values.id,
      capacity: {
        passenger: parseInt(values.capacity_passengers, 10),
        stop: parseInt(values.capacity_stops, 10),
        wheelchair: parseInt(values.capacity_wheelchairs, 10)
      },
      routing_engine: deepmerge(JSON.parse(defaultVehicleRoutingEngineJSON), {
        routing_engine_name: values.routing_engine_name.value,
        road_network: values.road_network
      }),
      assigned_nodes: [],
      completed_nodes: [],
      geofence_ids: [],
      partial_route: [],
      vehicle_cost: 100000,
      lat: 0.0,
      lon: 0.0
    };

    newData.stateless_api_request_data.vehicles = [
      ...newData.stateless_api_request_data.vehicles,
      newVehicle
    ];

    newData.result.vehicles[values.id] = [];

    METHOD('addVehicleRequest:Success', { newData });
    yield put({
      type: actions.COMMUTE_OFFER_ADD_VEHICLE_SUCCESS,
      payload: newData
    });
  } catch (error) {
    METHOD('addVehicleRequest:Failure', { error });
    yield put({
      type: actions.COMMUTE_OFFER_ADD_VEHICLE_FAILURE,
      payload: error
    });
  }
}

function* editVehicleRequest({ payload }) {
  METHOD('editVehicleRequest', payload);

  try {
    const { data, id, values } = payload;

    const newData = { ...data };

    const otherVehicles = data.stateless_api_request_data.vehicles.filter(
      item => item.agent_id !== id
    );
    METHOD('editVehicleRequest:otherVehicles', otherVehicles);

    const originalVehicle = data.stateless_api_request_data.vehicles.find(
      item => item.agent_id === id
    );
    METHOD('editVehicleRequest:originalVehicle', { originalVehicle, values });

    const { routing_engine } = originalVehicle;

    const newVehicle = {
      ...originalVehicle,
      agent_id: values.id,
      capacity: {
        passenger: parseInt(values.capacity_passengers, 10),
        stop: parseInt(values.capacity_stops, 10),
        wheelchair: parseInt(values.capacity_wheelchairs, 10)
      },
      routing_engine: {
        ...routing_engine,
        routing_engine_name: values.routing_engine_name.value,
        road_network: values.road_network
      },
      vehicle_color: parseInt(values.vehicle_color, 10)
    };
    METHOD('editVehicleRequest:newVehicle', { originalVehicle, newVehicle });

    newData.stateless_api_request_data.vehicles = [
      ...otherVehicles,
      newVehicle
    ];
    METHOD('editVehicleRequest:Result', newData);

    const newOffer = yield normalizeCommuteOffer(newData);

    yield put({
      type: actions.COMMUTE_OFFER_EDIT_VEHICLE_SUCCESS,
      payload: newOffer
    });
    yield put({
      type: actions.SET_VEHICLE_START_TIME,
      payload: {
        vehicleId: newVehicle.agent_id,
        time: moment(values.start_time)
          .tz(global.GEODISC_TIMEZONE)
          .format()
      }
    });
    yield put({
      type: actions.RECALCULATE_VEHICLE_TIME,
      payload: newVehicle.agent_id
    });
    // RECALCULATE_VEHICLE_TIME
  } catch (error) {
    yield put({
      type: actions.COMMUTE_OFFER_EDIT_VEHICLE_FAILURE,
      payload: error
    });
  }
}

function* showVehicleSource({ payload }) {
  METHOD('showVehicleSource:Request', payload);

  const id = payload;

  const currentOffer = filterCommuteOfferByVehicle(
    yield select(commuteOfferCurrentDataSelector),
    id
  );

  const { stateless_api_request_data, result } = currentOffer;

  global.openSourceEditor(
    {
      stateless_api_request_data,
      result
    },
    { title: `Vehicle ${id}`, readOnly: true }
  );
}

function* showVehicleUpdates({ payload }) {
  METHOD('showVehicleUpdates:Enter', payload);

  try {
    const id = payload;

    console.log(`${id || '---'} Calculating vehicle updates...`); // eslint-disable-line

    const originalOffer = filterCommuteOfferByVehicle(
      yield select(commuteOfferOriginalDataSelector),
      id
    );
    const currentOffer = filterCommuteOfferByVehicle(
      yield select(commuteOfferCurrentDataSelector),
      id
    );

    METHOD('showVehicleUpdates:Offers', {
      originalOffer,
      currentOffer
    });

    global.openDiffEditor(originalOffer, currentOffer, {
      title: 'Vehicle updates'
    });

    console.log(diffCommuteOffers(id || '---', originalOffer, currentOffer)); // eslint-disable-line
    console.log(`${id || '---'} Done.`); // eslint-disable-line
  } catch (e) {
    console.log(e); // eslint-disable-line
  }
}

function sleep(ms) {
  return new Promise(resolve => setTimeout(resolve, ms));
}

function* statelessNodeScheduler({ payload }) {
  METHOD('statelessNodeScheduler:Enter', payload);

  const { request, opts } = payload;

  try {
    // eslint-disable-next-line
    console.log({ request });
    METHOD('statelessNodeScheduler:Request', { request });
    const job = yield api.statelessNodeScheduler(request);

    // eslint-disable-next-line
    console.log({ job });
    METHOD('statelessNodeScheduler:Job', { job });

    if (!job.job_id) {
      throw new Error('Failed to get job_id');
    }

    // eslint-disable-next-line no-constant-condition
    for (let attempt = 1; attempt <= 360; ++attempt) {
      const serverResponse = yield api.jobResult(job.job_id);
      // eslint-disable-next-line
      console.log({ serverResponse });

      if (serverResponse.status && serverResponse.status === 'SUCCESS') {
        if (serverResponse.result && serverResponse.result.error) {
          throw new Error(serverResponse.result.error);
        }

        METHOD('statelessNodeScheduler:Response', { serverResponse });
        const validatedResult = yield normalizeCommuteOffer({
          result: serverResponse.result
        });

        METHOD('statelessNodeScheduler:Success', {
          serverResponse,
          validatedResult
        });
        yield opts.closePopup();
        yield put({
          type: actions.COMMUTE_OFFER_STATELESS_NODE_SCHEDULER_SUCCESS,
          payload: validatedResult.result
        });

        yield fetchAllRoutes();
        yield fetchAllWalkingRoutes();

        yield sleep(500);
        return;
      }

      // eslint-disable-next-line no-await-in-loop
      yield sleep(1000);
    }
  } catch (error) {
    METHOD('statelessNodeScheduler:Error', { error });
    yield opts.closePopup();
    yield put({
      type: actions.COMMUTE_OFFER_STATELESS_NODE_SCHEDULER_FAILURE,
      payload: error
    });
    yield sleep(500);
  }
}

function* importBookings({ payload }) {
  METHOD('importBookings:Request', payload);

  try {
    const { currentOffer, data, params, opts } = payload;

    const projects = yield select(projectsSelector);
    const currentProject = yield select(currentProjectSelector);

    const newOffer = yield commuteSchedule$ImportPassengersSchedule(
      currentOffer,
      data,
      params,
      { ...opts, currentProject, projects }
    );

    METHOD('importBookings:Success');
    yield put({
      type: actions.COMMUTE_OFFER_IMPORT_BOOKINGS_SUCCESS,
      payload: { newOffer }
    });

    yield fetchAllRoutes();
    yield fetchAllWalkingRoutes();

    yield sleep(1);
  } catch (error) {
    METHOD('importBookings:Failure', { error });
    yield put({
      type: actions.COMMUTE_OFFER_IMPORT_BOOKINGS_FAILURE,
      payload: error
    });
    throw error;
  }
}

function* Saga() {
  yield takeLatest(actions.COMMUTE_OFFER_FETCH_REQUEST, fetchCommuteOffer);
  yield takeLatest(actions.COMMUTE_OFFERS_FETCH_REQUEST, fetchCommuteOffers);
  yield takeLatest(actions.UPDATE_COMMUTE_OFFER_REQUEST, updateCommuteOffer);
  yield takeLatest(actions.DELETE_COMMUTE_OFFER_REQUEST, deleteCommuteOffer);
  yield takeEvery(actions.ROUTE_FETCH_REQUEST, fetchRoute);
  yield takeEvery(actions.ROUTE_FETCH_SUCCESS, routeFetchSuccess);
  yield takeEvery(uiActions.SET_ACTIVE_VEHICLE_ID, setActiveVehicleId);
  yield takeLatest(uiActions.SET_DRAGGABLE_POINT, changeRouteStop);
  yield takeLatest(actions.CHANGE_ROUTE_ORDER, changeRouteOrder);
  yield takeLatest(actions.SET_BOOKING_NODE, fetchCurrentRoute);
  yield takeLatest(actions.REMOVE_BOOKING_FROM_ROUTE, fetchCurrentRoute);
  yield takeLatest(actions.ADD_BOOKING_TO_ROUTE, addBookingToRoute);
  yield takeLatest(actions.ADD_POINT_TO_ROUTE, addPointToRoute);
  yield takeLatest(actions.ADD_STOP_TO_ROUTE, addStopToRoute);
  yield takeLatest(actions.RECALCULATE_VEHICLE_TIME, fetchCurrentRoute);
  yield takeLatest(actions.SET_POINT_TO_ROUTE, fetchCurrentRoute);
  yield takeLatest(actions.SET_STOP_TO_ROUTE, fetchCurrentRoute);
  yield takeLatest(actions.CHANGE_ROUTE_POINT, fetchCurrentRoute);
  yield takeLatest(actions.DELETE_ROUTE_POINT, fetchCurrentRoute);
  yield takeLatest(actions.CHANGE_ROUTE_EMPTY_STOP, fetchCurrentRoute);
  yield takeLatest(uiActions.TOGGLE_ROUTING_ENGINE, fetchAllRoutes);
  yield takeLatest(
    uiActions.TOGGLE_WALKING_ROUTING_ENGINE,
    fetchAllWalkingRoutes
  );
  yield takeLatest(actions.COMMUTE_OFFER_FETCH_SUCCESS, fetchAllRoutes);
  yield takeLatest(actions.DELETE_VEHICLE, deleteVehicle);
  yield takeLatest(actions.ADD_COMMUTE_OFFER_REQUEST, addCommuteOffer);
  yield takeLatest(
    actions.DUPLICATE_COMMUTE_OFFER_REQUEST,
    duplicateCommuteOffer
  );
  yield takeLatest(
    actions.COMMUTE_OFFER_ADD_VEHICLE_REQUEST,
    addVehicleRequest
  );
  yield takeLatest(
    actions.COMMUTE_OFFER_EDIT_VEHICLE_REQUEST,
    editVehicleRequest
  );
  yield takeLatest(actions.INC_SERIAL_NUMBER, incSerialNumber);
  yield takeLatest(
    actions.COMMUTE_OFFER_SHOW_VEHICLE_UPDATES,
    showVehicleUpdates
  );
  yield takeLatest(
    actions.COMMUTE_OFFER_SHOW_VEHICLE_SOURCE,
    showVehicleSource
  );
  yield takeLatest(
    actions.COMMUTE_OFFER_STATELESS_NODE_SCHEDULER_REQUEST,
    statelessNodeScheduler
  );
  yield takeLatest(
    actions.COMMUTE_OFFER_IMPORT_BOOKINGS_REQUEST,
    importBookings
  );
}

export default Saga;
