import React from 'react';
import Container from './Container';
import Button from './Button';

const config = {
  fromdataset: 'Create from dataset',
  file: 'Choose file',
  draw: 'Draw'
};

const Menu = ({ active, onChange }) => {
  const keys = Object.keys(config);

  return (
    <Container>
      {keys.map((key) => {
        const onClick = () => {
          onChange(key);
        };

        return (
          <Button key={key} isActive={active === key} onClick={onClick}>
            {config[key]}
          </Button>
        );
      })}
    </Container>
  );
};

export default Menu;
