export const status = {
  created: {
    color: '#ffeb00',
    text: 'Created'
  },
  running: {
    color: '#8181ff',
    text: 'Running'
  },
  failed: {
    color: '#ff7676',
    text: 'Failed'
  },
  queued: {
    color: '#ff8f00',
    text: 'Queued'
  },
  deleted: {
    color: '#ffc44e',
    text: 'Deleted'
  },
  completed: {
    color: '#26e898',
    text: 'Completed'
  }
};

export const getStatusText = value =>
  status[value] || {
    color: '#ffc44e',
    text: value
  };

export const metrics = [
  {
    id: 'acceptable_waiting_time',
    label: 'Max Waiting',
    getValue: value => value && Math.round(value / 60)
  },
  {
    id: 'avg_journey_time_difference',
    label: 'Journey Time Diff Avg',
    getValue: value => value && Math.round(value / 60)
  },
  {
    id: 'avg_planned_actual_journey_time_difference',
    label: 'Journey Time Deviation Avg',
    getValue: value => value && Math.round(value / 60)
  },
  {
    id: 'avg_waiting_time',
    label: 'Avg Waiting Time',
    getValue: value => value && Math.round(value / 60)
  },
  {
    id: 'avg_waiting_time_difference',
    label: 'Waiting Time Diff Avg',
    getValue: value => value && Math.round(value / 60)
  },
  {
    id: 'bookings_count',
    label: 'Bookings',
    getValue: value => value
  },
  {
    id: 'completed_bookings_count',
    label: 'Completed booking count',
    getValue: value => value
  },
  {
    id: 'completed_fixed_route_trips_count',
    label: 'Bookings by Fixed',
    getValue: value => value
  },
  {
    id: 'completed_odbs_trips_count',
    label: 'Bookings Completed',
    getValue: value => value
  },
  {
    id: 'conversion_rate',
    label: 'Conversion Rate',
    getValue: value => value && value.toFixed(1)
  },
  {
    id: 'created_at',
    label: 'Created At',
    getValue: (value) => {
      if (!value) {
        return null;
      }

      const date = new Date(value);
      return date.toUTCString();
    }
  },
  {
    id: 'dead_mileage',
    label: 'Mileage Dead',
    getValue: value => value && Math.round(value / 1000)
  },
  {
    id: 'driver_prep_time',
    label: 'Driver Preparation Time',
    getValue: value => value && Math.round(value / 60)
  },
  {
    id: 'start_time',
    label: 'Start Time',
    getValue: (value) => {
      if (!value) {
        return null;
      }

      const date = new Date(value);
      return date.toUTCString();
    }
  },
  {
    id: 'end_time',
    label: 'End Time',
    getValue: (value) => {
      if (!value) {
        return null;
      }

      const date = new Date(value);
      return date.toUTCString();
    }
  },
  {
    id: 'in_service_mileage',
    label: 'Mileage',
    getValue: value => value && Math.round(value / 1000)
  },
  {
    id: 'journey_duration_source',
    label: 'Journey Duration Source',
    getValue: value => String(value && Math.round(value / 60))
  },
  {
    id: 'max_additional_journey_time',
    label: 'Max Add Time',
    getValue: value => value && Math.round(value / 60)
  },
  {
    id: 'max_advance_booking_window',
    label: 'Max Booking Advance',
    getValue: value => value
  },
  {
    id: 'write_events_log',
    label: 'Record data for playback',
    getValue: value => (value ? 'Yes' : 'No')
  },
  {
    id: 'max_journey_time_difference',
    label: 'Journey Time Diff Max',
    getValue: value => value && Math.round(value / 60)
  },
  {
    id: 'max_planned_actual_journey_time_difference',
    label: 'Journey Time Deviation Max',
    getValue: value => value && Math.round(value / 60)
  },
  {
    id: 'max_waiting_time',
    label: 'Max Waiting Time',
    getValue: value => value && Math.round(value / 60)
  },
  {
    id: 'max_waiting_time_difference',
    label: 'Waiting Time Diff Max',
    getValue: value => value && Math.round(value / 60)
  },
  {
    id: 'max_walking_distance',
    label: 'Max Walking',
    getValue: value => value && Math.round(value / 1000)
  },
  {
    id: 'min_advance_booking_window',
    label: 'Min Booking Advance',
    getValue: value => value && Math.round(value / 60)
  },
  {
    id: 'min_driver_rest_time',
    label: 'Rest Time s.',
    getValue: value => value && Math.round(value / 60)
  },
  {
    id: 'min_journey_time_difference',
    label: 'Journey Time Diff Min',
    getValue: value => value && Math.round(value / 60)
  },
  {
    id: 'min_planned_actual_journey_time_difference',
    label: 'Journey Time Deviation Min',
    getValue: value => value && Math.round(value / 60)
  },
  {
    id: 'min_waiting_time',
    label: 'Min Waiting Time',
    getValue: value => value && Math.round(value / 60)
  },
  {
    id: 'min_waiting_time_difference',
    label: 'Waiting Time Diff Min',
    getValue: value => value && Math.round(value / 60)
  },
  {
    id: 'no_offer_count',
    label: 'No Offers Count',
    getValue: value => value
  },
  {
    id: 'number_of_vehicles',
    label: 'Vehicles Number',
    getValue: value => value
  },
  {
    id: 'odbs_trip_duration',
    label: 'Max ODBS Trip Time',
    getValue: value => value && Math.round(value / 60)
  },
  {
    id: 'odbs_trips_per_vehicle_per_hour',
    label: 'Vehicle Efficiency',
    getValue: value => value && value.toFixed(1)
  },
  {
    id: 'percentage_driver_rest_time',
    label: 'Rest Time %',
    getValue: value => value && value.toFixed(1)
  },
  {
    id: 'revenue',
    label: 'Revenue',
    getValue: value => value
  },
  {
    id: 'total_cost',
    label: 'Total Cost',
    getValue: value => value
  },
  {
    id: 'transfer_rate',
    label: 'Transfer Rate',
    getValue: value => value && value.toFixed(1)
  },
  {
    id: 'used_vehicles_count',
    label: 'Vehicles Used',
    getValue: value => value
  },
  {
    id: 'utilization_rate',
    label: 'Utilization Rate',
    getValue: value => value && value.toFixed(1)
  },
  {
    id: 'vehicle_capacity',
    label: 'Vehicle Capacity',
    getValue: value => value
  }
];

export const geofenceLayers = [
  {
    id: 'geofence_fill',
    type: 'fill',
    source: 'geofence',
    paint: {
      'fill-color': '#9583fc',
      'fill-opacity': 0.1
    }
  },
  {
    id: 'geofence_line',
    type: 'line',
    source: 'geofence',
    paint: {
      'line-width': [
        'interpolate',
        ['linear'],
        ['zoom'],
        10,
        6,
        12,
        6.4,
        15,
        7
      ],
      'line-color': '#9583fc',
      'line-blur': 4
    }
  },
  {
    id: 'geofence_line_dasharray',
    type: 'line',
    source: 'geofence',
    paint: {
      'line-width': [
        'interpolate',
        ['linear'],
        ['zoom'],
        10,
        1,
        12,
        1.4,
        15,
        2
      ],
      'line-dasharray': [1, 1],
      'line-color': '#ffffff'
    }
  }
];

export default {
  status,
  metrics
};
