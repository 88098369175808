import { connect } from 'react-redux';
import createImmutableSelector from 'create-immutable-selector';

import { setEditableDataset, updateEditableDataset } from 'modules/ui/actions';
import { saveDataset } from 'modules/datasets/actions';
import {
  datasetErrorSelector,
  editableDatasetSelector
} from 'modules/ui/selectors';
import { datasetSelector } from 'modules/datasets/selectors';

import Header from './Header';

const mapStateToProps = createImmutableSelector(
  datasetSelector,
  editableDatasetSelector,
  datasetErrorSelector,
  (dataset, editableDataset, error) => ({ dataset, editableDataset, error })
);

const mapDispatchToProps = {
  setEditableDataset,
  updateEditableDataset,
  saveDataset
};

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps
);

export default withConnect(Header);
