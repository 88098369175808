import React from 'react';
import { Redirect } from 'react-router';
import PropTypes from 'prop-types';
import { Route, Switch } from 'react-router-dom';

import { Client as Styletron } from 'styletron-engine-atomic';
import { Provider as StyletronProvider } from 'styletron-react';
import {
  LightTheme,
  DarkTheme,
  BaseProvider,
  ThemeProvider,
  styled
} from 'baseui';
import { StatefulInput } from 'baseui/input';

import Login from 'pages/Login';

import ModalWindows from 'components/ModalWindows';
import debug from 'utils/debug';
import MapDataset from '../MapDataset';
import MapSimulation from '../MapSimulation';
import MapGeofences from '../MapGeofences';

import routes from './routes';


const { FUNCTION } = debug('c:App');

const renderRoute = ({
  path,
  exact,
  strict,
  component: Component,
  ...rest
}) => (
  <Route
    key={path}
    path={path}
    exact={exact}
    strict={strict}
    render={props => <Component {...props} {...rest} />}
  />
);

const engine = new Styletron();

const Centered = styled('div', {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: '100%'
});

const MyDarkTheme = {
  ...DarkTheme,
  colors: {
    ...DarkTheme.colors,
    menuFill: 'rgb(43, 48, 58)',
    menuFillHover: 'rgb(54, 61, 74)'
  }
};

const App = ({ isAuthorized }) =>
  FUNCTION(
    'App',
    { isAuthorized, engine, MyDarkTheme, DarkTheme, LightTheme },
    () => (
      <StyletronProvider value={engine}>
        <BaseProvider theme={MyDarkTheme}>
          {isAuthorized ? (
            [
              <Switch key='pages'>{routes.map(renderRoute)}</Switch>,
              <Route
                key='DatasetList'
                path={['/datasets', '/datasets/:page']}
                exact
                component={() => null}
              />,
              <Route
                key='MapDataset'
                path={['/dataset/:id']}
                exact
                component={MapDataset}
              />,
              <Route
                key='SimulationList'
                path={['/simulations', '/simulations/:page']}
                exact
                component={() => null}
              />,
              <Route
                key='MapSimulation'
                path={['/simulation/:id']}
                exact
                component={MapSimulation}
              />,
              <Route
                key='CommuteOffer'
                path={[
                  '/commuteoffers',
                  '/commuteoffers/:page',
                  '/commuteoffer/:id'
                ]}
                exact
                component={() => null}
              />,
              <Route
                key='MapGeofences'
                path='/geofences'
                exact
                component={MapGeofences}
              />
            ]
          ) : (
            <Switch>
              <Route path='/login' component={Login} />
              <Route
                path='*'
                render={() => (
                  <Redirect to={`/login?next=${window.location.pathname}`} />
                )}
              />
            </Switch>
          )}
          <Centered>
            <StatefulInput />
          </Centered>
          <ThemeProvider theme={LightTheme}>
            <ModalWindows />
          </ThemeProvider>
        </BaseProvider>
      </StyletronProvider>
    )
  );

export default App;

Route.propTypes = {
  computedMatch: PropTypes.object,
  path: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
  exact: PropTypes.bool,
  strict: PropTypes.bool,
  sensitive: PropTypes.bool,
  component: PropTypes.func,
  render: PropTypes.func,
  children: PropTypes.oneOfType([PropTypes.func, PropTypes.node]),
  location: PropTypes.object
};
