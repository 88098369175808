import React, { PureComponent } from 'react';
import { Formik } from 'formik';

import debug from 'utils/debug';
import Input from 'components/Input';

import SubmitButton from 'components/SubmitButton';

import Row from './Row';
import ButtonsRow from './ButtonsRow';

const { METHOD } = debug('c:ModalWindow:DuplicateCommuteOffer');

class DuplicateCommuteOffer extends PureComponent {
  state = {
    waiting: false
  };

  handleKeyDown = (event) => {
    if (event.keyCode === 13) {
      event.preventDefault();
      return false;
    }
    return true;
  };

  onSubmit = (values) => {
    METHOD('onSubmit', { values });

    const { duplicateCommuteOffer } = this.props;
    this.setState({ waiting: true });

    duplicateCommuteOffer(this.props.id, values);
  };

  render() {
    METHOD('render');

    return (
      <Formik
        initialValues={{
          name: ''
        }}
        onSubmit={this.onSubmit}
      >
        {(formikProps) => {
          const {
            values,
            handleChange,
            handleSubmit,
            handleBlur
          } = formikProps;

          const { waiting } = this.state;

          return (
            <form onSubmit={handleSubmit} autoComplete='off'>
              <Row>
                <Input
                  name='name'
                  type='text'
                  label='Name'
                  value={values.name}
                  onKeyDown={this.handleKeyDown}
                  onChange={handleChange}
                  handleBlur={handleBlur}
                  autoComplete='off'
                  required
                />
              </Row>
              <ButtonsRow>
                <SubmitButton
                  type='submit'
                  disabled={waiting}
                  style={{ width: 320 }}
                >
                  {waiting ? 'Duplicating...' : 'Duplicate'}
                </SubmitButton>
              </ButtonsRow>
            </form>
          );
        }}
      </Formik>
    );
  }
}

export default DuplicateCommuteOffer;
