import debug from 'utils/debug';
import faker from 'faker';
import md5 from 'blueimp-md5';
import moment from 'moment';
import { crc32 } from 'utils/crc32';

import { csvToMap } from 'utils/csv';

import weekdays from 'utils/weekdays';

const { METHOD } = debug('utils:commuteOffer:silveray');

export const silverayScheduleInboundColumns = new Map([
  ['X', 'lon'],
  ['Y', 'lat'],
  ['Timestamp', 'timestamp'],
  ['RESIDENT', 'name'],
  ['NO', 'id'],
  ['No', 'id'],
  ['N', 'id'],
  ['Calculation group', 'calculation_group'],
  ['Group', 'calculation_group'],
  ['Mutually exclusive group', 'mutually_exclusive_group'],
  ['Bus', 'mutually_exclusive_group'],
  ['Special demand', 'special_demand'],
  ['Name of Resident', 'name'],
  ['M-AID', 'wheelchair'],
  ['Is resident on wheelchair?', 'wheelchair'],
  ['ADRESS', 'address'],
  [
    'Home address of resident for pick up and drop off. (Include unit number if door to door service is required)', // eslint-disable-line
    'address'
  ],
  ['POST CODE', 'zip_code'],
  ['Postal code', 'zip_code'],
  ['Going to the Eldercare center at', 'morning_dropoff_ts'],
  [
    'Earliest possible pick up time from home (OPTIONAL)',
    'morning_pickup_from_ts'
  ],
  ['Earliest possible pick up time from home', 'morning_pickup_from_ts'],
  [
    'Latest possible pick up time from home (OPTIONAL)',
    'morning_pickup_till_ts'
  ],
  ['Latest possible pick up time from home', 'morning_pickup_till_ts'],
  ['Leaving the Eldercare center at', 'evening_pickup_ts'],
  [
    'Earliest possible drop off time at home (OPTIONAL)',
    'evening_dropoff_from_ts'
  ],
  ['Earliest possible drop off time at home', 'evening_dropoff_from_ts'],
  [
    'Latest possible drop off time at home (OPTIONAL)',
    'evening_dropoff_till_ts'
  ],
  ['Latest possible drop off time at home', 'evening_dropoff_till_ts'],
  ['Country', 'country'],
  ['City', 'city'],
  ['Monday', 'mon'],
  ['Tuesday', 'tue'],
  ['Wednesday', 'wed'],
  ['Thursday', 'thu'],
  ['Friday', 'fri'],
  ['Saturday', 'sat'],
  ['Sunday', 'sun'],
  ['Mon', 'mon'],
  ['Tue', 'tue'],
  ['Wed', 'wed'],
  ['Thu', 'thu'],
  ['Thur', 'thu'],
  ['Fri', 'fri'],
  ['Sat', 'sat'],
  ['Sun', 'sun'],
  ['GENDER', 'gender'],
  ['Gender of Resident', 'gender'],
  ['Type of service', 'destination'],
  ['Door to door transport service required?', 'type_of_service'],
  [
    'Next-of-Kin name, relationship and contact number (Press enter to fill in multiple contacts on the next line)', // eslint-disable-line
    'contacts'
  ],
  ['REMARKS', 'remarks'],
  ['Any other remarks', 'remarks'],
  ['Request submitted by', 'submitted_by'],
  ['Rehab session', 'rehab_session']
]);

export const silverayDestinationTimes = {
  H2C: new Set(['08:30 AM', '09:30 AM', '10:30 AM', '11:30 AM', '12:30 PM']),
  C2H: new Set(['12:30 PM', '01:30 PM', '02:30 PM', '03:30 PM', '04:30 PM'])
};

export const silverayPossibleDestinationTimes = {
  H2C: [...silverayDestinationTimes.H2C.keys()].map(x => `"${x}"`).join(', '),
  C2H: [...silverayDestinationTimes.C2H.keys()].map(x => `"${x}"`).join(', ')
};

const silverayVanguardBuilding = {
  lon: 103.80458840692,
  lat: 1.44613248896987,
  nearestPoint: {
    annotate_distance_point: '0.4234625 m',
    camera_heading: null,
    camera_lat: null,
    camera_lon: null,
    camera_pitch: null,
    code: 'BLD267043',
    created_at: '2019-11-27T07:06:28.648000+00:00',
    extended_name: 'woodlands care home',
    extended_street: '2 woodlands rise woodlands care home singapore 737749',
    id: 349421,
    lat: 1.44612881154562,
    lon: 103.804589469079,
    name: 'WOODLANDS CARE HOME',
    name_translations: {},
    point: {
      coordinates: [103.804589469079, 1.44612881154562],
      type: 'Point'
    },
    resource_uri: '/api/v2/transitstop/349421',
    stop_type: 'building',
    street: '2 WOODLANDS RISE WOODLANDS CARE HOME SINGAPORE 737749',
    updated_at: '2019-11-27T07:06:28.648000+00:00',
    verified: false
  }
};

export const silverayDestinations = new Map([
  ['Vanguard', silverayVanguardBuilding],
  ['Maintenance', silverayVanguardBuilding],
  ['Maintenance day care', silverayVanguardBuilding],
  ['Dementia', silverayVanguardBuilding],
  ['Dementia day care', silverayVanguardBuilding],
  ['Enhanced dementia', silverayVanguardBuilding],
  ['Enhanced dementia day care', silverayVanguardBuilding],
  ['Rehab session', silverayVanguardBuilding]
]);

export const silverayGenders = new Map([
  ['Male', 'M'],
  ['M', 'M'],
  ['Female', 'F'],
  ['F', 'F']
]);

export const silverayVehicleCost = 100000;

export const silverayVehicleTemplate = {
  agent_id: null,
  assigned_nodes: [],
  capacity: {
    passenger: 10,
    stop: 100,
    wheelchair: 2
  },
  completed_nodes: [],
  geofence_ids: [],
  lat: 1.3600612,
  lon: 103.991377,
  max_physical_stops: null,
  partial_route: [],
  routing_engine: {
    url: 'https://mapbox-osrm-proxy.d.gcdev.swatrider.com',
    key: 'dmVyeSBzZWNyZXQga2V5',
    make_depot_zero: true,
    road_network: 'alldriving',
    routing_engine_name: 'osrme',
    speed: null,
    time_factor: 1,
    use_speed_in_routing: false
  },
  vehicle_cost: silverayVehicleCost
};

export const silverayVehicleTemplateJSON = JSON.stringify(
  silverayVehicleTemplate
);

export const silverayDayKeys = {
  0: 'sun',
  1: 'mon',
  2: 'tue',
  3: 'wed',
  4: 'thu',
  5: 'fri',
  6: 'sat'
};

export const silverayCsvToJson = async (data) => {
  METHOD('silverayNormalizeData:Request', { data });
  try {
    const jsonData = await csvToMap(data, {
      columns: silverayScheduleInboundColumns
    });
    METHOD('silverayNormalizeData:Success', jsonData);
    return jsonData;
  } catch (error) {
    METHOD('silverayNormalizeData:Failure', { error });
    throw new Error('Failed to parse CSV file');
  }
};

const parseSilverayTime = (value, timezone) => moment.tz(value, 'LT', timezone);

const formatSilverayTime = value => value.format('hh:mm A');

export const normalizeSilverayTime = (value, timezone) =>
  formatSilverayTime(parseSilverayTime(value, timezone));

export const silverayNormalizeData = async (jsonData, opts) => {
  METHOD('silverayNormalizeData:Request', { jsonData, opts });

  const options = opts || {};

  const ignoreErrors = options.ignoreErrors || false;
  const timezone = options.timezone || 'Singapore';
  const serviceTime = options.serviceTime || 30;

  const normalizedData = jsonData.map((item) => {
    const $errors = [];
    const destination = item.destination || 'Vanguard';
    const $destination_building = silverayDestinations.has(destination)
      ? silverayDestinations.get(destination)
      : undefined;
    if (!$destination_building) {
      $errors.push(`Unknown destination: ${destination}`);
    }
    const wheelchair =
      item.wheelchair &&
      (item.wheelchair === 'Yes' || item.wheelchair[0] === 'W');
    const gender =
      item.gender &&
      (silverayGenders.has(item.gender)
        ? silverayGenders.get(item.gender)
        : undefined);
    const weekdaysRecords = Object.keys(weekdays).reduce((acc, weekday) => {
      acc[weekday] = !!item[weekday];
      return acc;
    }, {});
    const name = item.name && item.name.toString().trim();
    if (!name) {
      $errors.push('Name cannot be empty');
    }
    const zip_code = item.zip_code && item.zip_code.toString().trim();
    const lon = item.lon && parseFloat(item.lon.toString().trim());
    const lat = item.lon && parseFloat(item.lat.toString().trim());
    if ((!lon || !lat) && !zip_code) {
      $errors.push(`${name}: Either zip code or coordinates must be specified`);
    }
    const country = item.country ? item.country.toString().trim() : 'Singapore';
    const city = item.city ? item.city.toString().trim() : 'Singapore';
    const calculation_group = item.calculation_group
      ? item.calculation_group.toString().trim()
      : 'None';
    const mutually_exclusive_group =
      item.mutually_exclusive_group &&
      item.mutually_exclusive_group.toString().trim();
    const special_demand =
      item.special_demand && item.special_demand.toString().trim();
    const service_time = item.service_time
      ? parseInt(item.service_time.toString().trim(), 10)
      : serviceTime;

    const morning_dropoff_ts = normalizeSilverayTime(
      item.morning_dropoff_ts || '8:30 AM',
      timezone
    );
    if (!silverayDestinationTimes.H2C.has(morning_dropoff_ts)) {
      $errors.push(
        [
          `${name}: "${morning_dropoff_ts}" is not allowed dropoff time`,
          `Possible values are ${silverayPossibleDestinationTimes.H2C}`
        ].join('. ')
      );
    }

    const morning_pickup_from_ts =
      item.morning_pickup_from_ts &&
      normalizeSilverayTime(item.morning_pickup_from_ts, timezone);
    if (
      morning_pickup_from_ts &&
      !parseSilverayTime(morning_pickup_from_ts, timezone).isBefore(
        parseSilverayTime(morning_dropoff_ts, timezone)
      )
    ) {
      $errors.push(
        // eslint-disable-next-line max-len
        `${name}: morning_pickup_from_ts (${morning_pickup_from_ts}) is not before morning_dropoff_ts (${morning_dropoff_ts})`
      );
    }

    const morning_pickup_till_ts =
      item.morning_pickup_till_ts &&
      normalizeSilverayTime(item.morning_pickup_till_ts, timezone);
    if (
      morning_pickup_till_ts &&
      !parseSilverayTime(morning_pickup_till_ts, timezone).isBefore(
        parseSilverayTime(morning_dropoff_ts, timezone)
      )
    ) {
      $errors.push(
        // eslint-disable-next-line max-len
        `${name}: morning_pickup_till_ts (${morning_pickup_till_ts}) is not before morning_dropoff_ts (${morning_dropoff_ts})`
      );
    }

    const evening_pickup_ts = normalizeSilverayTime(
      item.evening_pickup_ts || '04:30 PM',
      timezone
    );
    if (!silverayDestinationTimes.C2H.has(evening_pickup_ts)) {
      $errors.push(
        [
          `${name}: "${evening_pickup_ts}" is not allowed pickup time`,
          `Possible values are ${silverayPossibleDestinationTimes.C2H}`
        ].join('. ')
      );
    }

    const evening_dropoff_from_ts =
      item.evening_dropoff_from_ts &&
      normalizeSilverayTime(item.evening_dropoff_from_ts, timezone);
    if (
      evening_dropoff_from_ts &&
      !parseSilverayTime(evening_pickup_ts, timezone).isBefore(
        parseSilverayTime(evening_dropoff_from_ts, timezone)
      )
    ) {
      $errors.push(
        // eslint-disable-next-line max-len
        `evening_pickup_from_ts (${evening_pickup_ts}) is not before evening_dropoff_from_ts (${evening_dropoff_from_ts})`
      );
    }

    const evening_dropoff_till_ts =
      item.evening_dropoff_till_ts &&
      normalizeSilverayTime(item.evening_dropoff_till_ts, timezone);
    if (
      evening_dropoff_till_ts &&
      !parseSilverayTime(evening_pickup_ts, timezone).isBefore(
        parseSilverayTime(evening_dropoff_till_ts, timezone)
      )
    ) {
      $errors.push(
        // eslint-disable-next-line max-len
        `evening_pickup_from_ts (${evening_pickup_ts}) is not before evening_dropoff_till_ts (${evening_dropoff_till_ts})`
      );
    }

    const $uid = name ? md5(name) : md5(faker.random.uuid());

    return {
      ...item,
      ...weekdaysRecords,
      name,
      country,
      city,
      calculation_group,
      mutually_exclusive_group,
      special_demand,
      wheelchair,
      gender,
      zip_code,
      lon,
      lat,
      service_time,
      destination,
      morning_pickup_from_ts,
      morning_pickup_till_ts,
      morning_dropoff_ts,
      evening_pickup_ts,
      evening_dropoff_from_ts,
      evening_dropoff_till_ts,
      $destination_building,
      $errors,
      $uid
    };
  });
  METHOD('importBookings:normalizedData', { jsonData, normalizedData });

  if (!ignoreErrors) {
    const error = normalizedData.find(item => item.$errors.length);
    if (error) {
      throw new Error(error.$errors[0]);
    }
  }

  return normalizedData;
};

export const generateVehiclesForEachBooking = (bookings, opts) => {
  METHOD('generateVehiclesForEachBooking:Request', { bookings, opts });

  const options = opts || {};

  const {
    areSpecialDemandsEnabled,
    human_readable_uids,
    currentDate,
    currentDestinationTimeName,
    currentDeliveryTypeTag,
    isMorning,
    timezone,
    vehicle_passenger,
    vehicle_wheelchair
  } = options;

  const unassigned_only = options.unassigned_only || false;

  const vehicles = Object.keys(bookings).reduce((vehiclesAcc, uid) => {
    const booking = bookings[uid];

    const bookingSpecialDemand = areSpecialDemandsEnabled
      ? booking.record.special_demand
      : undefined;
    METHOD('generateVehiclesForEachBooking:vehicles:bookingSpecialDemand', {
      areSpecialDemandsEnabled,
      bookingSpecialDemand
    });

    if (unassigned_only && bookingSpecialDemand) {
      return vehiclesAcc;
    }

    const specialDemands = bookingSpecialDemand
      ? {
          [bookingSpecialDemand]: 1
        }
      : {};

    const currentDestinationTimeTag = `#${currentDestinationTimeName}`;
    const currentCalculationGroupTag = `#CG:${booking.record
      .calculation_group || 'None'}`;
    const currentSpecialDemandTag =
      bookingSpecialDemand && `#SD:${bookingSpecialDemand}`;
    const currentIdTag = `#${crc32(booking.record.$uid)}`;

    const newVehicleBase = JSON.parse(silverayVehicleTemplateJSON);
    const newVehicleUID = human_readable_uids
      ? [
          moment(currentDate).format('YYYY-MM-DD #ddd'),
          currentDeliveryTypeTag,
          currentDestinationTimeTag,
          currentCalculationGroupTag,
          currentSpecialDemandTag,
          currentIdTag
        ]
          .filter(x => x)
          .join(' ')
      : faker.random.uuid();
    const newVehicle = isMorning
      ? {
          ...newVehicleBase,
          start_time: `${moment(currentDate).format('YYYY-MM-DD')}T${moment
            .tz('08:30 AM', 'LT', timezone)
            .add(-180, 'minutes')
            .format('HH:mm:ssZ')}`,
          end_time: `${moment(currentDate).format('YYYY-MM-DD')}T${moment
            .tz('12:30 PM', 'LT', timezone)
            .format('HH:mm:ssZ')}`,
          agent_id: newVehicleUID,
          vehicle_color: crc32(newVehicleUID) % 360,
          routing_engine: {
            ...newVehicleBase.routing_engine,
            osrme_timestamp_mode: 'end_time'
          },
          capacity: {
            ...newVehicleBase.capacity,
            ...specialDemands,
            passenger: vehicle_passenger,
            wheelchair: vehicle_wheelchair
          }
        }
      : {
          ...newVehicleBase,
          start_time: `${moment(currentDate).format('YYYY-MM-DD')}T${moment
            .tz('12:30 PM', 'LT', timezone)
            .format('HH:mm:ssZ')}`,
          end_time: `${moment(currentDate).format('YYYY-MM-DD')}T${moment
            .tz('17:30 PM', 'LT', timezone)
            .add(180, 'minutes')
            .format('HH:mm:ssZ')}`,
          agent_id: newVehicleUID,
          vehicle_color: crc32(newVehicleUID) % 360,
          routing_engine: {
            ...newVehicleBase.routing_engine,
            osrme_timestamp_mode: 'start_time'
          },
          capacity: {
            ...newVehicleBase.capacity,
            ...specialDemands,
            passenger: vehicle_passenger,
            wheelchair: vehicle_wheelchair
          }
        };

    METHOD('generateVehiclesForEachBooking:vehicles:newVehicle', {
      newVehicle
    });
    return [...vehiclesAcc, newVehicle];
  }, []);

  METHOD('generateVehiclesForEachBooking:Success', { vehicles });
  return vehicles;
};
