import { connect } from 'react-redux';
import createImmutableSelector from 'create-immutable-selector';
import { compose, lifecycle } from 'recompose';

import debug from 'utils/debug';
import { addSimulation } from 'modules/simulations/actions';
import { fetchDatasets } from 'modules/datasets/actions';
import { fetchGeofences } from 'modules/geofences/actions';

import { listSelector as datasetsListSelector } from 'modules/datasets/selectors';
import { geofencesSelector } from 'modules/geofences/selectors';
import { currentProjectUrlSelector } from 'modules/user/selectors';

import NewSimulation from './NewSimulation';

const { METHOD } = debug('c:ModalWindow:NewSimulation');

const withLifecycle = lifecycle({
  componentDidMount() {
    METHOD('componentDidMount', { props: this.props });

    const { datasets, geofences } = this.props;

    if (!datasets) {
      this.props.fetchDatasets();
    }

    if (!geofences) {
      this.props.fetchGeofences();
    }
  }
});

const mapDispatchToProps = {
  addSimulation,
  fetchDatasets,
  fetchGeofences
};

const mapStateToProps = createImmutableSelector(
  datasetsListSelector,
  geofencesSelector,
  currentProjectUrlSelector,
  (datasets, geofences, projectUrl) => ({ datasets, geofences, projectUrl })
);

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps
);

const enhance = compose(
  withConnect,
  withLifecycle
);

export default enhance(NewSimulation);
