import React, { PureComponent } from 'react';
import debug from 'utils/debug';

import DropZone from 'components/DropZone';
import { validateJsonFile } from 'utils/file';

import SubmitButton from 'components/SubmitButton';
import Preview from './Preview';
import Progress from './Progress';
import Error from './Error';

import ButtonsRow from './ButtonsRow';

const { METHOD } = debug('c:ModalWindow:NewCommuteOffer');

class NewCommuteOffer extends PureComponent {
  state = {
    data: null,
    file: null,
    error: null
  };

  readFile = (file) => {
    METHOD('readFile', { file });

    const reader = new FileReader();

    reader.onloadend = (e) => {
      METHOD('readFile:onLoaded', { e });

      if (e.target.readyState === FileReader.DONE) {
        const data = JSON.parse(e.target.result);
        METHOD('readFile:onLoaded:data', { data });

        this.setState({ data });
      }
    };

    const blob = file.slice(0, 1024 ** 3);
    reader.readAsBinaryString(blob);
  };

  handleFileSelect = (e) => {
    METHOD('handleFileSelect', { e });

    const file = e.target.files[0]; // FileList object

    if (validateJsonFile(file)) {
      this.setState({ file, error: null });
      this.readFile(file);
    } else {
      this.setState({ error: 'Oops! Invalid format' });
    }
  };

  handleDropFileSelect = (e) => {
    METHOD('handleDropFileSelect', { e });

    e.stopPropagation();
    e.preventDefault();
    const file = e.dataTransfer.files[0];

    if (validateJsonFile(file)) {
      this.setState({ file, error: null });
      this.readFile(file);
    } else {
      this.setState({ error: 'Oops! Invalid format' });
    }
  };

  handleDragOver = (e) => {
    METHOD('handleDragOver', { e });

    e.stopPropagation();
    e.preventDefault();
    e.dataTransfer.dropEffect = 'copy'; // Explicitly show this is a copy.
  };

  uploadData = (params) => {
    METHOD('uploadData', { params });

    const { data } = this.state;

    this.props.addCommuteOffer(params, data);
  };

  clearData = () => {
    METHOD('clearData');

    this.setState({ file: null, data: null });
  };

  render() {
    METHOD('render');

    // Detect File APIs
    if (!(window.File && window.FileReader && window.FileList && window.Blob)) {
      return <div>The File APIs are not fully supported in this browser.</div>;
    }

    const { data, file, error } = this.state;
    const { progress, status, projectUrl } = this.props;
    const apiError = this.props.error;
    const isError = error || apiError;

    if (progress) {
      return <Progress progress={progress} status={status} />;
    }

    const onCreate = () => {
      window.location = '/commuteoffer/0';
    };

    if (data && !isError) {
      return (
        <Preview
          data={data}
          file={file}
          uploadData={this.uploadData}
          onReset={this.clearData}
          projectUrl={projectUrl}
        />
      );
    }

    return (
      <React.Fragment>
        <DropZone
          onDragOver={this.handleDragOver}
          onDrop={this.handleDropFileSelect}
          onChange={this.handleFileSelect}
        />
        {isError && <Error>{`${error || apiError}. Try again.`}</Error>}
        <ButtonsRow>
          <SubmitButton onClick={onCreate}>Create empty</SubmitButton>
        </ButtonsRow>
      </React.Fragment>
    );
  }
}

export default NewCommuteOffer;
