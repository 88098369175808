import { connect } from 'react-redux';
import { openPopup } from 'modules/ui/actions';

import ButtonPlus from './ButtonPlus';

const mapDispatchToProps = { openPopup };

const withConnect = connect(
  null,
  mapDispatchToProps
);

export default withConnect(ButtonPlus);
