import moment from 'moment';
import debug from 'utils/debug';

const { METHOD } = debug('m:CommuteSchedule:FixedScheduleFromOffers');

// eslint-disable-next-line import/prefer-default-export
export const commuteSchedule$FixedScheduleFromOffers = (
  calculatedOffers,
  timezone
) => {
  METHOD('Request', {
    calculatedOffers
  });

  const fixedScheduleTable = calculatedOffers.reduce(
    (fixedScheduleAcc, commuteOffer) => {
      METHOD('commuteOffer', {
        commuteOffer
      });
      const isMorning = commuteOffer.tags.filter(x => x === '#Morning')
        .length;
      const { result, stateless_api_request_data } = commuteOffer;
      if (result.rejected_bookings.length) {
        return {
          ...fixedScheduleAcc,
          rejected: [...fixedScheduleAcc.rejected, commuteOffer]
        };
      }
      const assignedBookings = result.assigned_bookings.reduce(
        (assignedBookingsAcc, assignedBooking) => {
          const {
            uid,
            scheduled_pickup_time,
            scheduled_dropoff_time
          } = assignedBooking;
          const booking = stateless_api_request_data.bookings[uid];
          return [
            ...assignedBookingsAcc,
            {
              name: booking.name,
              isMorning,
              scheduled_pickup_time,
              scheduled_dropoff_time,
              commuteOffer
            }
          ];
        },
        []
      );
      return {
        ...fixedScheduleAcc,
        resolved: [...fixedScheduleAcc.resolved, ...assignedBookings]
      };
    },
    { resolved: [], rejected: [] }
  );
  METHOD('fixedScheduleTable', {
    fixedScheduleTable
  });

  const fixedSchedule = fixedScheduleTable.resolved.reduce(
    (fixedScheduleAcc, record) => {
      const prevRecord = fixedScheduleAcc[record.name] || {};
      return {
        ...fixedScheduleAcc,
        [record.name]: record.isMorning
          ? {
              ...prevRecord,
              morning_pickup_ts: moment(record.scheduled_pickup_time)
                .tz(timezone)
                .format('hh:mm A'),
              // morning_dropoff_ts: moment(record.scheduled_dropoff_time)
              //   .tz(timezone)
              //   .format('hh:mm A'),
              morning_duration: moment(record.scheduled_dropoff_time).diff(
                moment(record.scheduled_pickup_time)
              )
            }
          : {
              ...prevRecord,
              // evening_pickup_ts: moment(record.scheduled_pickup_time)
              //   .tz(timezone)
              //   .format('hh:mm A'),
              evening_dropoff_ts: moment(record.scheduled_dropoff_time)
                .tz(timezone)
                .format('hh:mm A'),
              evening_duration: moment(record.scheduled_dropoff_time).diff(
                moment(record.scheduled_pickup_time)
              )
            }
      };
    },
    {}
  );
  METHOD('Success', {
    fixedSchedule
  });

  return fixedSchedule;
};
