import { takeLatest, put, select } from 'redux-saga/effects';
import { getViewportFromGeometry } from 'utils/maps';

import debug from 'utils/debug';
import * as actions from './actions';
import * as commuteOfferActions from '../commuteOffer/actions';
import * as uiActions from '../ui/actions';

import {
  editableBookingIdSelector,
  activeVehicleIdsSelector,
  addStopModeSelector
} from '../ui/selectors';

import {
  routeSourceSelector,
  vehiclesStopSourceSelector,
  bookingsSourceSelector,
  commuteOfferViewportSelector,
  simulationViewportSelector
} from './selectors';
import { nodesByBookingIdSelector } from '../commuteOffer/selectors';

const { DEBUG, TRACE, METHOD } = debug('m:maps:saga');

function* nodesLayerClick({ payload }) {
  const node = payload.properties;
  yield put(commuteOfferActions.setBookingNode([node]));
}

function* vehiclesLayerClick({ payload }) {
  const isEditBooking = yield select(editableBookingIdSelector);

  if (!isEditBooking) {
    const { properties } = payload;
    yield put(uiActions.setActiveVehicleId(properties.agent_id));
    yield put(uiActions.setActiveRouteStop(properties.id));
  }
}

function* bookingClickLayer({ payload }) {
  const { properties } = payload;

  if (properties.assigned_vehicle_id !== 'null') {
    yield put(uiActions.cleanEditableBookingId());
    yield put(uiActions.setActiveVehicleId(properties.assigned_vehicle_id));
    yield put(uiActions.setActiveRouteStop(properties.$routeStopId));
    yield put(uiActions.setEditableBookingId(properties.uid));
  } else {
    yield put(uiActions.setActiveBookingId(properties.uid));
  }
}

function* flyToCommuteOfferVehicle({ payload }) {
  DEBUG('flyToCommuteOfferVehicle()');
  TRACE($ => $('Payload:', payload));

  const id = payload;
  const routes = yield select(routeSourceSelector);

  if (routes.features.length < 2) {
    return;
  }

  const currentRoute = routes.features.find(
    item => item.properties.agent_id === id
  );
  const viewport = getViewportFromGeometry(currentRoute);
  yield put(actions.changeViewportCommuteOffer(viewport));
}

function* flyToCommuteOfferActiveStop({ payload }) {
  DEBUG('flyToCommuteOfferActiveStop()');
  TRACE($ => $('Payload:', payload));

  const id = payload;
  const vehicles = yield select(vehiclesStopSourceSelector);
  const viewport = yield select(commuteOfferViewportSelector);
  const activeVehicleIds = yield select(activeVehicleIdsSelector);

  DEBUG('Vehicles:', vehicles);

  const currentStop = vehicles.features.find(
    item =>
      item.properties.id === id &&
      activeVehicleIds.includes(item.properties.agent_id)
  );

  yield put(
    actions.changeViewportCommuteOffer({
      ...viewport,
      latitude: currentStop.properties.lat,
      longitude: currentStop.properties.lon,
      zoom: viewport.zoom < 13 ? 13 : viewport.zoom
    })
  );
}

function* flyToCommuteOfferActiveBooking({ payload }) {
  DEBUG('flyToCommuteOfferActiveBooking()');
  TRACE($ => $('Payload:', payload));

  const id = payload;
  const bookings = yield select(bookingsSourceSelector);
  const viewport = yield select(commuteOfferViewportSelector);

  DEBUG('Bookings:', bookings);

  const currentBooking = bookings.features.find(
    item => item.properties.uid === id
  );

  if (currentBooking) {
    yield put(
      actions.changeViewportCommuteOffer({
        ...viewport,
        latitude: currentBooking.geometry.coordinates[1],
        longitude: currentBooking.geometry.coordinates[0],
        zoom: viewport.zoom < 13 ? 13 : viewport.zoom
      })
    );
  }
}

function* flyToSimulationVehicle({ payload }) {
  DEBUG('flyToSimulationVehicle()');
  TRACE($ => $('Payload:', payload));

  const id = payload;
  const routes = yield select(routeSourceSelector);

  if (routes.features.length < 2) {
    return;
  }

  const currentRoute = routes.features.find(
    item => item.properties.agent_id === id
  );
  const viewport = getViewportFromGeometry(currentRoute);
  yield put(actions.changeViewportSimulation(viewport));
}

function* flyToSimulationActiveStop({ payload }) {
  DEBUG('flyToSimulationActiveStop()');
  TRACE($ => $('Payload:', payload));

  const id = payload;
  const vehicles = yield select(vehiclesStopSourceSelector);
  const viewport = yield select(simulationViewportSelector);
  const activeVehicleIds = yield select(activeVehicleIdsSelector);

  DEBUG('Vehicles:', vehicles);

  const currentStop = vehicles.features.find(
    item =>
      item.properties.id === id &&
      activeVehicleIds.includes(item.properties.agent_id)
  );

  if (currentStop) {
    yield put(
      actions.changeViewportSimulation({
        ...viewport,
        latitude: currentStop.properties.lat,
        longitude: currentStop.properties.lon,
        zoom: viewport.zoom < 13 ? 13 : viewport.zoom
      })
    );
  } else {
    DEBUG('Error:', 'There is no current stop');
  }
}

function* flyToSimulationActiveBooking({ payload }) {
  DEBUG('flyToSimulationActiveBooking()');
  TRACE($ => $('Payload:', payload));

  const id = payload;
  const bookings = yield select(bookingsSourceSelector);
  const viewport = yield select(simulationViewportSelector);

  DEBUG('Bookings:', bookings);

  const currentBooking = bookings.features.find(
    item => item.properties.uid === id
  );

  if (currentBooking) {
    yield put(
      actions.changeViewportSimulation({
        ...viewport,
        latitude: currentBooking.geometry.coordinates[1],
        longitude: currentBooking.geometry.coordinates[0],
        zoom: viewport.zoom < 13 ? 13 : viewport.zoom
      })
    );
  } else {
    DEBUG('Error:', 'There is no current booking');
  }
}

function* stopsClickLayer({ payload }) {
  METHOD('stopsClickLayer', { a: 'call', payload });

  const { event, feature } = payload;
  const addStopMode = yield select(addStopModeSelector);
  const activeVehicleIds = yield select(activeVehicleIdsSelector);

  if (addStopMode) {
    if (activeVehicleIds.length === 1) {
      const { code } = feature.properties;
      yield put(commuteOfferActions.addStopToRoute(code, activeVehicleIds[0]));
    } else {
      const { name } = feature.properties;
      yield put(uiActions.setStopSearchQuery(name));
    }
    return;
  }

  if (!activeVehicleIds.length) {
    return;
  }

  const nodesByBookingId = yield select(nodesByBookingIdSelector);
  const editableBookingId = yield select(editableBookingIdSelector);

  if (!editableBookingId) {
    return;
  }

  const nodeType = event.originalEvent.shiftKey ? 'dropoff' : 'pickup';

  const stop = feature;

  const nodes = nodesByBookingId[editableBookingId];
  METHOD('stopsClickLayer', { a: 'nodes', nodes });

  const node = nodes[0];
  const { properties } = stop;
  const { coordinates } = stop.geometry;

  const newNode = {
    ...node,
    uid: Math.random(),
    stop_id: String(properties.id),
    location_code: properties.code,
    location_name: `${properties.name} #${properties.code}`,
    lat: coordinates[1],
    lon: coordinates[0],
    node_type: nodeType
  };

  METHOD('stopsClickLayer', { a: 'newNode', newNode });

  if (editableBookingId) {
    yield put(commuteOfferActions.addNode([newNode]));
    yield put(commuteOfferActions.setBookingNode([newNode]));
  }

  if (activeVehicleIds.length && !editableBookingId) {
    yield put(commuteOfferActions.changeRouteStop(stop));
  }
}

function* Saga() {
  yield takeLatest(actions.NODES_LAYER_CLICK, nodesLayerClick);
  yield takeLatest(actions.VEHICLES_LAYER_CLICK, vehiclesLayerClick);
  yield takeLatest(actions.BOOKING_LAYER_CLICK, bookingClickLayer);
  yield takeLatest(actions.STOPS_LAYER_CLICK, stopsClickLayer);
  yield takeLatest(
    actions.FLY_TO_COMMUTE_OFFER_VEHICLE,
    flyToCommuteOfferVehicle
  );
  yield takeLatest(
    actions.FLY_TO_COMMUTE_OFFER_ACTIVE_STOP,
    flyToCommuteOfferActiveStop
  );
  yield takeLatest(
    actions.FLY_TO_COMMUTE_OFFER_ACTIVE_BOOKING,
    flyToCommuteOfferActiveBooking
  );
  yield takeLatest(actions.FLY_TO_SIMULATION_VEHICLE, flyToSimulationVehicle);
  yield takeLatest(
    actions.FLY_TO_SIMULATION_ACTIVE_STOP,
    flyToSimulationActiveStop
  );
  yield takeLatest(
    actions.FLY_TO_SIMULATION_ACTIVE_BOOKING,
    flyToSimulationActiveBooking
  );
}

export default Saga;
