import { call, put, takeLatest } from 'redux-saga/effects';
import { api$Entities as api } from 'api';
import debug from 'utils/debug';
import * as actions from './actions';

const { METHOD } = debug('m:entities:saga');

function* fetchStops() {
  METHOD('fetchStops:Request');

  try {
    const stops = yield call(api.getStops);

    const stopsByType = stops.features.reduce((acc, feature) => {
      // METHOD('fetchStops:Feature', feature);
      const { stop_type } = feature.properties;
      acc[stop_type] = acc[stop_type] || [];
      acc[stop_type].push(feature);
      return acc;
    }, {});

    METHOD('fetchStops:Success', { stops, stopsByType });
    yield put({
      type: actions.STOPS_FETCH_SUCCESS,
      payload: { stops, stopsByType }
    });
  } catch (error) {
    METHOD('fetchStops:Failure', { error });
    yield put({ type: actions.STOPS_FETCH_FAILURE, payload: error });
  }
}

function* Saga() {
  yield takeLatest(actions.STOPS_FETCH_REQUEST, fetchStops);
}

export default Saga;
