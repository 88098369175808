import { connect } from 'react-redux';
import { compose, lifecycle } from 'recompose';
import createImmutableSelector from 'create-immutable-selector';

import { commuteOfferListSelector } from 'modules/commuteOffer/selectors';
import { currentProjectIdSelector } from 'modules/user/selectors';

import { fetchCommuteOffers } from 'modules/commuteOffer/actions';

import CommuteOffers from './CommuteOffers';

const withLifecycle = lifecycle({
  componentDidMount() {
    const { list, projectId } = this.props;

    if (!list && projectId) {
      this.props.fetchCommuteOffers();
    }
  },
  componentDidUpdate(prevProps) {
    const { list, projectId } = this.props;
    if (!list && projectId) {
      this.props.fetchCommuteOffers();
    }

    if (prevProps.projectId !== projectId) {
      this.props.fetchCommuteOffers();
    }
  }
});

const mapStateToProps = createImmutableSelector(
  commuteOfferListSelector,
  currentProjectIdSelector,
  (list, projectId) => ({ list, projectId })
);

const mapDispatchToProps = {
  fetchCommuteOffers
};

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps
);

const enhance = compose(
  withConnect,
  withLifecycle
);

export default enhance(CommuteOffers);
