import styled from 'styled-components';

export default styled.div`
  width: ${({ isHidden }) => (isHidden ? '50px' : '300px')};
  height: calc(100% - ${global.COMMUTE_OFFER_HEADER_ENABLED ? 80 : 20}px);
  padding: 10px;
  border-radius: 10px;
  margin: ${global.COMMUTE_OFFER_HEADER_ENABLED ? 70 : 10}px 0px 10px 10px;

  background-color: rgb(54, 61, 74);
  cursor: ${({ isHidden }) => (isHidden ? 'pointer' : 'inherit')};

  &:hover {
    background-color: ${({ isHidden }) =>
      isHidden ? '#3f4b59' : 'rgb(54, 61, 74)'};
  }
`;
