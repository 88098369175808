// eslint-disable-next-line import/prefer-default-export
export const csvToMap = (csv, opts) => {
  const [firstLine, ...lines] = csv;

  const params = opts || {};
  const columns = params.columns || new Map();

  const header = firstLine.map((column) => {
    const key = column.trim();
    return columns.has(key) ? columns.get(key) : key;
  });

  const result = lines.map(line =>
    header.reduce(
      (curr, next, index) => ({
        ...curr,
        [next]: line[index]
      }),
      {}
    ));

  return result;
};

// eslint-disable-next-line import/prefer-default-export
export const csvToJson = async (csv) => {
  const [firstLine, ...lines] = await csv.split('\n');

  const json = await lines.map(line =>
    firstLine.split(',').reduce(
      (curr, next, index) => ({
        ...curr,
        [next]: line.split(',')[index]
      }),
      {}
    ));

  const result = await json.map((value, i) => {
    const newValue = value;
    newValue.id = i;
    return newValue;
  });

  return result;
};
