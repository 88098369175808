import { connect } from 'react-redux';
import createImmutableSelector from 'create-immutable-selector';

import { unfilteredBookingsSelector } from 'modules/commuteOffer/selectors';
import {
  activeVehicleIdsSelector,
  activeRouteStopSelector,
  addPointModeSelector
} from 'modules/ui/selectors';

import {
  setActiveRouteStop,
  cleanActiveRouteStop,
  setAddPointMode,
  cleanAddPointMode
} from 'modules/ui/actions';

import {
  changeRouteOrder,
  deleteRoutePoint
} from 'modules/commuteOffer/actions';

import { flyToCommuteOfferActiveStop } from 'modules/maps/actions';

import Route from './Route';

const mapStateToProps = createImmutableSelector(
  unfilteredBookingsSelector,
  activeVehicleIdsSelector,
  activeRouteStopSelector,
  addPointModeSelector,
  (bookings, activeVehicleIds, activeRouteStop, pointEditing) => ({
    bookings,
    activeVehicleIds,
    activeRouteStop,
    pointEditing
  })
);

const mapDispatchToProps = {
  setActiveRouteStop,
  cleanActiveRouteStop,
  flyToCommuteOfferActiveStop,
  changeRouteOrder,
  setAddPointMode,
  cleanAddPointMode,
  deleteRoutePoint
};

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps
);

export default withConnect(Route);
