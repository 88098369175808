import React from 'react';

import Menu from 'components/Menu';
import Star from 'components/Star';
import Eye from 'components/Eye';

import Container from './Container';
import DropdownMenu from '../DropdownMenu';

const Buttons = (props) => {
  const {
    geofence,
    geofenceKey,
    bookmarked,
    deleteGeofence,
    isVisible,
    updateGeofence,
    toggleVisibilityGeofence,
    setEditableGeofence,
    saveGeofence,
    copyGeofence
  } = props;

  const onClickStar = () => {
    const body = JSON.stringify({ bookmarked: !bookmarked });
    updateGeofence(geofenceKey, body);
  };

  const onClickEye = () => {
    toggleVisibilityGeofence(geofence.id);
  };

  return (
    <Container>
      <Menu width='120px'>
        <DropdownMenu
          geofence={geofence}
          deleteGeofence={deleteGeofence}
          setEditableGeofence={setEditableGeofence}
          saveGeofence={saveGeofence}
          copyGeofence={copyGeofence}
        />
      </Menu>
      <Eye onClick={onClickEye} isActive={isVisible} />
      <Star onClick={onClickStar} isActive={bookmarked} />
    </Container>
  );
};

export default Buttons;
