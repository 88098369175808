import React from 'react';

import Menu from 'components/Menu';

import Button from './Button';

const MenuBottom = ({
  duplicateCommuteOffer,
  exportCommuteOffer,
  deleteCommuteOffer
}) => (
  <Menu>
    <Button onClick={duplicateCommuteOffer}>Make a copy...</Button>
    {false && <Button onClick={exportCommuteOffer}>Export JSON</Button>}
    <Button onClick={deleteCommuteOffer}>Delete</Button>
  </Menu>
);

export default MenuBottom;
