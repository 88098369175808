import React from 'react';
import Start from 'components/Star';
import Menu from 'components/Menu';

import Container from './Container';
import MenuButton from './MenuButton';

const Buttons = (props) => {
  const {
    dataset,
    bookmarked,
    updateDataset,
    deleteDataset,
    setEditableDataset,
    downloadDatasetCsv
  } = props;

  const id = dataset.get('id');

  const onClickStar = () => {
    const body = JSON.stringify({ bookmarked: !bookmarked });
    updateDataset(id, body);
  };

  return (
    <Container>
      <Start isActive={bookmarked} onClick={onClickStar} />
      <Menu>
        <MenuButton onClick={() => downloadDatasetCsv([id])}>
          Export CSV
        </MenuButton>
        <MenuButton onClick={() => setEditableDataset(dataset)}>
          Edit
        </MenuButton>
        <MenuButton onClick={() => deleteDataset(id)}>Delete</MenuButton>
      </Menu>
    </Container>
  );
};

export default Buttons;
