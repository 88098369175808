import React from 'react';
import PanelHeader from '../../PanelHeader';
import HiddenButton from './HiddenButton';

const Header = ({ size, toggleBookingsHidden, isHidden }) => (
  <PanelHeader isHidden={isHidden}>
    {!isHidden && <HiddenButton onClick={toggleBookingsHidden} />}
    {`${size} Bookings`}
  </PanelHeader>
);

export default Header;
