import React from 'react';

import Title from './Title';
import Container from './Container';

const Panel = ({ count }) => (
  <Container>
    <Title>{`${count || '--'} commute offers`}</Title>
  </Container>
);

export default Panel;
