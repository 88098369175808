/* eslint-disable */
import React, { useImperativeHandle, useRef } from 'react';
import moment from 'moment';
import Button from 'pages/CommuteOffer/TextButton';

import Container from './Container';
import NodeType from './NodeType';
import Row from './Row';
import Info from './Info';
import Header from './Header';
import Time from './Time';

const Stop = React.forwardRef((props, ref) => {
  const isActive = false;
  const {
    // isActive,
    vehicleId,
    onClick,
    color,
    isHaveEditable,
    stop,
    isDragging,
    deleteRoutePoint,
    cleanActiveRouteStop
  } = props;

  const elementRef = useRef(null);
  const opacity = isDragging ? 0.3 : 1;
  useImperativeHandle(ref, () => ({
    getNode: () => elementRef.current
  }));

  const isPoint = stop.node_type === 'point' || stop.node_type === 'stop';

  const onDeleteRoutePoint = () => {
    deleteRoutePoint(stop.uid, vehicleId);
    cleanActiveRouteStop();
  };

  return (
    <Container
      isActive={isActive}
      color={color}
      isDropoff={stop.node_type === 'dropoff'}
    >
      <div ref={elementRef} style={Object.assign({}, { opacity })}>
        <Header onClick={onClick}>
          <div>{stop.location_name || '---'}</div>
          <Row>
            <NodeType>{stop.node_type}</NodeType>
            {!isPoint && `${stop.pax} pax`}
          </Row>
          {stop.scheduled_ts && (
            <Time>
              {moment(stop.scheduled_ts)
                .tz(global.GEODISC_TIMEZONE)
                .format('HH:mm')}
            </Time>
          )}
        </Header>
        {isActive && !isPoint && (
          <Info bookings={stop.bookings} isHaveEditable={isHaveEditable} />
        )}
      </div>
    </Container>
  );
});

export default Stop;
