import { connect } from 'react-redux';
import createImmutableSelector from 'create-immutable-selector';

import { addBookingToRoute } from 'modules/commuteOffer/actions';
import {
  flyToSimulationActiveStop,
  flyToSimulationActiveBooking
} from 'modules/maps/actions';

import { activeBookingIdSelector } from 'modules/ui/selectors';

import {
  setActiveVehicleId,
  setActiveRouteStop,
  setActiveBookingId,
  setEditableBookingId,
  cleanActiveBookingId
} from 'modules/ui/actions';

import Booking from './Booking';

const mapStateToProps = createImmutableSelector(
  activeBookingIdSelector,
  activeBookingId => ({ activeBookingId })
);

const mapDispatchToProps = {
  addBookingToRoute,
  setActiveVehicleId,
  setActiveRouteStop,
  setActiveBookingId,
  setEditableBookingId,
  flyToSimulationActiveStop,
  flyToSimulationActiveBooking,
  cleanActiveBookingId
};

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps
);

export default withConnect(Booking);
