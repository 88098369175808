import { connect } from 'react-redux';
import { branch, compose, renderNothing } from 'recompose';
import createImmutableSelector from 'create-immutable-selector';

import {
  selectedSimulationsSelector,
  compareModSelector
} from 'modules/ui/selectors';
import { toggleCompareMod } from 'modules/ui/actions';

import Compare from './Compare';

const mapStateToProps = createImmutableSelector(
  selectedSimulationsSelector,
  compareModSelector,
  (simulations, compareMod) => ({
    simulations,
    compareMod
  })
);

const mapDispatchToProps = {
  onClose: toggleCompareMod
};

const withBranch = branch(({ compareMod }) => !compareMod, renderNothing);

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps
);

const enhance = compose(
  withConnect,
  withBranch
);

export default enhance(Compare);
