import React from 'react';

import Container from './Container';
import Row from './Row';

const Table = (props) => {
  const { list, count, page, clearList, history, ...otherProps } = props;

  return (
    <React.Fragment>
      <Container>
        {list.valueSeq().map((dataset) => {
          const id = dataset.get('id');

          return (
            <Row
              key={id}
              url={`/dataset/${id}`}
              dataset={dataset}
              {...otherProps}
            />
          );
        })}
      </Container>
    </React.Fragment>
  );
};

export default Table;
// import { withRouter } from 'react-router-dom';
