// @flow
import { fetchData } from './net';
import { getHeaders } from './headers';
import { urls } from '../config';

export const getUser = async (username: string) => {
  try {
    const response = await fetchData(urls.user(username), {
      headers: getHeaders()
    });

    if (!response.ok) {
      throw new Error(response.status);
    }

    const responseJSON = await response.json();
    return responseJSON;
  } catch (error) {
    throw error;
  }
};

export const getToken = async () => {
  try {
    const response = await fetchData(urls.token, { headers: getHeaders() });

    if (!response.ok) {
      throw new Error(response.status);
    }

    const responseJSON = await response.json();
    return responseJSON;
  } catch (error) {
    throw error;
  }
};

export const getProjects = async () => {
  try {
    const response = await fetchData(urls.projects, { headers: getHeaders() });
    if (!response.ok) {
      throw new Error(response.status);
    }

    const responseJSON = await response.json();
    return responseJSON;
  } catch (error) {
    throw error;
  }
};
