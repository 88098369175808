import { connect } from 'react-redux';
import createImmutableSelector from 'create-immutable-selector';
import { compose, lifecycle } from 'recompose';

import debug from 'utils/debug';
import {
  commuteOfferAddVehicleRequest,
  commuteOfferEditVehicleRequest
} from 'modules/commuteOffer/actions';

import { commuteOfferCurrentDataSelector } from 'modules/commuteOffer/selectors';

import { currentProjectUrlSelector } from 'modules/user/selectors';

import CommuteOfferVehicleEditor from './CommuteOfferVehicleEditor';

const { METHOD } = debug('c:ModalWindow:CommuteOfferVehicleEditor');

const withLifecycle = lifecycle({
  componentDidMount() {
    METHOD('componentDidMount', { props: this.props });
  }
});

const mapDispatchToProps = {
  commuteOfferAddVehicleRequest,
  commuteOfferEditVehicleRequest
};

const mapStateToProps = createImmutableSelector(
  commuteOfferCurrentDataSelector,
  currentProjectUrlSelector,
  (data, projectUrl) => ({ data, projectUrl })
);

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps
);

const enhance = compose(
  withConnect,
  withLifecycle
);

export default enhance(CommuteOfferVehicleEditor);
