import { connect } from 'react-redux';
import { branch, compose, renderNothing } from 'recompose';
import createImmutableSelector from 'create-immutable-selector';

import { closePopup } from 'modules/ui/actions';

import { modalWindowSelector } from 'modules/ui/selectors';

import Modal from './Modal';

const mapStateToProps = createImmutableSelector(
  modalWindowSelector,
  modalWindow => ({
    modalWindow
  })
);

const mapDispatchToProps = {
  closePopup
};

const withBranch = branch(({ modalWindow }) => !modalWindow, renderNothing);

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps
);

const enhance = compose(
  withConnect,
  withBranch
);

export default enhance(Modal);
