import { combineReducers } from 'redux-immutable';
import undoable, { excludeAction } from 'redux-undo';
import { connectRouter } from 'connected-react-router/immutable';
import * as uiActions from 'modules/ui/actions';
import * as userActions from 'modules/user/actions';
import * as entitiesActions from 'modules/entities/actions';
import * as commuteOfferActions from 'modules/commuteOffer/actions';

import datasetsReducer from './datasets';
import geofencesReducer from './geofences';
import simulationsReducer from './simulations';
import commuteOfferReducer from './commuteOffer';
import entitiesReducer from './entities';
import uiReducer from './ui';
import userReducer from './user';
import mapsReducer from './maps';

const reducer = history =>
  combineReducers({
    router: connectRouter(history),
    geofences: geofencesReducer,
    datasets: datasetsReducer,
    simulations: simulationsReducer,
    commuteOffer: undoable(commuteOfferReducer, {
      filter: excludeAction([
        ...Object.values(uiActions),
        ...Object.values(userActions),
        ...Object.values(entitiesActions),
        commuteOfferActions.ROUTE_FETCH_REQUEST,
        commuteOfferActions.ROUTE_FETCH_SUCCESS,
        commuteOfferActions.ROUTE_FETCH_FAILURE,
        commuteOfferActions.SET_BOOKING_NODE,
        commuteOfferActions.SET_RESULT_VEHICLES
      ]),
      limit: 10
    }),
    entities: entitiesReducer,
    ui: uiReducer,
    user: userReducer,
    maps: mapsReducer
  });

export default reducer;
