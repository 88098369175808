import React, { PureComponent, lazy } from 'react';
import Loading from 'components/Loading';
import Menu from './Menu';

const FromDataset = lazy(() => import('./FromDataset'));
const Draw = lazy(() => import('./Draw'));
const ChooseFile = lazy(() => import('./ChooseFile'));

const Component = ({ activeTab, ...otherProps }) => {
  switch (activeTab) {
    case 'fromdataset':
      return <FromDataset {...otherProps} />;
    case 'file':
      return <ChooseFile {...otherProps} />;
    case 'draw':
      return <Draw {...otherProps} />;
    default:
      return <ChooseFile {...otherProps} />;
  }
};

class NewGeofence extends PureComponent {
  state = {
    activeTab: 'fromdataset'
  };

  onChangeTab = (id) => {
    this.setState({ activeTab: id });
  };

  render() {
    const { activeTab } = this.state;

    return (
      <React.Fragment>
        <Menu active={activeTab} onChange={this.onChangeTab} />
        <React.Suspense fallback={<Loading />}>
          <Component activeTab={activeTab} {...this.props} />
        </React.Suspense>
      </React.Fragment>
    );
  }
}

export default NewGeofence;
