import { connect } from 'react-redux';
import createImmutableSelector from 'create-immutable-selector';

import {
  commuteOfferImportBookingsRequest,
  commuteOfferImportBookingsProgress
} from 'modules/commuteOffer/actions';

import { commuteOfferCurrentDataSelector } from 'modules/commuteOffer/selectors';

import {
  importBookingsProgressSelector,
  importBookingsStatusSelector,
  importBookingsErrorSelector
} from 'modules/ui/selectors';

import { currentProjectUrlSelector } from 'modules/user/selectors';

import ImportBookings from './ImportBookings';

const mapDispatchToProps = {
  commuteOfferImportBookingsRequest,
  commuteOfferImportBookingsProgress
};

const mapStateToProps = createImmutableSelector(
  commuteOfferCurrentDataSelector,
  importBookingsProgressSelector,
  importBookingsStatusSelector,
  importBookingsErrorSelector,
  currentProjectUrlSelector,
  (currentOffer, progress, status, error, projectUrl) => ({
    currentOffer,
    progress,
    status,
    error,
    projectUrl
  })
);

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps
);

export default withConnect(ImportBookings);
