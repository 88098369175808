import styled from 'styled-components';

export default styled.div`
  width: 100%;
  height: calc(100% - 110px);
  overflow: scroll;
  margin-top: 5px;

  opacity: ${({ isHidden }) => (isHidden ? 0 : 1)};
  visibility: ${({ isHidden }) => (isHidden ? 'hidden' : 'auto')};
  user-select: ${({ isHidden }) => (isHidden ? 'none' : 'auto')};
  ${({ isHidden }) => isHidden && 'cursor: pointer'};
`;
