// $FlowFixMe
export const getBasicAuth = () => {
  const token = localStorage.getItem(`${global.GEODISC_VERSION.ver}:token`);

  return `Basic ${token}`;
};

export const getHeaders = () =>
  new Headers({
    Authorization: getBasicAuth(),
    'Content-Type': 'application/json'
  });

export default getHeaders;
