import React, { memo } from 'react';
import Logo from 'components/Logo';
import Search from './Search';

import Container from './Container';
import HeaderWrap from './HeaderWrap';
import Title from './Title';
import List from './List';

const Panel = ({ size }) => (
  <Container>
    <Logo />
    <HeaderWrap>
      <Title>{`${size || '--'} geofences`}</Title>
      <Search
        placeholder='Search or filter geofences'
        style={{ marginLeft: 0 }}
      />
    </HeaderWrap>
    <List />
  </Container>
);

export default memo(Panel);
