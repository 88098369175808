import base64 from 'base-64';
import React from 'react';
import { Formik } from 'formik';
import logo from 'assets/SWAT-Icon-Orange.png';

import Container from './Container';
import Input from './Input';
import Submit from './Submit';
import Logo from './Logo';
import Status from './Status';

const LoginPanel = ({ authorization, loginState }) => (
  <Container>
    <Logo src={logo} />
    <Formik
      initialValues={{
        login: '',
        password: ''
      }}
      onSubmit={(values) => {
        localStorage.setItem(
          `${global.GEODISC_VERSION.ver}:token`,
          base64.encode(`${values.login}:${values.password}`)
        );
        authorization(values.login);
      }}
    >
      {(formProps) => {
        const { values, handleChange, handleSubmit, isValid } = formProps;

        return (
          <form onSubmit={handleSubmit} autoComplete='new-password'>
            <Input
              id='login'
              type='text'
              placeholder='User'
              onChange={handleChange}
              value={values.login}
              required
              autoComplete='new-password'
            />
            <Input
              id='password'
              type='password'
              placeholder='Password'
              onChange={handleChange}
              value={values.password}
              required
              autoComplete='new-password'
            />
            <Submit type='submit' isValid={isValid} />
          </form>
        );
      }}
    </Formik>
    <Status
      error={loginState.get('error')}
      waiting={loginState.get('waiting')}
    />
  </Container>
);

export default LoginPanel;
