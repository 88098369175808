import faker from 'faker';
import { emptyCommuteOfferJSON } from 'utils/CommuteOffer/defaults';

import moment from 'moment';
import deepmerge from 'deepmerge';
import debug from 'utils/debug';
import { crc32 } from 'utils/crc32';

import {
  silverayVehicleTemplateJSON,
  generateVehiclesForEachBooking,
  silverayDestinationTimes
} from 'utils/CommuteOffer/silveray';

const { METHOD } = debug('m:CommuteSchedule:GenerateOffersForDate');

const timeForCurrentDate = (value, currentDate, timezone) =>
  value
    ? `${moment(currentDate).format('YYYY-MM-DD')}T${moment
        .tz(value, 'LT', timezone)
        .format('HH:mm:ssZ')}`
    : undefined;

// eslint-disable-next-line import/prefer-default-export
export const commuteSchedule$GenerateOffersForDate = async (
  data,
  currentDate,
  timezone,
  opts
) => {
  METHOD('Request', {
    data,
    currentDate,
    timezone,
    opts
  });

  try {
    const options = opts || {};

    const {
      areCalculationGroupsEnabled,
      areMutuallyExclusiveGroupsEnabled,
      areSpecialDemandsEnabled,
      human_readable_uids,
      vehicle_passenger,
      vehicle_wheelchair,
      unassigned_only,
      engineSettingsBase,
      max_slack,
      time_limit_ms,
      externalOffer,
      destinationTimeWindowDuration = 0
    } = options;

    const currentDateFmt = currentDate.format('YYYY-MM-DD');
    const currentDay = currentDate.format('ddd').toLowerCase();
    const A = {
      currentDateFmt,
      currentDay
    };

    METHOD('externalOffer', { ...A, externalOffer });

    const fixedSchedule = options.fixedSchedule || {};
    METHOD('fixedSchedule', { ...A, fixedSchedule });

    const dataByWeekdays = currentDay
      ? data.filter(record => record[currentDay])
      : data;
    METHOD('dataByWeekdays', { ...A, dataByWeekdays });

    if (!dataByWeekdays.length) {
      return [];
    }

    const calculationGroupSet = areCalculationGroupsEnabled
      ? dataByWeekdays.reduce((acc, person) => {
          acc.add(person.calculation_group);
          return acc;
        }, new Set(['None']))
      : new Set(['None']);
    const calculationGroups = [...calculationGroupSet.values()];
    METHOD('calculationGroups', {
      calculationGroups
    });

    const offersByGroups = calculationGroups.reduce(
      (offersByGroupsAcc, currentGroup) => {
        // eslint-disable-next-line no-shadow
        const A = {
          currentDateFmt,
          currentDay,
          currentGroup
        };

        const currentGroupTag = `#CG:${currentGroup}`;

        const dataByGroups = dataByWeekdays.filter(
          record => record.calculation_group === currentGroup
        );
        METHOD('dataByGroups', { ...A, dataByGroups });

        if (!dataByGroups.length) {
          return offersByGroupsAcc;
        }

        const offersByDeliveryTypes = ['H2C', 'C2H'].reduce(
          (offersByDeliveryTypesAcc, currentDeliveryType) => {
            // eslint-disable-next-line no-shadow
            const A = {
              currentDateFmt,
              currentDay,
              currentGroup,
              currentDeliveryType
            };

            const isMorning = currentDeliveryType === 'H2C';
            const currentDeliveryTypeTag = `#${
              isMorning ? 'Morning' : 'Evening'
            }`;

            const dataByDeliveryTypes = dataByGroups.filter(record => record);
            METHOD('dataByDeliveryTypes', {
              ...A,
              dataByDeliveryTypes
            });

            if (!dataByDeliveryTypes.length) {
              return offersByDeliveryTypesAcc;
            }

            const offersByDestinationTimes = [
              ...silverayDestinationTimes[currentDeliveryType].keys()
            ].reduce((offersByDestinationTimesAcc, currentDestinationTime) => {
              const currentDestinationTimeName = moment
                .tz(currentDestinationTime, 'LT', timezone)
                .format('HH:mm');
              const currentDestinationTimeTag = `#${currentDestinationTimeName}`;

              // eslint-disable-next-line no-shadow
              const A = {
                currentDateFmt,
                currentDay,
                currentGroup,
                currentDeliveryType,
                currentDestinationTimeName
              };

              const dataByDestinationTimes =
                currentDeliveryType === 'H2C'
                  ? dataByDeliveryTypes.filter(
                      record =>
                        record.morning_dropoff_ts === currentDestinationTime
                    )
                  : dataByDeliveryTypes.filter(
                      record =>
                        record.evening_pickup_ts === currentDestinationTime
                    );
              METHOD('dataByDestinationTimes', {
                ...A,
                dataByDestinationTimes
              });

              if (!dataByDestinationTimes.length) {
                return offersByDestinationTimesAcc;
              }

              const dataForGeneration = dataByDestinationTimes;

              const bookings = dataForGeneration.reduce(
                (bookingsAcc, record) => {
                  const currentMutuallyExclusiveGroupTag =
                    record.mutually_exclusive_group &&
                    `#EG:${record.mutually_exclusive_group}`;

                  const groups = areMutuallyExclusiveGroupsEnabled
                    ? [record.mutually_exclusive_group]
                    : undefined;

                  const uid = human_readable_uids
                    ? [
                        moment(currentDate).format('YYYY-MM-DD #ddd'),
                        currentDeliveryTypeTag,
                        currentGroupTag,
                        currentMutuallyExclusiveGroupTag,
                        record.name,
                        record.zip_code
                      ]
                        .filter(x => x)
                        .join(' ')
                    : faker.random.uuid();

                  const name = record.name;
                  const srcPoint = record.$buildings[0];
                  const dstPoint = record.$destination_building.nearestPoint;

                  const rawFixedScheduleRecord = fixedSchedule[name];

                  const currentFixedScheduleRecord = rawFixedScheduleRecord && {
                    morning_pickup_ts: timeForCurrentDate(
                      rawFixedScheduleRecord.morning_pickup_ts,
                      currentDate,
                      timezone
                    ),
                    evening_dropoff_ts: timeForCurrentDate(
                      rawFixedScheduleRecord.evening_dropoff_ts,
                      currentDate,
                      timezone
                    )
                  };

                  const $morningDropoffTime = `${moment(currentDate).format(
                    'YYYY-MM-DD'
                  )}T${moment
                    .tz(record.morning_dropoff_ts, 'LT', timezone)
                    .format('HH:mm:ssZ')}`;

                  const $eveningPickupTime = `${moment(currentDate).format(
                    'YYYY-MM-DD'
                  )}T${moment
                    .tz(record.evening_pickup_ts, 'LT', timezone)
                    .format('HH:mm:ssZ')}`;

                  const $times = {
                    morning: {
                      pickup:
                        currentFixedScheduleRecord &&
                        currentFixedScheduleRecord.morning_pickup_ts
                          ? {
                              from: moment(
                                currentFixedScheduleRecord.morning_pickup_ts
                              )
                                .subtract(10, 'minutes')
                                .tz(timezone)
                                .format(),
                              till: moment(
                                currentFixedScheduleRecord.morning_pickup_ts
                              )
                                .add(5, 'minutes')
                                .tz(timezone)
                                .format()
                            }
                          : {
                              from: record.morning_pickup_from_ts
                                ? timeForCurrentDate(
                                    record.morning_pickup_from_ts,
                                    currentDate,
                                    timezone
                                  )
                                : moment($morningDropoffTime)
                                    .subtract(50, 'minutes')
                                    .tz(timezone)
                                    .format(),
                              till: record.morning_pickup_till_ts
                                ? timeForCurrentDate(
                                    record.morning_pickup_till_ts,
                                    currentDate,
                                    timezone
                                  )
                                : moment($morningDropoffTime)
                                    .subtract(5, 'minutes')
                                    .tz(timezone)
                                    .format()
                            },
                      dropoff: {
                        from: moment($morningDropoffTime)
                          .subtract(destinationTimeWindowDuration, 'seconds')
                          .tz(timezone)
                          .format(),
                        till: $morningDropoffTime
                      }
                    },
                    evening: {
                      pickup: {
                        from: $eveningPickupTime,
                        till: moment($eveningPickupTime)
                          .add(destinationTimeWindowDuration, 'seconds')
                          .tz(timezone)
                          .format()
                      },
                      dropoff:
                        currentFixedScheduleRecord &&
                        currentFixedScheduleRecord.evening_dropoff_ts
                          ? {
                              from: moment(
                                currentFixedScheduleRecord.evening_dropoff_ts
                              )
                                .subtract(5, 'minutes')
                                .tz(timezone)
                                .format(),
                              till: moment(
                                currentFixedScheduleRecord.evening_dropoff_ts
                              )
                                .add(10, 'minutes')
                                .tz(timezone)
                                .format()
                            }
                          : {
                              from: record.evening_dropoff_from_ts
                                ? timeForCurrentDate(
                                    record.evening_dropoff_from_ts,
                                    currentDate,
                                    timezone
                                  )
                                : moment($eveningPickupTime)
                                    .add(5, 'minutes')
                                    .tz(timezone)
                                    .format(),
                              till: record.evening_dropoff_till_ts
                                ? timeForCurrentDate(
                                    record.evening_dropoff_till_ts,
                                    currentDate,
                                    timezone
                                  )
                                : moment($eveningPickupTime)
                                    .add(50, 'minutes')
                                    .tz(timezone)
                                    .format()
                            }
                    }
                  };
                  METHOD('bookings:$times', {
                    ...A,
                    record,
                    fixedSchedule,
                    currentFixedScheduleRecord,
                    $times
                  });

                  if (
                    $times.morning.pickup.from === 'Invalid date' ||
                    $times.morning.pickup.till === 'Invalid date' ||
                    $times.evening.pickup.from === 'Invalid date' ||
                    $times.evening.pickup.till === 'Invalid date' ||
                    $times.morning.dropoff.from === 'Invalid date' ||
                    $times.morning.dropoff.till === 'Invalid date' ||
                    $times.evening.dropoff.from === 'Invalid date' ||
                    $times.evening.dropoff.till === 'Invalid date'
                  ) {
                    METHOD('bookings:$times:Invalid', {
                      ...A,
                      record,
                      fixedSchedule,
                      currentFixedScheduleRecord,
                      $times
                    });
                    throw new Error('Invalid date');
                  }

                  const newBooking = isMorning
                    ? {
                        pickup_location_lon: srcPoint.coordinates[0],
                        pickup_location_lat: srcPoint.coordinates[1],
                        pickup_location_name: srcPoint.address,
                        pickup_time: null,
                        dropoff_location_lon: dstPoint.lon,
                        dropoff_location_lat: dstPoint.lat,
                        dropoff_location_name: dstPoint.street,
                        dropoff_time: $morningDropoffTime,
                        $times,
                        record,
                        groups,
                        name,
                        uid
                      }
                    : {
                        dropoff_location_lon: srcPoint.coordinates[0],
                        dropoff_location_lat: srcPoint.coordinates[1],
                        dropoff_location_name: srcPoint.address,
                        dropoff_time: null,
                        pickup_location_lon: dstPoint.lon,
                        pickup_location_lat: dstPoint.lat,
                        pickup_location_name: dstPoint.street,
                        pickup_time: $eveningPickupTime,
                        $times,
                        record,
                        groups,
                        name,
                        uid
                      };

                  return {
                    ...bookingsAcc,
                    [uid]: newBooking
                  };
                },
                {}
              );
              METHOD('bookings', {
                ...A,
                bookings
              });

              if (!Object.keys(bookings).length) {
                return offersByDestinationTimesAcc;
              }

              const rejected_bookings = Object.keys(bookings).map(uid => ({
                uid,
                // assigned_vehicle_id: null,
                scheduled_dropoff_stop_id: null,
                scheduled_dropoff_time: null,
                scheduled_pickup_stop_id: null,
                scheduled_pickup_time: null
              }));
              METHOD('rejected_bookings', {
                ...A,
                rejected_bookings
              });

              const nodes = Object.keys(bookings).reduce((nodesAcc, uid) => {
                const booking = bookings[uid];
                const srcPoint = booking.record.$buildings[0];
                const dstPoint = booking.record.$destination_building;

                const specialDemandsPickup =
                  areSpecialDemandsEnabled && booking.record.special_demand
                    ? {
                        [booking.record.special_demand]: 1
                      }
                    : {};
                const pickNodeUid = human_readable_uids
                  ? `${booking.uid} #Pick`
                  : faker.random.uuid();

                const pickupNode = isMorning
                  ? {
                      lon: srcPoint.nearestPoint.lon,
                      lat: srcPoint.nearestPoint.lat,
                      location_name: srcPoint.nearestPoint.street,
                      stop_id: srcPoint.nearestPoint.id,
                      open_time_ts: booking.$times.morning.pickup.from,
                      close_time_ts: booking.$times.morning.pickup.till,
                      close_time_ts_dynamic: booking.$times.morning.pickup.till,
                      max_timewindow_sub: 600,
                      max_timewindow_add: 300,
                      demand: {
                        ...specialDemandsPickup,
                        wheelchair: booking.record.wheelchair ? 1 : 0,
                        passenger: 1
                      },
                      trip_cost: 42,
                      weight: 0,
                      service_time: booking.record.service_time,
                      walking_distance_to_node: 0,
                      max_trip_duration: 7200,
                      booking_uid: booking.uid,
                      uid: pickNodeUid,
                      node_type: 'pickup'
                    }
                  : {
                      lon: dstPoint.nearestPoint.lon,
                      lat: dstPoint.nearestPoint.lat,
                      location_name: dstPoint.nearestPoint.street,
                      stop_id: dstPoint.nearestPoint.id,
                      open_time_ts: booking.$times.evening.pickup.from,
                      close_time_ts: booking.$times.evening.pickup.till,
                      close_time_ts_dynamic: booking.$times.evening.pickup.till,
                      max_timewindow_sub: 0,
                      max_timewindow_add: 0,
                      demand: {
                        ...specialDemandsPickup,
                        wheelchair: booking.record.wheelchair ? 1 : 0,
                        passenger: 1
                      },
                      trip_cost: 42,
                      weight: 0,
                      service_time: 0,
                      walking_distance_to_node: 0,
                      max_trip_duration: 7200,
                      booking_uid: booking.uid,
                      uid: pickNodeUid,
                      node_type: 'pickup'
                    };
                nodesAcc.push(pickupNode);

                const specialDemandsDropoff =
                  areSpecialDemandsEnabled && booking.record.special_demand
                    ? {
                        [booking.record.special_demand]: -1
                      }
                    : {};
                const dropNodeUid = human_readable_uids
                  ? `${booking.uid} #Drop`
                  : faker.random.uuid();

                const dropoffNode = isMorning
                  ? {
                      lon: dstPoint.nearestPoint.lon,
                      lat: dstPoint.nearestPoint.lat,
                      location_name: dstPoint.nearestPoint.street,
                      stop_id: dstPoint.nearestPoint.id,
                      open_time_ts: booking.$times.morning.dropoff.from,
                      close_time_ts: booking.$times.morning.dropoff.till,
                      close_time_ts_dynamic:
                        booking.$times.morning.dropoff.till,
                      max_timewindow_sub: 0,
                      max_timewindow_add: 0,
                      demand: {
                        ...specialDemandsDropoff,
                        wheelchair: booking.record.wheelchair ? -1 : 0,
                        passenger: -1
                      },
                      trip_cost: 42,
                      weight: 0,
                      service_time: 0,
                      walking_distance_to_node: 0,
                      max_trip_duration: 7200,
                      booking_uid: booking.uid,
                      uid: dropNodeUid,
                      node_type: 'dropoff'
                    }
                  : {
                      lon: srcPoint.nearestPoint.lon,
                      lat: srcPoint.nearestPoint.lat,
                      location_name: srcPoint.nearestPoint.street,
                      stop_id: srcPoint.nearestPoint.id,
                      open_time_ts: booking.$times.evening.dropoff.from,
                      close_time_ts: booking.$times.evening.dropoff.till,
                      close_time_ts_dynamic:
                        booking.$times.evening.dropoff.till,
                      max_timewindow_sub: 300,
                      max_timewindow_add: 600,
                      demand: {
                        ...specialDemandsDropoff,
                        wheelchair: booking.record.wheelchair ? -1 : 0,
                        passenger: -1
                      },
                      trip_cost: 42,
                      weight: 0,
                      service_time: booking.record.service_time,
                      walking_distance_to_node: 0,
                      max_trip_duration: 7200,
                      booking_uid: booking.uid,
                      uid: dropNodeUid,
                      node_type: 'dropoff'
                    };
                nodesAcc.push(dropoffNode);
                return nodesAcc;
              }, []);
              METHOD('nodes', {
                ...A,
                nodes
              });

              const todaySpecialDemandSet = dataForGeneration.reduce(
                (acc, person) => {
                  if (person.special_demand) {
                    acc.add(person.special_demand);
                  }
                  return acc;
                },
                new Set([undefined])
              );
              const todaySpecialDemands = [...todaySpecialDemandSet.values()];
              METHOD('todaySpecialDemands', {
                ...A,
                todaySpecialDemands
              });

              const vehiclesForSpecialDemands =
                areSpecialDemandsEnabled && !externalOffer
                  ? todaySpecialDemands
                      .filter(x => x)
                      .map((specialDemand) => {
                        const currentSpecialDemandTag = `#SD:${specialDemand}`;

                        const newVehicleBase = JSON.parse(
                          silverayVehicleTemplateJSON
                        );
                        const newVehicleUID = human_readable_uids
                          ? [
                              moment(currentDate).format('YYYY-MM-DD #ddd'),
                              currentDeliveryTypeTag,
                              currentSpecialDemandTag
                            ]
                              .filter(x => x)
                              .join(' ')
                          : faker.random.uuid();
                        const newVehicle = isMorning
                          ? {
                              ...newVehicleBase,
                              start_time: `${moment(currentDate).format(
                                'YYYY-MM-DD'
                              )}T${moment
                                .tz('08:30 AM', 'LT', timezone)
                                .add(-180, 'minutes')
                                .format('HH:mm:ssZ')}`,
                              end_time: `${moment(currentDate).format(
                                'YYYY-MM-DD'
                              )}T${moment
                                .tz('12:30 PM', 'LT', timezone)
                                .format('HH:mm:ssZ')}`,
                              agent_id: newVehicleUID,
                              vehicle_color: crc32(newVehicleUID) % 360,
                              routing_engine: {
                                ...newVehicleBase.routing_engine,
                                osrme_timestamp_mode: 'end_time'
                              },
                              capacity: {
                                ...newVehicleBase.capacity,
                                passenger: vehicle_passenger,
                                wheelchair: vehicle_wheelchair
                              }
                            }
                          : {
                              ...newVehicleBase,
                              start_time: `${moment(currentDate).format(
                                'YYYY-MM-DD'
                              )}T${moment
                                .tz('12:30 PM', 'LT', timezone)
                                .format('HH:mm:ssZ')}`,
                              end_time: `${moment(currentDate).format(
                                'YYYY-MM-DD'
                              )}T${moment
                                .tz('17:30 PM', 'LT', timezone)
                                .add(180, 'minutes')
                                .format('HH:mm:ssZ')}`,
                              agent_id: newVehicleUID,
                              vehicle_color: crc32(newVehicleUID) % 360,
                              routing_engine: {
                                ...newVehicleBase.routing_engine,
                                osrme_timestamp_mode: 'start_time'
                              },
                              capacity: {
                                ...newVehicleBase.capacity,
                                passenger: vehicle_passenger,
                                wheelchair: vehicle_wheelchair
                              }
                            };
                        const resultVehicle = {
                          ...newVehicle,
                          capacity: {
                            ...newVehicle.capacity,
                            [specialDemand]: vehicle_passenger
                          }
                        };
                        return resultVehicle;
                      })
                  : [];
              METHOD('vehiclesForSpecialDemands', {
                ...A,
                vehiclesForSpecialDemands
              });

              const vehiclesForBookings = !externalOffer
                ? generateVehiclesForEachBooking(bookings, {
                    areSpecialDemandsEnabled,
                    human_readable_uids,
                    currentDate,
                    currentDestinationTimeName,
                    currentDeliveryTypeTag,
                    isMorning,
                    timezone,
                    vehicle_passenger,
                    vehicle_wheelchair,
                    unassigned_only
                  })
                : [];
              METHOD('vehiclesForBookings', {
                ...A,
                vehiclesForBookings
              });

              const vehicles = [
                ...vehiclesForBookings,
                ...vehiclesForSpecialDemands
              ];
              METHOD('vehicles', {
                ...A,
                vehicles
              });

              const tags = [
                `#${moment(currentDate).format('YYYY-MM-DD')}`,
                `#${moment(currentDate).format('ddd')}`,
                currentDeliveryTypeTag,
                currentDestinationTimeTag,
                currentGroupTag
              ];

              const generation_context = {
                day: moment(currentDate).format('ddd'),
                delivery_type: currentDeliveryType,
                destination_time: currentDestinationTime,
                calculation_group: currentGroup
              };

              const mutually_exclusive_groups = areMutuallyExclusiveGroupsEnabled
                ? [
                    [
                      ...dataForGeneration
                        .reduce((acc, person) => {
                          acc.add(person.mutually_exclusive_group);
                          return acc;
                        }, new Set())
                        .keys()
                    ].filter(x => !!x)
                  ]
                : undefined;
              METHOD('mutually_exclusive_groups', {
                mutually_exclusive_groups
              });

              const newOfferBase = externalOffer
                ? deepmerge(JSON.parse(emptyCommuteOfferJSON), externalOffer)
                : JSON.parse(emptyCommuteOfferJSON);
              const newOffer = {
                ...newOfferBase,
                stateless_api_request_data: {
                  ...newOfferBase.stateless_api_request_data,
                  current_time: `${moment(currentDate).format(
                    'YYYY-MM-DD'
                  )}T${moment
                    .tz('00:00 AM', 'LT', timezone)
                    .format('HH:mm:ssZ')}`,
                  engine_settings: {
                    ...engineSettingsBase,
                    model_parameters: {
                      ...engineSettingsBase.model_parameters,
                      max_slack,
                      mutually_exclusive_groups
                    },
                    solver_parameters: {
                      ...engineSettingsBase.solver_parameters,
                      time_limit_ms
                    },
                    generation_context
                  },
                  bookings,
                  nodes,
                  vehicles
                },
                result: {
                  ...newOfferBase.result,
                  rejected_bookings
                },
                tags,
                name: tags.join(' ')
              };
              METHOD('newOffer', {
                ...A,
                newOffer
              });

              return [...offersByDestinationTimesAcc, newOffer];
            }, []);
            METHOD('offersByDestinationTimes', {
              ...A,
              offersByDestinationTimes
            });

            return [...offersByDeliveryTypesAcc, ...offersByDestinationTimes];
          },
          []
        );
        METHOD('offersByDeliveryTypes', {
          ...A,
          offersByDeliveryTypes
        });

        return [...offersByGroupsAcc, ...offersByDeliveryTypes];
      },
      []
    );
    METHOD('Success', { ...A, offersByGroups });

    return offersByGroups;
  } catch (error) {
    METHOD('Failure', { error });
    throw error;
  }
};
