import { connect } from 'react-redux';
import { compose, lifecycle } from 'recompose';
import createImmutableSelector from 'create-immutable-selector';

import {
  serialNumberSelector,
  commuteOfferCurrentDataSelector
} from 'modules/commuteOffer/selectors';

import { stopsSelector } from 'modules/entities/selectors';
import { cleanCommuteOfferUi } from 'modules/ui/actions';

import {
  fetchCommuteOffer,
  clearCommuteOffer,
  incSerialNumber
} from 'modules/commuteOffer/actions';
import { fetchStops } from 'modules/entities/actions';

import { routeIdSelector } from 'modules/router/selectors';

import CommuteOffer from './CommuteOffer';

const withLifecycle = lifecycle({
  componentDidMount() {
    const { id, data, stops } = this.props;

    if (!stops) {
      this.props.fetchStops();
    }

    if (!data || data.id !== id) {
      this.props.cleanCommuteOfferUi();
      this.props.clearCommuteOffer();
      this.props.fetchCommuteOffer(id);
    }
  }
});

const mapStateToProps = createImmutableSelector(
  serialNumberSelector,
  commuteOfferCurrentDataSelector,
  stopsSelector,
  routeIdSelector,
  (serialNumber, data, stops, id) => ({ serialNumber, data, stops, id })
);

const mapDispatchToProps = {
  fetchCommuteOffer,
  clearCommuteOffer,
  fetchStops,
  cleanCommuteOfferUi,
  incSerialNumber
};

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps
);

const enhance = compose(
  withConnect,
  withLifecycle
);

export default enhance(CommuteOffer);
