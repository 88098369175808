import { connect } from 'react-redux';
import createImmutableSelector from 'create-immutable-selector';

import {
  fetchSimulationDataStop,
  clearSimulation
} from 'modules/simulations/actions';
import { progressSelector } from 'modules/simulations/selectors';

import Header from './Header';

const mapDispatchToProps = {
  fetchSimulationDataStop,
  clearSimulation
};

const mapStateToProps = createImmutableSelector(
  progressSelector,
  progress => ({
    progress
  })
);

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps
);

export default withConnect(Header);
