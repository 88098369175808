import React from 'react';
import Container from './Container';
import Booking from './Booking';
import Title from './Title';
import Wrap from './Wrap';

const Info = (props) => {
  const { bookings, activeBookingId } = props;

  return (
    <Container>
      <Title>Info</Title>
      <div>
        {bookings &&
          bookings.map(({ id, pax }) => {
            const setActive = () => {};

            return (
              <Booking key={id}>
                <Wrap onClick={setActive} isActive={activeBookingId === id}>
                  <div>{`Booking #${id}`}</div>
                  <div>{`${pax} pax`}</div>
                </Wrap>
              </Booking>
            );
          })}
      </div>
    </Container>
  );
};

export default Info;
