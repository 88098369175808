import React, { memo } from 'react';

import Container from './Container';
import NavLink from './NavLink';

const Navigation = () => (
  <Container>
    {!window.GEODISC_UI_DATASETS_DISABLE && (
      <NavLink to='/datasets'>Datasets</NavLink>
    )}
    {!window.GEODISC_UI_GEOFENCES_DISABLE && (
      <NavLink to='/geofences'>Geofences</NavLink>
    )}
    {!window.GEODISC_UI_SIMULATIONS_DISABLE && (
      <NavLink to='/simulations'>Simulations</NavLink>
    )}
    {!window.GEODISC_UI_COMMUTE_OFFER_DISABLE && (
      <NavLink to='/commuteoffers'>CommuteOffers</NavLink>
    )}
  </Container>
);

export default memo(Navigation);
