import React from 'react';

import Layout from 'components/Layout';
import Panel from 'components/Panel';
import Logo from 'components/Logo';

import Header from './Header';
import VisualizationPanel from './VisualizationPanel';
import TimeSlider from './TimeSlider';
import BackButton from './BackButton';

const Dataset = () => (
  <Layout>
    <Panel>
      <BackButton to='/datasets'>
        <Logo />
      </BackButton>
      <Header />
      <VisualizationPanel />
      <TimeSlider />
    </Panel>
  </Layout>
);

export default Dataset;
