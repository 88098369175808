import React from 'react';
import { exportFile } from 'utils/file';

import Menu from 'components/Menu';
import debug from 'utils/debug';
import {
  commuteOffer$GanttScheduleTimeline,
  commuteOffers$Trips$TableColumns,
  commuteOffers$Trips$TableFromCommuteOffer,
  commuteOffer$FilterVehicles
} from 'utils/CommuteOffer';
import {
  commuteSchedule$PassengersTableFromOffer,
  commuteSchedule$PassengersTableHeaders
} from 'utils/CommuteSchedule';
import { api$CommuteOffer as api } from 'api';
import { useStyletron } from 'baseui';
import { Button, KIND, SIZE } from 'baseui/button';
import { StatefulPopover, PLACEMENT } from 'baseui/popover';
import { StatefulMenu } from 'baseui/menu';
import * as Icons from 'baseui/icon/icon-exports';
import Container from './Container';
import Search from './Search';
import ButtonHistory from './ButtonHistory';
import ButtonSave from './ButtonSave';
import ProjectLabel from './ProjectLabel';
import NameLabel from './NameLabel';
import MenuContainer from './MenuContainer';

import layersIcon from './layers-enabled.svg';

import undoImgEnabled from './undo-enabled.svg';
import undoImgDisabled from './undo-disabled.svg';

import redoImgEnabled from './redo-enabled.svg';
import redoImgDisabled from './redo-disabled.svg';
import savingImg from './saving.svg';

import MenuButton from './MenuButton';

const { METHOD } = debug('p:CommuteOffer:Panels:TopButtons');

const TopButtons = props => {
  METHOD('TopButtons', props);

  const [css, theme] = useStyletron();
  METHOD('theme', { css, theme, $compact: SIZE.compact });

  const {
    back,
    next,
    currentProject,
    projects,
    data,
    isChanged,
    updateCommuteOffer,
    changeCurrentProject,
    commuteOfferStatelessNodeScheduler,
    openPopup,
    closePopup,
    history,
    isSaving,
    isBookingsHidden,
    filteredVehicleIds,
    activeVehicleIds
  } = props;

  const isDataValid = !!data;
  const isDataTransient = isDataValid && !data.id;

  const onImportPassengersSchedule = () => {
    METHOD('onImportPassengersSchedule', { data });

    openPopup('CommuteScheduleImportPassengers', {
      title: 'Import passengers schedule',
      data
    });
  };

  const onExportAsJson = () => {
    METHOD('onExportAsJson', { data });

    const {
      name,
      result,
      commute_preferences,
      stateless_api_request_data
    } = data;

    const dataForExport = {
      name,
      result,
      commute_preferences,
      stateless_api_request_data
    };

    exportFile(JSON.stringify(dataForExport, null, '\t'), `${data.name}.json`);
  };

  const onAdmin = () => {
    METHOD('onAdmin', { data });

    if (isDataValid) {
      window.open(
        `${window.GEODISC_API_URL}/admin/commute/commuteoffer/${data.id}/change/`,
        '_blank'
      );
    }
  };

  const doSaveAs = () => {
    global.METHODS.reset();
    METHOD('doSaveAs', { data, filteredVehicleIds, activeVehicleIds });

    const existingActiveVehicleIds = activeVehicleIds.filter(
      id => id !== 'DEADBEEF'
    );

    global.openNameAndProject('', {
      title: 'Save as...',
      isSelectionAllowed:
        existingActiveVehicleIds.length || filteredVehicleIds.length,
      onSubmit: async ({ name, project, selection }) => {
        METHOD('doSaveAs:onSubmit', {
          name,
          project,
          selection,
          data,
          filteredVehicleIds,
          existingActiveVehicleIds
        });
        try {
          const vehicleSelectors = {
            all: () => data,
            selected: () =>
              commuteOffer$FilterVehicles(data, existingActiveVehicleIds),
            visible: () => commuteOffer$FilterVehicles(data, filteredVehicleIds)
          };
          const selectedVehicles = vehicleSelectors[selection]();
          METHOD('doSaveAs:onSubmit:selectedVehicles', {
            selection,
            selectedVehicles
          });
          const commuteOffer = {
            ...selectedVehicles,
            name,
            project: `/api/v2/project/${project}`,
            resource_uri: undefined,
            id: undefined
          };
          METHOD('doSaveAs:onSubmit:commuteOffer', { commuteOffer });
          await api.addCommuteOffer(commuteOffer);
          global.openInfoMessage('Saved successfully.', {
            title: 'Info'
          });
        } catch (error) {
          global.openInfoMessage(`Error: ${error.message}`, {
            title: 'Error'
          });
        }
      }
    });
  };

  const onSave = () => {
    METHOD('onSave', { data });

    if (isDataValid && data.id) {
      updateCommuteOffer(data.id, JSON.stringify(data));
    } else if (!isDataValid) {
      global.openInfoMessage('This offer cannot be saved', {
        title: 'Error'
      });
    } else {
      doSaveAs();
    }
  };

  const onSaveAs = () => {
    METHOD('onSaveAs', { data });

    if (!isDataValid) {
      global.openInfoMessage('This offer cannot be saved', {
        title: 'Error'
      });
    } else {
      doSaveAs();
    }
  };

  const onRecalculate = () => {
    METHOD('onRecalculate', { data });

    if (isDataValid) {
      const { stateless_api_request_data } = data;

      openPopup('ProgressWindow', {
        title: 'Nodes scheduler',
        isCloseDisabled: true,
        status: 'Calculating',
        progress: 1
      });

      const {
        nodes,
        vehicles,
        engine_settings,
        current_time
      } = stateless_api_request_data;
      commuteOfferStatelessNodeScheduler(
        {
          nodes,
          vehicles,
          engine_settings,
          current_time
        },
        {
          openPopup,
          closePopup
        }
      );
    }
  };

  const onViewPassengers = () => {
    METHOD('onViewPassengers', { data });

    if (isDataValid) {
      global.openTableViewer(
        commuteSchedule$PassengersTableHeaders(),
        commuteSchedule$PassengersTableFromOffer(data),
        {
          title: 'Passengers schedule',
          fileName: `${data.name ? `${data.name} - ` : ''}Passengers.csv`
        }
      );
    }
  };

  const onViewTrips = () => {
    METHOD('onViewTrips', { data });

    if (isDataValid) {
      global.openTableViewer(
        commuteOffers$Trips$TableColumns(),
        commuteOffers$Trips$TableFromCommuteOffer(
          commuteOffer$FilterVehicles(data, filteredVehicleIds)
        ),
        {
          title: 'Trips',
          fileName: `${data.name ? `${data.name} - ` : ''}Trips.csv`
        }
      );
    }
  };

  const onViewTimeline = () => {
    METHOD('onViewTimeline', { data });

    if (isDataValid) {
      global.METHODS.reset();
      const config = commuteOffer$GanttScheduleTimeline(
        data,
        global.GEODISC_TIMEZONE
      );
      METHOD('onViewTimeline:config', { config });

      global.openTimelineViewer(config, {
        title: 'Vehicles timeline'
      });
    }
  };

  const onViewRequestSource = () => {
    METHOD('onViewRequestSource', { data });

    if (isDataValid) {
      global.openSourceEditor(data.stateless_api_request_data, {
        title: 'Request source',
        readOnly: true
      });
    }
  };

  const onViewSource = () => {
    METHOD('onViewSource', { data });

    if (isDataValid) {
      global.openSourceEditor(data, { title: 'Commute Offer', readOnly: true });
    }
  };

  const onMenuItemSelect = props => {
    METHOD('onMenuItemSelect', { props });
  };

  const currentProjectId = currentProject ? currentProject.get('id') : 0;

  const projectId =
    data && data.id
      ? (() => {
          const re = /\/.*\/([0-9]*)$/;
          return parseInt(data.project.match(re)[1], 10);
        })()
      : currentProjectId;

  const projectInfo =
    projectId &&
    projects &&
    projects.find(item => item.get('id') === projectId);

  const projectName = projectInfo ? projectInfo.get('name') : undefined;

  if (
    currentProject &&
    projects.size &&
    projectId &&
    projectId !== currentProjectId
  ) {
    setImmediate(() => {
      changeCurrentProject(projectInfo);
    });
  }

  const isUrbicaTestDev =
    isDataValid &&
    (!projectId ||
      projectName === 'urbica_testdev' ||
      projectName === 'Silveray');
  METHOD('isUrbicaTestDev', {
    isDataValid,
    projectId,
    projectInfo,
    isUrbicaTestDev
  });

  const isPassengersTableExists =
    isDataValid &&
    data.stateless_api_request_data.inbound &&
    data.stateless_api_request_data.inbound.schedule &&
    !!Object.keys(data.stateless_api_request_data.inbound.schedule).length;
  METHOD('isPassengersTableExists', {
    isDataValid,
    data,
    isPassengersTableExists
  });

  return (
    <Container>
      <NameLabel isBookingsHidden={isBookingsHidden}>
        {data ? data.name : 'Loading...'}
      </NameLabel>
      <ProjectLabel to="/commuteoffers">
        {projectId // eslint-disable-line
          ? projectInfo
            ? projectInfo.get('name')
            : 'Loading...'
          : ''}
      </ProjectLabel>
      {false && (
        <Menu icon={layersIcon}>
          <MenuButton isActive={isDataValid} onClick={() => {}}>
            Root
          </MenuButton>
        </Menu>
      )}
      <Search />
      <ButtonHistory
        type="button"
        isActive={!!history.past.length}
        onClick={history.past.length ? back : null}
      >
        {history.past.length ? (
          <img alt="" src={undoImgEnabled} />
        ) : (
          <img alt="" src={undoImgDisabled} />
        )}
      </ButtonHistory>
      <ButtonHistory
        type="button"
        isActive={!!history.future.length}
        onClick={history.future.length ? next : null}
      >
        {history.future.length ? (
          <img alt="" src={redoImgEnabled} />
        ) : (
          <img alt="" src={redoImgDisabled} />
        )}
      </ButtonHistory>
      <ButtonSave
        isActive={
          !!(window.GEODISC_UI_COE_SAVE_BUTTON_ALWAYS_ACTIVE || isChanged)
        }
        isSaving={isSaving}
        type="button"
        onClick={onSave}
      >
        {isSaving ? <img alt="" src={savingImg} /> : 'Save'}
      </ButtonSave>
      <Menu>
        {isDataValid && data.id && (
          <MenuButton onClick={onAdmin}>Admin</MenuButton>
        )}
        {isDataValid && <MenuButton onClick={onSaveAs}>Save as...</MenuButton>}
        {!global.GEODISC_STATELESS_API_DISABLE &&
          (isUrbicaTestDev || isDataTransient) &&
          isDataValid && (
            <MenuButton onClick={onImportPassengersSchedule}>
              Import passengers...
            </MenuButton>
          )}
        {!global.GEODISC_STATELESS_API_DISABLE &&
          (isUrbicaTestDev || isDataTransient) &&
          isPassengersTableExists &&
          isDataValid &&
          global.openTableViewer && (
            <MenuButton onClick={onViewPassengers}>
              View passengers...
            </MenuButton>
          )}
        {isDataValid && global.openTimelineViewer && (
          <MenuButton onClick={onViewTrips}>View trips...</MenuButton>
        )}
        {isDataValid && global.openTimelineViewer && (
          <MenuButton onClick={onViewTimeline}>View timeline...</MenuButton>
        )}
        {isDataValid && global.openSourceEditor && (
          <MenuButton onClick={onViewRequestSource}>View request...</MenuButton>
        )}
        {isDataValid && global.openSourceEditor && (
          <MenuButton onClick={onViewSource}>View source...</MenuButton>
        )}
        {isDataValid && (
          <MenuButton onClick={onExportAsJson}>Export JSON</MenuButton>
        )}
        {!global.GEODISC_STATELESS_API_DISABLE && isDataValid && (
          <MenuButton onClick={onRecalculate}>Recalculate</MenuButton>
        )}
      </Menu>
      {false && (
        <StatefulPopover
          placement={PLACEMENT.bottomRight}
          content={() => (
            <StatefulMenu
              onItemSelect={onMenuItemSelect}
              items={[
                { label: 'Item One' },
                { label: 'Import passengers...' },
                { label: 'Item Three' },
                { label: 'Item Four' }
              ]}
            />
          )}
          returnFocus
          autoFocus
        >
          <MenuContainer>
            <Button size={SIZE.compact} kind={KIND.minimal}>
              <Icons.Menu size={24} />
            </Button>
          </MenuContainer>
        </StatefulPopover>
      )}
    </Container>
  );
};

export default React.memo(TopButtons);
