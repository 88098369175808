import React, { PureComponent } from 'react';
import debug from 'utils/debug';

import Button from 'pages/CommuteOffer/TextButton';

import Container from './Container';
import Time from './Time';
import RouteLine from './RouteLine';
import Stops from './Stops';
import Route from './Route';
import ButtonsWrap from './ButtonsWrap';
import Row from './Row';
import Close from './Close';

const { METHOD } = debug(
  'p:CommuteOffer:Panels:Vehicles:Vehicle:BookingStopsEditor'
);

class BookingStopsEditor extends PureComponent {
  onUnassigned = () => {
    METHOD('onUnassigned');

    const {
      nodes,
      removeBookingFromRoute,
      cleanEditableBookingId
    } = this.props;
    removeBookingFromRoute(nodes.booking_uid);
    cleanEditableBookingId();
  };

  onClose = () => {
    METHOD('onClose');

    this.props.cleanEditableBookingId();
  };

  onRemoveNodePickup = () => {
    METHOD('onRemoveNodePickup');

    const { nodes, removeBookingNode } = this.props;
    removeBookingNode(nodes.booking_uid, 'pickup');
  };

  onRemoveNodeDropoff = () => {
    METHOD('onRemoveNodeDropoff');

    const { nodes, removeBookingNode } = this.props;
    removeBookingNode(nodes.booking_uid, 'dropoff');
  };

  render() {
    const { nodes } = this.props;
    const isValid =
      nodes.pickup &&
      nodes.pickup.location_name &&
      nodes.dropoff &&
      nodes.dropoff.location_name;

    METHOD('render', { props: this.props, isValid });

    return (
      <Container>
        <div>{`Edit Booking #${nodes.booking_uid}`}</div>
        <Route>
          <Time>
            <div>{nodes.pickup.scheduled_ts || '--:--'}</div>
            <div>{nodes.dropoff.scheduled_ts || '--:--'}</div>
          </Time>
          <RouteLine />
          <Stops>
            <Row>
              <div>{nodes.pickup.location_name || '---'}</div>
              <Close onClick={this.onRemoveNodePickup} />
            </Row>
            <Row>
              <div>{nodes.dropoff.location_name || '---'}</div>
              <Close onClick={this.onRemoveNodeDropoff} />
            </Row>
          </Stops>
        </Route>

        <ButtonsWrap>
          <Button onClick={this.onUnassigned}>Unassigned</Button>
          {isValid && <Button onClick={this.onClose}>Close</Button>}
        </ButtonsWrap>
      </Container>
    );
  }
}

export default BookingStopsEditor;
