import React from 'react';
import styled from 'styled-components';

import progress_gif from 'assets/progress-1.gif';

const Container = styled.div`
  width: 25px;
  height: 25px;
  margin-top: 6px;
  margin-left: 6px;
  cursor: default;
`;

const Image = styled.img`
  width: 25px;
  height: 25px;
  cursor: default;
`;

const ProgressIndicator = () => (
  <Container>
    <Image alt='' src={progress_gif} />
  </Container>
);

export default ProgressIndicator;
