import { connect } from 'react-redux';
import createImmutableSelector from 'create-immutable-selector';

import {
  currentProjectSelector,
  projectsSelector
} from 'modules/user/selectors';
import { changeCurrentProject } from 'modules/user/actions';

import ProjectMenu from './ProjectMenu';

const mapStateToProps = createImmutableSelector(
  projectsSelector,
  currentProjectSelector,
  (projects, currentProject) => ({ projects, currentProject })
);

const mapDispatchToProps = {
  changeCurrentProject
};

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps
);

export default withConnect(ProjectMenu);
