import { HexagonLayer } from '@deck.gl/aggregation-layers';
import { getColorValueDomain, getColorScaleFunction } from 'utils/deckLayers';
import { pointToHexbin } from './hexagon-aggregator';

const defaultProps = {
  ...HexagonLayer.defaultProps,
  hexagonAggregator: pointToHexbin,
  colorScale: 'quantile'
};

export default class EnhancedHexagonLayer extends HexagonLayer {
  getDimensionUpdaters() {
    const dimensionUpdaters = super.getDimensionUpdaters();
    // add colorScale to dimension updates
    dimensionUpdaters.getColor[1].triggers.push('colorScale');
    return dimensionUpdaters;
  }

  /*
   * override default layer method to calculate color domain
   * and scale function base on color scale type
   */
  getColorValueDomain() {
    getColorValueDomain(this);
  }

  getColorScale() {
    getColorScaleFunction(this);
  }
}

EnhancedHexagonLayer.layerName = 'EnhancedHexagonLayer';
EnhancedHexagonLayer.defaultProps = defaultProps;
