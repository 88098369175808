import { connect } from 'react-redux';
import createImmutableSelector from 'create-immutable-selector';

import { changeDatasetsSearch } from 'modules/ui/actions';
import { clearList, fetchDatasets } from 'modules/datasets/actions';

import { datasetsSearchSelector } from 'modules/ui/selectors';

import Search from 'components/Search';

const mapStateToProps = createImmutableSelector(
  datasetsSearchSelector,
  text => ({
    text
  })
);

const mapDispatchToProps = {
  onChange: changeDatasetsSearch,
  dataFetch: fetchDatasets,
  clearList
};

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps
);

export default withConnect(Search);
