import React, { useState, useEffect } from 'react';
import Immutable from 'immutable';
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  ModalButton
} from 'baseui/modal';
import { ProgressBar } from 'baseui/progress-bar';
import { FormControl } from 'baseui/form-control';
import { Input } from 'baseui/input';
import { Select } from 'baseui/select';
import { sleep } from 'utils/async';

import debug from 'utils/debug';

const { FUNCTION, METHOD } = debug('c:ModalWindows:NameAndProject');

const defaultTitle = 'Choose name and project';

export default props =>
  FUNCTION('NameAndProject', { props }, ({ $METHOD }) => {
    const { currentProject, currentProjectId, projects } = props;

    const projectsArray =
      projects && projects instanceof Immutable.List ? projects.toJS() : [];
    $METHOD('projectsArray', {
      currentProject,
      currentProjectId,
      projects,
      projectsArray
    });

    const isDataValid = currentProject && projectsArray.length;

    const [isOpen, setIsOpen] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [windowTitle, setWindowTitle] = useState(defaultTitle);
    const [targetName, setTargetName] = useState('');
    const [targetProject, setTargetProject] = useState(null);
    const [isObjectSelectionAllowed, setObjectSelectionAllowed] = useState(
      false
    );
    const [objectSelection, setObjectSelection] = useState({
      label: 'All',
      id: 'all'
    });
    const [eventHandlers, setEventHandlers] = useState({});

    if (!targetProject && currentProjectId && projects) {
      const currentProjectInfo = projectsArray.find(
        x => x.id === currentProjectId
      );
      if (currentProjectInfo) {
        setTargetProject({
          label: currentProjectInfo.name,
          id: currentProjectInfo.id
        });
      }
    }

    const disabled = targetName.trim() === '' || !targetProject || isSubmitting;
    $METHOD('disabled', { disabled, targetName, targetProject });

    useEffect(() => {
      METHOD('Update');
      window.openNameAndProject = (name, opts) =>
        FUNCTION('openNameAndProject', { name, opts }, () => {
          const {
            title = defaultTitle,
            onSubmit = null,
            isSelectionAllowed = false
          } = opts || {};
          setTargetName(name || '');
          setWindowTitle(title);
          setEventHandlers({ onSubmit });
          setObjectSelectionAllowed(!!isSelectionAllowed);
          setIsSubmitting(false);
          setIsOpen(true);
        });
      window.closeNameAndProject = () => {
        METHOD('closeNameAndProject');
        setIsOpen(false);
      };
      return () => {
        METHOD('Cleanup');
        window.openNameAndProject = () => {};
      };
    });

    const onClose = () =>
      FUNCTION('onClose', { isSubmitting }, () => {
        if (!isSubmitting) {
          setIsOpen(false);
        }
      });

    const onSumbit = () =>
      FUNCTION(
        'onSumbit',
        { targetName, targetProject, eventHandlers, objectSelection },
        () => {
          if (eventHandlers.onSubmit) {
            const targetProjectId = Array.isArray(targetProject)
              ? targetProject[0].id
              : targetProject.id;
            const objectSelectionId = Array.isArray(objectSelection)
              ? objectSelection[0].id
              : objectSelection.id;
            const eventHandlerArgs = {
              name: targetName,
              project: targetProjectId,
              selection: isObjectSelectionAllowed ? objectSelectionId : 'all'
            };
            $METHOD('eventHandlerArgs', eventHandlerArgs);
            const result = eventHandlers.onSubmit(eventHandlerArgs);
            if (result instanceof Promise) {
              setIsSubmitting(true);
              result.then(
                ($result) => {
                  $METHOD('onSumbit:Done', { $result });
                  setIsOpen(false);
                },
                ($error) => {
                  $METHOD('onSumbit:Error', { $error });
                  setIsOpen(false);
                }
              );
            } else {
              setIsOpen(false);
            }
          } else {
            setIsOpen(false);
          }
        }
      );

    return (
      <React.Fragment>
        <Modal
          onClose={onClose}
          isOpen={isOpen}
          overrides={{
            Dialog: {
              style: {
                minWidth: '800px',
                maxWidth: '95vw',
                display: 'flex',
                flexDirection: 'column'
              }
            }
          }}
        >
          <ModalHeader>{windowTitle}</ModalHeader>
          {isDataValid && !isSubmitting ? (
            <ModalBody style={{ flex: '1 1 0' }}>
              <FormControl label={() => 'Name'}>
                <Input
                  value={targetName}
                  onChange={e => setTargetName(e.target.value)}
                  placeholder='Name'
                />
              </FormControl>
              <FormControl label={() => 'Project'}>
                <Select
                  clearable={false}
                  options={projectsArray.map(project => ({
                    label: project.name,
                    id: project.id
                  }))}
                  value={targetProject}
                  placeholder='Select a project'
                  onChange={params => setTargetProject(params.value)}
                />
              </FormControl>
              {isObjectSelectionAllowed && (
                <FormControl label={() => 'Objects'}>
                  <Select
                    clearable={false}
                    options={[
                      {
                        label: 'All',
                        id: 'all'
                      },
                      {
                        label: 'Selected only',
                        id: 'selected'
                      },
                      {
                        label: 'Visible only',
                        id: 'visible'
                      }
                    ]}
                    value={objectSelection}
                    placeholder='Select'
                    onChange={params => setObjectSelection(params.value)}
                  />
                </FormControl>
              )}
            </ModalBody>
          ) : (
            <ModalBody>
              <ProgressBar
                getProgressLabel={() =>
                  isSubmitting ? 'Saving...' : 'Loading...'}
                showLabel
                infinite
              />
            </ModalBody>
          )}
          <ModalFooter>
            <ModalButton disabled={disabled} onClick={onSumbit}>
              Save
            </ModalButton>
          </ModalFooter>
        </Modal>
      </React.Fragment>
    );
  });

window.openNameAndProject$Test1 = () => {
  window.openNameAndProject('', {
    title: 'Test 1',
    onSubmit: async () => {
      await sleep(5000);
    }
  });
};

window.openNameAndProject$Test2 = () => {
  window.openNameAndProject('', {
    title: 'Test 2',
    onSubmit: async () => {
      await sleep(5000);
      throw new Error('It happens...');
    }
  });
};
