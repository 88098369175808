import { connect } from 'react-redux';
import { ActionCreators } from 'redux-undo';
import createImmutableSelector from 'create-immutable-selector';

import { vehiclesSelector } from 'modules/simulations/selectors';
import { newVehicle } from 'modules/commuteOffer/actions';

import Vehicles from './Vehicles';

const mapStateToProps = createImmutableSelector(
  vehiclesSelector,
  vehicles => ({
    vehicles
  })
);

const mapDispatchToProps = {
  newVehicle,
  back: ActionCreators.undo,
  next: ActionCreators.redo
};

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps
);

export default withConnect(Vehicles);
