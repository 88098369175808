import debug from 'utils/debug';

const { METHOD } = debug('m:CommuteOffer:IsEmpty');

// eslint-disable-next-line import/prefer-default-export
export const commuteOffer$IsEmpty = (commuteOffer) => {
  METHOD('commuteOffer$IsEmpty', { commuteOffer });

  return (
    !commuteOffer.stateless_api_request_data.nodes.length &&
    !commuteOffer.stateless_api_request_data.vehicles.length
  );
};
