import React from 'react';
import Tag from 'components/Tag';

import Loading from 'components/Loading';
import Error from 'components/Error';

import Row from './Row';
import Container from './Container';
import Text from './Text';
import HeaderButtons from './HeaderButtons';
import Name from './Name';
import DescriptionInput from './DescriptionInput';
import Description from './Description';
import Button from './Button';
import EditButtons from './EditButtons';
import Tags from './Tags';

const Component = (props) => {
  const { error, editableDataset } = props;

  if (props.dataset) {
    const dataset_id = props.dataset.get('id');
    const isEditable =
      editableDataset && editableDataset.get('id') === dataset_id;
    const dataset = isEditable ? editableDataset : props.dataset;

    const name = dataset.get('name');
    const description = dataset.get('description');
    const tags = dataset.get('tags');

    const onSave = () => {
      const body = JSON.stringify({
        name,
        description
      });

      props.saveDataset(dataset_id, body);
    };

    const onCancelEdit = () => {
      props.setEditableDataset(null);
    };

    const handlerOnChange = (e) => {
      const { id, value } = e.target;
      props.updateEditableDataset(id, value);
    };

    return (
      <Container>
        <HeaderButtons />
        <Row>
          <Name
            id='name'
            value={dataset.get('name')}
            onChange={handlerOnChange}
            isEditable={isEditable}
            placeholder='name'
          />
        </Row>
        {description &&
          (isEditable ? (
            <DescriptionInput
              id='description'
              value={description}
              onChange={handlerOnChange}
              isEditable={isEditable}
              placeholder='description'
            />
          ) : (
            <Description>{description}</Description>
          ))}
        {isEditable && (
          <EditButtons>
            <Button onClick={onSave}>Save</Button>
            <Button onClick={onCancelEdit}>Cancel</Button>
          </EditButtons>
        )}
        {!isEditable && <Text>{`${dataset.get('trips')} rows`}</Text>}
        {!tags.isEmpty() ? (
          <Tags>
            {tags.map(tag => (
              <Tag key={tag} name={tag} />
            ))}
          </Tags>
        ) : null}
      </Container>
    );
  }

  return (
    <Container>
      <Row>{error ? <Error /> : <Loading />}</Row>
    </Container>
  );
};

const Header = props => (
  <React.Suspense>
    <Component {...props} />
  </React.Suspense>
);

export default Header;
