import styled from 'styled-components';

const Container = styled.div`
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background: ${({ color }) => color || 'blue'};
  border: solid 2px white;
`;

export default Container;
