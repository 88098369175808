import React from 'react';

import Header from 'components/Header';
import Layout from 'components/Layout';

import Container from './Container';
import Panel from './Panel';
import Table from './Table';
import TableHeader from './TableHeader';

const Datasets = () => (
  <Layout hasBackground>
    <Container>
      <Header />
      <Panel />
      <TableHeader />
      <Table />
    </Container>
  </Layout>
);

export default Datasets;
