import React from 'react';

import ButtonClose from './ButtonClose';

import Container from './Container';

const Selected = ({ selectedIds, clearSelectedDatasets }) => {
  if (!selectedIds.size) {
    return null;
  }

  return (
    <React.Fragment>
      <Container>{`${selectedIds.size} selected`}</Container>
      <ButtonClose onClick={clearSelectedDatasets} />
    </React.Fragment>
  );
};

export default Selected;
