import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import createImmutableSelector from 'create-immutable-selector';
import { branch, compose, renderNothing } from 'recompose';

import { userNameSelector, isAdminSelector } from 'modules/user/selectors';
import { deleteUser } from 'modules/user/actions';

import UserMenu from './UserMenu';

const withBranch = branch(({ userName }) => !userName, renderNothing);

const mapStateToProps = createImmutableSelector(
  userNameSelector,
  isAdminSelector,
  (userName, isAdmin) => ({ userName, isAdmin })
);

const mapDispatchToProps = { deleteUser };

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps
);

const enhance = compose(
  withConnect,
  withBranch,
  withRouter
);

export default enhance(UserMenu);
