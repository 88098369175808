import React, { memo } from 'react';
import Layout from 'components/Layout';
import debug from 'utils/debug';

import InfoPanel from './InfoPanel';
import LeftPanels from './LeftPanels';
import TopPanels from './TopPanels';
import EventListener from './EventListener';

const { DEBUG } = debug('p:Simulation:Simulation');

const Simulation = ({
  websocketConnected,
  websocketDisonnected,
  simulationEvent,
  id,
  wsToken
}) => {
  DEBUG('render()');

  const url =
    window.GEODISC_API_SIMULATION_EVENTS_URL ||
    `${window.location.protocol === 'https:' ? 'wss:' : 'ws:'}//${
      window.location.host
    }`;
  const version = parseInt(window.SIMULATION_EVENTS_VER || '1', 10);

  const tokenStr = wsToken !== '0' ? `?token=${wsToken}` : '';

  return (
    <Layout>
      {window.GEODISC_UI_SIMULATION_MULTIPLE_PANELS_DISABLE && <InfoPanel />}
      {!window.GEODISC_UI_SIMULATION_MULTIPLE_PANELS_DISABLE && <LeftPanels />}
      <TopPanels />
      {!window.GEODISC_API_SIMULATION_EVENT_LISTENER_DISABLE && wsToken && (
        <EventListener
          url={`${url}/simulation_events/${id}${tokenStr}`}
          version={version}
          websocketConnected={websocketConnected}
          websocketDisonnected={websocketDisonnected}
          simulationEvent={simulationEvent}
        />
      )}
    </Layout>
  );
};

export default memo(Simulation);
