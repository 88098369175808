import debug from 'utils/debug';

const { FUNCTION } = debug('m:CommuteOffer');

// eslint-disable-next-line import/prefer-default-export
export const commuteOffer$FilterVehicles = (commuteOffer, vehicles) =>
  FUNCTION('FilterVehicles', { commuteOffer, vehicles }, ({ $METHOD }) => {
    const vehicleIdSet = (() => {
      if (vehicles instanceof Set) {
        $METHOD('vehicles:Set', { vehicles });
        return vehicles;
      }
      if (Array.isArray(vehicles)) {
        $METHOD('vehicles:Array', { vehicles });
        return new Set(vehicles.filter(id => id !== 'DEADBEEF'));
      }
      if (typeof vehicles === 'string') {
        $METHOD('vehicles:String', { vehicles });
        return new Set([vehicles]);
      }
      $METHOD('vehicles:Invalid', { vehicles, $vehicles: typeof vehicles });
      throw new Error(`Invalid type ${typeof vehicles}`);
    })();
    $METHOD('vehicleIdSet', { vehicleIdSet });

    const { result, stateless_api_request_data } = commuteOffer;

    const selectedObjects = Object.keys(result.vehicles).reduce(
      (resultVehiclesMemo, vehicleId) => {
        if (!vehicleIdSet.has(vehicleId)) {
          return resultVehiclesMemo;
        }
        const vehicle = result.vehicles[vehicleId];
        $METHOD('selectedObjects:vehicle', {
          resultVehiclesMemo,
          vehicleId,
          vehicle
        });
        return {
          vehicles: {
            ...resultVehiclesMemo.vehicles,
            [vehicleId]: vehicle
          },
          bookings: [
            ...resultVehiclesMemo.bookings,
            ...vehicle.map(node => node.booking_uid)
          ]
        };
      },
      { vehicles: {}, bookings: [] }
    );
    $METHOD('selectedObjects', { selectedObjects });

    const bookingIdSet = new Set(selectedObjects.bookings);
    $METHOD('bookingIdSet', { bookingIdSet });

    const requestNodes = stateless_api_request_data.nodes.filter(node =>
      bookingIdSet.has(node.booking_uid));
    $METHOD('requestNodes', { requestNodes });

    const requestBookings = Object.keys(
      stateless_api_request_data.bookings
    ).reduce(
      (requestBookingsMemo, bookingId) =>
        bookingIdSet.has(bookingId)
          ? {
              ...requestBookingsMemo,
              [bookingId]: stateless_api_request_data.bookings[bookingId]
            }
          : requestBookingsMemo,
      {}
    );
    $METHOD('requestBookings', { requestBookings });

    const requestVehicles = stateless_api_request_data.vehicles.filter(
      vehicle => !!selectedObjects.vehicles[vehicle.agent_id]
    );
    $METHOD('requestVehicles', { requestVehicles });

    const resultAssignedBookings = result.assigned_bookings.filter(
      booking => !!selectedObjects.vehicles[booking.assigned_vehicle_id]
    );
    $METHOD('resultAssignedBookings', { resultAssignedBookings });

    return {
      ...commuteOffer,
      stateless_api_request_data: {
        ...commuteOffer.stateless_api_request_data,
        bookings: requestBookings,
        nodes: requestNodes,
        vehicles: requestVehicles,
        inbound: undefined
      },
      result: {
        ...commuteOffer.result,
        vehicles: selectedObjects.vehicles,
        assigned_bookings: resultAssignedBookings
      }
    };
  });
