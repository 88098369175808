import createImmutableSelector from 'create-immutable-selector';
import { getSource } from 'utils/geofences';
import {
  hiddenGeofencesSelector,
  geofencesSearchSelector
} from '../ui/selectors';

export const geofencesSelector = state => state.get('geofences');

export const geofencesFilteredSelector = createImmutableSelector(
  geofencesSelector,
  geofencesSearchSelector,
  (geofences, search) => {
    if (!geofences) {
      return [];
    }

    return geofences.filter((value) => {
      if (search === '') {
        return true;
      }

      if (search[0] === '#') {
        const tags = value.tags;
        return tags.includes(search.slice(1));
      }

      const name = value.name.toLowerCase();

      return name.indexOf(search.toLowerCase()) !== -1;
    });
  }
);

export const geofencesFilteredSizeSelector = createImmutableSelector(
  geofencesFilteredSelector,
  geofences => geofences.length
);

export const geofencesSourceSelector = createImmutableSelector(
  geofencesFilteredSelector,
  hiddenGeofencesSelector,
  (geofences, hiddenGeofences) => {
    if (!geofences.length) {
      return getSource([]);
    }

    const filteredGeofences = geofences.filter(
      value => !hiddenGeofences.includes(value.id)
    );
    return getSource(filteredGeofences);
  }
);
