import { connect } from 'react-redux';
import { compose, lifecycle } from 'recompose';
import createImmutableSelector from 'create-immutable-selector';

import { stopsSelector } from 'modules/entities/selectors';

import {
  simulationSelector,
  simulationDataSelector,
  simulationsWsTokenSelector
} from 'modules/simulations/selectors';

import {
  fetchSimulationDataStart,
  fetchSimulation,
  clearSimulation,
  websocketConnected,
  websocketDisconnected,
  simulationEvent
} from 'modules/simulations/actions';

import { routeIdSelector } from 'modules/router/selectors';
import { fetchStops } from 'modules/entities/actions';

import Simulation from './Simulation';

const withLifecycle = lifecycle({
  componentDidMount() {
    const { stops, simulation, id } = this.props;

    const refetch = simulation && simulation.get('id') !== id;

    if (!stops) {
      this.props.fetchStops();
    }

    if (!simulation || refetch) {
      this.props.clearSimulation();
      this.props.fetchSimulation(id);
      this.props.fetchSimulationDataStart(id);
    }
  }
});

const mapStateToProps = createImmutableSelector(
  stopsSelector,
  routeIdSelector,
  simulationSelector,
  simulationDataSelector,
  simulationsWsTokenSelector,
  (stops, id, simulation, data, wsToken) => ({
    stops,
    id,
    simulation,
    data,
    wsToken
  })
);

const mapDispatchToProps = {
  fetchStops,
  fetchSimulation,
  fetchSimulationDataStart,
  clearSimulation,
  websocketConnected,
  websocketDisconnected,
  simulationEvent
};

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps
);

const enhance = compose(
  withConnect,
  withLifecycle
);

export default enhance(Simulation);
