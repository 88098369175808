import React from 'react';
import { Formik } from 'formik';
import debug from 'utils/debug';

import Input from 'components/Input';
import ResetButton from 'components/ResetButton';
import SubmitButton from 'components/SubmitButton';

import Row from './Row';
import ButtonsRow from '../ButtonsRow';

const { METHOD } = debug('c:ModalWindow:NewCommuteOffer:Preview');

const Preview = ({ file, onReset, uploadData, projectUrl }) => (
  <Formik
    initialValues={{
      name: file.name,
      project: projectUrl
    }}
    onSubmit={(params) => {
      METHOD('onSubmit', params);

      uploadData(params);
    }}
  >
    {(props) => {
      const { values, handleChange, handleSubmit, handleBlur } = props;

      return (
        <form onSubmit={handleSubmit} autoComplete='off'>
          <Row>
            <Input
              name='name'
              type='text'
              label='Name'
              value={values.name}
              onChange={handleChange}
              handleBlur={handleBlur}
              autoComplete='off'
            />
          </Row>
          <ButtonsRow>
            <ResetButton onClick={onReset}>Choose a different file</ResetButton>
            <SubmitButton type='submit'>Create new commute offer</SubmitButton>
          </ButtonsRow>
        </form>
      );
    }}
  </Formik>
);

export default Preview;
