import styled from 'styled-components';

const Badge = styled.div`
  display: block;
  position: relative;
  text-align: center;
  vertical-align: middle;

  width: 90px;
  height: 25px;
  cursor: pointer;
  margin: 6px 2px 2px 2px;
  padding: 4px 8px 2px 8px;
  opacity: 1;
  color: white;
  border-radius: 7px
  background: gray;

  transition: background 0.2s;

  &:hover {
    background: gray;
  }
`;

export default Badge;
