import { connect } from 'react-redux';
import createImmutableSelector from 'create-immutable-selector';

import { geofencesSourceSelector } from 'modules/geofences/selectors';
import {
  editableGeofenceSelector,
  geofencesHoverSelector
} from 'modules/ui/selectors';

import { geofencesViewportSelector } from 'modules/maps/selectors';

import { updateEditableGeofence } from 'modules/ui/actions';
import { changeViewportGeofences } from 'modules/maps/actions';

import Map from './Map';

const mapStateToProps = createImmutableSelector(
  geofencesViewportSelector,
  geofencesSourceSelector,
  editableGeofenceSelector,
  geofencesHoverSelector,
  (viewport, source, editableGeofence, hoverId) => ({
    viewport,
    source,
    editableGeofence,
    hoverId
  })
);

const mapDispatchToProps = {
  updateEditableGeofence,
  onViewportChange: changeViewportGeofences
};

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps
);

export default withConnect(Map);
