import createImmutableSelector from 'create-immutable-selector';
import debug from 'utils/debug';
import { createSelector } from 'reselect';

import { featureCollection } from '@turf/helpers';

import { stopSearchQuerySelector } from 'modules/ui/selectors';

const { METHOD } = debug('m:entities:selectors');

export const entitiesSelector = (state) => {
  METHOD('entitiesSelector:Request', { state });

  const res = state.get('entities');

  METHOD('entitiesSelector:Success', { state, result: res });
  return res;
};

export const stopsSelector = createImmutableSelector(
  entitiesSelector,
  (entities) => {
    METHOD('stopsSelector:Request');

    const result = entities.get('stops');

    METHOD('stopsSelector:Success', result);
    return result;
  }
);

export const stopsByTypeSelector = createImmutableSelector(
  entitiesSelector,
  (entities) => {
    METHOD('stopsByTypeSelector:Request');

    const result = entities.get('stopsByType');

    METHOD('stopsByTypeSelector:Success', result);
    return result;
  }
);

export const filteredStopsSelector = createImmutableSelector(
  stopsSelector,
  stopSearchQuerySelector,
  (stops, stopSearchQuery) => {
    METHOD('filteredStopsSelector:Request', { stops, stopSearchQuery });

    if (!stops || !stopSearchQuery) {
      METHOD('filteredStopsSelector:Success', {
        stops,
        stopSearchQuery,
        result: []
      });
      return [];
    }

    const res = Object.keys(stops).reduce((acc, key) => {
      const query = stopSearchQuery.toLowerCase();

      if (stops[key].properties.name.toLowerCase().match(query)) {
        acc.push({ name: stops[key].properties.name, id: key });
      }
      return acc;
    }, []);

    METHOD('filteredStopsSelector:Success', {
      stops,
      stopSearchQuery,
      result: res
    });
    return res;
  }
);

export const stopsSourceSelector = createSelector(
  stopsSelector,
  (stops) => {
    METHOD('stopsSourceSelector:Request', { stops });

    const res = stops
      ? featureCollection(
          Object.keys(stops).map(key => ({
            id: stops[key].properties.id,
            ...stops[key]
          }))
        )
      : featureCollection([]);

    METHOD('stopsSourceSelector:Success', { stops, result: res });
    return res;
  }
);

export const stopTypesSelector = createSelector(
  stopsByTypeSelector,
  (stopsByType) => {
    METHOD('stopTypesSelector:Request', { stopsByType });

    const res = stopsByType ? Object.keys(stopsByType) : [];

    METHOD('stopTypesSelector:Success', { stopsByType, result: res });
    return res;
  }
);
