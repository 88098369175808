// @flow

import createImmutableSelector from 'create-immutable-selector';
import { createSelector } from 'reselect';
import moment from 'moment';

import { getRoute } from 'utils/CommuteOffer';

import { stopsSelector } from 'modules/entities/selectors';
import {
  editableBookingIdSelector,
  editableVehicleIdSelector,
  searchQuerySelector,
  bookingsFilterSelector,
  vehiclesFilterSelector,
  activeVehicleIdsSelector,
  activeBookingIdSelector
} from 'modules/ui/selectors';
import debug from 'utils/debug';
import { colorByValue } from 'utils/color';

const { METHOD, MESSAGE, FUNCTION } = debug('m:commuteOffer:selectors');

const areImmutableSelectorsEnabled = true;

const geodisc$CreateImmutableSelector = areImmutableSelectorsEnabled
  ? createImmutableSelector
  : createSelector;

const REQUIRED_SELECTOR = (name, fn) => {
  // return ({ ...a }) => {
  //   METHOD(`s:${name}`, { ...a });
  //   COUNTER(`s:${name}`);
  //   return fn(...a);
  // };
  return fn;
};

export const commuteOfferStoreSelector = state =>
  FUNCTION(
    'commuteOfferStoreSelector',
    { state, $fn: { state: () => state.toJS() } },
    () => state.getIn(['commuteOffer', 'present'])
  );

export const commuteOfferHistorySelector = state =>
  FUNCTION(
    'commuteOfferHistorySelector',
    { state, $fn: { state: () => state.toJS() } },
    () => ({
      past: state.getIn(['commuteOffer', 'past']).slice(2),
      future: state.getIn(['commuteOffer', 'future'])
    })
  );

export const commuteOfferListSelector = geodisc$CreateImmutableSelector(
  REQUIRED_SELECTOR(
    'commuteOfferListSelector:commuteOfferRoutesSelector',
    commuteOfferStoreSelector
  ),
  commuteOffer =>
    FUNCTION('commuteOfferListSelector', { commuteOffer }, () =>
      commuteOffer.get('list'))
);

export const serialNumberSelector = geodisc$CreateImmutableSelector(
  REQUIRED_SELECTOR(
    'commuteOfferListSelector:commuteOfferRoutesSelector',
    commuteOfferStoreSelector
  ),
  commuteOffer =>
    FUNCTION('serialNumberSelector', { commuteOffer }, () =>
      commuteOffer.get('serialNumber'))
);

export const commuteOfferRoutesSelector = geodisc$CreateImmutableSelector(
  REQUIRED_SELECTOR(
    'commuteOfferRoutesSelector:commuteOfferRoutesSelector',
    commuteOfferStoreSelector
  ),
  commuteOffer =>
    FUNCTION('commuteOfferRoutesSelector', { commuteOffer }, () =>
      commuteOffer.get('routes'))
);

export const commuteOfferCurrentLayerSelector = geodisc$CreateImmutableSelector(
  REQUIRED_SELECTOR(
    'commuteOfferCurrentLayerSelector:commuteOfferRoutesSelector',
    commuteOfferStoreSelector
  ),
  commuteOffer =>
    FUNCTION('commuteOfferCurrentLayerSelector', { commuteOffer }, () =>
      commuteOffer.get('currentLayer'))
);

export const countSelector = geodisc$CreateImmutableSelector(
  REQUIRED_SELECTOR(
    'countSelector:commuteOfferRoutesSelector',
    commuteOfferStoreSelector
  ),
  commuteOffer =>
    FUNCTION('countSelector', { commuteOffer }, () => commuteOffer.get('count'))
);

export const commuteOfferOriginalDataSelector = geodisc$CreateImmutableSelector(
  REQUIRED_SELECTOR(
    'commuteOfferOriginalDataSelector:commuteOfferRoutesSelector',
    commuteOfferStoreSelector
  ),
  (commuteOffer) => {
    const data = FUNCTION(
      'commuteOfferOriginalDataSelector',
      { commuteOffer },
      () => commuteOffer.get('originalOffer')
    );
    global.GEODISC_CO_ORIGINAL_DATA = data;
    return data;
  }
);
global.commuteOfferOriginalDataSelector = commuteOfferOriginalDataSelector;

export const commuteOfferCurrentDataSelector = geodisc$CreateImmutableSelector(
  REQUIRED_SELECTOR(
    'commuteOfferCurrentDataSelector:commuteOfferRoutesSelector',
    commuteOfferStoreSelector
  ),
  (commuteOffer) => {
    const data = FUNCTION(
      'commuteOfferCurrentDataSelector',
      { commuteOffer },
      () => commuteOffer.get('currentOffer')
    );
    global.GEODISC_CO_CURRENT_DATA = data;
    return data;
  }
);

export const nodesSelector = createSelector(
  REQUIRED_SELECTOR(
    'nodesSelector:commuteOfferCurrentDataSelector',
    commuteOfferCurrentDataSelector
  ),
  (data) => {
    const res = FUNCTION(
      'nodesSelector',
      { data },
      () => data && data.stateless_api_request_data.nodes
    );
    return res;
  }
);

export const nodesByBookingIdSelector = createSelector(
  nodesSelector,
  (nodes) => {
    const res = FUNCTION(
      'nodesByBookingIdSelector',
      { nodes },
      () =>
        nodes &&
        nodes.reduce((acc, item) => {
          if (!acc[item.booking_uid]) {
            acc[item.booking_uid] = [item];
          }
          acc[item.booking_uid].push(item);
          return acc;
        }, {})
    );
    return res;
  }
);

export const nodesDirectorySelector = geodisc$CreateImmutableSelector(
  nodesSelector,
  nodes =>
    FUNCTION(
      'nodesDirectorySelector',
      { nodes },
      () =>
        nodes &&
        nodes.reduce((acc, item) => {
          if (item.uid) {
            acc[item.uid] = item;
          }

          return acc;
        }, {})
    )
);

export const vehiclesRequestSelector = createSelector(
  REQUIRED_SELECTOR(
    'vehiclesRequestSelector:commuteOfferCurrentDataSelector',
    commuteOfferCurrentDataSelector
  ),
  (data) => {
    const res = FUNCTION('vehiclesRequestSelector', { data }, () =>
      data ? data.stateless_api_request_data.vehicles : []);
    return res;
  }
);

export const vehiclesWithColorSelector = createSelector(
  REQUIRED_SELECTOR(
    'vehiclesWithColorSelector:commuteOfferCurrentDataSelector',
    commuteOfferCurrentDataSelector
  ),
  data =>
    FUNCTION('vehiclesWithColorSelector', { data }, () => {
      const requestVehicles = data
        ? data.stateless_api_request_data.vehicles
        : [];
      const resultVehicles = data ? data.result.vehicles : [];

      if (!requestVehicles || !Array.isArray(requestVehicles)) {
        MESSAGE('vehiclesWithColorSelector', {
          message: 'Error: Invalid vehicles list type. It must be an array.',
          requestVehicles
        });
        return [];
      }

      return requestVehicles
        .map((vehicle) => {
          const resultVehicle = resultVehicles[vehicle.agent_id];
          const $hasResult = !!(resultVehicle && resultVehicle.length);
          const colors =
            resultVehicle && resultVehicle.length
              ? colorByValue(vehicle.vehicle_color)
              : { color: '#c5cede', activeColor: '#c5cede' };

          return {
            ...vehicle,
            ...colors,
            $hasResult
          };
        })
        .sort(vehicle => (vehicle.$hasResult ? -1 : 0));
    })
);

export const allVehiclesSelector = createSelector(
  REQUIRED_SELECTOR(
    'allVehiclesSelector:serialNumberSelector',
    serialNumberSelector
  ),
  REQUIRED_SELECTOR(
    'allVehiclesSelector:vehiclesWithColorSelector',
    vehiclesWithColorSelector
  ),
  REQUIRED_SELECTOR(
    'allVehiclesSelector:commuteOfferCurrentDataSelector',
    commuteOfferCurrentDataSelector
  ),
  REQUIRED_SELECTOR(
    'allVehiclesSelector:nodesDirectorySelector',
    nodesDirectorySelector
  ),
  (serialNumber, vehicles, currentData, nodes) =>
    FUNCTION(
      'allVehiclesSelector',
      {
        serialNumber,
        vehicles,
        currentData,
        nodes
      },
      () => {
        if (!currentData) {
          return [];
        }

        return vehicles.map((vehicle) => {
          const currentVehicle =
            currentData.result.vehicles &&
            currentData.result.vehicles[vehicle.agent_id];
          if (!currentVehicle) {
            MESSAGE('allVehiclesSelector', {
              message: 'Error: Vehicle not found',
              vehicle
            });
          }
          const vehicleRoute =
            currentVehicle && getRoute(currentVehicle, nodes, vehicle.agent_id);
          const route = currentVehicle ? vehicleRoute : [];

          return {
            ...vehicle,
            route
          };
        });
      }
    )
);

export const unfilteredVehiclesSelector = createSelector(
  REQUIRED_SELECTOR(
    'unfilteredVehiclesSelector:serialNumberSelector',
    allVehiclesSelector
  ),
  REQUIRED_SELECTOR(
    'unfilteredVehiclesSelector:vehiclesWithColorSelector',
    vehiclesWithColorSelector
  ),
  REQUIRED_SELECTOR(
    'unfilteredVehiclesSelector:commuteOfferOriginalDataSelector',
    commuteOfferOriginalDataSelector
  ),
  REQUIRED_SELECTOR(
    'unfilteredVehiclesSelector:commuteOfferCurrentDataSelector',
    commuteOfferCurrentDataSelector
  ),
  REQUIRED_SELECTOR(
    'unfilteredVehiclesSelector:nodesDirectorySelector',
    nodesDirectorySelector
  ),
  REQUIRED_SELECTOR(
    'unfilteredVehiclesSelector:activeVehicleIdsSelector',
    activeVehicleIdsSelector
  ),
  (
    serialNumber,
    vehicles,
    originalData,
    currentData,
    nodes,
    activeVehicleIds
  ) =>
    FUNCTION(
      'unfilteredVehiclesSelector',
      {
        serialNumber,
        vehicles,
        originalData,
        currentData,
        nodes,
        activeVehicleIds
      },
      () => {
        if (!currentData) {
          return [];
        }

        return vehicles.map((vehicle) => {
          const originalVehicleRequest = originalData.stateless_api_request_data.vehicles.filter(
            o => o.agent_id === vehicle.agent_id
          );
          const currentVehicleRequest = currentData.stateless_api_request_data.vehicles.filter(
            o => o.agent_id === vehicle.agent_id
          );
          const originalVehicle =
            originalData.result.vehicles &&
            originalData.result.vehicles[vehicle.agent_id];
          const currentVehicle =
            currentData.result.vehicles &&
            currentData.result.vehicles[vehicle.agent_id];
          const isChanged =
            JSON.stringify(originalVehicle) !==
              JSON.stringify(currentVehicle) ||
            JSON.stringify(originalVehicleRequest) !==
              JSON.stringify(currentVehicleRequest);
          if (!currentVehicle) {
            MESSAGE('unfilteredVehiclesSelector', {
              message: 'Error: Vehicle not found',
              vehicle
            });
          }
          const vehicleRoute =
            currentVehicle && getRoute(currentVehicle, nodes, vehicle.agent_id);
          const route =
            currentVehicle &&
            (!activeVehicleIds.length ||
              activeVehicleIds.includes(vehicle.agent_id))
              ? vehicleRoute
              : [];

          const passengers =
            vehicleRoute &&
            vehicleRoute.reduce(
              (acc, node) => {
                if (node.node_type === 'pickup') {
                  acc.count += node.pax;
                  acc.transferred += node.pax;
                } else if (node.node_type === 'dropoff') {
                  acc.count -= node.pax;
                }
                acc.occupied = Math.max(acc.count, acc.occupied);
                return acc;
              },
              { occupied: 0, transferred: 0, count: 0 }
            );

          return {
            ...vehicle,
            $debug: {
              originalVehicleRequest,
              currentVehicleRequest
            },
            isChanged,
            route,
            passengers: passengers || {
              occupied: 0,
              transferred: 0
            }
          };
        });
      }
    )
);

export const filteredVehiclesSelector = createSelector(
  unfilteredVehiclesSelector,
  vehiclesFilterSelector,
  (vehicles, vehiclesFilter) =>
    FUNCTION('filteredVehiclesSelector', { vehicles, vehiclesFilter }, () => {
      const filteringArgs = vehiclesFilter
        .trim()
        .split(' ')
        .map(x => x.trim());

      const commands = filteringArgs.filter(x => x.indexOf('!') === 0);
      const filters = filteringArgs.filter(x => x.indexOf('!') !== 0);

      if (!filters.length && !commands) {
        return vehicles;
      }

      return vehicles.filter(
        vehicle =>
          filters
            .map(
              filter => vehicle.agent_id.toLowerCase().indexOf(filter) >= 0
            )
            .find(x => !x) !== false
      );
    })
);

export const filteredVehicleIdsSelector = createSelector(
  filteredVehiclesSelector,
  vehicles =>
    FUNCTION('filteredVehicleIdsSelector', { vehicles }, () =>
      vehicles.map(vehicle => vehicle.agent_id))
);

export const unfilteredBookingsSelector = createSelector(
  REQUIRED_SELECTOR(
    'unfilteredBookingsSelector:serialNumberSelector',
    serialNumberSelector
  ),
  REQUIRED_SELECTOR(
    'unfilteredBookingsSelector:commuteOfferCurrentDataSelector',
    commuteOfferCurrentDataSelector
  ),
  REQUIRED_SELECTOR('unfilteredBookingsSelector:stopsSelector', stopsSelector),
  REQUIRED_SELECTOR(
    'unfilteredBookingsSelector:unfilteredVehiclesSelector',
    unfilteredVehiclesSelector
  ),
  REQUIRED_SELECTOR(
    'unfilteredBookingsSelector:bookingsFilterSelector',
    bookingsFilterSelector
  ),
  (serialNumber, data, stops, vehicles, bookingsFilter) =>
    FUNCTION(
      'unfilteredBookingsSelector',
      { serialNumber, data, stops, vehicles, bookingsFilter },
      () => {
        if (!data || !stops) {
          return [];
        }

        const { result, stateless_api_request_data } = data;
        const { assigned_bookings, rejected_bookings } = result;

        const assignedBookings = assigned_bookings || [];
        const rejectedBookings = rejected_bookings || [];
        const allBookings = [...assignedBookings, ...rejectedBookings];
        METHOD('unfilteredBookingsSelector:Bookings:All', allBookings);

        const res = allBookings.map((item) => {
          const requestBooking = stateless_api_request_data.bookings[item.uid];

          const currentVehicle = vehicles.find(
            vehicle => item.assigned_vehicle_id === vehicle.agent_id
          );
          if (!currentVehicle) {
            MESSAGE('unfilteredBookingsSelector', {
              message:
                'Error: Booking has a reference to a non-existing vehicle.',
              booking: item
            });
          }

          const $routeStops =
            currentVehicle &&
            currentVehicle.route.filter(
              stop =>
                stop.bookings &&
                stop.bookings.find(booking => booking.id === item.uid)
            );
          METHOD('unfilteredBookingsSelector:routeStops', { $routeStops });

          const routeStop =
            currentVehicle &&
            currentVehicle.route.find(
              stop =>
                stop.bookings &&
                stop.bookings.find(booking => booking.id === item.uid)
            );

          return {
            ...requestBooking,
            ...item,
            color: currentVehicle && currentVehicle.activeColor,
            $routeStopId: routeStop && routeStop.id,
            $routeStops
          };
        });

        return res;
      }
    )
);

export const filteredBookingsSelector = createSelector(
  unfilteredBookingsSelector,
  bookingsFilterSelector,
  filteredVehiclesSelector,
  (bookings, bookingsFilter, filteredVehicles) =>
    FUNCTION(
      'filteredBookingsSelector',
      { bookings, bookingsFilter, filteredVehicles },
      () => {
        const filteredVehiclesSet = filteredVehicles.reduce((acc, vehicle) => {
          return acc.add(vehicle.agent_id);
        }, new Set());
        METHOD(
          'filteredBookingsSelector:filteredVehiclesSet',
          filteredVehiclesSet
        );

        // const invalidBookings = bookings.filter(
        //   booking =>
        //     booking.assigned_vehicle_id &&
        //     !filteredVehiclesSet.has(booking.assigned_vehicle_id)
        // );
        // METHOD('filteredBookingsSelector:invalidBookings', {
        //   filteredVehiclesSet,
        //   invalidBookings
        // });

        const filteringArgs = bookingsFilter.trim()
          ? bookingsFilter.split(' ').map(x => x.trim())
          : [];

        const commands = filteringArgs.filter(x => x.indexOf('!') === 0);
        const filters = filteringArgs.filter(x => x.indexOf('!') !== 0);

        if (!filters.length && !commands.length) {
          return bookings;
        }

        const isUnassignedOnly = !!commands.filter(x => x.indexOf('!u') === 0)
          .length;
        const isAssignedOnly = !!commands.filter(x => x.indexOf('!a') === 0)
          .length;
        const selectAll = !isUnassignedOnly && !isAssignedOnly;

        const searchCriteria = filters.join(' ');

        METHOD('filteredBookingsSelector:criteria', {
          bookingsFilter,
          commands,
          filters,
          isUnassignedOnly,
          isAssignedOnly,
          selectAll,
          searchCriteria
        });

        const selectedBookings = bookings.filter(
          booking =>
            (((selectAll || isUnassignedOnly) &&
              !booking.assigned_vehicle_id) ||
              ((selectAll || isAssignedOnly) &&
                booking.assigned_vehicle_id &&
                filteredVehiclesSet.has(booking.assigned_vehicle_id))) &&
            filters
              .map(filter => booking.uid.toLowerCase().indexOf(filter) >= 0)
              .find(x => !x) !== false
        );
        METHOD('filteredBookingsSelector:selectedBookings', {
          commands,
          filters,
          isUnassignedOnly,
          isAssignedOnly,
          selectAll,
          searchCriteria,
          selectedBookings
        });
        return selectedBookings;
      }
    )
);

export const editableBookingsSelector = createSelector(
  REQUIRED_SELECTOR(
    'editableBookingsSelector:vehiclesWithColorSelector',
    vehiclesWithColorSelector
  ),
  REQUIRED_SELECTOR(
    'editableBookingsSelector:activeVehicleIdsSelector',
    activeVehicleIdsSelector
  ),
  REQUIRED_SELECTOR(
    'editableBookingsSelector:commuteOfferCurrentDataSelector',
    commuteOfferCurrentDataSelector
  ),
  REQUIRED_SELECTOR(
    'editableBookingsSelector:editableBookingIdSelector',
    editableBookingIdSelector
  ),
  (vehicles, activeVehicleIds, data, editableBookingId) =>
    FUNCTION(
      'editableBookingsSelector',
      { vehicles, activeVehicleIds, data, editableBookingId },
      () => {
        if (!data || !activeVehicleIds.length || !editableBookingId) {
          return null;
        }

        return {
          ...activeVehicleIds.reduce((acc, vehicleId) => {
            const vehicleNodes = data.result.vehicles[vehicleId];
            if (vehicleNodes) {
              acc[vehicleId] = vehicleNodes
                .filter(node => node.booking_uid === editableBookingId)
                .reduce(
                  (vehicleAcc, node) => {
                    // eslint-disable-next-line no-param-reassign
                    vehicleAcc[node.node_type] = {
                      ...node,
                      scheduled_ts:
                        node.uid &&
                        moment(node.scheduled_ts)
                          .tz(global.GEODISC_TIMEZONE)
                          .format('HH:mm')
                    };
                    return vehicleAcc;
                  },
                  {
                    booking_uid: editableBookingId,
                    pickup: {},
                    dropoff: {}
                  }
                );
            }
            return acc;
          }, {})
        };
      }
    )
);

export const editableVehicleSelector = geodisc$CreateImmutableSelector(
  vehiclesRequestSelector,
  editableVehicleIdSelector,
  (vehicles, id) =>
    FUNCTION(
      'editableVehicleSelector',
      { vehicles, id },
      () => id && vehicles.find(item => item.agent_id === id)
    )
);

export const vehiclesFilteredBySearchSelector = geodisc$CreateImmutableSelector(
  filteredVehiclesSelector,
  searchQuerySelector,
  (vehicles, searchQuery) =>
    FUNCTION(
      'vehiclesFilteredBySearchSelector',
      { vehicles, searchQuery },
      () =>
        searchQuery !== ''
          ? vehicles.filter(vehicle =>
              vehicle.agent_id.toLowerCase().match(searchQuery))
          : []
    )
);

export const bookingsFilteredBySearchSelector = geodisc$CreateImmutableSelector(
  filteredBookingsSelector,
  searchQuerySelector,
  (bookings, searchQuery) => {
    FUNCTION(
      'bookingsFilteredBySearchSelector',
      { bookings, searchQuery },
      () =>
        searchQuery !== ''
          ? bookings.filter(booking =>
              booking.uid.toLowerCase().match(searchQuery))
          : []
    );
  }
);

export const activeBookingIndexSelector = createSelector(
  filteredBookingsSelector,
  activeBookingIdSelector,
  (bookings, activeBookingId) =>
    FUNCTION('activeBookingIndexSelector', { bookings, activeBookingId }, () =>
      bookings.findIndex(booking => booking.uid === activeBookingId))
);

export const activeVehiclesIndexSelector = createSelector(
  filteredVehiclesSelector,
  activeVehicleIdsSelector,
  (vehicles, activeVehicleIds) =>
    FUNCTION(
      'activeVehiclesIndexSelector',
      { vehicles, activeVehicleIds },
      () =>
        vehicles.findIndex(vehicle => vehicle.agent_id === activeVehicleIds)
    )
);
