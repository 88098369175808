// @flow
import { fetchData } from './net';
import { getHeaders } from './headers';
import { urls } from '../config';

export const getGeofences = async (projectId: number) => {
  try {
    const response = await fetchData(urls.geofences(projectId), {
      headers: getHeaders()
    });
    if (!response.ok) {
      throw new Error(response.status);
    }

    const responseJSON: GeofencesResponse = await response.json();
    return responseJSON;
  } catch (error) {
    throw error;
  }
};

export const getGeofenceFilteredById = async (id: string) => {
  try {
    const response = await fetchData(urls.geofenceFilterById(id), {
      headers: getHeaders()
    });
    if (!response.ok) {
      throw new Error(response.status);
    }

    const responseJSON: GeofencesResponse = await response.json();
    return responseJSON;
  } catch (error) {
    throw error;
  }
};

export const addGeofence = async (body: any, fromDataset: boolean) => {
  const url = !fromDataset ? urls.addGeofences : urls.geofenceFromDataset;

  try {
    const response = await fetchData(url, {
      method: 'POST',
      headers: getHeaders(),
      body
    });

    if (!response.ok) {
      throw new Error(response.status);
    }

    const responseJSON: GeofencesResponse = await response.json();

    return responseJSON;
  } catch (error) {
    throw error;
  }
};

export const copyGeofence = async (id: number) => {
  const url = urls.geofenceCopy(id);
  try {
    const response = await fetchData(url, {
      method: 'POST',
      headers: getHeaders()
    });

    if (!response.ok) {
      throw new Error(response.status);
    }

    const responseJSON: GeofencesResponse = await response.json();

    return responseJSON;
  } catch (error) {
    throw error;
  }
};

export const deleteGeofence = async (id: number) => {
  const url = urls.geofence(id);

  try {
    const response = await fetchData(url, {
      method: 'DELETE',
      headers: getHeaders()
    });

    if (!response.ok) {
      throw new Error(response.status);
    }

    return null;
  } catch (error) {
    throw error;
  }
};

export const updateGeofence = async (id: number, body: any) => {
  const url = urls.geofence(id);

  try {
    const response = await fetchData(url, {
      method: 'PATCH',
      headers: getHeaders(),
      body
    });

    if (!response.ok) {
      throw new Error(response.status);
    }

    const responseJSON: GeofencesResponse = await response.json();

    return responseJSON;
  } catch (error) {
    throw error;
  }
};
