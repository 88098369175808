import Moment from 'moment';
import MomentTimezone from 'moment-timezone'; // eslint-disable-line

const guessTimezone = () => window.GEODISC_TIMEZONE || Moment.tz.guess();

// eslint-disable-next-line import/prefer-default-export
export const timezone = guessTimezone();

Moment.tz.setDefault(timezone);

window.GEODISC_MOMENT = Moment;
window.GEODISC_TIMEZONE = timezone;
