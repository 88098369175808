import { connect } from 'react-redux';
import createImmutableSelector from 'create-immutable-selector';
import { compose, lifecycle } from 'recompose';

import debug from 'utils/debug';

import { duplicateCommuteOffer } from 'modules/commuteOffer/actions';

import { currentProjectUrlSelector } from 'modules/user/selectors';

import DuplicateCommuteOffer from './DuplicateCommuteOffer';

const { METHOD } = debug('c:ModalWindow:DuplicateCommuteOffer:index');

const withLifecycle = lifecycle({
  componentDidMount() {
    METHOD('componentDidMount');
  }
});

const mapDispatchToProps = {
  duplicateCommuteOffer
};

const mapStateToProps = createImmutableSelector(
  currentProjectUrlSelector,
  projectUrl => ({ projectUrl })
);

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps
);

const enhance = compose(
  withConnect,
  withLifecycle
);

export default enhance(DuplicateCommuteOffer);
