import React, { PureComponent } from 'react';
import { List as VirtualizedList, AutoSizer } from 'react-virtualized';
import { memoHumanize, humanize } from 'utils/CommuteOffer';
import debug from 'utils/debug';
import PanelContainer from '../PanelContainer';
import Header from './Header';
import Booking from './Booking';
import Filter from './Filter';

import PanelList from '../PanelList';

const { METHOD } = debug('p:CommuteOffer:Panels:Bookings');

class Bookings extends PureComponent {
  componentDidUpdate() {
    METHOD('componentDidUpdate');

    if (this.theList) {
      this.theList.forceUpdateGrid();
    }
  }

  renderBooking = ({ index, key, style }) => {
    const { bookings } = this.props;

    return (
      <div key={key} style={style}>
        <Booking booking={memoHumanize(bookings[index])} />
      </div>
    );
  };

  render() {
    METHOD('render', { props: this.props });

    const {
      bookings,
      isHidden,
      toggleBookingsHidden,
      activeBookingIndex
    } = this.props;

    return (
      <PanelContainer
        isHidden={isHidden}
        onClick={isHidden ? toggleBookingsHidden : null}
      >
        <Header
          key="header"
          size={bookings.length}
          isHidden={isHidden}
          toggleBookingsHidden={toggleBookingsHidden}
        />
        {!global.GEODISC_UI_COMMUTE_OFFER_PANEL_FILTERING_DISABLE &&
          !isHidden && <Filter />}
        <PanelList key="list" isHidden={isHidden}>
          {false && (
            <AutoSizer>
              {({ height, width }) => (
                <VirtualizedList
                  // eslint-disable-next-line
                  ref={ref => (this.theList = ref)}
                  width={width}
                  height={height}
                  rowHeight={210}
                  rowRenderer={this.renderBooking}
                  scrollToIndex={activeBookingIndex}
                  rowCount={bookings.length}
                  overscanRowCount={10}
                />
              )}
            </AutoSizer>
          )}
          {bookings
            .slice(
              0,
              window.GEODISC_DEBUG_BOOKINGS_PANEL_MAX_RECORDS_COUNT || 100
            )
            .map(booking => (
              <Booking key={booking.uid} booking={humanize(booking)} />
            ))}
        </PanelList>
      </PanelContainer>
    );
  }
}

export default Bookings;
