/* eslint-disable */
import pagination from './pagination';
import normalizeURL from 'utils/normalizeURL';

const GEODISC_API_URL = normalizeURL(window.GEODISC_API_URL);

const GEODISC_API_V1_URL = normalizeURL(
  window.GEODISC_API_V1_URL || window.GEODISC_API_URL
);

export default {
  datasetSchema: `${GEODISC_API_URL}/api/v2/dataset/schema`,
  user: (username) =>
    `${GEODISC_API_URL}/api/v2/user?` + `username=${username}`,
  token: `${GEODISC_API_URL}/api/v2/user/token`,
  projects:
    `${GEODISC_API_URL}/api/v2/project?` + `limit=${pagination.projects}`,
  datasets: `${GEODISC_API_URL}/api/v2/dataset`,
  datasetsList: (offset, ordering, search, project) => {
    if (search) {
      return (
        `${GEODISC_API_URL}/api/v2/dataset?` +
        [
          `limit=${pagination.datasets}`,
          `offset=${offset}`,
          `order_by=${ordering}`,
          `name__contains=${search}`,
          `project=${project}`
        ].join('&')
      );
    }

    return (
      `${GEODISC_API_URL}/api/v2/dataset?` +
      [
        `limit=${pagination.datasets}`,
        `offset=${offset}`,
        `order_by=${ordering}`,
        `project=${project}`
      ].join('&')
    );
  },
  dataset: (id) => `${GEODISC_API_URL}/api/v2/dataset/${id}`,
  datasetCsv: (id) => `${GEODISC_API_URL}/api/v2/dataset/${id}/csv`,
  datasetZip: (ids) =>
    `${GEODISC_API_URL}/api/v2/dataset/csv?` +
    [`ids=${ids.join(',')}`].join('&'),
  geofenceSchema: `${GEODISC_API_URL}/api/v2/geofence/schema`,
  geofences: (project) =>
    `${GEODISC_API_URL}/api/v2/geofence?` +
    [`limit=0`, `order_by=-created_at`, `project=${project}`].join('&'),
  addGeofences: `${GEODISC_API_URL}/api/v2/geofence`,
  geofence: (id) => `${GEODISC_API_URL}/api/v2/geofence/${id}`,
  geofenceFilterById: (id) =>
    `${GEODISC_API_URL}/api/v2/geofence?` + [`id=${id}`].join('&'),
  geofenceCopy: (id) => `${GEODISC_API_URL}/api/v2/geofence/${id}/copy`,
  geofenceFromDataset: `${GEODISC_API_URL}/api/v2/geofence/create_from_dataset`,
  simulationSchema: `${GEODISC_API_URL}/api/v2/simulation/schema`,
  simulations: `${GEODISC_API_URL}/api/v2/simulation`,
  simulationsList: (offset, ordering, search, project) => {
    if (search) {
      return (
        `${GEODISC_API_URL}/api/v2/simulation?` +
        [
          `limit=${pagination.simulations}`,
          `offset=${offset}`,
          `order_by=${ordering}`,
          `name__contains=${search}`,
          `project=${project}`,
          `deleted=false`
        ].join('&')
      );
    }

    return (
      `${GEODISC_API_URL}/api/v2/simulation?` +
      [
        `limit=${pagination.simulations}`,
        `offset=${offset}`,
        `order_by=${ordering}`,
        `project=${project}`,
        `deleted=false`
      ].join('&')
    );
  },
  simulation: (id) => `${GEODISC_API_URL}/api/v2/simulation/${id}`,
  simulationLaunch: (id) => `${GEODISC_API_URL}/api/v2/simulation/${id}/launch`,
  simulationVehicleLog: (id, offset, limit) => {
    return (
      `${GEODISC_API_URL}/api/v2/simulation/${id}/vehicle_log/?` +
      [`limit=${limit}`, `offset=${offset}`].join('&')
    );
  },
  simulationNodeLog: (id, offset) => {
    return (
      `${GEODISC_API_URL}/api/v2/nodelog?` +
      [
        `limit=500`,
        `assigned_vehicle__simulation__id=${id}`,
        `offset=${offset}`
      ].join('&')
    );
  },
  simulationVehicleSet: (ids) => {
    return (
      `${GEODISC_API_URL}/api/v2/vehicle?` +
      [`limit=500`, `id__in=${[...ids].join(',')}`].join('&')
    );
  },
  simulationNodeSet: (ids) => {
    return (
      `${GEODISC_API_URL}/api/v2/node?` +
      [`limit=500`, `id__in=${[...ids].join(',')}`].join('&')
    );
  },
  bookingsForSimulation: (id) =>
    `${GEODISC_API_URL}/api/v2/booking/?` + [`simulation__id=${id}`].join('&'),
  vehiclesForSimulation: (id) =>
    `${GEODISC_API_URL}/api/v2/vehicle/?` + [`simulation__id=${id}`].join('&'),
  nodesForSimulation: (id) =>
    `${GEODISC_API_URL}/api/v2/node/?` +
    [`assigned_vehicle__simulation__id=${id}`].join('&'),
  commuteOffer: (id) => `${GEODISC_API_URL}/api/v2/commuteoffer/${id}`,
  commuteOffers: `${GEODISC_API_URL}/api/v2/commuteoffer`,
  commuteOffersByProject: (project) =>
    `${GEODISC_API_URL}/api/v2/commuteoffer?` +
    [`project=${project}`, `order_by=-id`, `limit=500`].join('&'),
  stops: `${GEODISC_API_URL}/mrt/stops_geojson`,
  statelessNodeScheduler: `${GEODISC_API_V1_URL}/api/v1/node_scheduler/`,
  jobResult: (id) => `${GEODISC_API_URL}/api/v2/result/${id}`,
  buildingByZipCode: (country, city, zipCode) =>
    `${GEODISC_API_URL}/api/v2/building/?` +
    [`postal_code=${zipCode}`, `city=${city || country}`].join('&'),
  nearestStop: (lon, lat, stopType) =>
    `${GEODISC_API_URL}/api/v2/transitstop/nearest/?` +
    [`lat=${lat}`, `lon=${lon}`, `stop_type=${stopType}`].join('&')
};
