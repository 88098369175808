import React, { useEffect } from 'react';

import GSTC from 'react-gantt-schedule-timeline-calendar';
import CalendarScroll from 'gantt-schedule-timeline-calendar/dist/CalendarScroll.plugin';

import debug from 'utils/debug';
import Overflow from './Overflow';

const { METHOD } = debug('c:ModalWindows:TimelineViewer');

export default (props) => {
  METHOD('Timeline', { props });

  const { useCalendarScroll, config, onTimelineState } = props;

  const subs = [];

  function onState(state) {
    METHOD('Timeline:onState', { state });

    if (onTimelineState && !subs.length) {
      onTimelineState(state);
    }

    for (let i = 0; i < 5; ++i) {
      setInterval(() => {
        state.update('config.chart.time.zoom', () => {
          return 15;
        });
      }, i * 1000);
    }

    subs.push(
      state.subscribe('config.chart.items', (items) => {
        METHOD('config.chart.item', { items });
      })
    );
    subs.push(
      state.subscribe('config.list.rows', (rows) => {
        METHOD('config.list.row', { rows });
      })
    );
    subs.push(
      state.subscribe('config.chart.time.zoom', (zoom) => {
        METHOD('config.chart.time.zoo', { zoom });
      })
    );
  }

  useEffect(() => {
    METHOD('Timeline:Update');

    return () => {
      METHOD('Timeline:Cleanup');

      subs.forEach(unsub => unsub());
    };
  });

  const newConfig = {
    ...config,
    height: useCalendarScroll ? 2048 : 600,
    list: {
      ...config.list,
      rowHeight: 30
    },
    plugins: useCalendarScroll
      ? [
          CalendarScroll({
            speed: 1 / 24 / 4,
            hideScroll: true,
            onChange(time) {
              METHOD('Timeline:CalendarScroll:onChange', { time });
            }
          })
        ]
      : []
  };
  METHOD('Timeline:newConfig', { newConfig });

  return (
    <React.Fragment>
      {useCalendarScroll ? (
        <Overflow>
          <GSTC config={newConfig} onState={onState} />
        </Overflow>
      ) : (
        <GSTC config={newConfig} onState={onState} />
      )}
    </React.Fragment>
  );
};
