import React from 'react';
import Row from './Row';
import Container from './Container';

const Body = ({ list, ...props }) => (
  <Container>
    {list.valueSeq().map(value => (
      <Row key={value.get('id')} simulation={value} {...props} />
    ))}
  </Container>
);

export default Body;
