import React from 'react';

import Menu from 'components/Menu';

import Button from './Button';

const MenuBottom = ({ exportSimulation, deleteSimulation }) => (
  <Menu>
    <Button onClick={exportSimulation}>Export</Button>
    <Button onClick={deleteSimulation}>Delete</Button>
  </Menu>
);

export default MenuBottom;
