import { connect } from 'react-redux';
import createImmutableSelector from 'create-immutable-selector';
import {
  projectsSelector,
  currentProjectSelector,
  currentProjectIdSelector
} from 'modules/user/selectors';

import NameAndProject from './NameAndProject';

const mapStateToProps = createImmutableSelector(
  currentProjectSelector,
  currentProjectIdSelector,
  projectsSelector,
  (currentProject, currentProjectId, projects) => ({
    currentProject,
    currentProjectId,
    projects
  })
);

const mapDispatchToProps = null;

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps
);

export default withConnect(NameAndProject);
