import { connect } from 'react-redux';
import createImmutableSelector from 'create-immutable-selector';
import { branch, compose, renderNothing } from 'recompose';

import { editableVehicleIdSelector } from 'modules/ui/selectors';
import { clearEditableVehicleId } from 'modules/ui/actions';

import { editableVehicleSelector } from 'modules/commuteOffer/selectors';
import { updateVehicle, deleteVehicle } from 'modules/commuteOffer/actions';

import VehiclesEditor from './VehiclesEditor';

const mapStateToProps = createImmutableSelector(
  editableVehicleSelector,
  editableVehicleIdSelector,
  (editableVehicle, editableVehicleId) => ({
    editableVehicle,
    editableVehicleId
  })
);

const mapDispatchToProps = {
  clearEditableVehicleId,
  updateVehicle,
  deleteVehicle
};

const withBranch = branch(
  ({ editableVehicle }) => !editableVehicle,
  renderNothing
);

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps
);

const enhance = compose(
  withConnect,
  withBranch
);

export default enhance(VehiclesEditor);
