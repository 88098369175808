import {
  delay,
  fork,
  put,
  cancel,
  select,
  takeLatest
} from 'redux-saga/effects';
import debug from 'utils/debug';
import * as actions from './actions';
import * as commuteOfferActions from '../commuteOffer/actions';

import {
  editableBookingIdSelector,
  isBookingsHiddenSelector,
  busStopsVisibleSelector,
  activeRouteStopSelector
} from './selectors';

const { METHOD } = debug('m:ui:saga');

function* datasetRun() {
  METHOD('datasetRun');

  while (true) {
    yield delay(300);
    yield put({ type: actions.DATASET_NEXT });
  }
}

let datasetTask;
function* datasetPlay() {
  METHOD('datasetPlay');

  datasetTask = yield fork(datasetRun);
}

function* datasetStop() {
  METHOD('datasetStop');

  if (datasetTask) {
    yield cancel(datasetTask);
  }
}

function* cleanActiveBooking() {
  METHOD('cleanActiveBooking');

  const editableBookingId = yield select(editableBookingIdSelector);

  if (editableBookingId) {
    yield put(actions.cleanEditableBookingId());
  }

  yield put(actions.cleanActiveBookingId());
  yield put(actions.removeDraggablePoint());
}

function* setActiveBooking() {
  METHOD('setActiveBooking');

  const isBookingsHidden = yield select(isBookingsHiddenSelector);

  if (isBookingsHidden) {
    yield put(actions.toggleBookingsHidden());
  }
}

function* setNewEditableVehicle() {
  METHOD('setNewEditableVehicle');

  yield put(actions.setEditableVehicleId('veh_new'));
}

function* setEditableBookingId({ payload }) {
  METHOD('setEditableBookingId', { payload });

  const id = payload;
  yield put(actions.setActiveBookingId(id));
}

function* cleanActiveVehicle() {
  METHOD('cleanActiveVehicle');

  const activeRouteStop = yield select(activeRouteStopSelector);
  if (activeRouteStop) {
    yield put(actions.cleanActiveRouteStop());
  }
}

function* setActiveVehicle({ payload }) {
  METHOD('setActiveVehicle', { payload });

  const id = payload;
  const editableBookingId = yield select(editableBookingIdSelector);
  if (editableBookingId) {
    yield put(actions.cleanEditableBookingId());
  }

  const element = window.document.getElementById(id);
  if (element) {
    element.scrollIntoView();
  }
}

function* enableStopMode() {
  METHOD('enableStopMode');

  const busStopsVisible = yield select(busStopsVisibleSelector);
  if (!busStopsVisible.includes('bus_stop')) {
    yield put(actions.toggleActiveStopsType('bus_stop'));
  }
}

function* addStop() {
  METHOD('addStop');

  yield put(actions.setStopSearchQuery(''));
}

function* undo() {
  METHOD('undo');

  yield put(actions.removeDraggablePoint());
}

function* isChanged({ payload }) {
  METHOD('isChanged', { payload });

  yield put(actions.setIsChanged(true));
}

function* Saga() {
  yield takeLatest(actions.DATASET_PLAY, datasetPlay);
  yield takeLatest(actions.DATASET_STOP, datasetStop);
  yield takeLatest(
    commuteOfferActions.REMOVE_BOOKING_FROM_ROUTE,
    cleanActiveBooking
  );
  yield takeLatest(actions.SET_ACTIVE_BOOKING_ID, setActiveBooking);
  yield takeLatest(
    [actions.CLEAN_ACTIVE_ROUTE_STOP, actions.CLEAN_EDITABLE_BOOKING_ID],
    cleanActiveBooking
  );
  yield takeLatest(actions.CLEAN_ACTIVE_VEHICLE_ID, cleanActiveVehicle);
  yield takeLatest(actions.SET_ACTIVE_VEHICLE_ID, setActiveVehicle);
  yield takeLatest(commuteOfferActions.NEW_VEHICLE, setNewEditableVehicle);
  yield takeLatest(actions.SET_EDITABLE_BOOKING_ID, setEditableBookingId);
  yield takeLatest(actions.SET_ADD_STOP_MODE, enableStopMode);
  yield takeLatest(commuteOfferActions.SET_STOP_TO_ROUTE, addStop);
  yield takeLatest('@@redux-undo/UNDO', undo);
  yield takeLatest(
    [
      commuteOfferActions.SET_RESULT_VEHICLES,
      commuteOfferActions.SET_BOOKING_NODE,
      commuteOfferActions.SET_POINT_TO_ROUTE,
      commuteOfferActions.SET_STOP_TO_ROUTE,
      commuteOfferActions.CHANGE_ROUTE_ORDER,
      commuteOfferActions.CHANGE_ROUTE_STOP,
      commuteOfferActions.CHANGE_ROUTE_EMPTY_STOP,
      commuteOfferActions.CLONE_VEHICLE,
      commuteOfferActions.DELETE_ROUTE_POINT,
      commuteOfferActions.DELETE_VEHICLE,
      commuteOfferActions.REMOVE_BOOKING_FROM_ROUTE,
      commuteOfferActions.REMOVE_BOOKING_NODE,
      commuteOfferActions.RECALCULATE_VEHICLE_TIME
    ],
    isChanged
  );
}

export default Saga;
