import React from 'react';
import { DateTimePicker } from 'react-widgets';

import 'react-widgets/dist/css/react-widgets.css';

import Label from './Label';
import Container from './Container';

export default ({ label, name, onChange, ...otherProps }) => {
  const handleChange = (value) => {
    onChange(name, value);
  };

  return (
    <Container>
      <Label>{label}</Label>
      <DateTimePicker onChange={handleChange} {...otherProps} />
    </Container>
  );
};
