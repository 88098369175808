import debug from 'utils/debug';

const { TRACE, METHOD } = debug('api:net');

function sleep(ms) {
  return new Promise(resolve => setTimeout(resolve, ms));
}

export const fetchData = async (url, opts) => {
  const { method } = opts || {};

  let status = 200;

  // eslint-disable-next-line no-constant-condition
  for (
    let attempt = 1;
    attempt <= (global.NET_FETCH_MAX_ATTEMPTS || 10);
    ++attempt
  ) {
    METHOD('fetch', { url, opts, attempt });
    TRACE($ => $(method || 'GET', url, opts));

    // eslint-disable-next-line no-await-in-loop
    const response = await fetch(url, opts);
    status = response.status;

    if (response.ok || (status >= 400 && status <= 404) || status === 504) {
      return response;
    }

    // eslint-disable-next-line no-await-in-loop
    await sleep(1000 + Math.floor(Math.random() * attempt * 500) + 1);
  }

  throw new Error(status);
};

export const fetchJSON = async (url, opts) => {
  METHOD('fetchJSON:Request', { url, opts });

  try {
    const response = await fetchData(url, opts);

    if (!response.ok) {
      throw new Error(response.status);
    }

    const responseJSON = await response.json();

    METHOD('fetchJSON:Success', { url, opts, response: responseJSON });
    return responseJSON;
  } catch (error) {
    METHOD('fetchJSON:Failure', { url, opts, error });
    throw error;
  }
};
