import React from 'react';
import { withRouter } from 'react-router-dom';
import { exportGeofence } from 'utils/file';
import { getGeojson } from 'utils/geofences';

import Button from './ButtonMenu';

const DropdownMenu = (props) => {
  const { geofence, copyGeofence, deleteGeofence, setEditableGeofence } = props;

  const onCopy = () => {
    copyGeofence(geofence.key);
  };

  const onDelete = () => {
    deleteGeofence(geofence.key);
  };

  const onEditable = () => {
    setEditableGeofence(geofence);
  };

  const onExport = () => {
    const geojson = getGeojson(geofence.geometry.coordinates);
    const { name } = geofence;
    exportGeofence(JSON.stringify(geojson), name);
  };

  return (
    <React.Fragment>
      <Button onClick={onExport}>Export</Button>
      <Button onClick={onEditable}>Edit</Button>
      <Button onClick={onCopy}>Copy</Button>
      <Button onClick={onDelete}>Delete</Button>
    </React.Fragment>
  );
};

export default withRouter(DropdownMenu);
