import { connect } from 'react-redux';
import { branch, compose, renderNothing } from 'recompose';
import createImmutableSelector from 'create-immutable-selector';

import {
  changeDatasetTime,
  datasetPlay,
  datasetStop,
  datasetToggleAllDay
} from 'modules/ui/actions';

import {
  datasetTimeSelector,
  csvErrorSelector,
  datasetRunningSelector,
  datasetAlldaySelector
} from 'modules/ui/selectors';

import {
  hasTimeDatasetSelector,
  dataLoadingSelector
} from 'modules/datasets/selectors';

import TimeSlider from 'components/TimeSlider';

const mapStateToProps = createImmutableSelector(
  dataLoadingSelector,
  datasetTimeSelector,
  csvErrorSelector,
  hasTimeDatasetSelector,
  datasetRunningSelector,
  datasetAlldaySelector,
  (loading, time, error, hasTime, isRunning, isAllDay) => ({
    loading,
    time,
    error,
    hasTime,
    isRunning,
    type: 'dataset',
    isAllDay
  })
);

const mapDispatchToProps = {
  onChange: changeDatasetTime,
  onPlay: datasetPlay,
  onStop: datasetStop,
  toggleAllday: datasetToggleAllDay
};

const withBranch = branch(({ hasTime }) => !hasTime, renderNothing);

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps
);

const enhance = compose(
  withConnect,
  withBranch
);

export default enhance(TimeSlider);
