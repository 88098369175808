import debug from 'debug';

function createRegistry() {
  return {
    data: new Map(),

    push(ident, value) {
      const time = window.performance.now();
      const identArray = this.data.get(ident) || [];
      this.data.set(ident, identArray);
      identArray.push({
        ...value,
        time,
        diff: identArray.length
          ? time - identArray[identArray.length - 1].time
          : null
      });
      return this;
    },

    inc(ident) {
      const value = this.data.get(ident) || 0;
      this.data.set(ident, value + 1);
      return this;
    },

    sort() {
      return new Map([...this.data].sort());
    },

    reset() {
      const data = this.data;
      this.data = new Map();
      return new Map([...data].sort());
    }
  };
}

global.COUNTERS = global.COUNTERS || createRegistry();

global.TIMINGS = global.TIMINGS || createRegistry();

global.METHODS = global.METHODS || createRegistry();

global.MESSAGES = global.MESSAGES || [];

export default (namespace) => {
  let COPY = o => o;

  if (global.GEODISC_DEBUG_ENABLED) {
    COPY = o => JSON.parse(JSON.stringify(o));
  }

  let DEBUG = () => {};

  if (global.GEODISC_DEBUG_ENABLED) {
    DEBUG = debug(namespace);
  }

  let TRACE = () => {};

  if (global.GEODISC_DEBUG_ENABLED) {
    TRACE = (fn) => {
      return fn(debug(namespace));
    };
  }

  let COUNTER = () => {};

  if (global.GEODISC_COUNTERS_ENABLED) {
    COUNTER = (name) => {
      const ident = `${namespace}:${name}`;
      global.COUNTERS.inc(ident);
    };
  }

  const EVENT = (name) => {
    DEBUG(name);
    COUNTER(name);
  };

  let PROFILER = (name, fn) => fn();

  if (global.GEODISC_PROFILER_ENABLED) {
    PROFILER = (name, fn) => {
      const sTime = window.performance.now();
      const result = fn();
      const eTime = window.performance.now();
      const pTime = eTime - sTime;
      const ident = `${namespace}:${name}`;

      let item = global.TIMINGS.data.get(ident);
      if (typeof item === 'undefined') {
        item = {
          total: pTime,
          avg: pTime,
          min: pTime,
          max: pTime,
          timings: [pTime]
        };
        global.TIMINGS.data.set(ident, item);
      } else {
        item.timings.push(pTime);
        item.total += pTime;
        item.avg = item.avg ? (item.avg + pTime) / 2 : pTime;
        item.min = item.min > pTime ? pTime : item.min;
        item.max = item.max < pTime ? pTime : item.max;
      }

      return result;
    };
  }

  let METHOD = () => {};

  const $V = (name, opts, value) => {
    METHOD(name, { ...opts, value });
    return value;
  };

  if (global.GEODISC_DEBUG_ENABLED) {
    METHOD = (name, payload, category) => {
      const $name = Array.isArray(name) ? name.join(':') : name.toString();
      const ident = `${namespace}:${$name}`;

      COUNTER(ident);

      const data = typeof payload === 'function' ? payload() : payload;

      const stack = (() => {
        const e = new Error();
        return e.stack;
      })()
        .split('\n')
        .slice(1);

      const categoryName = category || '_';

      global.METHODS.push(categoryName, {
        ___i: ident,
        ___s: stack,
        data
      });

      global.METHODS.push(ident, {
        ___s: stack,
        data
      });
    };
  }

  const MESSAGE = (name, payload) => {
    const ident = `${namespace}:${name}`;

    const data = typeof payload === 'function' ? payload() : payload;

    global.MESSAGES.push({
      name: ident,
      data
    });
  };

  const REDUX_EVENT_HANDLER2 = global.GEODISC_DEBUG_ENABLED
    ? (type, payload, state, fn) => {
        const name = `reducer:${type}`;
        COUNTER(`reducer:${type}`);
        METHOD(`${name}:Request`, {
          payload,
          state,
          $fn: { state: () => state.toJS() }
        });
        try {
          const value = PROFILER(`${name}`, () =>
            fn({
              $METHOD: ($name, $payload) =>
                METHOD(`reducer:${type}:${$name}`, $payload)
            }));
          METHOD(`${name}:Success`, {
            result: value,
            payload,
            state,
            $fn: { result: () => value.toJS(), state: () => state.toJS() }
          });
          return value;
        } catch (error) {
          METHOD(`${name}:Failure`, {
            error,
            payload,
            state,
            $fn: { state: () => state.toJS() }
          });
          throw error;
        }
      }
    : (type, payload, state, fn) => fn({ $METHOD: () => {} });

  let REDUX_EVENT_HANDLER = () => {};

  if (global.GEODISC_DEBUG_ENABLED) {
    REDUX_EVENT_HANDLER = (type, payload) => {
      METHOD(`reducer:${type}`, payload, 'redux');
      TRACE($ => $(type, { a: type, p: payload }));
    };
  }

  const FUNCTION = global.GEODISC_DEBUG_ENABLED
    ? (name, opts, fn) => {
        COUNTER(name);
        METHOD(`${name}:Request`, opts);
        try {
          const value = PROFILER(`${name}`, () =>
            fn({
              $METHOD: ($name, $payload) => METHOD(`${name}:${$name}`, $payload)
            }));
          METHOD(`${name}:Success`, { ...opts, result: value });
          return value;
        } catch (error) {
          METHOD(`${name}:Failure`, { ...opts, error });
          throw error;
        }
      }
    : (name, opts, fn) => fn({ $METHOD: () => {} });

  const result = {
    COPY,
    DEBUG,
    TRACE,
    COUNTER,
    EVENT,
    PROFILER,
    METHOD,
    MESSAGE,
    FUNCTION,
    REDUX_EVENT_HANDLER,
    REDUX_EVENT_HANDLER2,
    $V
  };
  return result;
};

// eslint-disable-next-line no-multi-assign
global.R = global.MR = () => {
  return global.METHODS.reset();
};

// eslint-disable-next-line no-multi-assign
global.M = global.MS = () => {
  return global.METHODS.sort();
};

// eslint-disable-next-line no-multi-assign
global.PR = global.TR = () => {
  return global.TIMINGS.reset();
};

// eslint-disable-next-line no-multi-assign
global.P = global.T = () => {
  return global.TIMINGS.sort();
};

// eslint-disable-next-line no-multi-assign
global.CR = () => {
  return global.COUNTERS.reset();
};

// eslint-disable-next-line no-multi-assign
global.C = global.CS = () => {
  return global.COUNTERS.sort();
};
