import { connect } from 'react-redux';
import createImmutableSelector from 'create-immutable-selector';
import { compose, lifecycle } from 'recompose';

import debug from 'utils/debug';

import {
  commuteOfferOriginalDataSelector,
  commuteOfferCurrentDataSelector
} from 'modules/commuteOffer/selectors';

import CommuteOfferVehicleUpdates from './CommuteOfferVehicleUpdates';

const { METHOD } = debug('c:ModalWindow:CommuteOfferVehicleUpdates');

const withLifecycle = lifecycle({
  componentDidMount() {
    METHOD('componentDidMount', { props: this.props });
  }
});

const mapDispatchToProps = null;

const mapStateToProps = createImmutableSelector(
  commuteOfferOriginalDataSelector,
  commuteOfferCurrentDataSelector,
  (originalOffer, currentOffer) => ({ originalOffer, currentOffer })
);

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps
);

const enhance = compose(
  withConnect,
  withLifecycle
);

export default enhance(CommuteOfferVehicleUpdates);
