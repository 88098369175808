import debug from 'utils/debug';
import { normalizeCommuteOffer } from 'utils/CommuteOffer';
// import $async from 'promise-async';
import { urls } from '../config';
import { fetchData, fetchJSON } from './net';
import { getHeaders } from './headers';

const { DEBUG, METHOD } = debug('api:commuteOffer');

function sleep(ms) {
  return new Promise(resolve => setTimeout(resolve, ms));
}

export const jobResult = async (id) => {
  METHOD('jobResult:Request', { id });

  try {
    const response = await fetchData(urls.jobResult(id), {
      headers: getHeaders()
    });

    if (!response.ok) {
      throw new Error(response.status);
    }

    const responseJSON = await response.json();

    METHOD('jobResult:Success', { id, response: responseJSON });
    return responseJSON;
  } catch (error) {
    METHOD('jobResult:Failure', { id, error });
    throw error;
  }
};

export const statelessNodeScheduler = async (data) => {
  METHOD('statelessNodeScheduler:Request', { data });

  try {
    const body = JSON.stringify(data);

    const response = await fetchData(urls.statelessNodeScheduler, {
      method: 'POST',
      headers: getHeaders(),
      body
    });

    if (!response.ok) {
      throw new Error(response.status);
    }

    const responseJSON = await response.json();

    METHOD('statelessNodeScheduler:Success', { response: responseJSON });
    return responseJSON;
  } catch (error) {
    METHOD('statelessNodeScheduler:Failure', { data, error });
    throw error;
  }
};

export const scheduleCommuteOffer = async (commuteOffer) => {
  METHOD('scheduleCommuteOffer:Request', commuteOffer);

  let statelessRequest = null;

  try {
    const {
      engine_settings,
      nodes,
      vehicles,
      current_time
    } = commuteOffer.stateless_api_request_data;
    statelessRequest = { engine_settings, nodes, vehicles, current_time };
    METHOD('scheduleCommuteOffer:statelessRequest', { statelessRequest });

    if (
      !statelessRequest.nodes ||
      !statelessRequest.vehicles ||
      !statelessRequest.current_time
    ) {
      return new Error('Invalid request structure');
    }

    if (!statelessRequest.nodes.length) {
      METHOD('scheduleCommuteOffer:Success', {
        commuteOffer,
        $reason: 'Empty nodes list'
      });
      return commuteOffer;
    }

    // eslint-disable-next-line
    console.log({ statelessRequest });
    METHOD('scheduleCommuteOffer:statelessRequest', { statelessRequest });
    const job = await statelessNodeScheduler(statelessRequest);

    // eslint-disable-next-line
    console.log({ job });
    METHOD('scheduleCommuteOffer:Job', { job });

    if (!job.job_id) {
      throw new Error('Failed to get job_id');
    }

    // eslint-disable-next-line no-constant-condition
    for (let attempt = 1; attempt <= 60 * 60 * 12; ++attempt) {
      // eslint-disable-next-line no-await-in-loop
      const serverResponse = await jobResult(job.job_id);
      // eslint-disable-next-line
      console.log({
        id: job.job_id,
        status: serverResponse && serverResponse.status,
        statelessRequest,
        response: serverResponse
      });

      if (serverResponse.status && serverResponse.status === 'FAILURE') {
        throw new Error(`Scheduler Failure: ${serverResponse.result}`);
      }

      if (serverResponse.status && serverResponse.status === 'SUCCESS') {
        if (serverResponse.result && serverResponse.result.error) {
          throw new Error(`Scheduler Error: ${serverResponse.result.error}`);
        }

        METHOD('scheduleCommuteOffer:Response', { serverResponse });
        // eslint-disable-next-line no-await-in-loop
        const validatedResult = await normalizeCommuteOffer({
          ...commuteOffer,
          result: serverResponse.result
        });

        METHOD('scheduleCommuteOffer:Success', {
          commuteOffer,
          serverResponse,
          validatedResult
        });

        return validatedResult;
      }

      // eslint-disable-next-line no-await-in-loop
      await sleep(2000 + Math.floor(Math.random() * 3000));
    }

    throw new Error('Timed out');
  } catch (error) {
    METHOD('scheduleCommuteOffer:Failure', {
      commuteOffer,
      statelessRequest,
      error
    });
    throw error;
  }
};

// export const scheduleCommuteOffers = async commuteOffers => {
//   METHOD('scheduleCommuteOffers:Calculate:Request', { commuteOffers });
//   const results = await Promise.all(
//     commuteOffers.map(offer => scheduleCommuteOffer(offer))
//   );
//   METHOD('scheduleCommuteOffers:Calculate:Results', { results });
//   return results;
// };

export const scheduleCommuteOffers = async (commuteOffers, opts) => {
  METHOD('scheduleCommuteOffers:Request', { commuteOffers, opts });

  const options = opts || {};

  const ignoreErrors = options.ignoreErrors || false;
  const setProgress = options.setProgress || (async () => {});

  setProgress(0);

  const resolved = [];
  const rejected = [];

  const requests = commuteOffers.map((commuteOffer) => {
    return scheduleCommuteOffer(commuteOffer).then(
      (result) => {
        resolved.push(result);
      },
      (error) => {
        rejected.push({ commuteOffer, error });
      }
    );
  });
  METHOD('scheduleCommuteOffers:requests', { requests });

  // eslint-disable-next-line no-constant-condition
  while (true) {
    const progress = (resolved.length + rejected.length) / requests.length;
    METHOD('scheduleCommuteOffers:scheduleCommuteOffers:Progress', {
      resolved,
      rejected,
      requests,
      progress
    });

    // eslint-disable-next-line no-await-in-loop
    await setProgress(progress);

    if (resolved.length + rejected.length === requests.length) {
      break;
    }
    // eslint-disable-next-line no-await-in-loop
    await sleep(500);
  }

  setProgress(1);

  if (!ignoreErrors) {
    if (rejected.length) {
      const error = rejected[0].error;

      METHOD('scheduleCommuteOffers:Failure', { rejected });
      throw error;
    }
  }

  METHOD('scheduleCommuteOffers:Success', { resolved });
  return resolved;
};

export const getCommuteOffers = async projectId =>
  fetchJSON(urls.commuteOffersByProject(projectId), {
    headers: getHeaders()
  });

export const getCommuteOffer = async id =>
  fetchJSON(urls.commuteOffer(id), {
    headers: getHeaders()
  });

export const deleteCommuteOffer = async (id) => {
  try {
    const response = await fetchData(urls.commuteOffer(id), {
      method: 'DELETE',
      headers: getHeaders()
    });
    if (!response.ok) {
      throw new Error(response.status);
    }

    return null;
  } catch (error) {
    throw error;
  }
};

export const updateCommuteOffer = async (id, body) => {
  const url = urls.commuteOffer(id);

  try {
    const response = await fetchData(url, {
      method: 'PUT',
      headers: getHeaders(),
      body
    });

    if (!response.ok) {
      throw new Error(response.status);
    }

    const responseJSON = await response.json();

    return responseJSON;
  } catch (error) {
    throw error;
  }
};

export const getRoute = async (url) => {
  METHOD('getRoute:Request', { url });

  try {
    DEBUG(`URL: ${url}`);
    const response = await fetchData(url);

    if (!response.ok) {
      METHOD('getRoute:Failure', { url, response });
      if (response.status === 400) {
        DEBUG('Error: Failed to load a route.', { url });
        return {
          routes: [],
          waypoints: [],
          code: 'Ok'
        };
      }
      throw new Error(response.status);
    }

    const responseJSON = await response.json();

    METHOD('getRoute:Result', { url, response: responseJSON });
    return responseJSON;
  } catch (error) {
    throw error;
  }
};

export const addCommuteOffer = async (data) => {
  METHOD('addCommuteOffer', { data });

  try {
    const body = JSON.stringify(data);

    const response = await fetchData(urls.commuteOffers, {
      method: 'POST',
      headers: getHeaders(),
      body
    });

    if (!response.ok) {
      throw new Error(response.status);
    }

    const responseJSON = await response.json();

    METHOD('addCommuteOffer:Result', responseJSON);
    return responseJSON;
  } catch (error) {
    throw error;
  }
};
