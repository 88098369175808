import { connect } from 'react-redux';
import createImmutableSelector from 'create-immutable-selector';
import { branch, compose, renderComponent } from 'recompose';

import {
  deleteGeofence,
  updateGeofence,
  saveGeofence,
  fetchGeofences,
  copyGeofence
} from 'modules/geofences/actions';

import {
  toggleVisibilityGeofence,
  setEditableGeofence,
  updateEditableGeofence,
  changeGeofencesHover
} from 'modules/ui/actions';

import { geofencesFilteredSelector } from 'modules/geofences/selectors';

import {
  editableGeofenceSelector,
  geofencesErrorSelector,
  hiddenGeofencesSelector,
  geofencesHoverSelector
} from 'modules/ui/selectors';

import Error from 'components/Error';
import Loading from 'components/Loading';

import List from './List';

const mapDispatchToProps = {
  deleteGeofence,
  updateGeofence,
  toggleVisibilityGeofence,
  setEditableGeofence,
  updateEditableGeofence,
  saveGeofence,
  changeGeofencesHover,
  fetchGeofences,
  copyGeofence
};

const mapStateToProps = createImmutableSelector(
  geofencesFilteredSelector,
  hiddenGeofencesSelector,
  editableGeofenceSelector,
  geofencesHoverSelector,
  geofencesErrorSelector,
  (geofences, hiddenGeofences, editableGeofence, geofencesHover, error) => ({
    geofences,
    hiddenGeofences,
    editableGeofence,
    geofencesHover,
    error
  })
);

const withBranch = compose(
  branch(({ error }) => error, renderComponent(Error)),
  branch(({ geofences }) => !geofences.length, renderComponent(Loading))
);

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps
);

const enhance = compose(
  withConnect,
  withBranch
);

export default enhance(List);
