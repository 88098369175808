export const DATASETS_FETCH_REQUEST = '@@datasets/DATASETS_FETCH_REQUEST';
export const DATASETS_FETCH_SUCCESS = '@@datasets/DATASETS_FETCH_SUCCESS';
export const DATASETS_FETCH_FAILURE = '@@datasets/DATASETS_FETCH_FAILURE';
export const fetchDatasets = () => ({ type: DATASETS_FETCH_REQUEST });

export const DATASET_FETCH_REQUEST = '@@datasets/DATASET_FETCH_REQUEST';
export const DATASET_FETCH_SUCCESS = '@@datasets/DATASET_FETCH_SUCCESS';
export const DATASET_FETCH_FAILURE = '@@datasets/DATASET_FETCH_FAILURE';
export const fetchDataset = id => ({
  type: DATASET_FETCH_REQUEST,
  payload: id
});

export const CSV_FETCH_REQUEST = '@@datasets/CSV_FETCH_REQUEST';
export const CSV_FETCH_SUCCESS = '@@datasets/CSV_FETCH_SUCCESS';
export const CSV_FETCH_FAILURE = '@@datasets/CSV_FETCH_FAILURE';
export const fetchDatasetCsv = id => ({
  type: CSV_FETCH_REQUEST,
  payload: id
});

export const CSV_DOWNLOAD_REQUEST = '@@datasets/CSV_DOWNLOAD_REQUEST';
export const CSV_DOWNLOAD_SUCCESS = '@@datasets/CSV_DOWNLOAD_SUCCESS';
export const CSV_DOWNLOAD_FAILURE = '@@datasets/CSV_DOWNLOAD_FAILURE';
export const downloadDatasetCsv = id => ({
  type: CSV_DOWNLOAD_REQUEST,
  payload: id
});

export const CLEAR_DATASET = '@@datasets/CLEAR_DATASET';
export const clearDataset = () => ({ type: CLEAR_DATASET });

export const CLEAR_LIST = '@@datasets/CLEAR_LIST';
export const clearList = () => ({ type: CLEAR_LIST });

export const UPLOAD_DATASET_REQUEST = '@@datasets/UPLOAD_DATASET_REQUEST';
export const UPLOAD_DATASET_PROGRESS = '@@datasets/UPLOAD_DATASET_PROGRESS';
export const UPLOAD_DATASET_SUCCESS = '@@datasets/UPLOAD_DATASET_SUCCESS';
export const UPLOAD_DATASET_FAILURE = '@@datasets/UPLOAD_DATASET_FAILURE';
export const uploadDatasetRequest = (id, file) => ({
  type: UPLOAD_DATASET_REQUEST,
  payload: { id, file }
});
export const uploadDatasetProgress = (file, progress) => ({
  type: UPLOAD_DATASET_PROGRESS,
  payload: progress,
  meta: { file }
});
export const uploadDatasetSuccess = file => ({
  type: UPLOAD_DATASET_SUCCESS,
  meta: { file }
});
export const uploadDatasetFailure = (file, err) => ({
  type: UPLOAD_DATASET_FAILURE,
  payload: err,
  error: true,
  meta: { file }
});

export const ADD_DATASET_REQUEST = '@@datasets/ADD_DATASET_REQUEST';
export const ADD_DATASET_SUCCESS = '@@datasets/ADD_DATASET_SUCCESS';
export const ADD_DATASET_FAILURE = '@@datasets/ADD_DATASET_FAILURE';
export const addDataset = (body, file) => ({
  type: ADD_DATASET_REQUEST,
  payload: { body, file }
});

export const DELETE_DATASET_REQUEST = '@@datasets/DELETE_DATASET_REQUEST';
export const DELETE_DATASET_SUCCESS = '@@datasets/DELETE_DATASET_SUCCESS';
export const DELETE_DATASET_FAILURE = '@@datasets/DELETE_DATASET_FAILURE';
export const deleteDataset = id => ({
  type: DELETE_DATASET_REQUEST,
  payload: id
});

export const UPDATE_DATASET_REQUEST = '@@datasets/UPDATE_DATASET_REQUEST';
export const UPDATE_DATASET_SUCCESS = '@@datasets/UPDATE_DATASET_SUCCESS';
export const UPDATE_DATASET_FAILURE = '@@datasets/UPDATE_DATASET_FAILURE';
export const updateDataset = (id, body) => ({
  type: UPDATE_DATASET_REQUEST,
  payload: { id, body }
});

export const SAVE_DATASET = '@@geofences/SAVE_DATASET';
export const saveDataset = (id, body) => ({
  type: SAVE_DATASET,
  payload: { id, body }
});
