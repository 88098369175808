import { connect } from 'react-redux';
import createImmutableSelector from 'create-immutable-selector';

import { addCommuteOffer } from 'modules/commuteOffer/actions';

import {
  uploadProgressSelector,
  uploadStatusSelector,
  newDatasetErrorSelector
} from 'modules/ui/selectors';

import { currentProjectUrlSelector } from 'modules/user/selectors';

import NewCommuteOffer from './NewCommuteOffer';

const mapDispatchToProps = { addCommuteOffer };

const mapStateToProps = createImmutableSelector(
  uploadProgressSelector,
  uploadStatusSelector,
  newDatasetErrorSelector,
  currentProjectUrlSelector,
  (progress, status, error, projectUrl) => ({
    progress,
    status,
    error,
    projectUrl
  })
);

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps
);

export default withConnect(NewCommuteOffer);
