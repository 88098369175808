import React from 'react';
import debug from 'utils/debug';
import Moment from 'moment';
import { getRouteCoordinates } from 'utils/CommuteOffer';
import Container from './Container';
import Booking from './Booking';
import Close from './Close';
import Title from './Title';
import Wrap from './Wrap';
import Slack from './Slack';

const { METHOD } = debug('p:CommuteOffer:Panels:Vehicles:Vehicle:Route:Stop');

const Info = (props) => {
  METHOD('Info', { props });

  const {
    stop,
    bookings,
    removeBookingFromRoute,
    setEditableBookingId,
    activeBookingId
  } = props;

  return (
    <Container>
      <Title>Info</Title>
      <div>
        {stop.bookings.map((booking) => {
          const { id, pax } = booking;
          const { booking_uid, slack } = booking.node;

          METHOD('Info:Booking', {
            id,
            pax,
            booking,
            slack,
            slack$type: typeof slack
          });

          const sourceBooking = bookings.find(
            item => item.uid === booking_uid
          );

          const routeId = `${id}:${stop.uid}`;

          const coordinates =
            stop.node_type === 'pickup'
              ? [
                  {
                    lon: sourceBooking && sourceBooking.pickup_location_lon,
                    lat: sourceBooking && sourceBooking.pickup_location_lat
                  },
                  {
                    lon: booking.node.lon,
                    lat: booking.node.lat
                  }
                ]
              : [
                  {
                    lon: sourceBooking && sourceBooking.dropoff_location_lon,
                    lat: sourceBooking && sourceBooking.dropoff_location_lat
                  },
                  {
                    lon: booking.node.lon,
                    lat: booking.node.lat
                  }
                ];

          const isArrived = false;
          // stop.lon === booking.node.lon && stop.lat === booking.node.lat;

          const routeCoordinates = getRouteCoordinates(
            coordinates,
            true,
            false
          );

          const walkingRoute =
            global.WALKING_ROUTES &&
            global.WALKING_ROUTES[routeCoordinates] &&
            global.WALKING_ROUTES[routeCoordinates].route;
          const legs =
            walkingRoute &&
            walkingRoute.routes &&
            walkingRoute.routes[0] &&
            walkingRoute.routes[0].legs;

          const distance = isArrived
            ? 0
            : legs &&
              legs.reduce((acc, leg) => {
                return acc + leg.distance;
              }, 0);
          const duration = isArrived
            ? 0
            : legs &&
              legs.reduce((acc, leg) => {
                return acc + leg.duration;
              }, 0);
          METHOD('Info:WalkingRoute', {
            bookings_id: booking.id,
            isArrived,
            booking,
            stop,
            coordinates,
            routeId,
            routeCoordinates,
            walkingRoute,
            legs,
            distance,
            duration
          });

          const removeBooking = () => {
            removeBookingFromRoute(id);
          };

          const setActive = () => {
            setEditableBookingId(id);
          };

          const isDistanceEnabled =
            !window.GEODISC_UI_COMMUTE_OFFER_WALKING_DISTANCE_DISABLE &&
            window.GEODISC_WALKING_ROUTING_ENGINE !== 'euclidean';
          const isDurationEnabled =
            !window.GEODISC_UI_COMMUTE_OFFER_WALKING_DURATION_DISABLE &&
            window.GEODISC_WALKING_ROUTING_ENGINE !== 'euclidean';

          return (
            <Booking key={id}>
              <Wrap onClick={setActive} isActive={activeBookingId === id}>
                <div>{`Booking #${id}`}</div>
                <div>{`${pax} pax`}</div>
                {!!(slack && slack >= 60) && (
                  <Slack>
                    <strong>
                      {Moment.duration(Math.round(slack), 'seconds').humanize()}
                      {' '}
                      slack
                    </strong>
                  </Slack>
                )}
                {isDistanceEnabled && (
                  <div>
                    {typeof distance !== 'undefined'
                      ? `${Math.round(distance)} meters`
                      : 'unknown distance'}
                  </div>
                )}
                {isDurationEnabled && (
                  <div>
                    {typeof duration !== 'undefined'
                      ? `${Moment.duration(
                          Math.round(duration),
                          'seconds'
                        ).humanize()}`
                      : 'unknown duration'}
                  </div>
                )}
              </Wrap>
              <Close onClick={removeBooking} />
            </Booking>
          );
        })}
      </div>
    </Container>
  );
};

export default Info;
