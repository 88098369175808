import React from 'react';
import { Dropdown } from '@urbica/ui-kit';

import Button from './Button';
import Content from './Content';

const Menu = ({ width, icon, iconHover, children }) => (
  <Dropdown
    renderHandler={({ toggle, toggledOn }) => (
      <Button
        onClick={(e) => {
          e.preventDefault();
          toggle();
        }}
        toggledOn={toggledOn}
        icon={icon}
        iconHover={iconHover || icon}
      />
    )}
    renderContent={({ toggle }) => (
      <Content width={width} onClick={toggle}>
        {typeof children === 'function' ? children(toggle) : children}
      </Content>
    )}
  />
);

export default Menu;
