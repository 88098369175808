import React, { PureComponent } from 'react';
import { Formik } from 'formik';
import moment from 'moment';

import Input from 'components/Input';
import InputSelect from 'components/InputSelect';
import DateTimePicker from 'components/DateTimePicker';

import SubmitButton from 'components/SubmitButton';
import { datasetsToOptions, geofencesToOptions, getMultiBody } from 'utils';

import Row from './Row';
import ButtonsRow from './ButtonsRow';

class NewSimulation extends PureComponent {
  state = {
    waiting: false
  };

  handleKeyDown = (event) => {
    if (event.keyCode === 13) {
      event.preventDefault();
      return false;
    }
    return true;
  };

  onSubmit = (values) => {
    const { addSimulation, projectUrl } = this.props;
    const { dataset, geofence, road_network, ...otherValues } = values;
    this.setState({ waiting: true });

    if (dataset && geofence) {
      const arrayValues = getMultiBody(otherValues);

      arrayValues.forEach((item) => {
        const number_of_vehicles = parseInt(item.number_of_vehicles, 10);
        const vehicle_capacity = parseInt(item.vehicle_capacity, 10);

        const vehicle_configuration = [];
        for (let i = 0; i < number_of_vehicles; i++) {
          vehicle_configuration.push({
            capacity: { passenger: vehicle_capacity },
            lat: 1.2740788888387,
            lon: 103.84687388890413,
            breaks: [],
            can_be_shifted: true
          });
        }

        const body = JSON.stringify({
          simulation_mode: item.simulation_mode.value,
          dataset: `/api/v2/dataset/${dataset.value}`,
          geofence_id: geofence.value,
          number_of_vehicles: parseInt(item.number_of_vehicles, 10),
          vehicle_capacity: parseInt(item.vehicle_capacity, 10),
          acceptable_waiting_time:
            parseInt(item.acceptable_waiting_time, 10) * 60,
          max_additional_journey_time:
            parseInt(item.max_additional_journey_time, 10) * 60,
          min_advance_booking_window:
            parseInt(item.min_advance_booking_window, 10) * 60,
          max_advance_booking_window:
            parseInt(item.max_advance_booking_window, 10) * 60,
          road_network: road_network.value,
          write_events_log: item.write_events_log.value,
          start_time: moment(item.start_time)
            .tz(global.GEODISC_TIMEZONE)
            .format(),
          end_time: moment(item.end_time)
            .tz(global.GEODISC_TIMEZONE)
            .format(),
          conversion_rate: parseInt(item.conversion_rate, 10),
          name: item.name,
          description: item.name,
          allow_jump: false,
          adjust_driver_breaks_enabled: true,
          mixed_fleet: true,
          offer_generation_enabled: false,
          driver_prep_time: 5 * 60,
          min_driver_rest_time: 5 * 60,
          odbs_trip_duration: 90 * 60,
          percentage_driver_rest_time: 10,
          max_walking_distance: 200,
          vehicle_ordering_in_one_by_one_stage: 1,
          journey_duration_source: 'public_transit',
          project: projectUrl,
          data: {
            simulation_step_pause_scheduler_seconds: 30,
            use_websocket_for_frontend: item.use_websocket_for_frontend.value,
            vehicle_configuration
          }
        });

        addSimulation(body);
      });
    }
  };

  render() {
    const { datasets, geofences } = this.props;

    return (
      <Formik
        initialValues={{
          name: '',
          simulation_mode: 'fast_simulation',
          dataset: null,
          geofence: null,
          number_of_vehicles: 5,
          vehicle_capacity: 18,
          acceptable_waiting_time: 10,
          max_additional_journey_time: 15,
          min_advance_booking_window: 2,
          max_advance_booking_window: 10,
          conversion_rate: 30,
          road_network: { label: 'Omnibus', value: 'omnibus_weekdays' },
          write_events_log: false,
          use_websocket_for_frontend: false,
          start_time: new Date(2018, 11, 4, 7),
          end_time: new Date(2018, 11, 4, 8)
        }}
        onSubmit={this.onSubmit}
      >
        {(formikProps) => {
          const {
            values,
            handleChange,
            handleSubmit,
            handleBlur,
            setFieldValue,
            setFieldTouched
          } = formikProps;

          const { waiting } = this.state;

          return (
            <form onSubmit={handleSubmit} autoComplete='off'>
              <Row>
                <Input
                  name='name'
                  type='text'
                  label='Name'
                  value={values.name}
                  onKeyDown={this.handleKeyDown}
                  onChange={handleChange}
                  handleBlur={handleBlur}
                  autoComplete='off'
                  required
                />
              </Row>
              <Row>
                <InputSelect
                  id='simulation_mode'
                  name='simulation_mode'
                  label='Simulation mode'
                  options={[
                    { label: 'Fast', value: 'fast_simulation' },
                    { label: 'Real-time', value: 'real_time' },
                    { label: 'Real-operations', value: 'real_operations' }
                  ]}
                  value={values.simulation_mode}
                  onKeyDown={this.handleKeyDown}
                  onChange={setFieldValue}
                  onBlur={setFieldTouched}
                />
              </Row>
              <Row>
                <InputSelect
                  id='dataset'
                  name='dataset'
                  label='Dataset'
                  options={datasetsToOptions(datasets)}
                  value={values.dataset}
                  onKeyDown={this.handleKeyDown}
                  onChange={setFieldValue}
                  onBlur={setFieldTouched}
                />
                <InputSelect
                  id='geofence'
                  name='geofence'
                  label='Geofence'
                  options={geofencesToOptions(geofences)}
                  value={values.geofence}
                  onKeyDown={this.handleKeyDown}
                  onChange={setFieldValue}
                  onBlur={setFieldTouched}
                />
              </Row>
              <Row>
                <Input
                  name='number_of_vehicles'
                  type='text'
                  label='Number of vehicle'
                  value={values.number_of_vehicles}
                  onKeyDown={this.handleKeyDown}
                  onChange={handleChange}
                  required
                />
                <Input
                  name='vehicle_capacity'
                  type='text'
                  label='Vehicle capacity'
                  value={values.vehicle_capacity}
                  onKeyDown={this.handleKeyDown}
                  onChange={handleChange}
                  required
                />
              </Row>
              <Row>
                <Input
                  name='acceptable_waiting_time'
                  type='text'
                  label='Acceptable waiting time, min'
                  value={values.acceptable_waiting_time}
                  onKeyDown={this.handleKeyDown}
                  onChange={handleChange}
                  required
                />
                <Input
                  name='max_additional_journey_time'
                  type='text'
                  label='Max additional journey time, min'
                  value={values.max_additional_journey_time}
                  onKeyDown={this.handleKeyDown}
                  onChange={handleChange}
                  required
                />
              </Row>
              <Row>
                <Input
                  name='min_advance_booking_window'
                  type='text'
                  label='Min advance booking time window, min'
                  value={values.min_advance_booking_window}
                  onKeyDown={this.handleKeyDown}
                  onChange={handleChange}
                  required
                />
                <Input
                  name='max_advance_booking_window'
                  type='text'
                  label='Max advance booking time window, min'
                  value={values.max_advance_booking_window}
                  onKeyDown={this.handleKeyDown}
                  onChange={handleChange}
                  required
                />
              </Row>
              <Row>
                <InputSelect
                  id='road_network'
                  name='road_network'
                  label='Road network'
                  options={[
                    { label: 'Omnibus', value: 'omnibus_weekdays' },
                    { label: 'Combi', value: 'combi' }
                  ]}
                  value={values.road_network}
                  onKeyDown={this.handleKeyDown}
                  onChange={setFieldValue}
                  onBlur={setFieldTouched}
                />
                <Input
                  name='conversion_rate'
                  label='Conversion rate'
                  value={values.conversion_rate}
                  onKeyDown={this.handleKeyDown}
                  onChange={handleChange}
                  required
                />
              </Row>
              <Row>
                <InputSelect
                  id='write_events_log'
                  name='write_events_log'
                  label='Record data for playback'
                  options={[
                    { label: 'Yes', value: true },
                    { label: 'No', value: false }
                  ]}
                  value={values.write_events_log}
                  onKeyDown={this.handleKeyDown}
                  onChange={setFieldValue}
                  onBlur={setFieldTouched}
                />
                <InputSelect
                  id='use_websocket_for_frontend'
                  name='use_websocket_for_frontend'
                  label='Use Websockets'
                  options={[
                    { label: 'Yes', value: true },
                    { label: 'No', value: false }
                  ]}
                  value={values.use_websocket_for_frontend}
                  onKeyDown={this.handleKeyDown}
                  onChange={setFieldValue}
                  onBlur={setFieldTouched}
                />
              </Row>
              <Row>
                <DateTimePicker
                  name='start_time'
                  label='Start time'
                  format='YYYY-MM-DDTHH:mmZ'
                  value={values.start_time}
                  onKeyDown={this.handleKeyDown}
                  onChange={setFieldValue}
                />
                <DateTimePicker
                  name='end_time'
                  label='End time'
                  format='YYYY-MM-DDTHH:mmZ'
                  value={values.end_time}
                  onKeyDown={this.handleKeyDown}
                  onChange={setFieldValue}
                />
              </Row>
              <ButtonsRow>
                <SubmitButton
                  type='submit'
                  disabled={waiting}
                  style={{ width: 320 }}
                >
                  {waiting ? 'Creating...' : 'Create new simulation'}
                </SubmitButton>
              </ButtonsRow>
            </form>
          );
        }}
      </Formik>
    );
  }
}

export default NewSimulation;
