/* eslint-disable */
import { isImmutable } from 'immutable';
import { scaleQuantize, scaleOrdinal, scaleQuantile } from 'd3-scale';
import { unique } from './datasets';
import layers from 'config/deckLayers';
import matcher from 'config/deckMatcher';
import {
  getGeoJSON,
  getScale,
  getCluster,
  getClusteredArcs
} from './cluster-utils';

// Enable render color by customized color Scale
export function getBinColorDomain(scaleType, bins, [lowerIdx, upperIdx]) {
  switch (scaleType) {
    case 'quantize':
      return [bins[lowerIdx].value, bins[upperIdx].value];

    case 'quantile':
      return bins.slice(lowerIdx, upperIdx + 1).map((d) => d.value);
    case 'ordinal':
      return unique(bins.map((b) => b.value)).sort();
    default:
      return [bins[lowerIdx].value, bins[upperIdx].value];
  }
}

export function getScaleFunctor(scaleType) {
  switch (scaleType) {
    case 'quantize':
      return scaleQuantize;

    case 'quantile':
      return scaleQuantile;
    case 'ordinal':
      return scaleOrdinal;
    default:
      return scaleQuantile;
  }
}

export function getColorValueDomain(layer) {
  const { lowerPercentile, upperPercentile, colorScale } = layer.props;
  const { sortedBins } = layer.state.sortedColorBins;
  const len = sortedBins.length;

  if (!len) {
    // err... what do we do
    layer.state.colorValueDomain = null;
  } else {
    const lowerIdx = Math.ceil((lowerPercentile / 100) * (len - 1));
    const upperIdx = Math.floor((upperPercentile / 100) * (len - 1));

    // calculate valueDomain based on
    layer.state.colorValueDomain = getBinColorDomain(colorScale, sortedBins, [
      lowerIdx,
      upperIdx
    ]);
    layer.getColorScale();
  }

  layer.props.onSetColorDomain(layer.state.colorValueDomain);
}

export function getColorScaleFunction(layer) {
  const { colorScale, colorDomain } = layer.props;
  layer.state.colorScaleFunc = getScaleFunctor(colorScale)()
    .domain(
      colorDomain || layer.state.colorDomain || layer.state.colorValueDomain
    )
    .range(layer.props.colorRange);
}

export const updateDeckLayer = (id, inputId, inputValue) => {
  if (!matcher[id][inputId]) {
    return null;
  }

  const { layerId, paramId } = matcher[id][inputId];
  let value = inputValue;

  if (isImmutable(value)) {
    value = value.toJS();
  }

  layers[id].layers[layerId].setProps({ [paramId]: value });
};

export const updateData = async (id, data, zoom, style) => {
  const currentLayers = layers[id].layers;

  if (id === 'arcClusters' || id === 'clusters') {
    const clusterRadiusPickup = style.get('pickupRadius');
    const clusterRadiusDropoff = style.get('dropoffRadius');
    const geoJsonPickup = await getGeoJSON(
      data.pickup || data,
      getPositionPickup
    );
    const geoJsonDropoff = await getGeoJSON(
      data.pickup || data,
      getPositionDropoff
    );
    const clusterPickup = await getCluster({
      clusterRadius: clusterRadiusPickup,
      geoJSON: geoJsonPickup
    });
    const clusterDropoff = await getCluster({
      clusterRadius: clusterRadiusDropoff,
      geoJSON: geoJsonDropoff
    });
    const clusterDataPickup = await clusterPickup.getClusters(
      [-180, -85, 180, 85],
      Math.round(zoom)
    );
    const clusterDataDropoff = await clusterDropoff.getClusters(
      [-180, -85, 180, 85],
      Math.round(zoom)
    );

    const pickupRadiusMax = style.get('pickupRadiusMax');
    const dropoffRadiusMax = style.get('dropoffRadiusMax');

    const pickupScale = await getScale(
      clusterDataPickup,
      pickupRadiusMax,
      Math.round(zoom)
    );
    const dropoffScale = await getScale(
      clusterDataDropoff,
      dropoffRadiusMax,
      Math.round(zoom)
    );

    if (id === 'arcClusters') {
      const arcsData = await getClusteredArcs(
        clusterPickup,
        clusterDropoff,
        clusterDataPickup,
        clusterDataDropoff
      );

      currentLayers.arc.setProps({ data: arcsData });
    }

    currentLayers.pickup.setProps({ data: clusterDataPickup });
    currentLayers.pickup.setProps({ radiusScale: pickupScale });
    currentLayers.dropoff.setProps({ data: clusterDataDropoff });
    currentLayers.dropoff.setProps({ radiusScale: dropoffScale });
    return null;
  }

  if (id === 'points') {
    currentLayers.dropoff.setProps({ data: data.dropoff || data });
    currentLayers.pickup.setProps({ data: data.pickup || data });
    return null;
  }

  currentLayers.main.setProps({ data: data.pickup || data });
  return null;
};

export const getPositionDropoff = (d) => [
  +d.dropoff_lon || +d.dropoff_location_lon,
  +d.dropoff_lat || +d.dropoff_location_lat
];

export const getPositionPickup = (d) => [
  +d.pickup_lon || +d.pickup_location_lon,
  +d.pickup_lat || +d.pickup_location_lat
];
