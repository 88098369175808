import React, { PureComponent } from 'react';
import { List as VirtualizedList, AutoSizer } from 'react-virtualized';
import Container from './Container';
import Item from './Item';

class List extends PureComponent {
  componentDidUpdate(prevProps) {
    if (prevProps.geofencesHover === this.props.geofencesHover) {
      this.theList.forceUpdateGrid();
    }
  }

  renderRow = ({ index, key, style }) => {
    const { geofences, hiddenGeofences, ...otherProps } = this.props;
    const geofence = geofences[index];
    const isVisible = !hiddenGeofences.includes(geofence.id);

    return (
      <Item
        key={key}
        style={style}
        geofence={geofence}
        isVisible={isVisible}
        {...otherProps}
      />
    );
  };

  render() {
    const { geofences } = this.props;

    const rowCount = geofences.length;

    return (
      <Container>
        <AutoSizer>
          {({ height }) => (
            <VirtualizedList
              // eslint-disable-next-line no-return-assign
              ref={ref => (this.theList = ref)}
              width={320}
              height={height}
              rowHeight={150}
              rowRenderer={this.renderRow}
              rowCount={rowCount}
              overscanRowCount={10}
            />
          )}
        </AutoSizer>
      </Container>
    );
  }
}

export default List;
