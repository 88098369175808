import debug from 'utils/debug';

import { Map, fromJS } from 'immutable';
import * as actions from './actions';

const { REDUX_EVENT_HANDLER } = debug('m:datasets:index');

export const initialState = fromJS({
  count: null,
  list: null,
  dataset: null,
  datasetData: null
});

const reducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case actions.DATASETS_FETCH_SUCCESS: {
      REDUX_EVENT_HANDLER(type, payload);

      const objects = payload.get('objects');
      const count = payload.getIn(['meta', 'total_count']);

      return state.set('list', objects).set('count', count);
    }
    case actions.DATASET_FETCH_SUCCESS: {
      REDUX_EVENT_HANDLER(type, payload);

      const dataset = payload;
      return state.set('dataset', dataset);
    }
    case actions.CSV_FETCH_SUCCESS: {
      REDUX_EVENT_HANDLER(type, payload);

      const data = payload;
      return state.set('datasetData', data);
    }
    case actions.CLEAR_DATASET: {
      REDUX_EVENT_HANDLER(type, payload);

      return state.set('dataset', null).set('datasetData', null);
    }
    case actions.CLEAR_LIST: {
      REDUX_EVENT_HANDLER(type, payload);

      return state.set('list', null);
    }
    case actions.UPDATE_DATASET_SUCCESS: {
      REDUX_EVENT_HANDLER(type, payload);

      const data = payload;
      const id = data.get('id');

      return state
        .update('list', (list) => {
          if (!list) {
            return list;
          }

          const index = list.findIndex(item => item.get('id') === id);
          return list.set(index, data);
        })
        .update('dataset', (dataset) => {
          if (dataset && dataset.get('id') === id) {
            return Map(data);
          }

          return dataset;
        });
    }
    case actions.ADD_DATASET_REQUEST: {
      REDUX_EVENT_HANDLER(type, payload);
      return state;
    }
    case actions.ADD_DATASET_SUCCESS: {
      REDUX_EVENT_HANDLER(type, payload);
      return state;
    }
    case actions.ADD_DATASET_FAILURE: {
      REDUX_EVENT_HANDLER(type, payload);
      return state;
    }
    default:
      return state;
  }
};

export default reducer;
