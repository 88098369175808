import React from 'react';
import Container from './Container';
import ProgressBar from './ProgressBar';

const getStatus = (progress, status) => {
  if (status === 'success') {
    return 'Uploaded';
  }

  if (progress === 1) {
    return 'Processing...';
  }

  return `${Math.round(progress * 100)} %`;
};

export default ({ progress, status }) => (
  <Container>
    {getStatus(progress, status)}
    <ProgressBar progress={progress} />
  </Container>
);
