// eslint-disable-next-line import/prefer-default-export
export const commuteOffers$Trips$TableColumns = () => [
  ...[
    {
      kind: 'STRING',
      name: 'trip_id',
      label: 'Trip',
      width: '100px'
    },
    {
      kind: 'STRING',
      name: 'scheduled_ts',
      label: 'Time',
      width: '100px'
    },
    {
      kind: 'STRING',
      name: 'location_name',
      label: 'Location',
      width: 'minmax(350px, max-content)'
    },
    {
      kind: 'STRING',
      name: 'pickup_passengers',
      label: 'Passengers to be picked up',
      width: 'minmax(350px, max-content)'
    },
    {
      kind: 'STRING',
      name: 'dropoff_passengers',
      label: 'Passengers to be dropped off',
      width: 'minmax(350px, max-content)'
    }
  ]
];

global.commuteOffers$Trips$TableColumns = commuteOffers$Trips$TableColumns;
