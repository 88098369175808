import React from 'react';
import Container from './Container';
import TableHeader from './TableHeader';
import Size from './Size';
import Row from './Row';
import Header from './Header';
import Column from './Column';
import Label from './Label';

const Table = ({ data, size }) => {
  if (!data) {
    return null;
  }

  const slicedData = data.slice(0, 5);
  const firstRow = slicedData.shift();

  return (
    <React.Fragment>
      <TableHeader>
        <Label>Preview</Label>
        <Size>{`${Math.round(size / (1024 * 1024))} mb`}</Size>
      </TableHeader>
      <Container>
        <Header>
          {Object.keys(firstRow).map(key => (
            <Column key={key} header>{`${firstRow[key]} `}</Column>
          ))}
        </Header>
        {slicedData.map((row, i) => (
          <Row key={i}>
            {Object.keys(row).map(key => (
              <Column key={key}>{`${row[key]} `}</Column>
            ))}
          </Row>
        ))}
      </Container>
    </React.Fragment>
  );
};

export default Table;
