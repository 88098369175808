import React from 'react';

import Header from 'components/Header';
import Layout from 'components/Layout';

import Panel from './Panel';
import Table from './Table';

import Container from './Container';

const CommuteOffers = () => (
  <Layout hasBackground>
    <Container>
      <Header />
      <Panel />
      <Table />
    </Container>
  </Layout>
);

export default CommuteOffers;
