import React from 'react';

import Loading from 'components/Loading';
import Live from 'components/Live';
import Error from 'components/Error';

import debug from 'utils/debug';
import Container from './Container';
import Slider from './Slider';

const { DEBUG } = debug('c:TimeSlider:Time:index');

const Component = (props) => {
  const {
    loading,
    progress,
    isLive,
    time,
    isUnix,
    error,
    ...otherProps
  } = props;
  DEBUG('Time:', time);

  if (isLive) {
    return <Live time={time} isUnix={isUnix} />;
  }

  if (loading) {
    return <Loading progress={progress} />;
  }

  if (error) {
    return <Error />;
  }

  return <Slider time={time} isUnix={isUnix} {...otherProps} />;
};

const TimeSlider = props => (
  <Container>
    <React.Suspense>
      <Component {...props} />
    </React.Suspense>
  </Container>
);

export default TimeSlider;
