import React, { PureComponent } from 'react';
import moment from 'moment';
import DateTime from 'react-datetime';
import DateTimeContainer from './DateTimeContainer';

import Title from './Title';
import Border from './Border';
import Container from './Container';
import Header from './Header';
import Route from './Route';
import Label from './Label';
import ButtonClose from './ButtonClose';

class Vehicle extends PureComponent {
  onClickHeader = () => {
    const { activeVehicleIds, vehicle } = this.props;

    if (!activeVehicleIds.includes(vehicle.agent_id)) {
      this.props.setActiveVehicleId(vehicle.agent_id);
    } else {
      this.props.cleanActiveVehicleId(vehicle.agent_id);
    }
  };

  onChangeStartTime = (value) => {
    const { vehicle } = this.props;
    if (value instanceof moment) {
      this.props.setVehicleStartTime(
        vehicle.agent_id,
        value.format('YYYY-MM-DDTHH:mm:ssZ')
      );
    }
  };

  render() {
    const {
      vehicle,
      activeVehicleIds,
      isOver,
      setActiveRouteStop,
      activeRouteStop,
      editableBooking,
      pointEditing,
      isEditableVehicleTime
    } = this.props;

    const isActive = activeVehicleIds.includes(vehicle.agent_id);

    const available =
      vehicle.route.length > 2
        ? vehicle.route.slice(0, -1).reduce((acc, stop) => {
            if (stop.node_type === 'dropoff') {
              // return acc - stop.pax;
              return acc;
            }

            return acc + stop.pax;
          }, 0)
        : '--';

    return (
      <Container id={vehicle.agent_id} isOver={isOver} isActive={isActive}>
        <Header onClick={this.onClickHeader}>
          <Title>
            <Border color={vehicle.activeColor} />
            {`Vehicle #${vehicle.agent_id}`}
            {isActive && <ButtonClose />}
          </Title>
          <div>
            <Label>Available:</Label>
            {`${available} / ${vehicle.capacity.passenger || '--'}`}
          </div>
        </Header>
        {isActive && (
          <React.Fragment>
            <Route
              vehicleId={vehicle.agent_id}
              route={vehicle.route}
              setActiveRouteStop={setActiveRouteStop}
              activeRouteStop={activeRouteStop}
              color={vehicle.activeColor}
              isHaveEditable={editableBooking}
            />
            <i>{pointEditing && 'Select on the map'}</i>
            {isEditableVehicleTime && vehicle.route.length && (
              <React.Fragment>
                <DateTimeContainer>
                  <DateTime
                    viewMode='time'
                    value={moment(vehicle.route[0].scheduled_ts)}
                    onChange={this.onChangeStartTime}
                    dateFormat='YYYY-MM-DD'
                    timeFormat='HH:mm'
                    utc
                  />
                </DateTimeContainer>
              </React.Fragment>
            )}
          </React.Fragment>
        )}
      </Container>
    );
  }
}

export default Vehicle;
