import React from 'react';

import Header from 'components/Header';
import Layout from 'components/Layout';

import Container from './Container';
import Table from './Table';
import Panel from './Panel';
import Compare from './Compare';

const Simulations = () => (
  <Layout hasBackground>
    <Container>
      <Header />
      <Panel />
      <Table />
      <Compare />
    </Container>
  </Layout>
);

export default Simulations;
