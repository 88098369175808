import React from 'react';

import PanelContainer from '../PanelContainer';
import PanelHeader from '../PanelHeader';
import PanelList from '../PanelList';
import Vehicle from './Vehicle';
import VehicleEditor from './VehiclesEditor';

const Vehicles = ({ vehicles }) => {
  return (
    <PanelContainer>
      <PanelHeader>{`${vehicles.length} Vehicles`}</PanelHeader>
      <VehicleEditor />
      <PanelList>
        {vehicles.map(vehicle => (
          <Vehicle key={vehicle.agent_id} vehicle={vehicle} />
        ))}
      </PanelList>
    </PanelContainer>
  );
};

export default Vehicles;
