import React, { memo } from 'react';
import PropTypes from 'prop-types';

import Container from './Container';
import Logo from '../Logo';
import Navigation from './Navigation';
import ProjectMenu from './ProjectMenu';
import UserMenu from './UserMenu';
import Separator from './Separator';

const Header = (props) => {
  if (window.GEODISC_UI_HEADER_DISABLE) {
    return null;
  }

  const {
    isLogo,
    hasBackground,
    isShadow,
    isProjectSelectionDisabled,
    projectLabelTarget
  } = props;

  return (
    <Container hasBackground={hasBackground} isShadow={isShadow}>
      {isLogo && <Logo />}
      <UserMenu />
      <Navigation />
      <Separator />
      <ProjectMenu
        isProjectSelectionDisabled={isProjectSelectionDisabled}
        projectLabelTarget={projectLabelTarget}
      />
    </Container>
  );
};

Header.propTypes = {
  isLogo: PropTypes.bool,
  hasBackground: PropTypes.bool,
  isShadow: PropTypes.bool,
  isProjectSelectionDisabled: PropTypes.bool,
  projectLabelTarget: PropTypes.string
};

Header.defaultProps = {
  isLogo: true,
  hasBackground: false,
  isShadow: false,
  isProjectSelectionDisabled: false,
  projectLabelTarget: '/'
};

export default memo(Header);
