import React from 'react';
import Container from './Container';
import Search from './Search';
import Return from './Return';
import TimeSlider from './TimeSlider/index';

const TopButtons = () => {
  return (
    <Container>
      <Return to='/simulations' />
      <TimeSlider />
      <Search />
    </Container>
  );
};

export default React.memo(TopButtons);
