import React from 'react';
import { Dropdown } from '@urbica/ui-kit';

import Container from './Container';
import Content from './Content';
import Name from './Name';
import Type from './Type';
import Info from './Info';
import Logout from './Logout';

const UserMenu = ({ userName, isAdmin, deleteUser }) => {
  const onLogOut = () => {
    deleteUser();
  };

  return (
    <Dropdown
      renderHandler={({ toggle, toggledOn }) => (
        <Container isActive={toggledOn} onClick={toggle}>
          {userName}
        </Container>
      )}
      renderContent={({ toggle }) => (
        <Content onClick={toggle}>
          <Info>
            <Name>{userName}</Name>
            <Type>{isAdmin ? 'admin' : 'user'}</Type>
          </Info>
          <Logout onClick={onLogOut}>Log out</Logout>
        </Content>
      )}
    />
  );
};

export default UserMenu;
