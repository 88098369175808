import styled from 'styled-components';
import plus from 'assets/plusWhite.svg';

const Close = styled.div`
  display: block;
  position: absolute;
  top: 0;
  right: 0;

  width: 15px;
  height: 15px;
  cursor: pointer;
  background: url(${plus}) no-repeat center;
  margin-bottom: 5px;
  opacity: 1;
  transform: rotate(45deg);

  transition: background 0.2s;

  &:hover {
    background: url(${plus}) no-repeat center;
  }
`;

export default Close;
