import React from 'react';

import Container from './Container';
import Bookings from './Bookings';
import Vehicles from './Vehicles';

const Panels = () => (
  <Container>
    <Bookings />
    <Vehicles />
  </Container>
);

export default Panels;
