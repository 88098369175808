import { connect } from 'react-redux';
import { branch, compose, renderComponent } from 'recompose';
import createImmutableSelector from 'create-immutable-selector';

import { simulationSelector } from 'modules/simulations/selectors';
import { updateSimulation } from 'modules/simulations/actions';

import Error from 'components/Error';
import Loading from 'components/Loading';

import Info from './Info';

const mapStateToProps = createImmutableSelector(
  simulationSelector,
  simulation => ({ simulation })
);

const mapDispatchToProps = { updateSimulation };

const withBranch = compose(
  branch(({ error }) => error, renderComponent(Error)),
  branch(({ simulation }) => !simulation, renderComponent(Loading))
);

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps
);

const enhance = compose(
  withConnect,
  withBranch
);

export default enhance(Info);
