import { connect } from 'react-redux';
import { compose, lifecycle } from 'recompose';
import createImmutableSelector from 'create-immutable-selector';

import {
  fetchDatasets,
  downloadDatasetCsv,
  clearList
} from 'modules/datasets/actions';

import { listSelector } from 'modules/datasets/selectors';
import {
  datasetsErrorSelector,
  selectedIdsDatasetsSelector
} from 'modules/ui/selectors';
import { currentProjectIdSelector } from 'modules/user/selectors';

import Datasets from './Datasets';

// Refactoring
const withLifecycle = lifecycle({
  componentDidMount() {
    const { list, projectId } = this.props;
    if (!list && projectId) {
      this.props.fetchDatasets();
    }
  },
  componentDidUpdate(prevProps) {
    const { page, projectId } = this.props;
    if (page !== prevProps.page && projectId) {
      this.props.fetchDatasets();
    }

    if (prevProps.projectId !== projectId) {
      this.props.fetchDatasets();
    }
  }
});

const mapStateToProps = createImmutableSelector(
  listSelector,
  datasetsErrorSelector,
  selectedIdsDatasetsSelector,
  currentProjectIdSelector,
  (list, error, selectedIds, projectId) => ({
    list,
    error,
    selectedIds,
    projectId
  })
);

const mapDispatchToProps = {
  downloadDatasetCsv,
  fetchDatasets,
  clearList
};

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps
);

const enhance = compose(
  withConnect,
  withLifecycle
);

export default enhance(Datasets);
