import React from 'react';
import Container from './Container';

import StatusCircle from '../StatusCircle';

const Loading = ({ progress }) => (
  <Container>
    &nbsp;&nbsp;&nbsp;
    <StatusCircle color='gray' />
    &nbsp;&nbsp; Loading... &nbsp;&nbsp;
    {progress ? `(${Math.round(progress * 100)}%)` : ''}
  </Container>
);

export default Loading;
