import React, { Component } from 'react';
import MapGL, { CustomLayer } from '@urbica/react-map-gl';
import turfBbox from '@turf/bbox';
import geoViewport from '@mapbox/geo-viewport';
import { map, deckLayers } from 'config';
import { updateDeckLayer, updateData } from 'utils/deckLayers';
import Container from './Container';

class Map extends Component {
  componentDidMount() {
    this.map = this.mapRef.current.getMap();
  }

  componentDidUpdate(prevProps) {
    const { viewport } = this.props;
    const { data, layerStyle, activeLayer } = this.props;
    const { layers } = deckLayers[activeLayer];
    const isClustered =
      activeLayer === 'clusters' || activeLayer === 'arcClusters';
    const isChangeZoom =
      Math.round(prevProps.viewport.zoom) !== Math.round(viewport.zoom);
    const isLoaded = !prevProps.data.length && data.length;
    const isDataUpdated = prevProps.data !== data;

    const isSwitchLayer = prevProps.activeLayer !== activeLayer;
    const isChangeLayer =
      !isSwitchLayer && !layerStyle.equals(prevProps.layerStyle);

    // zoom to Geofence
    if (isLoaded && (data[0].dropoff_lon || data[0].dropoff_location_lon)) {
      const geometry = {
        type: 'LineString',
        coordinates: data.map(item => [
          item.dropoff_lon || item.dropoff_location_lon,
          item.dropoff_lat || item.dropoff_location_lat
        ])
      };

      this.zoomToGeofence(geometry);
    }

    // Update DATA
    if (isDataUpdated || (isClustered && isChangeZoom)) {
      updateData(activeLayer, data, viewport.zoom, layerStyle);
    }

    // Update property
    if (isChangeLayer) {
      prevProps.layerStyle.forEach((value, key) => {
        const newValue = layerStyle.get(key);
        if (newValue !== value) {
          updateDeckLayer(activeLayer, key, newValue);

          if (isClustered) {
            updateData(activeLayer, data, viewport.zoom, layerStyle);
          }
        }
      });
    }

    // Switch layers
    if (isSwitchLayer) {
      const oldLayers = deckLayers[prevProps.activeLayer].layers;
      Object.keys(oldLayers).forEach((key) => {
        this.map.removeLayer(oldLayers[key].id);
      });

      Object.keys(layers).forEach((key) => {
        this.map.addLayer(layers[key]);
      });

      updateData(activeLayer, data, viewport.zoom, layerStyle);
    }
  }

  zoomToGeofence = (geometry) => {
    const bbox = turfBbox(geometry).map(item => parseFloat(item, 10));
    const { center, zoom } = geoViewport.viewport(bbox, [300, 200]);
    const [longitude, latitude] = center;

    const { viewport } = this.props;
    viewport.zoom = zoom;
    viewport.latitude = latitude;
    viewport.longitude = longitude;

    this.props.onViewportChange(viewport);
  };

  mapRef = React.createRef();

  render() {
    const { viewport, activeLayer } = this.props;
    const { layers } = deckLayers[activeLayer];

    return (
      <Container>
        <MapGL
          ref={this.mapRef}
          style={{ width: '100%', height: '100vh' }}
          mapStyle={map.mapStyle}
          accessToken={map.token}
          onViewportChange={this.props.onViewportChange}
          {...viewport}
        >
          {Object.keys(layers).map(key => (
            <CustomLayer key={key} layer={layers[key]} />
          ))}
        </MapGL>
      </Container>
    );
  }
}

export default Map;
