import React from 'react';
import faker from 'faker';

import { crc32 } from 'utils/crc32';
import debug from 'utils/debug';
import PanelContainer from '../PanelContainer';
import PanelHeader from '../PanelHeader';
import PanelList from '../PanelList';
import Vehicle from './Vehicle';
import Button from './Button';
import Filter from './Filter';
import VehicleEditor from './VehiclesEditor';

const { METHOD } = debug('p:CommuteOffer:Panels:Bookings');

const Vehicles = ({ serialNumber, vehicles, newVehicle, openPopup }) => {
  METHOD('render', { serialNumber, vehicles, newVehicle, openPopup });

  const onClickNew = () => {
    METHOD('onClickNew');

    const id = faker.random.uuid();

    openPopup('CommuteOfferVehicleEditor', {
      mode: 'new',
      title: 'Add new vehicle',
      submitCaption: 'Create',
      submittingCaption: 'Creating...',
      initialValues: {
        id,
        capacity_passengers: '15',
        capacity_stops: '100',
        capacity_wheelchairs: '0',
        start_time: new Date(),
        routing_engine_name: { value: 'osrme', label: 'osrme' },
        road_network: 'driving',
        vehicle_color: crc32(id) % 360
      }
    });
  };

  return (
    <PanelContainer>
      <PanelHeader>
        {`${vehicles.length} Vehicles`}
        <Button onClick={onClickNew}>New</Button>
      </PanelHeader>
      {!global.GEODISC_UI_COMMUTE_OFFER_PANEL_FILTERING_DISABLE && <Filter />}
      <VehicleEditor />

      <PanelList>
        {vehicles
          .slice(0, window.GEODISC_DEBUG_VEHICLES_PANEL_MAX_RECORDS_COUNT || 50)
          .map(vehicle => (
            <Vehicle key={vehicle.agent_id} vehicle={vehicle} />
          ))}
      </PanelList>
    </PanelContainer>
  );
};

export default Vehicles;
