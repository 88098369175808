import { api$CommuteOffer as api, api$Masstransit } from 'api';

import moment from 'moment';
import debug from 'utils/debug';
import weekdays from 'utils/weekdays';

import {
  silverayCsvToJson,
  silverayNormalizeData
} from 'utils/CommuteOffer/silveray';

import { makeScheduleDates, sleep } from 'utils/CommuteSchedule/utils';
import {
  commuteOffer$DeleteEmptyVehicles,
  commuteOffer$MergeCommuteOffers
} from 'utils/CommuteOffer';
import {
  commuteSchedule$GenerateOffersForDate,
  commuteSchedule$FixedScheduleGenerators,
  //  commuteSchedule$GeneratePhysicalVehicles,
  commuteSchedule$FixedScheduleFromOffers
} from 'utils/CommuteSchedule';

const { METHOD } = debug('m:CommuteSchedule:ImportPassengersSchedule');

// eslint-disable-next-line import/prefer-default-export
export const commuteSchedule$ImportPassengersSchedule = async (
  currentOffer,
  data,
  params,
  opts
) => {
  METHOD('Request', { currentOffer, data, params, opts });

  try {
    // const fromDate = moment('2020-03-16');
    const fromDate = moment(new Date(params.from_date).setHours(0, 0, 0, 0));
    METHOD('fromDate', { fromDate });

    // const tillDate = moment('2020-03-16');
    const tillDate = moment(new Date(params.till_date).setHours(0, 0, 0, 0));
    METHOD('tillDate', { tillDate });

    const scheduleDates = [...makeScheduleDates(fromDate, tillDate)];
    METHOD('scheduleDates', { scheduleDates });

    const majorStepCount = 5 + scheduleDates.length * 2;
    const majorStepSize = 1 / majorStepCount;

    let currentMajorStep = 0;

    const options = opts || {};

    const currentProject = options.currentProject
      ? options.currentProject.toJS()
      : null;

    const projects = options.projects ? options.projects.toJS() : [];

    const logsProject = projects.find(
      project => project.name === 'urbica_silveray_testdev'
    );

    METHOD('projects', { projects, currentProject, logsProject });

    let currentProgress = {
      status: null,
      progress: null
    };

    const setProgress = async (status, progress) => {
      const newProgress = {
        status,
        progress: Math.max(
          0.01,
          Math.min(
            progress / majorStepCount + currentMajorStep * majorStepSize,
            1
          )
        )
      };
      METHOD('setProgress', { status, progress, newProgress });
      if (
        currentProgress.status !== newProgress.status ||
        currentProgress.progress !== newProgress.progress
      ) {
        if (newProgress.progress < currentProgress.progress) {
          return;
          // throw new Error(
          //   `${status}: Progress error: ${newProgress.progress} < ${currentProgress.progress}`
          // );
        }
        currentProgress = newProgress;
        // console.log(`${status} (${Math.floor(newProgress.progress * 100)}%)`);
        if (options.setProgress) {
          await options.setProgress(newProgress.status, newProgress.progress);
          await sleep(100);
        }
      }
    };

    const nextMajorStep = async (status) => {
      currentMajorStep += 1;
      return setProgress(status, 0);
    };

    const ignoreErrors = params.ignore_errors.value;
    const human_readable_uids = params.human_readable_uids.value;
    const fixedScheduleGeneratorType = params.fixedScheduleGeneratorType.value;
    const areCalculationGroupsEnabled =
      params.areCalculationGroupsEnabled.value;
    const areMutuallyExclusiveGroupsEnabled =
      params.areMutuallyExclusiveGroupsEnabled.value;
    const areSpecialDemandsEnabled = params.areSpecialDemandsEnabled.value;
    const unassigned_only =
      areSpecialDemandsEnabled && params.unassigned_only.value;
    const timezone = params.source_file_timezone.value;
    const max_slack = parseInt(params.max_slack, 10);
    // const max_slack_ph = parseInt(params.max_slack_ph, 10);
    const serviceTime = parseInt(params.service_time, 10);
    const time_limit_ms = params.time_limit_ms.value;

    const vehicle_passenger = parseInt(params.vehicle_passenger, 10);
    if (!vehicle_passenger) {
      throw new Error('Vehicle capacity cannot be zero');
    }
    const vehicle_wheelchair = parseInt(params.vehicle_wheelchair, 10);
    if (vehicle_wheelchair > vehicle_passenger) {
      throw new Error(
        'Number of wheelchair passengers must be less than a vehicle capacity'
      );
    }

    await setProgress('Parsing', 0);

    const jsonData = await silverayCsvToJson(data);
    METHOD('jsonData', jsonData);

    const normalizedData0 = await silverayNormalizeData(jsonData, {
      ignoreErrors,
      timezone,
      serviceTime
    });
    METHOD('normalizedData0', { jsonData, normalizedData0 });

    const normalizedData = normalizedData0.map((record) => {
      const calculation_group = areCalculationGroupsEnabled
        ? record.calculation_group
        : 'None';
      const mutually_exclusive_group = areMutuallyExclusiveGroupsEnabled
        ? record.mutually_exclusive_group
        : undefined;
      const special_demand = areSpecialDemandsEnabled
        ? record.special_demand
        : undefined;
      return {
        ...record,
        calculation_group,
        mutually_exclusive_group,
        special_demand
      };
    });
    METHOD('normalizedData', { jsonData, normalizedData });

    const publicPassengerSchedule = normalizedData.reduce(
      (publicPassengerScheduleAcc, record) => {
        const {
          $uid,
          name,
          country,
          city,
          zip_code,
          lon,
          lat,
          calculation_group,
          mutually_exclusive_group,
          special_demand,
          wheelchair,
          mon,
          tue,
          wed,
          thu,
          fri,
          sat,
          sun,
          destination,
          type_of_service,
          morning_dropoff_ts,
          morning_pickup_from_ts,
          morning_pickup_till_ts,
          evening_pickup_ts,
          evening_dropoff_from_ts,
          evening_dropoff_till_ts
        } = record;
        return {
          ...publicPassengerScheduleAcc,
          [name]: {
            $uid,
            name,
            country,
            city,
            zip_code,
            lon,
            lat,
            calculation_group,
            mutually_exclusive_group,
            special_demand,
            wheelchair,
            mon,
            tue,
            wed,
            thu,
            fri,
            sat,
            sun,
            destination,
            type_of_service,
            morning_dropoff_ts,
            morning_pickup_from_ts,
            morning_pickup_till_ts,
            evening_pickup_ts,
            evening_dropoff_from_ts,
            evening_dropoff_till_ts
          }
        };
      },
      {}
    );
    METHOD('publicPassengerSchedule', { publicPassengerSchedule });

    const specialDemandSet = normalizedData.reduce((acc, person) => {
      if (person.special_demand) {
        acc.add(person.special_demand);
      }
      return acc;
    }, new Set());
    const specialDemands = [...specialDemandSet.values()];
    METHOD('specialDemands', {
      specialDemands
    });

    const zipCodes = normalizedData.reduce((acc, person) => {
      if (person.zip_code) {
        acc.add(person.zip_code);
      }
      return acc;
    }, new Set());
    METHOD('zipCodes', {
      zipCodes,
      values: [...zipCodes.values()],
      count: zipCodes.length
    });

    await nextMajorStep('Resolving zip codes');

    const buildingRequestsResolved = [];
    const buildingRequestsRejected = [];

    const buildingRequests = [...zipCodes.values()].map(zipCode =>
      api$Masstransit.buildingByZipCode('Singapore', 'Singapore', zipCode).then(
        (result) => {
          buildingRequestsResolved.push(result);
        },
        (error) => {
          buildingRequestsRejected.push({ zipCode, error });
        }
      ));
    METHOD('buildingRequests', { buildingRequests });

    // eslint-disable-next-line no-constant-condition
    while (true) {
      const progress =
        (buildingRequestsResolved.length + buildingRequestsRejected.length) /
        buildingRequests.length;
      METHOD('buildingRequests:Progress', {
        buildingRequestsResolved,
        buildingRequestsRejected,
        buildingRequests,
        progress
      });
      // eslint-disable-next-line no-await-in-loop
      await setProgress('Resolving zip codes', progress);
      if (
        buildingRequestsResolved.length + buildingRequestsRejected.length ===
        buildingRequests.length
      ) {
        break;
      }
      // eslint-disable-next-line no-await-in-loop
      await sleep(500);
    }
    METHOD('buildingRequestsResolved', {
      buildingRequestsResolved
    });
    METHOD('buildingRequestsRejected', {
      buildingRequestsRejected
    });

    const buildingsByZipCode = buildingRequestsResolved.reduce(
      (acc, record) => {
        const { result } = record;
        if (result.objects && result.objects.length) {
          acc.resolved[record.zipCode] = result.objects.map(item => ({
            name: item.name,
            address: item.address,
            blk_no: item.blk_no,
            coordinates: item.point.coordinates,
            zipCode: record.zipCode
          }));
        } else {
          acc.failed.push({
            zipCode: record.zipCode,
            error: new Error('No objects returned')
          });
        }
        return acc;
      },
      { resolved: {}, failed: [] }
    );
    METHOD('buildingsByZipCode', { buildingsByZipCode });

    if (!ignoreErrors) {
      const buildingRequestsFailed = [
        ...buildingRequestsRejected,
        ...buildingsByZipCode.failed
      ];
      if (buildingRequestsFailed.length) {
        throw new Error(
          `Failed to resolve zip codes: ${buildingRequestsFailed
            .map(item => item.zipCode)
            .join(', ')}`
        );
      }
    }

    const dataWithBuildings = normalizedData.map((record) => {
      const recordCoordinates =
        record.lon && record.lat
          ? { coordinates: [record.lon, record.lat] }
          : undefined;
      const buildingByZipCode = buildingsByZipCode.resolved[record.zip_code];
      const $buildings =
        record.zip_code && buildingByZipCode
          ? buildingByZipCode
          : [recordCoordinates];
      return {
        ...record,
        $buildings
      };
    });
    METHOD('dataWithBuildings', { dataWithBuildings });

    const coordinates = dataWithBuildings.reduce((acc, record) => {
      const { $buildings } = record;
      $buildings.forEach((item) => {
        acc.push(item.coordinates);
      });
      return acc;
    }, []);
    METHOD('coordinates', { coordinates });

    await nextMajorStep('Searching for stops');

    const stopsByCoordinates = await api$Masstransit.findStopsByCoordinates(
      coordinates,
      {
        ignoreErrors,
        setProgress: async (progress) => {
          await setProgress('Searching for stops', progress);
        }
      }
    );
    METHOD('stopsByCoordinates', { stopsByCoordinates });

    const dataWithStops = dataWithBuildings.map((record) => {
      const $buildings = record.$buildings.map((building) => {
        const lon = building.coordinates[0];
        const lat = building.coordinates[1];
        const ident = JSON.stringify({ lon, lat, stopType: 'building' });
        const nearestPoint = stopsByCoordinates[ident].result.objects[0];
        return {
          ...building,
          nearestPoint
        };
      });
      return {
        ...record,
        $buildings
      };
    });
    METHOD('dataWithStops', { dataWithStops });

    const resultData = dataWithStops;
    METHOD('resultData', { resultData });

    const engineSettingsBase = JSON.parse(
      JSON.stringify(currentOffer.stateless_api_request_data.engine_settings)
    );

    const currentExternalSource =
      currentOffer.stateless_api_request_data.inbound &&
      currentOffer.stateless_api_request_data.inbound.external &&
      currentOffer.stateless_api_request_data.inbound.external.source;

    const externalOffer =
      currentExternalSource || JSON.parse(JSON.stringify(currentOffer));
    METHOD('importBookings:externalOffer', { externalOffer });

    if (externalOffer.result.rejected_bookings.length) {
      throw new Error(
        'External Commute Offer cannot contain any rejected bookings'
      );
    }

    const dataWithoutErrors = resultData.filter(
      record => record.$errors.length === 0
    );
    METHOD('importBookings:dataWithoutErrors', { dataWithoutErrors });

    await nextMajorStep('Calculating fixed schedule');

    const fixedScheduleGenerator =
      commuteSchedule$FixedScheduleGenerators[fixedScheduleGeneratorType] ||
      (async () => {
        return null;
      });

    const fixedSchedule = await fixedScheduleGenerator(
      dataWithoutErrors,
      timezone,
      {
        human_readable_uids,
        vehicle_passenger,
        vehicle_wheelchair,
        unassigned_only,
        engineSettingsBase,
        max_slack,
        time_limit_ms,
        areCalculationGroupsEnabled,
        areMutuallyExclusiveGroupsEnabled,
        areSpecialDemandsEnabled,
        setProgress: async (progress) => {
          await setProgress('Calculating fixed schedule', progress);
        }
      }
    );
    METHOD('fixedSchedule', {
      fixedSchedule
    });

    await nextMajorStep('Generating offers');

    const offersByScheduleDates = await scheduleDates.reduce(
      async (offersByScheduleDatesAcc$Promise, currentDate) => {
        const offersByScheduleDatesAcc = await offersByScheduleDatesAcc$Promise;
        METHOD('offersByScheduleDates:currentDate', {
          offersByScheduleDatesAcc,
          currentDate
        });

        // const externalOffer$GeneratedOffers = await commuteSchedule$GenerateOffersForDate(
        //   dataWithoutErrors,
        //   currentDate,
        //   timezone,
        //   {
        //     areMutuallyExclusiveGroupsEnabled,
        //     human_readable_uids,
        //     areSpecialDemandsEnabled,
        //     vehicle_passenger,
        //     vehicle_wheelchair,
        //     unassigned_only,
        //     engineSettingsBase,
        //     max_slack,
        //     time_limit_ms,
        //     fixedSchedule: fixedSchedule && fixedSchedule.schedule
        //   }
        // );
        // METHOD('offersByScheduleDates:externalOffer$GeneratedOffers', {
        //   currentDate,
        //   externalOffer$GeneratedOffers
        // });

        // const externalOffer$PreparedOffers = externalOffer$GeneratedOffers.map(
        //   commuteOffer => ({
        //     ...commuteOffer,
        //     stateless_api_request_data: {
        //       ...commuteOffer.stateless_api_request_data,
        //       bookings: {
        //         ...commuteOffer.stateless_api_request_data.bookings,
        //         ...externalOffer.stateless_api_request_data.bookings
        //       },
        //       nodes: [
        //         ...commuteOffer.stateless_api_request_data.nodes,
        //         ...externalOffer.stateless_api_request_data.nodes
        //       ],
        //       vehicles: [...externalOffer.stateless_api_request_data.vehicles]
        //     },
        //     result: {}
        //   })
        // );
        // METHOD('offersByScheduleDates:externalOffer$PreparedOffers', {
        //   currentDate,
        //   externalOffer$PreparedOffers
        // });

        // const externalOffer$CalculatedOffers = await api.scheduleCommuteOffers(
        //   externalOffer$PreparedOffers,
        //   {
        //     ignoreErrors,
        //     setProgress: async progress => {
        //       await setProgress('Calculating offers', progress);
        //     }
        //   }
        // );
        // METHOD('offersByScheduleDates:externalOffer$CalculatedOffers', {
        //   externalOffer$CalculatedOffers
        // });

        const externalOffer$CalculatedOffers = [];

        const generatedOffers = await commuteSchedule$GenerateOffersForDate(
          dataWithoutErrors,
          currentDate,
          timezone,
          {
            areMutuallyExclusiveGroupsEnabled,
            human_readable_uids,
            areSpecialDemandsEnabled,
            vehicle_passenger,
            vehicle_wheelchair,
            unassigned_only,
            engineSettingsBase,
            max_slack,
            time_limit_ms,
            destinationTimeWindowDuration: 30 * 60,
            fixedSchedule: fixedSchedule && fixedSchedule.schedule
          }
        );
        METHOD('offersByScheduleDates:generatedOffers', {
          currentDate,
          offersByScheduleDatesAcc,
          generatedOffers
        });

        return [
          ...offersByScheduleDatesAcc,
          {
            currentDate,
            externalOffer$CalculatedOffers,
            generatedOffers
          }
        ];
      },
      []
    );
    METHOD('offersByScheduleDates', { offersByScheduleDates });

    const externalOffer$CalculatedResults = offersByScheduleDates.reduce(
      (acc, item) => [...acc, ...item.externalOffer$CalculatedOffers],
      []
    );
    METHOD('externalOffer$CalculatedResults', {
      externalOffer$CalculatedResults
    });

    const calculatedPhysicalVehiclesByScheduleDates = await offersByScheduleDates.reduce(
      async (memo$promise, item) => {
        const memo = await memo$promise;
        METHOD('calculatedPhysicalVehiclesByScheduleDates:Request', {
          memo,
          item
        });

        await nextMajorStep('Calculating offers');

        const { currentDate, generatedOffers } = item;

        const calculatedOffers = await api.scheduleCommuteOffers(
          generatedOffers,
          {
            ignoreErrors,
            setProgress: async (progress) => {
              await setProgress('Calculating offers', progress);
            }
          }
        );
        METHOD('calculatedPhysicalVehiclesByScheduleDates:calculatedOffers', {
          calculatedOffers
        });

        const calculatedResults = commuteOffer$DeleteEmptyVehicles(
          calculatedOffers
        );
        METHOD('calculatedPhysicalVehiclesByScheduleDates:calculatedResults', {
          calculatedResults
        });

        // await nextMajorStep('Calculating offers');

        // const generatedPhysicalVehicles = await commuteSchedule$GeneratePhysicalVehicles(
        //   calculatedResults,
        //   currentDate,
        //   timezone,
        //   { max_slack_ph }
        // );
        // METHOD(
        //   'calculatedPhysicalVehiclesByScheduleDates:generatedPhysicalVehicles',
        //   {
        //     generatedPhysicalVehicles
        //   }
        // );

        // const calculatedPhysicalVehicleOffers = await api.scheduleCommuteOffer(
        //   generatedPhysicalVehicles
        // );
        // METHOD(
        //   'calculatedPhysicalVehiclesByScheduleDates:calculatedPhysicalVehicleOffers',
        //   {
        //     calculatedPhysicalVehicleOffers
        //   }
        // );

        // const calculatedPhysicalVehicleResults = commuteOffer$DeleteEmptyVehicles(
        //   [calculatedPhysicalVehicleOffers]
        // );
        // METHOD(
        //   'calculatedPhysicalVehiclesByScheduleDates:calculatedPhysicalVehicleResults',
        //   {
        //     calculatedPhysicalVehicleResults
        //   }
        // );

        // const resultOffers = calculatedPhysicalVehicleResults.map(
        //   (mergedOffer, majorIndex) => {
        //     const newVehicleIDs = mergedOffer.stateless_api_request_data.vehicles.map(
        //       (vehicle, index) => [
        //         vehicle.agent_id,
        //         `${currentDate.format('YYYY-MM-DD')} #${currentDate.format(
        //           'ddd'
        //         )} #N:${majorIndex * 1000 + index + 1}`
        //       ]
        //     );

        //     return commuteOffer$RenameVehicles(mergedOffer, newVehicleIDs);
        //   }
        // );
        // METHOD('calculatedPhysicalVehiclesByScheduleDates:resultOffers', {
        //   calculatedPhysicalVehicleResults,
        //   resultOffers
        // });

        await setProgress('Calculating offers', 1);

        const newMemo = [
          ...memo,
          {
            currentDate,
            trips: { calculatedOffers, calculatedResults },
            vehicles: [] // resultOffers
          }
        ];
        METHOD('calculatedPhysicalVehiclesByScheduleDates:Result', {
          newMemo
        });
        return newMemo;
      },
      []
    );
    METHOD('calculatedPhysicalVehiclesByScheduleDates', {
      calculatedPhysicalVehiclesByScheduleDates
    });

    const trips$AllOffers = calculatedPhysicalVehiclesByScheduleDates.reduce(
      (acc, item) => [...acc, ...item.trips.calculatedOffers],
      []
    );
    METHOD('trips$AllOffers', { trips$AllOffers });

    const trips$AllResults = calculatedPhysicalVehiclesByScheduleDates.reduce(
      (acc, item) => [...acc, ...item.trips.calculatedResults],
      []
    );
    METHOD('trips$AllResults', { trips$AllResults });

    const trips$StructuredOffers = Object.keys(weekdays).reduce(
      (trips$StructuredOffersMemo, weekday) => {
        const weekdayTag = `#${weekdays[weekday].shortName}`;
        METHOD('trips$StructuredOffers:weekdayTag', { weekdayTag });
        const offersByWeekdays = trips$AllResults.filter(commuteOffer =>
          commuteOffer.tags.find(x => x === weekdayTag));
        METHOD('trips$StructuredOffers:offersByWeekdays', { offersByWeekdays });
        return {
          ...trips$StructuredOffersMemo,
          [weekday]: {
            offers: offersByWeekdays,
            schedule: commuteSchedule$FixedScheduleFromOffers(
              offersByWeekdays,
              timezone
            )
          }
        };
      },
      {}
    );
    METHOD('trips$StructuredOffers', { trips$StructuredOffers });

    const vehicles$AllOffers = calculatedPhysicalVehiclesByScheduleDates.reduce(
      (acc, item) => [...acc, ...item.vehicles],
      []
    );
    METHOD('vehicles$AllOffers', { vehicles$AllOffers });

    await nextMajorStep('Saving');

    const currentOfferName = currentOffer.name || 'Unnamed';

    await Promise.all(
      fixedSchedule && logsProject
        ? fixedSchedule.offers.map(offer =>
            api.addCommuteOffer({
              ...offer,
              name: `${currentOfferName} - FixedSchedule - ${offer.name}`,
              project: logsProject.resource_uri
            }))
        : []
    );

    const fixedSchedule$Merged =
      fixedSchedule && fixedSchedule.offers
        ? commuteOffer$MergeCommuteOffers(trips$AllResults)
        : null;

    await api.addCommuteOffer({
      ...fixedSchedule$Merged,
      name: `${currentOfferName} - FixedSchedule - All In One`,
      project: logsProject.resource_uri
    });

    const scheduleSections = {
      schedule: publicPassengerSchedule,
      fixed_schedule: fixedSchedule
    };
    METHOD('scheduleSections', { scheduleSections });

    await Promise.all(
      logsProject
        ? trips$AllOffers.map(offer =>
            api.addCommuteOffer({
              ...offer,
              name: `${currentOfferName} - Trips - ${offer.name}`,
              project: logsProject.resource_uri
            }))
        : []
    );

    const trips$MergedOffers = commuteOffer$MergeCommuteOffers(trips$AllOffers);

    await api.addCommuteOffer({
      ...trips$MergedOffers,
      name: `${currentOfferName} - Trips - All In One`,
      project: logsProject.resource_uri
    });

    const trips$MergedResults = commuteOffer$MergeCommuteOffers(
      trips$AllResults
    );

    await api.addCommuteOffer({
      ...trips$MergedResults,
      stateless_api_request_data: {
        ...trips$MergedResults.stateless_api_request_data,
        inbound: {
          ...scheduleSections,
          version: 1
        }
      },
      name: `${currentOfferName} - Trips - All Results`,
      project: logsProject.resource_uri
    });

    const trips$ResultsByWeekdays = Object.keys(weekdays).map((weekday) => {
      const weekdayInfo = weekdays[weekday];
      const prefix = weekdayInfo.name;
      const offers = trips$AllResults.filter(commuteOffer =>
        commuteOffer.tags.find(x => x === `#${weekdayInfo.shortName}`));
      return {
        prefix,
        offers
      };
    });
    METHOD('trips$ResultsByWeekdays', { trips$ResultsByWeekdays });

    if (logsProject) {
      await Promise.all(
        trips$ResultsByWeekdays.map(async (weekdayRecord) => {
          const commuteOffer = commuteOffer$MergeCommuteOffers(
            weekdayRecord.offers
          );
          return api.addCommuteOffer({
            ...commuteOffer,
            stateless_api_request_data: {
              ...commuteOffer.stateless_api_request_data,
              inbound: {
                ...scheduleSections,
                version: 1
              }
            },
            name: `${currentOfferName} - Trips - All Results - ${weekdayRecord.prefix}`,
            project: logsProject.resource_uri
          });
        })
      );
    }

    const vehicles$Merged = commuteOffer$MergeCommuteOffers(vehicles$AllOffers);

    // const offersToMeMerged = vehicles$AllOffers;
    // const isRootReadonly = true;

    const offersToMeMerged = trips$AllResults;
    const isRootReadonly = false;

    // const offersToMeMerged = [externalOffer$CalculatedResults[0]];
    // const isRootReadonly = true;

    const mergedOffer = commuteOffer$MergeCommuteOffers(offersToMeMerged);
    METHOD('mergedOffer', { mergedOffer });

    const newOffer = {
      ...mergedOffer,
      result: {
        ...mergedOffer.result,
        readOnly: isRootReadonly
      },
      stateless_api_request_data: {
        ...mergedOffer.stateless_api_request_data,
        inbound: {
          $version: 1,
          schedule: publicPassengerSchedule,
          external: {
            source: externalOffer,
            result: externalOffer$CalculatedResults
          },
          fixed_schedule: fixedSchedule,
          trips: {
            ...trips$StructuredOffers,
            source: trips$AllResults,
            result: trips$MergedOffers
          },
          vehicles: {
            source: vehicles$AllOffers,
            result: vehicles$Merged
          },
          generation_parameters: params
        },
        current_time: `${moment(fromDate).format('YYYY-MM-DD')}T${moment
          .tz('00:00 AM', 'LT', timezone)
          .format('HH:mm:ssZ')}`
      },
      tags: [],
      id: currentOffer.id,
      name: currentOffer.name,
      project: currentOffer.project,
      resource_uri: currentOffer.resource_uri
    };
    METHOD('newOffer', { newOffer });

    return newOffer;
  } catch (error) {
    METHOD('Failure', { error });
    throw error;
  }
};
