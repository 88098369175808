// @flow
import { Map, List } from 'immutable';
import debug from 'utils/debug';
import { fetchData } from './net';
import { getHeaders } from './headers';
import { pagination, urls } from '../config';

const { DEBUG, TRACE, METHOD } = debug('api:simulations');

export const getSimulations = async (
  page: number,
  orderingParam: boolean,
  search: string,
  projectId: number
) => {
  DEBUG('getSimulations()');

  try {
    const offset = pagination.datasets * (page - 1);
    const url = urls.simulationsList(offset, orderingParam, search, projectId);
    TRACE($ => $('GET', url));

    const response = await fetchData(url, {
      headers: getHeaders()
    });

    if (!response.ok) {
      throw new Error(response.status);
    }

    const responseJSON: SimulationResponse = await response.json();
    return responseJSON;
  } catch (error) {
    throw error;
  }
};

export const getSimulation = async (id: number) => {
  DEBUG('getSimulation()');

  try {
    const url = urls.simulation(id);
    TRACE($ => $('GET', url));

    const response = await fetchData(url, {
      headers: getHeaders()
    });

    if (!response.ok) {
      throw new Error(response.status);
    }

    const responseJSON = await response.json();

    responseJSON.isLive =
      responseJSON.state === 'running' ||
      responseJSON.state === 'created' ||
      responseJSON.state === 'queued';

    responseJSON.useVehicleLog =
      !responseJSON.isLive ||
      (responseJSON.isLive && !responseJSON.data.use_websocket_for_frontend);

    return responseJSON;
  } catch (error) {
    throw error;
  }
};

export const getBookingsForSimulation = async (id: number) => {
  DEBUG('getBookingsForSimulation()');

  try {
    const url = urls.bookingsForSimulation(id);
    TRACE($ => $('GET', url));

    const response = await fetchData(url, {
      headers: getHeaders()
    });

    if (!response.ok) {
      throw new Error(response.status);
    }

    const responseJSON = await response.json();

    return responseJSON;
  } catch (error) {
    throw error;
  }
};

export const getVehiclesForSimulation = async (id: number) => {
  DEBUG('getVehiclesForSimulation()');

  try {
    const response = await fetchData(urls.vehiclesForSimulation(id), {
      headers: getHeaders()
    });

    if (!response.ok) {
      throw new Error(response.status);
    }

    const responseJSON = await response.json();

    return responseJSON;
  } catch (error) {
    throw error;
  }
};

export const getNodesForSimulation = async (id: number) => {
  DEBUG('getNodesForSimulation()');

  try {
    const url = urls.nodesForSimulation(id);
    TRACE($ => $('GET', url));

    const response = await fetchData(url, {
      headers: getHeaders()
    });

    if (!response.ok) {
      throw new Error(response.status);
    }

    const responseJSON = await response.json();

    return responseJSON;
  } catch (error) {
    throw error;
  }
};

export const getSimulationVehicleSet = async (ids) => {
  DEBUG('getSimulationVehicleSet()');

  try {
    const url = urls.simulationVehicleSet(ids);
    TRACE($ => $('GET', url));

    const response = await fetchData(url, {
      headers: getHeaders()
    });

    if (!response.ok) {
      throw new Error(response.status);
    }

    const responseJSON = await response.json();
    DEBUG('Response:', responseJSON);

    const result = await responseJSON.objects.reduce(
      (acc, item) => acc.set(parseInt(item.id, 10), item),
      Map()
    );

    return result;
  } catch (error) {
    throw error;
  }
};

export const getSimulationNodeSet = async (nodes) => {
  DEBUG('getSimulationNodes()');

  try {
    const url = urls.simulationNodeSet(nodes);
    TRACE($ => $('GET', url));

    const response = await fetchData(url, {
      headers: getHeaders()
    });

    if (!response.ok) {
      throw new Error(response.status);
    }

    const responseJSON = await response.json();
    DEBUG('Response:', responseJSON);

    const result = await responseJSON.objects.reduce(
      (acc, item) => acc.set(parseInt(item.id, 10), item),
      Map()
    );

    return result;
  } catch (error) {
    throw error;
  }
};

export const getData = async (id: number, offset: number, limit: number) => {
  DEBUG('getData()');

  try {
    const url = urls.simulationVehicleLog(id, offset, limit);
    TRACE($ => $('GET', url));

    const response = await fetchData(url, {
      headers: getHeaders()
    });

    if (!response.ok) {
      throw new Error(response.status);
    }

    const responseJSON = await response.json();
    DEBUG('Response:', responseJSON);

    // const requiredNodes = await responseJSON.objects.reduce((acc, item) => {
    //   item.assigned_nodes.forEach((node) => {
    //     acc.add(node.id);
    //   });
    //   return acc;
    // }, new Set());

    // const nodes = await getSimulationNodeSet([...requiredNodes]);
    // TRACE($ => $('Nodes:', nodes.toJS()));

    return responseJSON;
  } catch (error) {
    throw error;
  }
};

export const getSimulationVehicleLog = async (
  id: number,
  offset: number,
  limit: number
) => {
  METHOD('getSimulationVehicleLog:Request', { id, offset, limit });

  try {
    const url = urls.simulationVehicleLog(id, offset, limit);
    TRACE($ => $('GET', url));

    const response = await fetchData(url, {
      headers: getHeaders()
    });

    if (!response.ok) {
      throw new Error(response.status);
    }

    const responseJSON = await response.json();
    DEBUG('Response:', responseJSON);

    const { meta } = responseJSON;

    const requiredNodes = await responseJSON.objects.reduce((acc, item) => {
      item.assigned_nodes.forEach((node) => {
        acc.add(node.id);
      });
      return acc;
    }, new Set());

    const nodes = await getSimulationNodeSet([...requiredNodes]);

    const result = await responseJSON.objects.reduce((acc, item) => {
      const assigned_nodes = item.assigned_nodes.reduce(
        (assignedNodes, assignedNode) => {
          const nodeFromLog = {
            id: assignedNode.id,
            scheduled_ts: assignedNode.scheduled_ts
          };
          const nodeFromAPI = nodes.get(assignedNode.id);
          if (nodeFromAPI) {
            return assignedNodes.push({
              ...nodeFromAPI,
              ...nodeFromLog
            });
          }
          DEBUG(`*** Node ${assignedNode.id} not found.`);
          // return assignedNodes.push(nodeFromLog);
          return assignedNodes;
        },
        List()
      );
      // eslint-disable-next-line
      item.assigned_nodes = assigned_nodes;

      if (acc.get(item.vehicle_id)) {
        return acc.update(item.vehicle_id, value => value.push(item));
      }

      return acc.set(item.vehicle_id, List([item]));
    }, Map());

    return {
      meta,
      data: result
    };
  } catch (error) {
    throw error;
  }
};

export const addSimulations = async (body: any) => {
  try {
    const response = await fetchData(urls.simulations, {
      method: 'POST',
      headers: getHeaders(),
      body
    });

    if (!response.ok) {
      throw new Error(response.status);
    }

    const responseJSON: SimulationResponse = await response.json();

    return responseJSON;
  } catch (error) {
    throw error;
  }
};

export const launchSimulation = async (id: number) => {
  try {
    const response = await fetchData(urls.simulationLaunch(id), {
      method: 'POST',
      headers: getHeaders()
    });

    if (!response.ok) {
      throw new Error(response.status);
    }

    return null;
  } catch (error) {
    throw error;
  }
};

export const deleteSimulation = async (id: number) => {
  try {
    const response = await fetchData(urls.simulation(id), {
      method: 'DELETE',
      headers: getHeaders()
    });
    if (!response.ok) {
      throw new Error(response.status);
    }

    return null;
  } catch (error) {
    throw error;
  }
};

export const updateSimulation = async (id: number, body: any) => {
  try {
    const response = await fetchData(urls.simulation(id), {
      method: 'PATCH',
      headers: getHeaders(),
      body
    });

    if (!response.ok) {
      throw new Error(response.status);
    }

    const responseJSON: SimulationResponse = await response.json();

    return responseJSON;
  } catch (error) {
    throw error;
  }
};
