import { connect } from 'react-redux';
import { ActionCreators } from 'redux-undo';
import { createSelector } from 'reselect';

import { openPopup, closePopup } from 'modules/ui/actions';
import {
  updateCommuteOffer,
  commuteOfferStatelessNodeScheduler
} from 'modules/commuteOffer/actions';

import {
  commuteOfferCurrentDataSelector,
  commuteOfferHistorySelector,
  filteredVehicleIdsSelector
} from 'modules/commuteOffer/selectors';

import {
  currentProjectSelector,
  projectsSelector
} from 'modules/user/selectors';
import {
  isChangedSelector,
  isSavingSelector,
  isBookingsHiddenSelector,
  activeVehicleIdsSelector
} from 'modules/ui/selectors';

import { changeCurrentProject } from 'modules/user/actions';

import TopButtons from './TopButtons';

const mapStateToProps = createSelector(
  currentProjectSelector,
  projectsSelector,
  commuteOfferCurrentDataSelector,
  commuteOfferHistorySelector,
  isChangedSelector,
  isSavingSelector,
  isBookingsHiddenSelector,
  filteredVehicleIdsSelector,
  activeVehicleIdsSelector,
  (
    currentProject,
    projects,
    data,
    history,
    isChanged,
    isSaving,
    isBookingsHidden,
    filteredVehicleIds,
    activeVehicleIds
  ) => ({
    currentProject,
    projects,
    data,
    history,
    isChanged,
    isSaving,
    isBookingsHidden,
    filteredVehicleIds,
    activeVehicleIds
  })
);

const mapDispatchToProps = {
  back: ActionCreators.undo,
  next: ActionCreators.redo,
  updateCommuteOffer,
  changeCurrentProject,
  commuteOfferStatelessNodeScheduler,
  openPopup,
  closePopup
};

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps
);

export default withConnect(TopButtons);
