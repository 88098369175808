import React, { memo } from 'react';
import { Dropdown } from '@urbica/ui-kit';

import Button from './Button';
import MenuButton from './MenuButton';
import Content from './Content';

const config = [
  { id: 'dataset', title: 'Add a dataset' },
  { id: 'geofence', title: 'Add a geofence' },
  { id: 'simulation', title: 'Add a simulation' },
  { id: 'commuteoffer', title: 'Add a commute offer' }
];

const ButtonPlus = ({ openPopup }) => (
  <Dropdown
    renderHandler={({ toggle }) => <Button onClick={toggle} />}
    renderContent={({ toggle }) => (
      <Content onClick={toggle}>
        {config.map((item) => {
          const { id, title } = item;

          const onClick = () => {
            openPopup(id, { title });
          };

          return (
            <MenuButton key={id} onClick={onClick}>
              {title}
            </MenuButton>
          );
        })}
      </Content>
    )}
  />
);

export default memo(ButtonPlus);
