import moment from 'moment';

import { getRouteCoordinates } from 'utils/CommuteOffer';

window.test1 = a => moment(a);

const routing = {
  euclidean: {
    name: 'Euclidean',
    url: null
  },
  asteria: {
    name: 'Asteria',
    url: null
  },
  mapbox: {
    name: 'Mapbox',
    url: (route, params, opts) => {
      // const mapboxUrl = opts.url || window.GEODISC_MAPBOX_URL;
      // const mapboxKey = opts.key || window.GEODISC_MAPBOX_TOKEN;
      const mapboxUrl = window.GEODISC_MAPBOX_URL;
      const mapboxKey = window.GEODISC_MAPBOX_TOKEN;
      const mapboxRoutingProfile =
        opts.road_network || window.GEODISC_MAPBOX_ROUTING_PROFILE;

      const { approaches, curb } = params;
      const coordinates = getRouteCoordinates(route, approaches, curb);

      // eslint-disable-next-line
      return `${mapboxUrl}/directions/v5/mapbox/${mapboxRoutingProfile}/${coordinates}access_token=${mapboxKey}&overview=full&geometries=geojson`;
    }
  },
  osrme: {
    name: 'OSRME',
    url: (route, params, opts) => {
      // const osrmUrl = opts.url || window.GEODISC_OSRM_URL;
      // const osrmKey = opts.key || window.GEODISC_OSRM_TOKEN;
      const osrmUrl = window.GEODISC_OSRM_URL;
      const osrmKey = window.GEODISC_OSRM_TOKEN;
      const osrmRoutingProfile =
        opts.road_network || window.GEODISC_OSRM_ROUTING_PROFILE;

      const { approaches, curb, start_time } = params;
      const coordinates = getRouteCoordinates(route, approaches, curb);

      const startTime = encodeURIComponent(
        moment(start_time)
          // .tz(global.GEODISC_TIMEZONE)
          .utc()
          .format('YYYY-MM-DDTHH:mm:ssZ')
      );

      const args = [
        `${osrmUrl}/tdroute/v1/${osrmRoutingProfile}/${coordinates}`,
        `access_token=${osrmKey}`,
        'steps=true&geometries=geojson&overview=full',
        `start_time=${startTime}`
      ];

      return args.join('&');
    }
  },
  osrm: {
    name: 'OSRM',
    url: (route, params, opts) => {
      // const osrmUrl = opts.url || window.GEODISC_OSRM_URL;
      // const osrmKey = opts.key || window.GEODISC_OSRM_TOKEN;
      const osrmUrl = window.GEODISC_OSRM_URL;
      const osrmKey = window.GEODISC_OSRM_TOKEN;
      const osrmRoutingProfile =
        opts.road_network || window.GEODISC_OSRM_ROUTING_PROFILE;

      const { approaches, curb } = params;
      const coordinates = getRouteCoordinates(route, approaches, curb);

      return [
        `${osrmUrl}/directions/v5/mapbox/${osrmRoutingProfile}/${coordinates}`,
        `access_token=${osrmKey}`,
        'steps=true&geometries=geojson&overview=full'
      ].join('&');
    }
  },
  here: {
    name: 'here.com',
    url: null
  },
  defined_by_vehicle_settings: {
    name: 'Defined by Vehicle Settings',
    url: null
  }
};

if (global.GEODISC_HERE_DISABLE) {
  delete routing.here;
}

export default routing;
