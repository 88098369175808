import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { branch, compose, renderComponent } from 'recompose';
import createImmutableSelector from 'create-immutable-selector';

import { openPopup, toggleSelectedCommuteOffers } from 'modules/ui/actions';

import {
  updateCommuteOffer,
  deleteCommuteOffer
} from 'modules/commuteOffer/actions';

import { commuteOfferListSelector } from 'modules/commuteOffer/selectors';
import {
  selectedCommuteOfferIdsSelector,
  commuteOffersErrorSelector
} from 'modules/ui/selectors';

import Error from 'components/Error';
import Loading from 'components/Loading';

import Body from './Body';

const mapStateToProps = createImmutableSelector(
  commuteOfferListSelector,
  selectedCommuteOfferIdsSelector,
  commuteOffersErrorSelector,
  (list, selectedIds, error) => ({
    list,
    selectedIds,
    error
  })
);

const mapDispatchToProps = {
  openPopup,
  toggleSelectedCommuteOffers,
  updateCommuteOffer,
  deleteCommuteOffer
};

const withBranch = compose(
  branch(({ error }) => error, renderComponent(Error)),
  branch(({ list }) => !list, renderComponent(Loading))
);

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps
);

const enhance = compose(
  withConnect,
  withBranch,
  withRouter
);

export default enhance(Body);
