import { connect } from 'react-redux';
import createImmutableSelector from 'create-immutable-selector';

import { changeSimulationsOrdering } from 'modules/ui/actions';
import { clearList, fetchSimulations } from 'modules/simulations/actions';

import { simulationsOrderingSelector } from 'modules/ui/selectors';

import Header from './Header';

const mapStateToProps = createImmutableSelector(
  simulationsOrderingSelector,
  ordering => ({
    ordering
  })
);

const mapDispatchToProps = {
  changeSimulationsOrdering,
  clearList,
  fetchSimulations
};

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps
);

export default withConnect(Header);
