import createImmutableSelector from 'create-immutable-selector';
import debug from 'utils/debug';

const { COUNTER, METHOD } = debug('m:user:selectors');

export const userSelector = (state) => {
  COUNTER('userSelector');
  return state.get('user');
};

export const userNameSelector = createImmutableSelector(userSelector, user =>
  user.get('username'));

export const projectsSelector = createImmutableSelector(userSelector, user =>
  user.get('projects'));

export const currentProjectSelector = createImmutableSelector(
  userSelector,
  user => user.get('currentProject')
);

export const currentProjectIdSelector = createImmutableSelector(
  currentProjectSelector,
  (project) => {
    METHOD('currentProjectIdSelector', { project });
    return project && project.get('id');
  }
);

export const currentProjectUrlSelector = createImmutableSelector(
  currentProjectSelector,
  project => project && project.get('resource_uri')
);

export const isAdminSelector = createImmutableSelector(
  userSelector,
  user => user && user.get('is_superuser')
);
