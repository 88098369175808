import React, { useState, useEffect } from 'react';
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  ModalButton
} from 'baseui/modal';

import { diff as DiffEditor } from 'react-ace';

import 'ace-builds/src-noconflict/mode-json';
import 'ace-builds/src-noconflict/theme-github';

import ReactDiffViewer from 'react-diff-viewer';
import debug from 'utils/debug';
import Overflow from './Overflow';

const { METHOD } = debug('c:ModalWindows:DiffEditor');

export default (props) => {
  METHOD('DiffEditor', props);

  const [isOpen, setIsOpen] = useState(false);
  const [content, setContent] = useState(['', '']);
  const [title, setTitle] = useState('Info');
  const [config, setConfig] = useState({});

  useEffect(() => {
    METHOD('Update', { props });
    window.openDiffEditor = (src, dst, opts) => {
      METHOD('openDiffEditor', { src, dst, opts });
      const options = opts || {};

      const $src = typeof src === 'string' ? src : JSON.stringify(src, '\n', 4);
      const $dst = typeof dst === 'string' ? dst : JSON.stringify(dst, '\n', 4);
      const sources = [$src, $dst];
      METHOD('sources', { sources });

      setTitle(options.title || 'Source');
      setContent(sources);
      setConfig(options);
      setIsOpen(true);
    };
    window.closeDiffEditor = () => {
      METHOD('closeDiffEditor', {
        isOpen,
        title,
        content,
        config
      });
      setIsOpen(false);
    };
    return () => {
      METHOD('Cleanup');
      window.closeDiffEditor = null;
    };
  });

  function onClose() {
    METHOD('onClonse', { isOpen, title, content, config });

    setIsOpen(false);
  }

  function onSave() {
    METHOD('onSave', { isOpen, title, content, config });

    if (config.onSave) {
      setConfig({});
      config.onSave(content);
    }

    setIsOpen(false);
  }

  return (
    <React.Fragment>
      <Modal
        onClose={onClose}
        isOpen={isOpen}
        overrides={{
          Dialog: {
            style: {
              width: '90vw',
              display: 'flex',
              flexDirection: 'column'
            }
          }
        }}
      >
        <ModalHeader>{title}</ModalHeader>
        <ModalBody style={{ flex: '1 1 0' }}>
          {false && (
            <DiffEditor
              mode='json'
              theme='github'
              value={content}
              defaultValue={content}
              onChange={setContent}
              name='ace'
              width='100%'
              height='100%'
              setOptions={{
                useWorker: false
              }}
              editorProps={{ $blockScrolling: true }}
              readOnly
            />
          )}
          <Overflow>
            <ReactDiffViewer
              oldValue={content[0]}
              newValue={content[1]}
              splitView
            />
          </Overflow>
        </ModalBody>
        <ModalFooter>
          <ModalButton onClick={onClose}>Close</ModalButton>
          {false && <ModalButton onClick={onSave}>Save</ModalButton>}
        </ModalFooter>
      </Modal>
    </React.Fragment>
  );
};
