import { connect } from 'react-redux';
import { compose, lifecycle } from 'recompose';
import createImmutableSelector from 'create-immutable-selector';

import { fetchGeofences } from 'modules/geofences/actions';
import { geofencesFilteredSelector } from 'modules/geofences/selectors';
import { currentProjectIdSelector } from 'modules/user/selectors';

import Geofences from './Geofences';

const withLifecycle = lifecycle({
  componentDidMount() {
    const { geofences, projectId } = this.props;
    if (!geofences && projectId) {
      this.props.fetchGeofences();
    }
  },
  componentDidUpdate(prevProps) {
    const { projectId } = this.props;

    if (prevProps.projectId !== projectId) {
      this.props.fetchGeofences();
    }
  }
});

const mapStateToProps = createImmutableSelector(
  geofencesFilteredSelector,
  currentProjectIdSelector,
  (geofences, projectId) => ({ geofences, projectId })
);

const mapDispatchToProps = { fetchGeofences };

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps
);

const enhance = compose(
  withConnect,
  withLifecycle
);

export default enhance(Geofences);
