import React from 'react';

import debug from 'utils/debug';
import Container from './Container';

import TimelineViewer from './TimelineViewer';
import SourceEditor from './SourceEditor';
import DiffViewer from './DiffViewer';
import TableViewer from './TableViewer';
import DataTableViewer from './DataTableViewer';
import NameAndProject from './NameAndProject';
import InfoMessage from './InfoMessage';

const { METHOD } = debug('c:ModalWindows');

export default () => {
  METHOD('ModalWindows');

  return (
    <React.Fragment>
      <Container>
        <TableViewer />
        <DataTableViewer />
        <TimelineViewer />
        <DiffViewer />
        <SourceEditor />
        <NameAndProject />
        <InfoMessage />
      </Container>
    </React.Fragment>
  );
};
