/* eslint-disable no-underscore-dangle */
import React from 'react';
import * as Sentry from '@sentry/browser';
import ReactDOM from 'react-dom';
import createSagaMiddleware from 'redux-saga';
import { createLogger } from 'redux-logger';
import { createBrowserHistory } from 'history';
import { applyMiddleware, createStore, compose } from 'redux';
import {
  routerMiddleware as createRouterMiddleware,
  ConnectedRouter
} from 'connected-react-router/immutable';
import { Provider } from 'react-redux';

import Moment from 'moment';
import MomentTimezone from 'moment-timezone'; // eslint-disable-line
import momentLocalizer from 'react-widgets-moment';

import debug from 'debug';
import GlobalStyle from 'components/GlobalStyle';
import App from 'components/App';

import Saga from 'modules/saga';
import createRootReducer from 'modules';
import { ident } from 'config/ident';

import { Promise } from 'bluebird';

global.Promise = Promise;

// eslint-disable-next-line
console.log('Version:', ident);

const parseLocationArgs = str =>
  str
    ? new Map(
        str
          .substring(1)
          .split('&')
          .map(v => v.split('='))
      )
    : new Map();

global.GEODISC_LOCATION_SEARCH_OPTS = parseLocationArgs(window.location.search);

global.GEODISC_LOCATION_HASH_OPTS = parseLocationArgs(window.location.hash);

Moment.relativeTimeThreshold('s', 60);
Moment.relativeTimeThreshold('m', 60);
Moment.relativeTimeThreshold('h', 24);
Moment.relativeTimeThreshold('d', 31);
Moment.relativeTimeThreshold('M', 12);
Moment.relativeTimeThreshold('y', 365);

if (window.SENTRY_URL) {
  Sentry.init({ dsn: window.SENTRY_URL });
}

Moment.locale('en');
momentLocalizer();

window.GEODISC_MOMENT = Moment;
window.GEODISC_TIMEZONE = window.GEODISC_TIMEZONE || Moment.tz.guess();

const history = createBrowserHistory();
const routerMiddleware = createRouterMiddleware(history);
const sagaMiddleware = createSagaMiddleware();

let composeEnhancers = compose;
const middlewares = [routerMiddleware, sagaMiddleware];
if (process.env.NODE_ENV === 'development') {
  window.DEBUG = window.DEBUG || '-*';
  debug.enable(window.DEBUG);
  composeEnhancers =
    (window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ &&
      window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__(
        window.REDUX_DEVTOOLS_EXTENSION_CONFIG || {}
      )) ||
    compose;
  if (window.REDUX_LOGGER_CONFIG) {
    middlewares.push(createLogger(window.REDUX_LOGGER_CONFIG));
  }
}

const store = createStore(
  createRootReducer(history),
  composeEnhancers(applyMiddleware(...middlewares))
);

sagaMiddleware.run(Saga);

const root = document.getElementById('root');

ReactDOM.render(
  <Provider store={store}>
    <ConnectedRouter history={history}>
      <GlobalStyle />
      <App />
    </ConnectedRouter>
  </Provider>,
  root
);

if (module.hot) {
  module.hot.accept('./modules', () => {
    // eslint-disable-next-line
    const nextRootReducer = require('./modules');
    store.replaceReducer(nextRootReducer);
  });

  module.hot.accept('./components/App', () => {
    // eslint-disable-next-line
    const NextApp = require('./components/App').default;
    ReactDOM.render(<NextApp history={history} store={store} />, root);
  });
}
