import { connect } from 'react-redux';
import { compose, lifecycle } from 'recompose';
import createImmutableSelector from 'create-immutable-selector';

import { authorization } from 'modules/user/actions';

import { userNameSelector } from 'modules/user/selectors';

import App from './App';

const withLifecycle = lifecycle({
  componentDidMount() {
    const isAuthorized = !!localStorage.getItem(
      `${global.GEODISC_VERSION.ver}:login`
    );
    if (!this.props.userName && isAuthorized) {
      this.props.authorization(
        localStorage.getItem(`${global.GEODISC_VERSION.ver}:login`),
        true
      );
    }
  }
});

const mapStateToProps = createImmutableSelector(
  userNameSelector,
  userName => ({
    isAuthorized: !!(
      userName || localStorage.getItem(`${global.GEODISC_VERSION.ver}:login`)
    )
  })
);

const mapDispatchToProps = {
  authorization
};

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps
);

const enhance = compose(
  withConnect,
  withLifecycle
);

export default enhance(App);
