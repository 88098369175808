import React, { memo } from 'react';

import debug from 'utils/debug';
import Container from './Container';
import ButtonPlus from '../ButtonPlus';
import ModalWindow from '../ModalWindow';

const { METHOD } = debug('c:Layout');

const Layout = (props) => {
  METHOD('Layout', props);
  const { hasBackground, children } = props;
  return (
    <Container hasBackground={hasBackground}>
      {children}
      <ModalWindow />
      <ButtonPlus />
    </Container>
  );
};

export default memo(Layout);
