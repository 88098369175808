import React from 'react';

import Container from './Container';
import LoginPanel from './LoginPanel';

const Login = () => (
  <Container>
    <LoginPanel />
  </Container>
);

export default Login;
