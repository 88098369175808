export const ver = 'DEBUG8';

export const gid = '$Id: 271be6f66a42e5165cbc403f8b39d59c8719fd86 $';

export const version = window.GEODISC_COMMIT_ID
  ? {
      ver: window.GEODISC_COMMIT_ID,
      cid: window.GEODISC_COMMIT_ID,
      url: window.GEODISC_COMMIT_URL
    }
  : { ver, gid };

export const ident = {
  ...version,
  api: window.GEODISC_API_URL
};

global.GEODISC_VERSION = ident;
