import { connect } from 'react-redux';
import createImmutableSelector from 'create-immutable-selector';

import {
  changeViewportCommuteOffer,
  onClickNodesLayer,
  onClickVehiclesLayer,
  onClickBookingLayer,
  onClickStopsLayer
} from 'modules/maps/actions';

import {
  addPointToRoute,
  changeRoutePoint
} from 'modules/commuteOffer/actions';
import { setDraggablePoint } from 'modules/ui/actions';
import {
  commuteOfferViewportSelector,
  editableBookingsSourceSelector,
  vehiclesStopSourceSelector,
  nodesSourceSelector,
  bookingsSourceSelector,
  routeSourceSelector,
  walkingRoutesSourceSelector
} from 'modules/maps/selectors';

import { stopsSourceSelector } from 'modules/entities/selectors';
import {
  busStopsVisibleSelector,
  activeVehicleIdsSelector,
  addPointModeSelector,
  activeRouteStopUidSelector,
  draggablePointSelector,
  editableBookingIdSelector
} from 'modules/ui/selectors';

import Map from './Map';

const mapStateToProps = createImmutableSelector(
  commuteOfferViewportSelector,
  stopsSourceSelector,
  vehiclesStopSourceSelector,
  nodesSourceSelector,
  editableBookingsSourceSelector,
  bookingsSourceSelector,
  routeSourceSelector,
  walkingRoutesSourceSelector,
  busStopsVisibleSelector,
  activeVehicleIdsSelector,
  addPointModeSelector,
  activeRouteStopUidSelector,
  draggablePointSelector,
  editableBookingIdSelector,
  (
    viewport,
    stops,
    vehicles,
    nodes,
    editableBookingsSource,
    bookings,
    route,
    walkingRoutes,
    busStopsVisible,
    activeVehicleIds,
    pointEditing,
    activeRouteStopUid,
    draggablePoint,
    editableBookingId
  ) => ({
    viewport,
    stops,
    vehicles,
    nodes,
    editableBookingsSource,
    bookings,
    route,
    walkingRoutes,
    busStopsVisible,
    activeVehicleIds,
    pointEditing,
    activeRouteStopUid,
    draggablePoint,
    editableBookingId
  })
);

const mapDispatchToProps = {
  onViewportChange: changeViewportCommuteOffer,
  onClickNodesLayer,
  onClickVehiclesLayer,
  onClickBookingLayer,
  onClickStopsLayer,
  addPointToRoute,
  changeRoutePoint,
  setDraggablePoint
};

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps
);

export default withConnect(Map);
