import React from 'react';

import Logo from 'components/Logo';
import Container from 'components/Panel';

import Header from './Header';
import Info from './Info';

const Panel = () => (
  <Container>
    <Logo />
    <Header />
    <Info />
  </Container>
);

export default Panel;
