import React from 'react';

import SettingsSimulation from './Simulation';
import SettingsDataset from './Dataset';

const SettingsComponent = ({
  type,
  speed,
  setSpeed,
  toggleAllday,
  isAllDay
}) => {
  switch (type) {
    case 'simulation':
      return <SettingsSimulation speed={speed} setSpeed={setSpeed} />;
    case 'dataset':
      return (
        <SettingsDataset isAllDay={isAllDay} toggleAllday={toggleAllday} />
      );
    default:
      return <SettingsSimulation speed={speed} setSpeed={setSpeed} />;
  }
};

export default props => (
  <React.Suspense fallback>
    <SettingsComponent {...props} />
  </React.Suspense>
);
