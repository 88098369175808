import React from 'react';
import Container from './Container';
import Button from './Button';

const Settings = ({ isAllDay, toggleAllday }) => (
  <Container>
    <Button isActive={isAllDay} onClick={toggleAllday}>
      All day
    </Button>
  </Container>
);

export default Settings;
