import { connect } from 'react-redux';
import { createSelector } from 'reselect';

import { busStopsVisibleSelector } from 'modules/ui/selectors';
import { stopTypesSelector } from 'modules/entities/selectors';

import {
  toggleActiveStopsType,
  clearActiveStopsType
} from 'modules/ui/actions';

import StopsButtons from './StopsButtons';

const mapStateToProps = createSelector(
  busStopsVisibleSelector,
  stopTypesSelector,
  (busStopsVisible, stopsTypes) => ({ busStopsVisible, stopsTypes })
);

const mapDispatchToProps = {
  toggleActiveStopsType,
  clearActiveStopsType
};

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps
);

export default withConnect(StopsButtons);
