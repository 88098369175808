import React, { PureComponent } from 'react';
import debug from 'utils/debug';
import withDragDropContext from '../../../withDragDropContext';

import Container from './Container';
import Stop from './Stop';

const { METHOD } = debug('p:CommuteOffer:Panels:Vehicles:Vehicle:Route');

class Route extends PureComponent {
  moveCard = (dragIndex, hoverIndex) => {
    const { route, vehicleId } = this.props;
    this.props.changeRouteOrder(dragIndex, hoverIndex, route, vehicleId);
  };

  render() {
    METHOD('render', { props: this.props });

    const {
      bookings,
      vehicleId,
      route,
      color,
      activeRouteStop,
      setActiveRouteStop,
      cleanActiveRouteStop,
      removeBookingFromRoute,
      flyToCommuteOfferActiveStop,
      isHaveEditable,
      togglePointEditing,
      deleteRoutePoint,
      pointEditing,
      activeVehicleIds
    } = this.props;

    if (!route.length) {
      return <Container>No assigned bookings</Container>;
    }

    return (
      <Container>
        {route.map((stop, i) => {
          const isActive = stop.id === activeRouteStop;

          const onClick = () => {
            if (isActive) {
              cleanActiveRouteStop();
            } else {
              setActiveRouteStop(stop.id, stop.uid);
              flyToCommuteOfferActiveStop(stop.id);
            }
          };

          return (
            <Stop
              isActive={isActive}
              stop={stop}
              bookings={bookings}
              index={i}
              id={i}
              key={i}
              color={color}
              vehicleId={vehicleId}
              removeBookingFromRoute={removeBookingFromRoute}
              isHaveEditable={isHaveEditable}
              onClick={onClick}
              moveCard={this.moveCard}
              togglePointEditing={togglePointEditing}
              pointEditing={pointEditing}
              deleteRoutePoint={deleteRoutePoint}
              activeVehicleIds={activeVehicleIds}
              cleanActiveRouteStop={cleanActiveRouteStop}
            />
          );
        })}
      </Container>
    );
  }
}

export default withDragDropContext(Route);
