import { connect } from 'react-redux';
import createImmutableSelector from 'create-immutable-selector';

import { changeLayerPanel, toggleLayer } from 'modules/ui/actions';
import { datasetSelector } from 'modules/datasets/selectors';
import { activeLayerSelector, layerStyleSelector } from 'modules/ui/selectors';

import VisualizationPanel from './VisualizationPanel';

const mapDispatchToProps = {
  changeLayerPanel,
  toggleLayer
};

const mapStateToProps = createImmutableSelector(
  datasetSelector,
  activeLayerSelector,
  layerStyleSelector,
  (dataset, activeLayer, layerStyle) => ({ dataset, activeLayer, layerStyle })
);

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps
);

export default withConnect(VisualizationPanel);
