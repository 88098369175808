import { connect } from 'react-redux';
import { compose, lifecycle } from 'recompose';
import createImmutableSelector from 'create-immutable-selector';

import { addGeofence } from 'modules/geofences/actions';
import { fetchDatasets } from 'modules/datasets/actions';
import { listSelector as datasetsListSelector } from 'modules/datasets/selectors';
import { currentProjectUrlSelector } from 'modules/user/selectors';

import NewGeofence from './NewGeofence';

const withLifecycle = lifecycle({
  componentDidMount() {
    const { datasets } = this.props;
    if (!datasets) {
      this.props.fetchDatasets();
    }
  }
});

const mapStateToProps = createImmutableSelector(
  datasetsListSelector,
  currentProjectUrlSelector,
  (datasets, projectUrl) => ({ datasets, projectUrl })
);

const mapDispatchToProps = {
  addGeofence,
  fetchDatasets
};

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps
);

const enhance = compose(
  withConnect,
  withLifecycle
);

export default enhance(NewGeofence);
