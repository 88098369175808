import { connect } from 'react-redux';
import { compose, lifecycle } from 'recompose';
import createImmutableSelector from 'create-immutable-selector';

import { datasetDataSelector } from 'modules/datasets/selectors';

import {
  fetchDataset,
  fetchDatasetCsv,
  clearDataset
} from 'modules/datasets/actions';
import { routeIdSelector } from 'modules/router/selectors';

import Dataset from './Dataset';

const withLifecycle = lifecycle({
  componentDidMount() {
    const { dataset, id } = this.props;

    if (!dataset || dataset.get('id') !== id) {
      this.props.clearDataset();
      this.props.fetchDataset(id);
      this.props.fetchDatasetCsv(id);
    }
  }
});

const mapStateToProps = createImmutableSelector(
  datasetDataSelector,
  routeIdSelector,
  (data, id) => ({ data, id })
);

const mapDispatchToProps = {
  fetchDataset,
  fetchDatasetCsv,
  clearDataset
};

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps
);

const enhance = compose(
  withConnect,
  withLifecycle
);

export default enhance(Dataset);
