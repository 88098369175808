import React from 'react';
import monent from 'moment';
import debug from 'utils/debug';
import Container from './Container';

const { DEBUG } = debug('c:TimeSlider:Time:index');

const TimeComponent = ({ time, isUnix }) => {
  DEBUG('Time:', time);
  DEBUG('isUnix:', isUnix);

  if (isUnix) {
    const formattedTime = monent(time * 1000)
      .tz(global.GEODISC_TIMEZONE)
      .format('HH:mm.ss');
    return <Container>{formattedTime}</Container>;
  }

  return <Container>{time <= 12 ? `${time} AM` : `${time - 12} PM`}</Container>;
};

export default props => (
  <React.Suspense fallback>
    <TimeComponent {...props} />
  </React.Suspense>
);
