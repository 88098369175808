import { getRouteCoordinates } from 'utils/CommuteOffer';

const routing = {
  euclidean: {
    name: 'Radial',
    url: null
  },
  osrm: {
    name: 'OSRM',
    url: (route, opts) => {
      // const osrmUrl = opts.url || window.GEODISC_WALKING_OSRM_URL;
      // const osrmKey = opts.key || window.GEODISC_WALKING_OSRM_TOKEN;
      const osrmUrl = window.GEODISC_WALKING_OSRM_URL;
      const osrmKey = window.GEODISC_WALKING_OSRM_TOKEN;
      const osrmRoutingProfile =
        opts.road_network || window.GEODISC_WALKING_OSRM_ROUTING_PROFILE;

      const coordinates = getRouteCoordinates(route, true, false);

      // eslint-disable-next-line
      return `${osrmUrl}/directions/v5/mapbox/${osrmRoutingProfile}/${coordinates}&steps=true&geometries=geojson&overview=full&access_token=${osrmKey}`;
    }
  },
  here: {
    name: 'here.com',
    url: null
  }
};

if (global.GEODISC_WALKING_HERE_DISABLE) {
  delete routing.here;
}

export default routing;
