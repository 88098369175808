import React from 'react';
import Container from './Container';
import Column from './Column';
import FirstColumn from './FirstColumn';

const config = {
  booking_data_format: {
    label: 'Format',
    marginRight: '0px'
  },
  trips: {
    label: 'rows',
    marginRight: '75px'
  },
  created_at: {
    label: 'date',
    marginRight: '30px'
  }
};

const Header = (props) => {
  const { ordering, changeDatasetsOrdering, clearList, fetchDatasets } = props;

  const onChange = (id) => {
    // refactoring
    changeDatasetsOrdering(id);
    clearList();
    fetchDatasets();
  };

  return (
    <Container>
      <FirstColumn
        onClick={() => onChange('name')}
        isActive={ordering.get('id') === 'name'}
        sort={ordering.get('sort')}
      >
        Name
      </FirstColumn>
      {Object.keys(config).map((key) => {
        const isActive = ordering.get('id') === key;
        const { label, marginRight } = config[key];

        return (
          <Column
            key={key}
            isActive={isActive}
            onClick={() => onChange(key)}
            marginRight={marginRight}
            sort={ordering.get('sort')}
          >
            {label}
          </Column>
        );
      })}
      <Column marginRight='50px'>Favorites</Column>
    </Container>
  );
};

export default Header;
