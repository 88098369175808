import { Redirect } from 'react-router';
import Dataset from 'pages/Dataset';
import Datasets from 'pages/Datasets';
import Geofences from 'pages/Geofences';
import Simulation from 'pages/Simulation';
import Simulations from 'pages/Simulations';
import CommuteOffer from 'pages/CommuteOffer';
import CommuteOffers from 'pages/CommuteOffers';

const routes = [
  {
    path: '/',
    exact: true,
    component: Redirect,
    to: window.GEODISC_UI_DEFAULT_ROUTE || '/datasets'
  },
  {
    path: '/login',
    component: Redirect,
    to: window.GEODISC_UI_DEFAULT_ROUTE || '/datasets'
  }
].concat(
  window.GEODISC_UI_DATASETS_DISABLE
    ? []
    : [
        {
          path: '/dataset/:id',
          component: Dataset
        },
        {
          path: '/datasets/:page',
          component: Datasets
        },
        {
          path: '/datasets',
          component: Datasets
        }
      ],
  window.GEODISC_UI_GEOFENCES_DISABLE
    ? []
    : [
        {
          path: '/geofences',
          component: Geofences
        }
      ],
  window.GEODISC_UI_SIMULATIONS_DISABLE
    ? []
    : [
        {
          path: '/simulation/:id',
          component: Simulation
        },
        {
          path: '/simulations/:page',
          component: Simulations
        },
        {
          path: '/simulations',
          component: Simulations
        }
      ],
  window.GEODISC_UI_COMMUTE_OFFER_DISABLE
    ? []
    : [
        {
          path: '/commuteoffer/:id',
          component: CommuteOffer
        },
        {
          path: '/commuteoffers/:page',
          component: CommuteOffers
        },
        {
          path: '/commuteoffers',
          component: CommuteOffers
        }
      ]
);

export default routes;
