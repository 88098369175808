import { connect } from 'react-redux';
import createImmutableSelector from 'create-immutable-selector';
import { withRouter } from 'react-router';
import { compose } from 'recompose';

import { authorization } from 'modules/user/actions';
import { loginStateSelector } from 'modules/ui/selectors';

import LoginPanel from './LoginPanel';

const mapStateToProps = createImmutableSelector(
  loginStateSelector,
  loginState => ({ loginState })
);

const mapDispatchToProps = { authorization };

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps
);

const enhance = compose(
  withConnect,
  withRouter
);

export default enhance(LoginPanel);
