import React, { PureComponent } from 'react';
import CreatableSelect from './CreatableSelect';
import Container from './Container';
import Label from './Label';

class InputSelectTags extends PureComponent {
  handleChange = (value) => {
    this.props.onChange(this.props.name, value);
  };

  handleBlur = () => {
    this.props.onBlur(this.props.name, true);
  };

  render() {
    const { name, options, value, label } = this.props;
    return (
      <Container>
        <Label>{label}</Label>
        <CreatableSelect
          name={name}
          options={options}
          isMulti
          onChange={this.handleChange}
          onBlur={this.handleBlur}
          value={value}
        />
      </Container>
    );
  }
}

export default InputSelectTags;
