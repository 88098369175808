import React, { PureComponent } from 'react';
import debug from 'utils/debug';
import parseColor from 'parse-color';

import { DropTarget } from 'react-dnd';
import Button from 'pages/CommuteOffer/TextButton';
import moment from 'moment';
import DateTime from 'react-datetime';
import Menu from 'components/Menu';
import DateTimeContainer from './DateTimeContainer';
import MenuButton from './MenuButton';

import Title from './Title';
import Caption from './Caption';
import Border from './Border';
import Container from './Container';
import Header from './Header';
import Route from './Route';
import BookingStopsEditor from './BookingStopsEditor';
import Label from './Label';
import ButtonsContainer from './ButtonsContainer';
import Input from './Input';
import SearchResult from './SearchResults';
import UpdatedBadge from './UpdatedBadge';
import ProgressIndicator from '../../ProgressIndicator';

const { METHOD } = debug('p:CommuteOffer:Panels:Vehicles:Vehicle');

const defaultRoutingProfile = (engine) => {
  if (engine === 'asteria') {
    return window.ASTERIA_ROUTING_PROFILE;
  }
  return window.OSRM_ROUTING_PROFILE;
};

class Vehicle extends PureComponent {
  onClickHeader = () => {
    METHOD('onClickHeader');

    const { activeVehicleIds, vehicle } = this.props;

    if (!activeVehicleIds.includes(vehicle.agent_id)) {
      this.props.setActiveVehicleId(vehicle.agent_id);
    } else {
      this.props.cleanActiveVehicleId(vehicle.agent_id);
    }
  };

  onAddPoint = () => {
    METHOD('onAddPoint');

    if (this.props.activeRouteStop) {
      this.props.cleanActiveRouteStop();
    } else {
      this.props.setAddPointMode(this.props.vehicle.agent_id);
    }
  };

  onAddStop = () => {
    METHOD('onAddStop');

    this.props.setAddStopMode(this.props.vehicle.agent_id);
  };

  onEdit = () => {
    METHOD('onEdit');

    const { vehicle } = this.props;
    const { agent_id, capacity, route, vehicle_color } = vehicle;
    const { routing_engine_name, road_network } = vehicle.routing_engine
      ? vehicle.routing_engine
      : {
          routing_engine_name: 'osrm',
          road_network: 'driving'
        };

    const start_time = route.length
      ? moment(route[0].scheduled_ts).toDate()
      : new Date();

    this.props.openPopup('CommuteOfferVehicleEditor', {
      mode: 'edit',
      title: 'Edit a vehicle',
      submitCaption: 'Save',
      submittingCaption: 'Saving...',
      vehicle,
      initialValues: {
        id: agent_id,
        capacity_passengers: capacity.passenger || capacity.passengers || '0',
        capacity_stops: capacity.stop || '0',
        capacity_wheelchairs: capacity.wheelchair || '0',
        start_time,
        routing_engine_name: {
          value: routing_engine_name,
          label: routing_engine_name
        },
        road_network,
        vehicle_color: parseColor(vehicle_color).hsl[0]
      }
    });
  };

  onClone = () => {
    METHOD('onClone');

    // const { vehicle } = this.props;
    // this.props.clonedVehicle(vehicle);
    // this.props.setEditableVehicleId("veh_new");
  };

  onDelete = () => {
    METHOD('onDelete');

    const { vehicle } = this.props;
    this.props.deleteVehicle(vehicle.agent_id);
  };

  onShowVehicleSource = () => {
    METHOD('onShowVehicleSource');

    const { vehicle } = this.props;

    this.props.showVehicleSource(vehicle.agent_id);
  };

  onShowVehicleUpdates = () => {
    METHOD('onShowVehicleUpdates');

    const { vehicle } = this.props;

    this.props.showVehicleUpdates(vehicle.agent_id);

    // this.props.openPopup('CommuteOfferVehicleUpdates', {
    //   title: 'Vehicle updates',
    //   id: vehicle.agent_id
    // });
  };

  onSearchInputChange = (e) => {
    METHOD('onSearchInputChange');

    this.props.setStopSearchQuery(e.target.value);
  };

  onChangeStartTime = (value) => {
    METHOD('onChangeStartTime');

    const { vehicle } = this.props;
    if (value instanceof moment) {
      this.props.setVehicleStartTime(
        vehicle.agent_id,
        value.tz(global.GEODISC_TIMEZONE).format()
      );
    }
  };

  render() {
    METHOD('render', { props: this.props });

    const {
      vehicle,
      activeVehicleIds,
      isOver,
      connectDropTarget,
      setActiveRouteStop,
      activeRouteStop,
      editableBooking,
      pointEditing,
      addStopMode,
      filteredStops,
      stopSearchQuery,
      cleanAddStopMode,
      cleanAddPointMode,
      addStopToRoute,
      isEditableVehicleTime
    } = this.props;

    const isActive = activeVehicleIds.includes(vehicle.agent_id);

    const { occupied, transferred } = vehicle.passengers;

    const capacity = vehicle.capacity.passenger || vehicle.capacity.passengers;

    const routing_engine = vehicle.routing_engine || {};

    const routingEngine = routing_engine.routing_engine_name || 'osrme';
    const routingProfile =
      routing_engine.road_network ||
      defaultRoutingProfile(routing_engine.routing_engine_name);

    const isProcessing = false;

    return (
      <Container
        id={vehicle.agent_id}
        ref={connectDropTarget}
        isOver={isOver}
        isActive={isActive}
      >
        <Header>
          <Title color={vehicle.activeColor}>
            <Border color={vehicle.activeColor} />
            <Caption onClick={this.onClickHeader}>
              Vehicle
              <br />
              {vehicle.name || vehicle.agent_id}
            </Caption>
            {vehicle.isChanged && (
              <UpdatedBadge onClick={this.onShowVehicleUpdates}>
                &nbsp;Updated&nbsp;
              </UpdatedBadge>
            )}
            {isProcessing ? (
              <ProgressIndicator />
            ) : (
              <Menu>
                <MenuButton onClick={this.onAddStop}>Add stop</MenuButton>
                <MenuButton onClick={this.onAddPoint}>Add point</MenuButton>
                <MenuButton onClick={this.onEdit}>Edit...</MenuButton>
                <MenuButton onClick={this.onShowVehicleSource}>
                  View source...
                </MenuButton>
                <MenuButton onClick={this.onDelete}>Delete</MenuButton>
              </Menu>
            )}
          </Title>
          {!window.GEODISC_UI_COMMUTE_OFFER_TRANSFERRED_DISABLE && (
            <div>
              <Label>Transferred:</Label>
              {transferred}
            </div>
          )}
          <div>
            <Label>Occupied:</Label>
            {`${occupied} / ${capacity}`}
          </div>
          <div>
            <Label>Routing:</Label>
            {`${routingEngine} - ${routingProfile}`}
          </div>
        </Header>
        <div>
          {editableBooking && isActive && (
            <BookingStopsEditor nodes={editableBooking[vehicle.agent_id]} />
          )}
        </div>
        {isActive && (
          <React.Fragment>
            <Route
              vehicleId={vehicle.agent_id}
              route={vehicle.route}
              setActiveRouteStop={setActiveRouteStop}
              activeRouteStop={activeRouteStop}
              color={vehicle.activeColor}
              isHaveEditable={editableBooking}
            />
            <i>{pointEditing && 'Select on the map'}</i>
            {addStopMode === vehicle.agent_id && (
              <React.Fragment>
                <Input
                  onChange={this.onSearchInputChange}
                  placeholder='Choose stop or click on map'
                  value={stopSearchQuery || ''}
                />
                <SearchResult
                  stops={filteredStops}
                  addStopToRoute={addStopToRoute}
                  vehicleId={vehicle.agent_id}
                />
              </React.Fragment>
            )}
            {isEditableVehicleTime && vehicle.route.length && (
              <React.Fragment>
                <DateTimeContainer>
                  <DateTime
                    viewMode='time'
                    value={moment(vehicle.route[0].scheduled_ts)}
                    onChange={this.onChangeStartTime}
                    dateFormat='YYYY-MM-DD'
                    timeFormat='HH:mm'
                    utc
                  />
                </DateTimeContainer>
                <Button onClick={this.props.recalculateVehicleTime}>
                  Recalculate time
                </Button>
              </React.Fragment>
            )}
            {false && (
              <ButtonsContainer>
                <Button onClick={this.props.toggleEditableTimeMode}>
                  {isEditableVehicleTime
                    ? 'Cancel editing start time'
                    : 'Editing start time'}
                </Button>
              </ButtonsContainer>
            )}
            {addStopMode === vehicle.agent_id && (
              <ButtonsContainer>
                <Button onClick={cleanAddStopMode}>Finish adding stops</Button>
              </ButtonsContainer>
            )}
            {pointEditing && (
              <ButtonsContainer>
                <Button onClick={cleanAddPointMode}>
                  Finish adding points
                </Button>
              </ButtonsContainer>
            )}
            {false && (
              <ButtonsContainer>
                <Button onClick={this.onEdit}>Edit</Button>
                <Button onClick={this.onClone}>Clone</Button>
                <Button onClick={this.onDelete}>Delete</Button>
              </ButtonsContainer>
            )}
          </React.Fragment>
        )}
      </Container>
    );
  }
}

export default DropTarget(
  'booking',
  {
    drop: props => ({
      ...props.vehicle,
      isHaveEditable: props.editableBooking
    })
  },
  (connect, monitor) => ({
    connectDropTarget: connect.dropTarget(),
    isOver: monitor.isOver(),
    canDrop: monitor.canDrop()
  })
)(Vehicle);
