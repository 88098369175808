/* eslint-disable */
import React, { useImperativeHandle, useRef } from 'react';
import { DragSource, DropTarget } from 'react-dnd';
import moment from 'moment';
import Button from 'pages/CommuteOffer/TextButton';
import debug from 'utils/debug';

import Container from './Container';
import ButtonContainer from './ButtonsContainer';
import NodeType from './NodeType';
import Row from './Row';
import Info from './Info';
import Header from './Header';
import Time from './Time';
import Location from './Location';
import IndexBadge from './IndexBadge';
import Slack from './Slack';

const { METHOD } = debug('p:CommuteOffer:Panels:Vehicles:Vehicle:Route');

const Stop = React.forwardRef((props, ref) => {
  METHOD('Stop', { props });

  const {
    index,
    isActive,
    vehicleId,
    onClick,
    color,
    isHaveEditable,
    stop,
    isDragging,
    connectDragSource,
    connectDropTarget,
    deleteRoutePoint,
    cleanActiveRouteStop
  } = props;

  const elementRef = useRef(null);
  connectDragSource(elementRef);
  connectDropTarget(elementRef);
  const opacity = isDragging ? 0.3 : 1;
  useImperativeHandle(ref, () => ({
    getNode: () => elementRef.current
  }));

  const isPoint = stop.node_type === 'point' || stop.node_type === 'stop';

  const onDeleteRoutePoint = () => {
    deleteRoutePoint(stop.uid, vehicleId);
    cleanActiveRouteStop();
  };

  const slack = stop.slack;
  // const slack = stop.bookings.reduce((memo, item) => {
  //   if (item.slack && item.slack >= 60) {
  //     return memo + item.slack;
  //   }
  //   return memo;
  // }, 0);

  return (
    <Container
      isActive={isActive}
      color={color}
      isDropoff={stop.node_type === 'dropoff'}
    >
      <div ref={elementRef} style={Object.assign({}, { opacity })}>
        <Header onClick={onClick}>
          <Location>
            <IndexBadge>
              <strong>{index + 1}</strong>
            </IndexBadge>
            &nbsp;-&nbsp;{stop.location_name || '---'}
          </Location>
          <Row>
            <NodeType>{stop.node_type}</NodeType>
            {!isPoint && `${stop.pax} pax`}
          </Row>
          {!!(slack && slack >= 60) && (
            <Slack>
              <strong>
                {moment.duration(Math.round(slack), 'seconds').humanize()} slack
              </strong>
            </Slack>
          )}
          {stop.scheduled_ts && (
            <Time>
              {moment(stop.scheduled_ts)
                .tz(global.GEODISC_TIMEZONE)
                .format('HH:mm')}
            </Time>
          )}
        </Header>
        {isActive && !isPoint && (
          <Info
            stop={stop}
            bookings={stop.bookings}
            isHaveEditable={isHaveEditable}
          />
        )}
        {isActive && isPoint && (
          <ButtonContainer>
            <Button onClick={onDeleteRoutePoint}>Delete</Button>
          </ButtonContainer>
        )}
      </div>
    </Container>
  );
});

export default DropTarget(
  'stop',
  {
    hover(props, monitor, component) {
      if (!component) {
        return null;
      }
      // node = HTML Div element from imperative API
      const node = component.getNode();
      if (!node) {
        return null;
      }
      const dragIndex = monitor.getItem().index;
      const hoverIndex = props.index;
      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return null;
      }
      // Determine rectangle on screen
      const hoverBoundingRect = node.getBoundingClientRect();
      // Get vertical middle
      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      // Determine mouse position
      const clientOffset = monitor.getClientOffset();
      // Get pixels to the top
      const hoverClientY = clientOffset.y - hoverBoundingRect.top;
      // Only perform the move when the mouse has crossed half of the items height
      // When dragging downwards, only move when the cursor is below 50%
      // When dragging upwards, only move when the cursor is above 50%
      // Dragging downwards
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return null;
      }
      // Dragging upwards
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return null;
      }
      // Time to actually perform the action
      props.moveCard(dragIndex, hoverIndex);
      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      monitor.getItem().index = hoverIndex;
      return null;
    }
  },
  (connect) => ({
    connectDropTarget: connect.dropTarget()
  })
)(
  DragSource(
    'stop',
    {
      beginDrag: (props) => ({
        id: props.id,
        index: props.index
      })
    },
    (connect, monitor) => ({
      connectDragSource: connect.dragSource(),
      isDragging: monitor.isDragging()
    })
  )(Stop)
);
