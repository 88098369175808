import { connect } from 'react-redux';
import { ActionCreators } from 'redux-undo';
import createImmutableSelector from 'create-immutable-selector';
import {
  serialNumberSelector,
  filteredVehiclesSelector
} from 'modules/commuteOffer/selectors';
import { newVehicle } from 'modules/commuteOffer/actions';
import { openPopup } from 'modules/ui/actions';

import Vehicles from './Vehicles';

const mapStateToProps = createImmutableSelector(
  serialNumberSelector,
  filteredVehiclesSelector,
  (serialNumber, vehicles) => ({
    serialNumber,
    vehicles
  })
);

const mapDispatchToProps = {
  newVehicle,
  openPopup,
  back: ActionCreators.undo,
  next: ActionCreators.redo
};

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps
);

export default withConnect(Vehicles);
