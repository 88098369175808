import { connect } from 'react-redux';
import { compose, lifecycle } from 'recompose';
import createImmutableSelector from 'create-immutable-selector';

import { fetchSimulations } from 'modules/simulations/actions';
import { listSelector } from 'modules/simulations/selectors';
import { routePageSelector } from 'modules/router/selectors';
import { currentProjectIdSelector } from 'modules/user/selectors';

import Simulations from './Simulations';

const withLifecycle = lifecycle({
  componentDidMount() {
    const { list, page, projectId } = this.props;
    if ((!list || !page) && projectId) {
      this.props.fetchSimulations();
    }
  },
  componentDidUpdate(prevProps) {
    const { list, page, projectId } = this.props;
    if ((!list || page !== prevProps.page) && projectId) {
      this.props.fetchSimulations();
    }
  }
});

const mapStateToProps = createImmutableSelector(
  listSelector,
  routePageSelector,
  currentProjectIdSelector,
  (list, page, projectId) => ({
    list,
    page,
    projectId
  })
);

const mapDispatchToProps = { fetchSimulations };

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps
);

const enhance = compose(
  withConnect,
  withLifecycle
);

export default enhance(Simulations);
