import React from 'react';

import Logo from 'components/Logo';
import { simulations as simulationsConfig } from 'config';

import Container from './Container';
import BackButton from './BackButton';
import Header from './Header';
import TableHeader from './TableHeader';
import Table from './Table';
import Row from './Row';
import Column from './Column';

const Compare = ({ simulations, onClose }) => (
  <Container>
    <Header>
      <Logo />
      <BackButton onClick={onClose}>Back to the list</BackButton>
    </Header>
    <Table>
      <TableHeader>{`${simulations.size} simulations`}</TableHeader>
      <Row underline sticky>
        <Column style={{ lineHeight: '25px' }}>Name of simulation</Column>
        {simulations.map(item => (
          <Column
            key={item.get('id')}
            style={{ fontSize: 25, fontWeight: 600 }}
          >
            {item.get('name')}
          </Column>
        ))}
      </Row>
      {simulationsConfig.metrics.map((metric) => {
        const { label, getValue } = metric;
        const key = metric.id;
        return (
          <Row key={key}>
            <Column>{label}</Column>
            {simulations.map(item => (
              <Column key={item.get('id')}>{getValue(item.get(key))}</Column>
            ))}
          </Row>
        );
      })}
    </Table>
  </Container>
);

export default Compare;
