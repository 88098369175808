import React, { memo } from 'react';

import Header from 'components/Header';
import Layout from 'components/Layout';

import Panel from './Panel';

const Geofences = () => (
  <Layout>
    <Header isLogo={false} isShadow />
    <Panel />
  </Layout>
);

export default memo(Geofences);
