import styled from 'styled-components';

export default styled.div`
  position: absolute;
  top: ${global.COMMUTE_OFFER_HEADER_ENABLED ? 60 : 0}px;
  right: 0;

  display: flex;
  align-items: flex-start;

  padding: 10px;
`;
