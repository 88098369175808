import parseColor from 'parse-color';
import getContrastRatio from 'get-contrast-ratio';

import { crc32 } from './crc32';

export const getContrastRandomColor = (background) => {
  const color = `#${Math.random()
    .toString(16)
    .substr(-6)}`;
  if (getContrastRatio(background, color) > 5) {
    return color;
  }

  return getContrastRandomColor(background);
};

export const rgbaToDeckColor = (rgba) => {
  const { r, g, b, a } = rgba;

  return [r, g, b, a * 255];
};

export const hexToDeckColor = (hex) => {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result
    ? [
        parseInt(result[1], 16),
        parseInt(result[2], 16),
        parseInt(result[3], 16)
      ]
    : null;
};

export const deckColorToRgba = (color) => {
  const [r, g, b, alpha] = color;
  const a = alpha >= 1 && alpha <= 255 ? alpha / 255 : 1;
  return { r, g, b, a };
};

export const deckColorToHex = (color) => {
  const [r, g, b] = color;

  let hexR = r.toString(16);
  let hexG = g.toString(16);
  let hexB = b.toString(16);

  hexR = hexR.length === 1 ? `0${hexR}` : hexR;
  hexG = hexG.length === 1 ? `0${hexG}` : hexG;
  hexB = hexB.length === 1 ? `0${hexB}` : hexB;

  return `#${hexR}${hexG}${hexB}`;
};

export const colorByValue = (value) => {
  if (typeof value === 'number') {
    const hue = Math.round(value % 360);
    return {
      activeColor: `hsl(${hue}, 100%, 70%)`,
      color: `hsl(${hue}, 100%, 50%)`
    };
  }
  const color = parseColor(value || '#FF0000');
  if (!color || !color.hsl) {
    throw new Error(`Failed to parse color "${value}" (${typeof value})`);
  }

  const hue = color.hsl[0];
  return {
    activeColor: `hsl(${hue}, 100%, 70%)`,
    color: `hsl(${hue}, 100%, 50%)`
  };
};

export const colorById = id => colorByValue(crc32(id));

export default rgbaToDeckColor;
