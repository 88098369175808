import React from 'react';
import FileInput from './FileInput';
import Container from './Container';
import Text from './Text';

const DropZone = (props) => {
  const { onDragOver, onDrop, onChange } = props;

  return (
    <Container onDragOver={onDragOver} onDrop={onDrop}>
      <Text>Drop files here</Text>
      <div>
        {'or '}
        <FileInput onChange={onChange} />
      </div>
    </Container>
  );
};

export default DropZone;
