import React from 'react';
import Container from './Container';
import Column from './Column';
import LeftColumn from './LeftColumn';
import Main from './Main';
import RightColumn from './RightColumn';

const config = [
  { key: 'state', id: 'state', width: '150px', label: 'Status' },
  {
    key: 'dataset_name',
    id: 'dataset_name',
    style: { paddingLeft: 20 },
    label: 'Dataset',
    width: '200px'
  },
  {
    key: 'geofence_name',
    id: 'geofence_name',
    label: 'Geofence',
    width: '200px'
  },
  {
    key: 'number_of_vehicles',
    id: 'number_of_vehicles',
    label: 'Number of vehicles'
  },
  {
    key: 'min_waiting_time',
    id: 'min_waiting_time',
    label: 'Min waiting time'
  },
  {
    key: 'max_waiting_time',
    id: 'max_waiting_time',
    label: 'Max waiting time'
  },
  {
    key: 'bookings_count',
    id: 'bookings_count',
    label: 'Total number of bookings'
  },
  {
    key: 'vehicle_capacity',
    id: 'vehicle_capacity',
    label: 'Vehicle capacity'
  },
  {
    key: 'max_additional_journey_time',
    id: 'max_additional_journey_time',
    width: '150px',
    label: 'Max additional journey time'
  },
  {
    key: 'min_advance_booking_window',
    id: 'min_advance_booking_window',
    width: '120px',
    label: 'Min advance booking window'
  },
  {
    key: 'max_advance_booking_window',
    id: 'max_advance_booking_window',
    width: '150px',
    label: 'Max advance booking window'
  },
  { key: 'modifaed_at', id: 'created_at', label: 'Date' },
  { key: 'transfer_rate', id: 'transfer_rate', label: 'Transfer rate' }
];

const Header = (props) => {
  const onSort = (value) => {
    // refactoring
    props.changeSimulationsOrdering(value);
    props.clearList();
    props.fetchSimulations();
  };

  return (
    <Container>
      <LeftColumn>
        <Column
          isActive={props.ordering.get('id') === 'id'}
          sort={props.ordering.get('id') === 'id' && props.ordering.get('sort')}
          onClick={() => onSort('id')}
          width='70px'
          style={{ marginLeft: -10 }}
        >
          ID
        </Column>
        <Column
          isActive={props.ordering.get('id') === 'name'}
          sort={
            props.ordering.get('id') === 'name' && props.ordering.get('sort')
          }
          onClick={() => onSort('name')}
        >
          Name
        </Column>
      </LeftColumn>
      <Main>
        {config.map(({ label, ...properties }) => {
          const isActive =
            properties.id && props.ordering.get('id') === properties.id;

          const sort = isActive && props.ordering.get('sort');

          const onChange = () => {
            // refactoring
            props.changeSimulationsOrdering(properties.id);
            props.clearList();
            props.fetchSimulations();
          };

          return (
            <Column
              isActive={isActive}
              onClick={onChange}
              {...properties}
              sort={sort}
            >
              {label}
            </Column>
          );
        })}
      </Main>
      <RightColumn>
        <Column>content</Column>
      </RightColumn>
    </Container>
  );
};

export default Header;
