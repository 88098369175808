export default {
  vehicles: {
    id: 'vehicles',
    type: 'circle',
    source: 'vehicles',
    before: 'vehicles_active',
    paint: {
      'circle-radius': [
        'interpolate',
        ['exponential', 1.25],
        ['zoom'],
        8,
        ['case', ['boolean', ['feature-state', 'hover'], false], 5, 3],
        22,
        ['case', ['boolean', ['feature-state', 'hover'], false], 23, 20]
      ],
      'circle-color': [
        'match',
        ['to-string', ['get', 'node_type']],
        'pickup',
        ['get', 'activeColor'],
        'dropoff',
        '#fff',
        'gray'
      ],
      'circle-opacity': ['case', ['boolean', ['get', 'noActive'], false], 1, 0],
      'circle-stroke-width': [
        'interpolate',
        ['exponential', 1.5],
        ['zoom'],
        9,
        1,
        22,
        4
      ],
      'circle-stroke-color': [
        'match',
        ['to-string', ['get', 'node_type']],
        'pickup',
        '#fff',
        'dropoff',
        ['get', 'activeColor'],
        'gray'
      ],
      'circle-stroke-opacity': [
        'case',
        ['boolean', ['get', 'noActive'], false],
        1,
        0
      ]
    }
  },
  vehicles_active: {
    id: 'vehicles_active',
    type: 'circle',
    source: 'vehicles',
    before: 'bookings_circle',
    paint: {
      'circle-radius': [
        'interpolate',
        ['exponential', 1.25],
        ['zoom'],
        8,
        ['case', ['boolean', ['feature-state', 'hover'], false], 5, 3],
        22,
        ['case', ['boolean', ['feature-state', 'hover'], false], 23, 20]
      ],
      'circle-color': [
        'match',
        ['to-string', ['get', 'node_type']],
        'pickup',
        ['get', 'activeColor'],
        'dropoff',
        '#fff',
        'gray'
      ],
      'circle-stroke-width': [
        'interpolate',
        ['exponential', 1.5],
        ['zoom'],
        9,
        1,
        22,
        4
      ],
      'circle-stroke-color': [
        'match',
        ['to-string', ['get', 'node_type']],
        'pickup',
        '#fff',
        'dropoff',
        ['get', 'activeColor'],
        'gray'
      ]
    },
    filter: ['==', 'isActive', true]
  },
  vehicle_active_stop: {
    id: 'vehicle_active_stop',
    type: 'circle',
    source: 'vehicles',
    before: 'vehicles_active',
    paint: {
      'circle-radius': [
        'interpolate',
        ['exponential', 1.25],
        ['zoom'],
        8,
        ['case', ['boolean', ['feature-state', 'hover'], false], 16, 12],
        22,
        ['case', ['boolean', ['feature-state', 'hover'], false], 55, 50]
      ],
      'circle-color': ['get', 'activeColor'],
      'circle-opacity': [
        'case',
        ['boolean', ['get', 'isActiveStop'], false],
        0.5,
        0
      ]
    }
  },
  nodes: {
    id: 'nodes',
    type: 'circle',
    source: 'nodes',
    before: 'route',
    paint: {
      'circle-radius': [
        'interpolate',
        ['exponential', 1.4],
        ['zoom'],
        9,
        ['case', ['boolean', ['feature-state', 'hover'], false], 2.2, 1.5],
        22,
        ['case', ['boolean', ['feature-state', 'hover'], false], 40, 25]
      ],
      'circle-color': '#D8D8D8',
      'circle-stroke-color': '#979797',
      'circle-stroke-width': [
        'interpolate',
        ['exponential', 1.25],
        ['zoom'],
        9,
        1,
        22,
        3
      ]
    }
  },
  bookings: {
    id: 'bookings',
    type: 'symbol',
    source: 'bookings',
    before: 'vehicles',
    minzoom: 13,
    paint: {
      'text-color': [
        'match',
        ['to-string', ['get', 'status']],
        'unassigned',
        '#CC5231',
        'unassignedActive',
        '#FF6646',
        'assigned',
        '#6A6A6A',
        'activeVehicle',
        '#B7B7B7',
        'active',
        '#FBFBFB',
        'grey'
      ],
      'text-halo-color': '#18191A',
      'text-halo-width': 0.5,
      'text-halo-blur': 0.4,
      'text-opacity': ['case', ['boolean', ['get', 'noActive'], false], 1, 0.1],
      'icon-opacity': ['case', ['boolean', ['get', 'noActive'], false], 1, 0]
    },
    layout: {
      'text-field': ['concat', 'Booking: #', ['get', 'uid']],
      'text-size': [
        'interpolate',
        ['linear'],
        ['zoom'],
        15.99,
        0,
        16,
        11,
        22,
        15
      ],
      'text-max-width': 6,
      'text-offset': [
        'interpolate',
        ['linear'],
        ['zoom'],
        16,
        ['literal', [0, 0.3]],
        22,
        ['literal', [0, 1]]
      ],
      'text-anchor': 'top',
      'text-font': ['DIN Offc Pro Bold', 'Arial Unicode MS Regular'],
      'icon-size': [
        'interpolate',
        ['exponential', 1.15],
        ['zoom'],
        14,
        0.75,
        17,
        1
      ],
      'icon-offset': [
        'interpolate',
        ['exponential', 1.15],
        ['zoom'],
        12,
        ['literal', [0, 8]],
        15,
        ['literal', [0, 0]]
      ],
      'icon-image': [
        'match',
        ['to-string', ['get', 'status']],
        'unassigned',
        'red',
        'unassignedActive',
        'red-active',
        'assigned',
        'grey',
        'activeVehicle',
        'grey-slightly',
        'active',
        'white',
        'grey'
      ],
      'icon-allow-overlap': true,
      'text-allow-overlap': true
    }
  },
  bookings_active: {
    id: 'bookings_active',
    type: 'symbol',
    source: 'bookings',
    minzoom: 13,
    paint: {
      'text-color': [
        'match',
        ['to-string', ['get', 'status']],
        'unassigned',
        '#CC5231',
        'unassignedActive',
        '#FF6646',
        'assigned',
        '#6A6A6A',
        'activeVehicle',
        '#B7B7B7',
        'active',
        '#FBFBFB',
        'grey'
      ],
      'text-halo-color': '#18191A',
      'text-halo-width': 0.5,
      'text-halo-blur': 0.4
    },
    layout: {
      'text-field': ['concat', 'Booking: #', ['get', 'uid']],
      'text-size': [
        'interpolate',
        ['linear'],
        ['zoom'],
        14.99,
        0,
        15,
        11,
        22,
        15
      ],
      'text-max-width': 6,
      'text-offset': [
        'interpolate',
        ['linear'],
        ['zoom'],
        16,
        ['literal', [0, 0.3]],
        22,
        ['literal', [0, 1]]
      ],
      'text-anchor': 'top',
      'text-font': ['DIN Offc Pro Bold', 'Arial Unicode MS Regular'],
      'icon-size': [
        'interpolate',
        ['exponential', 1.15],
        ['zoom'],
        14,
        0.75,
        17,
        1
      ],
      'icon-offset': [
        'interpolate',
        ['exponential', 1.15],
        ['zoom'],
        12,
        ['literal', [0, 8]],
        15,
        ['literal', [0, 0]]
      ],
      'icon-image': [
        'match',
        ['to-string', ['get', 'status']],
        'unassigned',
        'red',
        'unassignedActive',
        'red-active',
        'assigned',
        'grey',
        'activeVehicle',
        'grey-slightly',
        'active',
        'white',
        'grey'
      ],
      'icon-allow-overlap': true,
      'text-allow-overlap': true
    },
    filter: ['==', 'isActive', true]
  },
  bookings_circle: {
    id: 'bookings_circle',
    type: 'circle',
    source: 'bookings',
    maxzoom: 13,
    paint: {
      'circle-radius': [
        'interpolate',
        ['exponential', 1.25],
        ['zoom'],
        11,
        ['case', ['boolean', ['feature-state', 'hover'], false], 3, 2],
        22,
        ['case', ['boolean', ['feature-state', 'hover'], false], 18, 15]
      ],
      'circle-color': [
        'match',
        ['to-string', ['get', 'status']],
        'unassigned',
        '#18191A',
        'unassignedActive',
        '#18191A',
        'assigned',
        '#FFFFFF',
        'activeVehicle',
        '#2B2D2F',
        'active',
        '#303030',
        'grey'
      ],
      'circle-opacity': ['case', ['boolean', ['get', 'noActive'], false], 1, 0],
      'circle-stroke-width': [
        'interpolate',
        ['exponential', 1.25],
        ['zoom'],
        11,
        ['case', ['boolean', ['feature-state', 'hover'], false], 4, 3],
        22,
        ['case', ['boolean', ['feature-state', 'hover'], false], 23, 20]
      ],
      'circle-stroke-color': [
        'match',
        ['to-string', ['get', 'status']],
        'unassigned',
        '#CC5231',
        'unassignedActive',
        '#FF6646',
        'assigned',
        '#6A6A6A',
        'activeVehicle',
        '#B7B7B7',
        'active',
        '#FFFFFF',
        'grey'
      ],
      'circle-stroke-opacity': [
        'case',
        ['boolean', ['get', 'noActive'], false],
        1,
        0
      ]
    }
  },
  bookings_circle_active: {
    id: 'bookings_circle_active',
    type: 'circle',
    source: 'bookings',
    maxzoom: 13,
    paint: {
      'circle-radius': [
        'interpolate',
        ['exponential', 1.25],
        ['zoom'],
        11,
        ['case', ['boolean', ['feature-state', 'hover'], false], 3, 2],
        22,
        ['case', ['boolean', ['feature-state', 'hover'], false], 18, 15]
      ],
      'circle-color': [
        'match',
        ['to-string', ['get', 'status']],
        'unassigned',
        '#18191A',
        'unassignedActive',
        '#18191A',
        'assigned',
        '#FFFFFF',
        'activeVehicle',
        '#2B2D2F',
        'active',
        '#303030',
        'grey'
      ],
      'circle-stroke-width': [
        'interpolate',
        ['exponential', 1.25],
        ['zoom'],
        11,
        ['case', ['boolean', ['feature-state', 'hover'], false], 4, 3],
        22,
        ['case', ['boolean', ['feature-state', 'hover'], false], 23, 20]
      ],
      'circle-stroke-color': [
        'match',
        ['to-string', ['get', 'status']],
        'unassigned',
        '#CC5231',
        'unassignedActive',
        '#FF6646',
        'assigned',
        '#6A6A6A',
        'activeVehicle',
        '#B7B7B7',
        'active',
        '#FFFFFF',
        'grey'
      ]
    },
    filter: ['==', 'isActive', true]
  },
  route: {
    id: 'route',
    type: 'line',
    source: 'route',
    before: 'vehicles',
    paint: {
      'line-color': ['get', 'activeColor'],
      'line-opacity': ['case', ['boolean', ['get', 'noActive'], false], 1, 0],
      'line-width': [
        'interpolate',
        ['exponential', 1.5],
        ['zoom'],
        11,
        2,
        22,
        11
      ]
    }
  },
  route_active: {
    id: 'route_active',
    type: 'line',
    source: 'route',
    before: 'route',
    paint: {
      'line-color': ['get', 'activeColor'],
      'line-width': [
        'interpolate',
        ['exponential', 1.5],
        ['zoom'],
        11,
        2,
        22,
        11
      ]
    },
    filter: ['==', 'isActive', true]
  },
  walkingRoutes: {
    id: 'walkingRoutes',
    type: 'line',
    source: 'walkingRoutes',
    before: 'bookings',
    paint: {
      'line-color': [
        'match',
        ['to-string', ['get', 'status']],
        'active',
        '#6A6A6A',
        'inactive',
        '#6A6A6A',
        'activeStop',
        '#B7B7B7',
        'activeBooking',
        '#FFFFFF',
        '#6A6A6A'
      ],
      'line-width': [
        'interpolate',
        ['exponential', 1.5],
        ['zoom'],
        11,
        1.25,
        22,
        8
      ],
      'line-opacity': [
        'case',
        ['boolean', ['get', 'noActive'], false],
        1,
        ['match', ['to-string', ['get', 'status']], 'inactive', 0, 1]
      ],
      'line-dasharray': [1, 1]
    }
  },
  stops: {
    id: 'stops',
    type: 'circle',
    source: 'stops',
    before: 'route',
    paint: {
      'circle-color': [
        'match',
        ['get', 'stop_type'],
        'bus_stop',
        '#4667fb',
        'taxi_stand',
        '#fee09a',
        'mrt_station',
        '#D1114A',
        'lrt_station',
        '#13B5A0',
        'grey'
      ],
      'circle-radius': [
        'interpolate',
        ['exponential', 1.4],
        ['zoom'],
        9,
        ['match', ['get', 'stop_type'], 'mrt_station', 1.75, 1.2],
        22,
        ['match', ['get', 'stop_type'], 'mrt_station', 30, 25]
      ]
    }
  },
  stops_label: {
    id: 'stops_label',
    type: 'symbol',
    source: 'stops',
    paint: {
      'text-color': [
        'match',
        ['get', 'stop_type'],
        'bus_stop',
        '#4667fb',
        'taxi_stand',
        '#fee09a',
        'mrt_station',
        '#D1114A',
        'lrt_station',
        '#119892',
        'grey'
      ],
      'text-halo-color': '#18191A',
      'text-halo-width': 0.5,
      'text-halo-blur': 0.4
    },
    layout: {
      'text-field': ['get', 'name'],
      'text-size': [
        'interpolate',
        ['linear'],
        ['zoom'],
        15.99,
        0,
        16,
        10,
        22,
        12
      ],
      'text-max-width': 6,
      'text-offset': [
        'interpolate',
        ['linear'],
        ['zoom'],
        16,
        ['literal', [0, 0.7]],
        22,
        ['literal', [0, 2.5]]
      ],
      'text-anchor': 'top',
      'text-font': ['DIN Offc Pro Bold', 'Arial Unicode MS Regular']
    }
  }
};
