import { connect } from 'react-redux';
import { createSelector } from 'reselect';

import { removeBookingFromRoute } from 'modules/commuteOffer/actions';
import { setEditableBookingId } from 'modules/ui/actions';
import { activeBookingIdSelector } from 'modules/ui/selectors';

import Info from './Info';

const mapStateToProps = createSelector(
  activeBookingIdSelector,
  activeBookingId => ({ activeBookingId })
);

const mapDispatchToProps = {
  removeBookingFromRoute,
  setEditableBookingId
};

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps
);

export default withConnect(Info);
