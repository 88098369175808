export const INC_SERIAL_NUMBER = '@@commuteOffer/INC_SERIAL_NUMBER';
export const incSerialNumber = opts => ({
  type: INC_SERIAL_NUMBER,
  payload: opts
});

export const COMMUTE_OFFER_FETCH_REQUEST =
  '@@commuteOffer/COMMUTE_OFFER_FETCH_REQUEST';
export const COMMUTE_OFFER_FETCH_SUCCESS =
  '@@commuteOffer/COMMUTE_OFFER_FETCH_SUCCESS';
export const COMMUTE_OFFER_FETCH_FAILURE =
  '@@commuteOffer/COMMUTE_OFFER_FETCH_FAILURE';
export const fetchCommuteOffer = id => ({
  type: COMMUTE_OFFER_FETCH_REQUEST,
  payload: id
});

export const COMMUTE_OFFERS_FETCH_REQUEST =
  '@@commuteOffer/COMMUTE_OFFERS_FETCH_REQUEST';
export const COMMUTE_OFFERS_FETCH_SUCCESS =
  '@@commuteOffer/COMMUTE_OFFERS_FETCH_SUCCESS';
export const COMMUTE_OFFERS_FETCH_FAILURE =
  '@@commuteOffer/COMMUTE_OFFERS_FETCH_FAILURE';
export const fetchCommuteOffers = () => ({
  type: COMMUTE_OFFERS_FETCH_REQUEST
});

export const UPDATE_COMMUTE_OFFER_REQUEST =
  '@@commuteOffer/UPDATE_COMMUTE_OFFER_REQUEST';
export const UPDATE_COMMUTE_OFFER_SUCCESS =
  '@@commuteOffer/UPDATE_COMMUTE_OFFER_SUCCESS';
export const UPDATE_COMMUTE_OFFER_FAILURE =
  '@@commuteOffer/UPDATE_COMMUTE_OFFER_FAILURE';
export const updateCommuteOffer = (id, body) => ({
  type: UPDATE_COMMUTE_OFFER_REQUEST,
  payload: { id, body }
});

export const DELETE_COMMUTE_OFFER_REQUEST =
  '@@simulations/DELETE_COMMUTE_OFFER_REQUEST';
export const DELETE_COMMUTE_OFFER_SUCCESS =
  '@@simulations/DELETE_COMMUTE_OFFER_SUCCESS';
export const DELETE_COMMUTE_OFFER_FAILURE =
  '@@simulations/DELETE_COMMUTE_OFFER_FAILURE';
export const deleteCommuteOffer = id => ({
  type: DELETE_COMMUTE_OFFER_REQUEST,
  payload: id
});

export const ROUTE_FETCH_REQUEST = '@@commuteOffer/ROUTE_FETCH_REQUEST';
export const ROUTE_FETCH_SUCCESS = '@@commuteOffer/ROUTE_FETCH_SUCCESS';
export const ROUTE_FETCH_FAILURE = '@@commuteOffer/ROUTE_FETCH_FAILURE';
export const fetchRoute = (vehicle, routingEngine) => ({
  type: ROUTE_FETCH_REQUEST,
  payload: { vehicle, routingEngine }
});

export const SET_RESULT_VEHICLES = '@@commuteOffer/SET_RESULT_VEHICLES';
export const setResultVehicles = (vehicleId, nodes) => ({
  type: SET_RESULT_VEHICLES,
  payload: { vehicleId, nodes }
});

export const SET_VEHICLE_START_TIME = '@@commuteOffer/SET_VEHICLE_START_TIME';
export const setVehicleStartTime = (vehicleId, time) => ({
  type: SET_VEHICLE_START_TIME,
  payload: { vehicleId, time }
});

export const RECALCULATE_VEHICLE_TIME =
  '@@commuteOffer/RECALCULATE_VEHICLE_TIME';
export const recalculateVehicleTime = () => ({
  type: RECALCULATE_VEHICLE_TIME
});

export const CLEAR_COMMUTE_OFFER = '@@commuteOffer/CLEAR_COMMUTE_OFFER';
export const clearCommuteOffer = () => ({ type: CLEAR_COMMUTE_OFFER });

export const REMOVE_BOOKING_FROM_ROUTE =
  '@@commuteOffer/REMOVE_BOOKING_FROM_ROUTE';
export const removeBookingFromRoute = id => ({
  type: REMOVE_BOOKING_FROM_ROUTE,
  payload: id
});

export const ADD_BOOKING_TO_ROUTE = '@@commuteOffer/ADD_BOOKING_TO_ROUTE';
export const addBookingToRoute = (bookingId, vehicleId) => ({
  type: ADD_BOOKING_TO_ROUTE,
  payload: { bookingId, vehicleId }
});

export const ADD_POINT_TO_ROUTE = '@@commuteOffer/ADD_POINT_TO_ROUTE';
export const addPointToRoute = (lon, lat, vehicleId) => ({
  type: ADD_POINT_TO_ROUTE,
  payload: { lon, lat, vehicleId }
});

export const SET_POINT_TO_ROUTE = '@@commuteOffer/SET_POINT_TO_ROUTE';
export const setPointToRoute = (lon, lat, vehicleId, route, position) => ({
  type: SET_POINT_TO_ROUTE,
  payload: { lon, lat, vehicleId, route, position }
});

export const ADD_STOP_TO_ROUTE = '@@commuteOffer/ADD_STOP_TO_ROUTE';
export const addStopToRoute = stopId => ({
  type: ADD_STOP_TO_ROUTE,
  payload: stopId
});

export const SET_STOP_TO_ROUTE = '@@commuteOffer/SET_STOP_TO_ROUTE';
export const setStopToRoute = (feature, vehicleId, route) => ({
  type: SET_STOP_TO_ROUTE,
  payload: { feature, vehicleId, route }
});

export const CHANGE_ROUTE_POINT = '@@commuteOffer/CHANGE_ROUTE_POINT';
export const changeRoutePoint = (lon, lat, id, vehicleId) => ({
  type: CHANGE_ROUTE_POINT,
  payload: { lon, lat, id, vehicleId }
});

export const DELETE_ROUTE_POINT = '@@commuteOffer/DELETE_ROUTE_POINT';
export const deleteRoutePoint = (id, vehicleId) => ({
  type: DELETE_ROUTE_POINT,
  payload: { id, vehicleId }
});

export const SET_BOOKING_NODE = '@@commuteOffer/SET_BOOKING_NODE';
export const setBookingNode = nodes => ({
  type: SET_BOOKING_NODE,
  payload: nodes
});

export const REMOVE_BOOKING_NODE = '@@commuteOffer/REMOVE_BOOKING_NODE';
export const removeBookingNode = (bookingId, nodeType) => ({
  type: REMOVE_BOOKING_NODE,
  payload: { bookingId, nodeType }
});

export const CHANGE_ROUTE_ORDER = '@@commuteOffer/CHANGE_ROUTE_ORDER';
export const changeRouteOrder = (currentIndex, newIndex, route, vehicleId) => ({
  type: CHANGE_ROUTE_ORDER,
  payload: { currentIndex, newIndex, route, vehicleId }
});

export const CHANGE_ROUTE_STOP = '@@commuteOffer/CHANGE_ROUTE_STOP';
export const changeRouteStop = stopId => ({
  type: CHANGE_ROUTE_STOP,
  payload: stopId
});

export const CHANGE_ROUTE_EMPTY_STOP = '@@commuteOffer/CHANGE_ROUTE_EMPTY_STOP';
export const changeRouteEmptyStop = (id, stop, vehicleId) => ({
  type: CHANGE_ROUTE_EMPTY_STOP,
  payload: { id, stop, vehicleId }
});

export const UPDATE_VEHICLE = '@@commuteOffer/UPDATE_VEHICLE';
export const updateVehicle = (vehicleId, vehicle) => ({
  type: UPDATE_VEHICLE,
  payload: { vehicleId, vehicle }
});

export const NEW_VEHICLE = '@@commuteOffer/NEW_VEHICLE';
export const newVehicle = () => ({
  type: NEW_VEHICLE
});

export const CLONE_VEHICLE = '@@commuteOffer/CLONE_VEHICLE';
export const clonedVehicle = vehicle => ({
  type: CLONE_VEHICLE,
  payload: vehicle
});

export const DELETE_VEHICLE = '@@commuteOffer/DELETE_VEHICLE';
export const deleteVehicle = vehicleId => ({
  type: DELETE_VEHICLE,
  payload: vehicleId
});

export const ADD_NODE = '@@commuteOffer/ADD_NODE';
export const addNode = newNodes => ({
  type: ADD_NODE,
  payload: newNodes
});

export const ADD_COMMUTE_OFFER_REQUEST =
  '@@commuteOffer/ADD_COMMUTE_OFFER_REQUEST';
export const ADD_COMMUTE_OFFER_SUCCESS =
  '@@commuteOffer/ADD_COMMUTE_OFFER_SUCCESS';
export const ADD_COMMUTE_OFFER_FAILURE =
  '@@commuteOffer/ADD_COMMUTE_OFFER_FAILURE';
export const addCommuteOffer = (params, data) => ({
  type: ADD_COMMUTE_OFFER_REQUEST,
  payload: { params, data }
});

export const DUPLICATE_COMMUTE_OFFER_REQUEST =
  '@@commuteOffer/DUPLICATE_COMMUTE_OFFER_REQUEST';
export const DUPLICATE_COMMUTE_OFFER_SUCCESS =
  '@@commuteOffer/DUPLICATE_COMMUTE_OFFER_SUCCESS';
export const DUPLICATE_COMMUTE_OFFER_FAILURE =
  '@@commuteOffer/DUPLICATE_COMMUTE_OFFER_FAILURE';
export const duplicateCommuteOffer = (id, params) => ({
  type: DUPLICATE_COMMUTE_OFFER_REQUEST,
  payload: { id, params }
});

export const COMMUTE_OFFER_ADD_VEHICLE_REQUEST =
  '@@commuteOffer/COMMUTE_OFFER_ADD_VEHICLE_REQUEST';
export const COMMUTE_OFFER_ADD_VEHICLE_SUCCESS =
  '@@commuteOffer/COMMUTE_OFFER_ADD_VEHICLE_SUCCESS';
export const COMMUTE_OFFER_ADD_VEHICLE_FAILURE =
  '@@commuteOffer/COMMUTE_OFFER_ADD_VEHICLE_FAILURE';
export const commuteOfferAddVehicleRequest = (data, values) => ({
  type: COMMUTE_OFFER_ADD_VEHICLE_REQUEST,
  payload: { data, values }
});

export const COMMUTE_OFFER_EDIT_VEHICLE_REQUEST =
  '@@commuteOffer/COMMUTE_OFFER_EDIT_VEHICLE_REQUEST';
export const COMMUTE_OFFER_EDIT_VEHICLE_SUCCESS =
  '@@commuteOffer/COMMUTE_OFFER_EDIT_VEHICLE_SUCCESS';
export const COMMUTE_OFFER_EDIT_VEHICLE_FAILURE =
  '@@commuteOffer/COMMUTE_OFFER_EDIT_VEHICLE_FAILURE';
export const commuteOfferEditVehicleRequest = (data, id, values) => ({
  type: COMMUTE_OFFER_EDIT_VEHICLE_REQUEST,
  payload: { data, id, values }
});

export const COMMUTE_OFFER_SHOW_VEHICLE_UPDATES =
  '@@commuteOffer/COMMUTE_OFFER_SHOW_VEHICLE_UPDATES';
export const showVehicleUpdates = id => ({
  type: COMMUTE_OFFER_SHOW_VEHICLE_UPDATES,
  payload: id
});

export const COMMUTE_OFFER_SHOW_VEHICLE_SOURCE =
  '@@commuteOffer/COMMUTE_OFFER_SHOW_VEHICLE_SOURCE';
export const showVehicleSource = id => ({
  type: COMMUTE_OFFER_SHOW_VEHICLE_SOURCE,
  payload: id
});

export const COMMUTE_OFFER_STATELESS_NODE_SCHEDULER_REQUEST =
  '@@commuteOffer/COMMUTE_OFFER_STATELESS_NODE_SCHEDULER_REQUEST';
export const COMMUTE_OFFER_STATELESS_NODE_SCHEDULER_SUCCESS =
  '@@commuteOffer/COMMUTE_OFFER_STATELESS_NODE_SCHEDULER_SUCCESS';
export const COMMUTE_OFFER_STATELESS_NODE_SCHEDULER_FAILURE =
  '@@commuteOffer/COMMUTE_OFFER_STATELESS_NODE_SCHEDULER_FAILURE';
export const commuteOfferStatelessNodeScheduler = (request, opts) => ({
  type: COMMUTE_OFFER_STATELESS_NODE_SCHEDULER_REQUEST,
  payload: { request, opts }
});

export const COMMUTE_OFFER_IMPORT_BOOKINGS_REQUEST =
  '@@commuteOffer/COMMUTE_OFFER_IMPORT_BOOKINGS_REQUEST';
export const COMMUTE_OFFER_IMPORT_BOOKINGS_PROGRESS =
  '@@commuteOffer/COMMUTE_OFFER_IMPORT_BOOKINGS_PROGRESS';
export const COMMUTE_OFFER_IMPORT_BOOKINGS_SUCCESS =
  '@@commuteOffer/COMMUTE_OFFER_IMPORT_BOOKINGS_SUCCESS';
export const COMMUTE_OFFER_IMPORT_BOOKINGS_FAILURE =
  '@@commuteOffer/COMMUTE_OFFER_IMPORT_BOOKINGS_FAILURE';
export const commuteOfferImportBookingsRequest = (
  currentOffer,
  data,
  params,
  opts
) => ({
  type: COMMUTE_OFFER_IMPORT_BOOKINGS_REQUEST,
  payload: { currentOffer, data, params, opts }
});
export const commuteOfferImportBookingsProgress = (status, progress) => ({
  type: COMMUTE_OFFER_IMPORT_BOOKINGS_PROGRESS,
  payload: { status, progress }
});
