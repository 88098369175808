import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import createImmutableSelector from 'create-immutable-selector';
import { branch, compose, renderComponent } from 'recompose';

import {
  deleteDataset,
  updateDataset,
  saveDataset,
  downloadDatasetCsv,
  clearList
} from 'modules/datasets/actions';

import {
  setEditableDataset,
  updateEditableDataset,
  toggleSelectedDatasets
} from 'modules/ui/actions';

import {
  datasetsErrorSelector,
  editableDatasetSelector,
  selectedIdsDatasetsSelector
} from 'modules/ui/selectors';

import { countSelector, listSelector } from 'modules/datasets/selectors';
import { routePageSelector } from 'modules/router/selectors';

import Loading from 'components/Loading';
import Error from 'components/Error';

import Table from './Table';

const mapDispatchToProps = {
  deleteDataset,
  updateDataset,
  setEditableDataset,
  updateEditableDataset,
  saveDataset,
  downloadDatasetCsv,
  toggleSelectedDatasets,
  clearList
};

const mapStateToProps = createImmutableSelector(
  editableDatasetSelector,
  listSelector,
  datasetsErrorSelector,
  selectedIdsDatasetsSelector,
  countSelector,
  routePageSelector,
  (editableDataset, list, error, selectedIds, count, page) => ({
    editableDataset,
    list,
    error,
    selectedIds,
    count,
    page
  })
);

const withBranch = compose(
  branch(({ error }) => error, renderComponent(Error)),
  branch(({ list }) => !list, renderComponent(Loading))
);

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps
);

const enhance = compose(
  withConnect,
  withBranch,
  withRouter
);

export default enhance(Table);
