import { ScatterplotLayer, ArcLayer } from '@deck.gl/layers';
import { MapboxLayer } from '@deck.gl/mapbox';

import config from 'config/layerPanel';

import HexagonLayer from 'utils/hexagon/enhanced-hexagon-layer';
import { getPositionPickup, getPositionDropoff } from 'utils/deckLayers';

export default {
  points: {
    id: 'points',
    name: 'points',
    layers: {
      dropoff: new MapboxLayer({
        id: 'points-dropoff',
        data: [],
        type: ScatterplotLayer,
        getPosition: getPositionDropoff,
        getRadius: config.points.dropoffRadius,
        getFullColor: config.points.dropoffColor,
        getLineColor: config.points.dropoffColor
      }),
      pickup: new MapboxLayer({
        id: 'points-pickup',
        type: ScatterplotLayer,
        data: [],
        getPosition: getPositionPickup,
        getRadius: config.points.pickupRadius,
        getFillColor: config.points.pickupColor,
        getLineColor: config.points.pickupColor
      })
    }
  },
  arcs: {
    id: 'arcs',
    name: 'arcs',
    layers: {
      main: new MapboxLayer({
        id: 'arc',
        type: ArcLayer,
        data: [],
        getSourcePosition: getPositionPickup,
        getTargetPosition: getPositionDropoff,
        getSourceColor: config.arcs.pickupArcColor,
        getTargetColor: config.arcs.dropoffArcColor,
        getStrokeWidth: config.arcs.width
      })
    }
  },
  clusters: {
    id: 'clusters',
    name: 'Cluster all bookings',
    layers: {
      dropoff: new MapboxLayer({
        id: 'cluster-dropoff',
        type: ScatterplotLayer,
        data: [],
        getPosition: d => d.geometry.coordinates,
        getRadius: d => d.properties.point_count,
        radiusMinPixels: config.clusters.dropoffRadiusMin,
        radiusMaxPixels: config.clusters.dropoffRadiusMax,
        opacity: config.clusters.dropoffOpacity,
        radiusScale: 1,
        getFillColor: config.clusters.dropoffColor,
        getLineColor: config.clusters.dropoffColor
      }),
      pickup: new MapboxLayer({
        id: 'cluster-pickup',
        type: ScatterplotLayer,
        data: [],
        getPosition: d => d.geometry.coordinates,
        getRadius: d => d.properties.point_count,
        radiusMinPixels: config.clusters.pickupRadiusMin,
        radiusMaxPixels: config.clusters.pickupRadiusMax,
        opacity: config.clusters.pickupOpacity,
        radiusScale: 1,
        getFillColor: config.clusters.pickupColor,
        getLineColor: config.clusters.pickupColor
      })
    }
  },
  arcClusters: {
    id: 'arcClusters',
    name: 'Arc Cluster',
    layers: {
      dropoff: new MapboxLayer({
        id: 'cluster-dropoff',
        type: ScatterplotLayer,
        data: [],
        opacity: config.arcClusters.dropoffOpacity,
        getPosition: d => d.geometry.coordinates,
        getRadius: d => d.properties.point_count,
        getFillColor: config.arcClusters.dropoffColor,
        getLineColor: config.arcClusters.dropoffColor
      }),
      pickup: new MapboxLayer({
        id: 'cluster-pickup',
        type: ScatterplotLayer,
        data: [],
        opacity: config.arcClusters.pickupOpacity,
        getPosition: d => d.geometry.coordinates,
        getRadius: d => d.properties.point_count,
        getFillColor: config.arcClusters.pickupColor,
        getLineColor: config.arcClusters.pickupColor
      }),
      arc: new MapboxLayer({
        id: 'arc',
        type: ArcLayer,
        data: [],
        getSourcePosition: d => d.coordinatesPickup,
        getTargetPosition: d => d.coordinatesDropoff,
        getSourceColor: config.arcClusters.pickupArcColor,
        getTargetColor: config.arcClusters.dropoffArcColor,
        getStrokeWidth: d => (d.weight / 20 + 1 < 20 ? d.weight / 20 + 1 : 20)
      })
    }
  },
  hex_pickup: {
    id: 'hex_pickup',
    name: 'Hexagon pick up',
    layers: {
      main: new MapboxLayer({
        id: 'hex_pickup',
        type: HexagonLayer,
        data: [],
        getPosition: getPositionPickup,
        getColorValue: (points) => {
          if (points && points[0].weight) {
            return points.reduce(
              (acc, value) => acc + parseFloat(value.weight),
              0
            );
          }
          return points.length;
        },
        // extruded, opacity, radius, coverage, elevationScale, colorRange
        ...config.hex_pickup
      })
    }
  },
  hex_dropoff: {
    id: 'hex_dropoff',
    name: 'Hexagon drop off',
    layers: {
      main: new MapboxLayer({
        id: 'hex_dropoff',
        type: HexagonLayer,
        data: [],
        getPosition: getPositionDropoff,
        getColorValue: (points) => {
          if (points && points[0].weight) {
            return points.reduce(
              (acc, value) => acc + parseFloat(value.weight),
              0
            );
          }
          return points.length;
        },
        // extruded, opacity, radius, coverage, elevationScale, colorRange
        ...config.hex_dropoff
      })
    }
  }
};
