import React, { PureComponent } from 'react';
import MapGL, {
  Layer,
  Source,
  FeatureState,
  Marker
} from '@urbica/react-map-gl';
import { map, commuteOfferlayers as layers } from 'config';
import findNearestPoint from 'utils/nearest-point';
import { point } from '@turf/helpers';

import debug from 'utils/debug';
import { getPopup, getClickPopup } from './utils';

import Container from './Container';
import MarkerPoint from './MarkerPoint';

const { TRACE, METHOD } = debug('c:MapCommuteOfferEditor:Map');

class Map extends PureComponent {
  state = {
    cursorStyle: '',
    hoveredLayerId: null,
    hoveredStateId: null,
    popupData: null,
    clickPopup: null
  };

  componentDidMount() {
    METHOD('componentDidMount');

    this.map = this._map.current.getMap();
  }

  _map = React.createRef();

  onLayerOver = (e) => {
    METHOD('onLayerOver', e);

    this.setState({
      cursorStyle: 'pointer',
      hoveredLayerId: e.features[0].layer.source,
      hoveredStateId: e.features[0].id,
      popupData: e.features[0]
    });
  };

  onLayerLeave = (e) => {
    METHOD('onLayerLeave', e);

    this.setState({
      cursorStyle: '',
      hoveredLayerId: null,
      hoveredStateId: null,
      popupData: null
    });
  };

  onDragEnd = (e) => {
    const { draggablePoint } = this.props;

    METHOD('onDragEnd', { e, draggablePoint });

    const targetPoint = point([e.lng, e.lat]);
    if (draggablePoint.node_type !== 'point') {
      const nearestPoint = findNearestPoint(targetPoint, this.props.stops);
      METHOD('onDragEnd:nearestPoint', {
        e,
        draggablePoint,
        targetPoint,
        nearestPoint
      });
      this.props.setDraggablePoint({
        ...draggablePoint,
        lon: nearestPoint.geometry.coordinates[0],
        lat: nearestPoint.geometry.coordinates[1],
        nearestPoint
      });
    } else {
      METHOD('onDragEnd:targetPoint', { e, draggablePoint, targetPoint });
      this.props.setDraggablePoint({
        ...draggablePoint,
        lon: targetPoint.geometry.coordinates[0],
        lat: targetPoint.geometry.coordinates[1]
      });
    }
  };

  onMapClick = (e) => {
    METHOD('onMapClick', e);

    const {
      pointEditing,
      addPointToRoute,
      changeRoutePoint,
      activeVehicleIds,
      activeRouteStopUid
    } = this.props;

    const features =
      e.features ||
      this.map
        .queryRenderedFeatures(e.point)
        .filter(item =>
          [
            'bookings_circle',
            'bookings',
            'vehicles',
            'nodes',
            'stops'
          ].includes(item.layer.id));

    METHOD('onMapClick:features', features);

    if (!features.length) {
      this.setState({
        clickPopup: null,
        popupData: null
      });
    }

    if (features.length > 1) {
      this.setState({
        clickPopup: { event: e, features },
        popupData: null
      });
    }

    if (features.length === 1) {
      if (this.state.clickPopup) {
        this.setState({ clickPopup: null });
      }

      const feature = features[0];
      const { id } = feature.layer;

      switch (id) {
        case 'nodes': {
          this.props.onClickNodesLayer(feature);
          return;
        }
        case 'bookings_circle': {
          this.props.onClickBookingLayer(feature);
          return;
        }
        case 'bookings': {
          this.props.onClickBookingLayer(feature);
          return;
        }
        case 'stops': {
          this.props.onClickStopsLayer(e, feature);
          return;
        }
        case 'vehicles': {
          if (this.props.editableBookingId) {
            return;
          }

          this.props.onClickVehiclesLayer(feature);

          this.props.setDraggablePoint({
            ...feature.properties,
            id: feature.properties.uid,
            lon: feature.properties.lon,
            lat: feature.properties.lat,
            color: feature.properties.activeColor,
            node_type: feature.properties.node_type
          });
          return;
        }
        default:
          return;
      }
    }

    if (pointEditing) {
      const { lngLat } = e;
      const { lng, lat } = lngLat;

      if (activeRouteStopUid) {
        changeRoutePoint(lng, lat, activeRouteStopUid, activeVehicleIds);
      } else {
        addPointToRoute(lng, lat, activeVehicleIds);
      }
    }
  };

  render() {
    METHOD('render', { state: this.state, props: this.props, layers });

    const {
      viewport,
      onViewportChange,
      stops,
      vehicles,
      nodes,
      editableBookingsSource,
      bookings,
      route,
      walkingRoutes,
      busStopsVisible,
      activeVehicleIds,
      pointEditing,
      draggablePoint
    } = this.props;

    const {
      hoveredStateId,
      hoveredLayerId,
      popupData,
      clickPopup
    } = this.state;

    const isActive = activeVehicleIds.length;

    TRACE($ => $('Active:', isActive));
    TRACE($ => $('Layers:', layers));
    TRACE($ => $('Nodes:', nodes));
    TRACE($ => $('Route:', route));

    return (
      <Container>
        <MapGL
          ref={this._map}
          style={{ width: '100%', height: '100vh' }}
          mapStyle={map.mapStyle}
          accessToken={map.token}
          onViewportChange={onViewportChange}
          cursorStyle={pointEditing ? 'crosshair' : this.state.cursorStyle}
          viewportChangeMethod='flyTo'
          boxZoom={false}
          onClick={this.onMapClick}
          {...viewport}
        >
          <Source id='nodes' type='geojson' data={nodes}>
            <Layer
              {...layers.nodes}
              onHover={this.onLayerOver}
              onLeave={this.onLayerLeave}
            />
          </Source>
          <Source id='route' type='geojson' data={route}>
            <Layer
              {...layers.route}
              onHover={isActive ? null : this.onLayerOver}
              onLeave={isActive ? null : this.onLayerLeave}
            />
            <Layer
              {...layers.route_active}
              onHover={isActive ? null : this.onLayerOver}
              onLeave={isActive ? null : this.onLayerLeave}
            />
          </Source>

          <Source id='vehicles' type='geojson' data={vehicles}>
            <Layer
              {...layers.vehicles}
              onHover={isActive ? null : this.onLayerOver}
              onLeave={isActive ? null : this.onLayerLeave}
            />
            <Layer
              {...layers.vehicles_active}
              onHover={this.onLayerOver}
              onLeave={this.onLayerLeave}
            />
            <Layer {...layers.vehicle_active_stop} />
          </Source>

          {draggablePoint && (
            <Marker
              longitude={draggablePoint.lon}
              latitude={draggablePoint.lat}
              onDragEnd={this.onDragEnd}
              draggable
            >
              <MarkerPoint color={draggablePoint.color} />
            </Marker>
          )}

          <Source id='bookings' type='geojson' data={bookings}>
            <Layer
              {...layers.bookings}
              onHover={isActive ? null : this.onLayerOver}
              onLeave={isActive ? null : this.onLayerLeave}
            />
            <Layer
              {...layers.bookings_active}
              onHover={this.onLayerOver}
              onLeave={this.onLayerLeave}
            />
            <Layer
              {...layers.bookings_circle}
              onHover={isActive ? null : this.onLayerOver}
              onLeave={isActive ? null : this.onLayerLeave}
            />
            <Layer
              {...layers.bookings_circle_active}
              onHover={this.onLayerOver}
              onLeave={this.onLayerLeave}
            />
          </Source>

          {!window.GEODISC_UI_COMMUTE_OFFER_WALKING_ROUTE_DISABLE && (
            <Source id='walkingRoutes' type='geojson' data={walkingRoutes}>
              <Layer {...layers.walkingRoutes} />
            </Source>
          )}

          <Source id='stops' type='geojson' data={stops}>
            <Layer
              {...layers.stops}
              onHover={this.onLayerOver}
              onLeave={this.onLayerLeave}
              filter={['in', 'stop_type', ...busStopsVisible]}
            />
            <Layer
              {...layers.stops_label}
              filter={['in', 'stop_type', ...busStopsVisible]}
            />
          </Source>

          <Source
            id='editableBookings'
            type='geojson'
            data={editableBookingsSource}
          >
            <Layer
              id='editableBookings'
              type='circle'
              source='editableBookings'
              paint={{
                'circle-radius': 12,
                'circle-color': '#0077c8'
              }}
            />
          </Source>
          {popupData && getPopup(hoveredLayerId, popupData)}
          {clickPopup && getClickPopup(clickPopup, this.onMapClick)}
          {hoveredStateId && hoveredLayerId && (
            <FeatureState
              id={hoveredStateId}
              source={hoveredLayerId}
              state={{ hover: true }}
            />
          )}
        </MapGL>
      </Container>
    );
  }
}

export default Map;
