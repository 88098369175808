import moment from 'moment';
import debug from 'utils/debug';
import { api$CommuteOffer as api } from 'api';
import { commuteSchedule$GenerateOffersForDate } from './GenerateOffersForDate';
import { commuteSchedule$FixedScheduleFromOffers } from './FixedScheduleFromOffers';

const { METHOD } = debug('m:CommuteSchedule:FixedScheduleGenerators');

// eslint-disable-next-line import/prefer-default-export
export const commuteSchedule$FixedScheduleGenerators = {
  'Every passenger takes a taxi': async (data, timezone, opts) => {
    METHOD('TaxiRides:Request', { data, timezone, opts });

    const options = opts || {};

    const {
      human_readable_uids,
      vehicle_passenger,
      vehicle_wheelchair,
      unassigned_only,
      engineSettingsBase,
      time_limit_ms,
      setProgress
    } = options;

    const currentDate = moment();
    const currentDay = currentDate.format('ddd').toLowerCase();

    const commuteOffersForPassengers = await commuteSchedule$GenerateOffersForDate(
      data.map(item => ({
        ...item,
        calculation_group: item.name,
        [currentDay]: true
      })),
      currentDate,
      timezone,
      {
        areCalculationGroupsEnabled: true,
        areMutuallyExclusiveGroupsEnabled: false,
        areSpecialDemandsEnabled: false,
        human_readable_uids,
        vehicle_passenger,
        vehicle_wheelchair,
        unassigned_only,
        engineSettingsBase,
        max_slack: 0,
        time_limit_ms
      }
    );
    METHOD('TaxiRides:commuteOffersForPassengers', {
      commuteOffersForPassengers
    });

    const calculatedOffersForPassengers = await api.scheduleCommuteOffers(
      commuteOffersForPassengers,
      {
        ignoreErrors: false,
        setProgress
      }
    );
    METHOD('TaxiRides:calculatedOffersForPassengers', {
      calculatedOffersForPassengers
    });

    const fixedSchedule = commuteSchedule$FixedScheduleFromOffers(
      calculatedOffersForPassengers,
      timezone
    );
    METHOD('TaxiRides:Success', {
      calculatedOffersForPassengers,
      fixedSchedule
    });

    return {
      schedule: fixedSchedule,
      offers: calculatedOffersForPassengers
    };
  },
  'Sample day with all passengers': async (data, timezone, opts) => {
    METHOD('SingleDayWithAllPassengers:Request', { data, timezone, opts });

    const options = opts || {};

    const {
      human_readable_uids,
      vehicle_passenger,
      vehicle_wheelchair,
      unassigned_only,
      engineSettingsBase,
      time_limit_ms,
      setProgress,
      areCalculationGroupsEnabled,
      areMutuallyExclusiveGroupsEnabled,
      areSpecialDemandsEnabled
    } = options;

    const currentDate = moment();
    const currentDay = currentDate.format('ddd').toLowerCase();

    const commuteOffersForPassengers = await commuteSchedule$GenerateOffersForDate(
      data.map(item => ({
        ...item,
        [currentDay]: true
      })),
      currentDate,
      timezone,
      {
        areCalculationGroupsEnabled,
        areMutuallyExclusiveGroupsEnabled,
        areSpecialDemandsEnabled,
        human_readable_uids,
        vehicle_passenger,
        vehicle_wheelchair,
        unassigned_only,
        engineSettingsBase,
        max_slack: 180,
        time_limit_ms
      }
    );
    METHOD('SingleDayWithAllPassengers:commuteOffersForPassengers', {
      commuteOffersForPassengers
    });

    const calculatedOffersForPassengers = await api.scheduleCommuteOffers(
      commuteOffersForPassengers,
      {
        ignoreErrors: false,
        setProgress
      }
    );
    METHOD('SingleDayWithAllPassengers:calculatedOffersForPassengers', {
      calculatedOffersForPassengers
    });

    const fixedSchedule = await commuteSchedule$FixedScheduleFromOffers(
      calculatedOffersForPassengers,
      timezone
    );
    METHOD('SingleDayWithAllPassengers:Success', {
      fixedSchedule
    });

    return {
      schedule: fixedSchedule,
      offers: calculatedOffersForPassengers
    };
  },
  Disabled: async () => {
    METHOD('Disabled');

    return null;
  }
};
