import { fromJS } from 'immutable';
import debug from 'utils/debug';
import { STOPS_FETCH_SUCCESS } from './actions';

const { REDUX_EVENT_HANDLER } = debug('m:entities:index');

export const initialState = fromJS({
  stops: null
});

const reducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case STOPS_FETCH_SUCCESS: {
      REDUX_EVENT_HANDLER(type, payload);

      const { stops, stopsByType } = payload;

      return state.set('stopsByType', stopsByType).set(
        'stops',
        stops.features.reduce((acc, item) => {
          const { code } = item.properties;
          acc[code] = item;
          return acc;
        }, {})
      );
    }
    default:
      return state;
  }
};

export default reducer;
