import createImmutableSelector from 'create-immutable-selector';
import { matchPath } from 'react-router-dom';

import routes from 'components/App/routes';

const routerSelector = state => state.get('router');

export const locationSelector = createImmutableSelector(
  routerSelector,
  router => router.get('location')
);

export const routeParamsSelector = createImmutableSelector(
  locationSelector,
  (location) => {
    if (!location) return {};

    const activeRoute =
      routes.find(route => matchPath(location.get('pathname'), route)) || {};

    const match = matchPath(location.get('pathname'), activeRoute);
    return match.params;
  }
);

export const routeIdSelector = createImmutableSelector(
  routeParamsSelector,
  params => params.id && parseInt(params.id, 10)
);

export const routePageSelector = createImmutableSelector(
  routeParamsSelector,
  params => (params.page && parseInt(params.page, 10)) || 1
);
