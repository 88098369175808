// @flow
import * as React from 'react';
import Container from './Container';
import Result from './Result';

type Props = {
  vehicles: Array<Vehicle>,
  bookings: Array<Booking>,
  setActiveVehicleId: Function,
  setActiveBookingId: Function,
  flyToSimulationActiveBooking: Function,
  flyToSimulationVehicle: Function
};

const SearchResults = (props: Props) => {
  const {
    vehicles,
    bookings,
    setActiveVehicleId,
    setActiveBookingId,
    flyToSimulationActiveBooking,
    flyToSimulationVehicle
  } = props;

  const onVehicleClick = id => () => {
    setActiveVehicleId(id);
    flyToSimulationVehicle(id);
  };

  const onBookingClick = id => () => {
    setActiveBookingId(id);
    flyToSimulationActiveBooking(id);
  };

  return (
    <Container>
      {vehicles.map(vehicle => (
        <Result
          type='button'
          key={vehicle.agent_id}
          onClick={onVehicleClick(vehicle.agent_id)}
        >
          {`Vehicle #${vehicle.agent_id}`}
        </Result>
      ))}
      {bookings.map(booking => (
        <Result
          type='button'
          key={booking.uid}
          onClick={onBookingClick(booking.uid)}
        >
          {`Booking #${booking.uid}`}
        </Result>
      ))}
    </Container>
  );
};

export default React.memo<Props>(SearchResults);
