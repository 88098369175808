export default {
  mon: {
    name: 'Monday',
    shortName: 'Mon'
  },
  tue: {
    name: 'Tuesday',
    shortName: 'Tue'
  },
  wed: {
    name: 'Wednersday',
    shortName: 'Wed'
  },
  thu: {
    name: 'Thuesday',
    shortName: 'Thu'
  },
  fri: {
    name: 'Friday',
    shortName: 'Fri'
  },
  sat: {
    name: 'Saturday',
    shortName: 'Sat'
  },
  sun: {
    name: 'Sunday',
    shortName: 'Sun'
  }
};
