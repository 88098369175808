import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { branch, compose, renderComponent } from 'recompose';
import createImmutableSelector from 'create-immutable-selector';

import { updateSimulation } from 'modules/simulations/actions';
import { toggleSelectedSimulations } from 'modules/ui/actions';

import { listSelector } from 'modules/simulations/selectors';
import {
  selectedSimulationIdsSelector,
  simulationsErrorSelector
} from 'modules/ui/selectors';

import Error from 'components/Error';
import Loading from 'components/Loading';

import Body from './Body';

const mapStateToProps = createImmutableSelector(
  listSelector,
  selectedSimulationIdsSelector,
  simulationsErrorSelector,
  (list, selectedIds, error) => ({
    list,
    selectedIds,
    error
  })
);

const mapDispatchToProps = {
  toggleSelectedSimulations,
  updateSimulation
};

const withBranch = compose(
  branch(({ error }) => error, renderComponent(Error)),
  branch(({ list }) => !list, renderComponent(Loading))
);

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps
);

const enhance = compose(
  withConnect,
  withBranch,
  withRouter
);

export default enhance(Body);
