import React, { PureComponent } from 'react';
import debug from 'utils/debug';

import {
  filterCommuteOfferByVehicle,
  diffCommuteOffers
} from 'utils/CommuteOffer';

import Container from './Container';
import Row from './Row';

const { METHOD } = debug('c:ModalWindow:CommuteOfferVehicleUpdates');

const colors = {
  ' ': 'black',
  '-': 'red',
  '+': 'green'
};

class CommuteOfferVehicleUpdates extends PureComponent {
  state = {
    updates: []
  };

  componentDidMount = () => {
    METHOD('componentDidMount', { props: this.props });

    const { originalOffer, currentOffer, id } = this.props;

    const o = filterCommuteOfferByVehicle(originalOffer, id);
    const c = filterCommuteOfferByVehicle(currentOffer, id);

    this.setState({
      updates: diffCommuteOffers(id || '---', o, c, { color: false }).split(
        '\n'
      )
    });
  };

  render() {
    METHOD('render', { props: this.props, state: this.state });

    const { updates } = this.state;

    return (
      <Container>
        {updates.map((line, index) => {
          const c = line[0] || ' ';
          return (
            <Row key={`line-${index}`} fontColor={colors[c] || 'black'}>
              {line}
            </Row>
          );
        })}
      </Container>
    );
  }
}

export default CommuteOfferVehicleUpdates;
