import createImmutableSelector from 'create-immutable-selector';

import { createSelector } from 'reselect';
import { featureCollection, point, lineString } from '@turf/helpers';
import lineSlice from '@turf/line-slice';
import lineSplit from '@turf/line-split';
import nearestPointOnLine from 'utils/nearest-point-on-line';
import {
  getBookingType,
  getWalkingRouteType,
  getRouteCoordinates
} from 'utils/CommuteOffer';
import debug from 'utils/debug';
import walkingRouting from 'config/walkingRouting';
import { fetchJSON } from 'api/net';

import {
  activeBookingIdSelector,
  activeRouteStopSelector,
  activeVehicleIdsSelector,
  editableBookingIdSelector,
  routingEngineSelector
} from '../ui/selectors';

import {
  filteredBookingsSelector,
  commuteOfferCurrentDataSelector,
  editableBookingsSelector,
  nodesSelector,
  filteredVehiclesSelector,
  commuteOfferRoutesSelector
} from '../commuteOffer/selectors';

const { DEBUG, TRACE, COUNTER, METHOD, FUNCTION } = debug('m:maps:selectors');

global.WALKING_ROUTES_SN = 1;
global.WALKING_ROUTES = {};
global.WALKING_ROUTES_BY_IDS = {};

const incSerialNumber = () => {
  global.WALKING_ROUTES_SN += 1;
  METHOD('incSerialNumber', { serialNumber: global.WALKING_ROUTES_SN });
};

export const mapsSelector = state =>
  FUNCTION('mapsSelector', { state, $fn: { state: () => state.toJS() } }, () =>
    state.get('maps'));

export const datasetViewportSelector = createImmutableSelector(
  mapsSelector,
  maps =>
    FUNCTION('datasetViewportSelector', { maps }, () =>
      maps.getIn(['dataset', 'viewport']))
);

export const commuteOfferViewportSelector = createImmutableSelector(
  mapsSelector,
  maps =>
    FUNCTION('commuteOfferViewportSelector', { maps }, () =>
      maps.getIn(['commuteOffer', 'viewport']))
);

export const simulationViewportSelector = createImmutableSelector(
  mapsSelector,
  maps =>
    FUNCTION('simulationViewportSelector', { maps }, () =>
      maps.getIn(['simulation', 'viewport']))
);

export const geofencesViewportSelector = createImmutableSelector(
  mapsSelector,
  maps =>
    FUNCTION('geofencesViewportSelector', { maps }, () =>
      maps.getIn(['geofences', 'viewport']))
);

export const newGeofenceViewportSelector = createImmutableSelector(
  mapsSelector,
  maps =>
    FUNCTION('newGeofenceViewportSelector', { maps }, () =>
      maps.getIn(['newGeofence', 'viewport']))
);

// commuteOffer sources
export const bookingsSourceSelector = createSelector(
  filteredBookingsSelector,
  activeBookingIdSelector,
  activeVehicleIdsSelector,
  commuteOfferCurrentDataSelector,
  filteredVehiclesSelector,
  (bookings, activeBookingId, activeVehicleIds, data) => {
    COUNTER('bookingsSourceSelector');

    if (!data) {
      return featureCollection([]);
    }

    const bookingCoordinates = data.stateless_api_request_data.bookings;

    return featureCollection(
      bookings
        ? bookings.reduce((acc, booking) => {
            const saBooking = bookingCoordinates[booking.uid];
            if (typeof saBooking === 'undefined') {
              DEBUG(
                `Booking "${booking.uid}" not found in stateless_api_request_data`
              );
              return acc;
            }

            if (
              !booking.assigned_vehicle_id ||
              activeVehicleIds.includes(booking.assigned_vehicle_id) ||
              !activeVehicleIds.length
            ) {
              acc.push(
                point(
                  [
                    bookingCoordinates[booking.uid].pickup_location_lon ||
                      bookingCoordinates[booking.uid].pickup_lon,
                    bookingCoordinates[booking.uid].pickup_location_lat ||
                      bookingCoordinates[booking.uid].pickup_lat
                  ],
                  {
                    ...booking,
                    uid: booking.uid,
                    type: 'pickup',
                    noActive: !activeVehicleIds.length,
                    isActive:
                      activeVehicleIds.includes(booking.assigned_vehicle_id) ||
                      !booking.assigned_vehicle_id,
                    status: getBookingType(
                      activeVehicleIds.includes(booking.assigned_vehicle_id),
                      activeBookingId === booking.uid,
                      booking.assigned_vehicle_id
                    )
                  }
                )
              );

              acc.push(
                point(
                  [
                    bookingCoordinates[booking.uid].dropoff_location_lon ||
                      bookingCoordinates[booking.uid].dropoff_lon,
                    bookingCoordinates[booking.uid].dropoff_location_lat ||
                      bookingCoordinates[booking.uid].dropoff_lat
                  ],
                  {
                    ...booking,
                    uid: booking.uid,
                    type: 'dropoff',
                    noActive: !activeVehicleIds.length,
                    isActive:
                      activeVehicleIds.includes(booking.assigned_vehicle_id) ||
                      !booking.assigned_vehicle_id,
                    status: getBookingType(
                      activeVehicleIds.includes(booking.assigned_vehicle_id),
                      activeBookingId === booking.uid,
                      booking.assigned_vehicle_id
                    )
                  }
                )
              );
            }

            return acc;
          }, [])
        : []
    );
  }
);

export const vehiclesStopSourceSelector = createSelector(
  filteredVehiclesSelector,
  activeVehicleIdsSelector,
  activeRouteStopSelector,
  (vehicles, activeVehicleIds, activeRouteStop) => {
    COUNTER('vehiclesStopSourceSelector');

    return vehicles
      ? featureCollection(
          vehicles.reduce(
            (acc, { agent_id, route, color, activeColor }, iVehicles) =>
              acc.concat(
                route.map((item, i) => {
                  const id = parseInt(`${iVehicles}${i}`, 10) + 1;

                  return {
                    ...point([item.lon, item.lat], {
                      ...item,
                      agent_id,
                      color,
                      activeColor,
                      noActive: !activeVehicleIds.length,
                      isActive: activeVehicleIds.includes(agent_id),
                      isActiveStop:
                        activeRouteStop === item.id &&
                        activeVehicleIds.includes(agent_id)
                    }),
                    id
                  };
                })
              ),
            []
          )
        )
      : featureCollection([]);
  }
);

export const routeSourceSelector = createSelector(
  filteredVehiclesSelector,
  activeVehicleIdsSelector,
  commuteOfferRoutesSelector,
  routingEngineSelector,
  (vehicles, activeVehicleIds, routes, routingEngine) => {
    return FUNCTION(
      'routeSourceSelector',
      { vehicles, activeVehicleIds, routes, routingEngine },
      () => {
        if (!vehicles) {
          return featureCollection([]);
        }

        if (activeVehicleIds.length) {
          return featureCollection(
            vehicles
              .reduce((acc, vehicle) => {
                const id = vehicle.agent_id;
                TRACE($ => $(`Processing vehicle ${id}...`));

                const route = routes.get(id);

                if (!route || !route.routes) {
                  TRACE($ => $(`Route ${id} not found`));
                  return acc;
                }

                const routeCoordinates =
                  route.routes &&
                  route.routes.length &&
                  route.routes[0].geometry.coordinates;
                // TRACE($ => $('Coordinates:', routeCoordinates));

                const segments =
                  vehicle.route.length >= 2 &&
                  vehicle.route.reduce((sAcc, stop, i) => {
                    const isLastStop = i + 1 === vehicle.route.length - 1;

                    const nextStop = vehicle.route[i + 1];

                    const params = {
                      position: i,
                      noActive: !activeVehicleIds.length,
                      isActive: activeVehicleIds.includes(id)
                    };

                    if (nextStop) {
                      if (
                        route &&
                        routeCoordinates &&
                        routingEngine !== 'euclidean'
                      ) {
                        const line = lineString(routeCoordinates);
                        TRACE($ => $('Line:', line));

                        if (isLastStop) {
                          const nearestStop = nearestPointOnLine(
                            line,
                            point([stop.lon, stop.lat])
                          );
                          TRACE($ => $('Nearest stop:', nearestStop));

                          const splitLines = lineSplit(line, nearestStop);
                          TRACE($ => $('Splited lines:', splitLines));

                          const segment = splitLines.features[1] || line;
                          TRACE($ => $('Segment:', segment));

                          sAcc.push({
                            ...segment,
                            properties: {
                              ...vehicle,
                              ...params
                            }
                          });
                        } else {
                          const segment = lineSlice(
                            point([stop.lon, stop.lat]),
                            point([nextStop.lon, nextStop.lat]),
                            line
                          );

                          sAcc.push({
                            ...segment,
                            properties: {
                              ...vehicle,
                              ...params
                            }
                          });
                        }
                      } else {
                        const segment = lineString(
                          [[stop.lon, stop.lat], [nextStop.lon, nextStop.lat]],
                          {
                            ...vehicle,
                            ...params
                          }
                        );

                        sAcc.push(segment);
                      }
                    }
                    return sAcc;
                  }, []);
                TRACE($ => $('Segments:', segments));
                return acc.concat(segments);
              }, [])
              .filter(feature => !!feature)
          );
        }

        return featureCollection(
          vehicles.map((vehicle) => {
            const id = vehicle.agent_id;

            const route = routes.get(id);

            if (
              routingEngine !== 'euclidean' &&
              route &&
              route.routes &&
              route.routes.length
            ) {
              return lineString(route.routes[0].geometry.coordinates, {
                ...vehicle,
                noActive: !activeVehicleIds.length,
                isActive: activeVehicleIds.includes(id)
              });
            }

            return (
              vehicle.route.length >= 2 &&
              lineString(vehicle.route.map(item => [item.lon, item.lat]), {
                ...vehicle,
                noActive: !activeVehicleIds.length,
                isActive: activeVehicleIds.includes(id)
              })
            );
          })
        );
      }
    );
  }
);

const routingEngineDrivers = {
  here: {
    fetch: async (route, opts) => {
      METHOD('routing:here:fetch', { route, opts });

      const hereUrl = window.GEODISC_WALKING_HERE_ROUTE_URL;
      const hereAppId = window.GEODISC_WALKING_HERE_APP_ID;
      const hereAppCode = window.GEODISC_WALKING_HERE_APP_CODE;
      const hereMode = opts.mode || window.GEODISC_WALKING_HERE_MODE;

      const coordinates = route
        .map((herePoint, index) => {
          return `waypoint${index}=geo!${herePoint.lat},${herePoint.lon}`;
        }, [])
        .join('&');

      // eslint-disable-next-line
      const url = `${hereUrl}/calculateroute.json?app_id=${hereAppId}&app_code=${hereAppCode}&${coordinates}&mode=${hereMode}`;

      const responseJSON = await fetchJSON(url);

      DEBUG('Response:', responseJSON);

      const responseRoute =
        responseJSON.response &&
        responseJSON.response.route &&
        responseJSON.response.route[0];

      const res =
        responseRoute && responseRoute.waypoint
          ? {
              routes: [
                {
                  duration: responseRoute.leg.reduce(
                    (acc, item) => acc + item.travelTime,
                    0
                  ),
                  distance: responseRoute.leg.reduce(
                    (acc, item) => acc + item.length,
                    0
                  ),
                  geometry: {
                    coordinates: global.GEODISC_UI_COMMUTE_OFFER_WALKING_ROUTE_DISPLAY_EUCLIDEAN
                      ? route.map(item => [item.lon, item.lat])
                      : responseRoute.leg.reduce((legAcc, leg) => {
                          const maneuver = leg.maneuver.reduce(
                            (maneuverAcc, maneuverPoint) => {
                              maneuverAcc.push([
                                maneuverPoint.position.longitude,
                                maneuverPoint.position.latitude
                              ]);
                              return maneuverAcc;
                            },
                            []
                          );
                          return [...legAcc, ...maneuver];
                        }, []),
                    type: 'LineString'
                  },
                  legs: responseRoute.leg.map(leg => ({
                    duration: leg.travelTime,
                    distance: leg.length
                  }))
                }
              ]
            }
          : {
              routes: [
                {
                  duration: 0,
                  distance: 0,
                  geometry: {
                    coordinates: route.map(item => [item.lon, item.lat]),
                    type: 'LineString'
                  },
                  legs: route.map(() => ({ duration: 0 }))
                }
              ]
            };

      METHOD('routing:here:fetch:result', {
        route,
        opts,
        responseJSON,
        data: res
      });
      return res;
    }
  }
};

export const walkingRoutesSourceSelector = createSelector(
  vehiclesStopSourceSelector,
  bookingsSourceSelector,
  activeBookingIdSelector,
  activeVehicleIdsSelector,
  (vehicles, bookings, activeBookingId, activeVehicleIds) => {
    METHOD('walkingRoutesSourceSelector', {
      a: 'call',
      p: { vehicles, bookings, activeBookingId, activeVehicleIds }
    });

    const allLines = vehicles.features
      .filter(
        vehicle =>
          vehicle.geometry.coordinates[0] && vehicle.geometry.coordinates[1]
      )
      .reduce((acc, vehicle) => {
        // METHOD('walkingRoutesSourceSelector', {
        //   a: 'vehicles.features.reduce',
        //   vehicle
        // });
        const { node_type } = vehicle.properties;

        if (node_type === 'point') {
          return acc;
        }

        const bookingsIds = vehicle.properties.bookings
          .filter(booking => !booking.$isPhantom)
          .map(item => item.id);

        const filteredBookings = bookings.features.filter(
          item =>
            !item.$isPhantom &&
            bookingsIds.includes(item.properties.uid) &&
            node_type === item.properties.type
        );

        const lines = filteredBookings
          .filter(
            booking =>
              booking.geometry.coordinates[0] && booking.geometry.coordinates[1]
          )
          .map((booking) => {
            // METHOD('walkingRoutesSourceSelector', {
            //   a: 'filteredBookings.map',
            //   booking
            // });

            const properties = {
              noActive: !activeVehicleIds.length,
              status: getWalkingRouteType(
                vehicle.properties.isActive,
                vehicle.properties.isActiveStop,
                activeBookingId === booking.properties.uid
              )
            };

            const bookingId = booking.properties.uid;
            const vehicleId = vehicle.properties.uid;
            const routeId = `${bookingId}:${vehicleId}`;

            const coordinates = [
              {
                lon: booking.geometry.coordinates[0],
                lat: booking.geometry.coordinates[1]
              },
              {
                lon: vehicle.geometry.coordinates[0],
                lat: vehicle.geometry.coordinates[1]
              }
            ];

            const routeCoordinates = getRouteCoordinates(
              coordinates,
              true,
              false
            );

            global.WALKING_ROUTES = global.WALKING_ROUTES || {};
            global.WALKING_ROUTES_BY_IDS = global.WALKING_ROUTES_BY_IDS || {};

            if (global.GEODISC_WALKING_ROUTING_ENGINE === 'euclidean') {
              global.WALKING_ROUTES[routeCoordinates] = {
                route: {
                  routes: [
                    {
                      duration: 0,
                      geometry: {
                        coordinates: [
                          booking.geometry.coordinates,
                          vehicle.geometry.coordinates
                        ],
                        type: 'LineString'
                      },
                      legs: [{ duration: 0 }]
                    }
                  ]
                }
              };
            }

            if (!global.WALKING_ROUTES[routeCoordinates]) {
              if (global.GEODISC_WALKING_ROUTING_ENGINE !== 'osrm') {
                const driver =
                  routingEngineDrivers[global.GEODISC_WALKING_ROUTING_ENGINE];

                global.WALKING_ROUTES[routeCoordinates] = {};

                global.WALKING_ROUTES[routeCoordinates].result = driver
                  .fetch(coordinates, {})
                  .then(
                    (route) => {
                      METHOD(
                        [
                          'walkingRoutesSourceSelector',
                          global.GEODISC_WALKING_ROUTING_ENGINE,
                          'resolved'
                        ],
                        {
                          vehicleId,
                          bookingId,
                          route
                        }
                      );
                      global.WALKING_ROUTES[routeCoordinates].route = route;
                      incSerialNumber();
                    },
                    (reason) => {
                      METHOD(
                        [
                          'walkingRoutesSourceSelector',
                          global.GEODISC_WALKING_ROUTING_ENGINE,
                          'rejected'
                        ],
                        {
                          vehicleId,
                          bookingId,
                          reason
                        }
                      );
                      global.WALKING_ROUTES[routeCoordinates].error = reason;
                      incSerialNumber();
                    }
                  );

                global.WALKING_ROUTES_BY_IDS[routeId] =
                  global.WALKING_ROUTES[routeCoordinates];
              } else {
                const { url } = walkingRouting[
                  global.GEODISC_WALKING_ROUTING_ENGINE
                ];

                global.WALKING_ROUTES[routeCoordinates] = {
                  addr: url(coordinates, {}),
                  opts: {
                    booking,
                    bookingId,
                    vehicle,
                    vehicleId,
                    properties
                  }
                };

                global.WALKING_ROUTES[routeCoordinates].result = fetchJSON(
                  global.WALKING_ROUTES[routeCoordinates].addr
                ).then(
                  (route) => {
                    METHOD('walkingRoutesSourceSelector:resolved', {
                      vehicleId,
                      bookingId,
                      route
                    });
                    setImmediate(() => {
                      global.WALKING_ROUTES[routeCoordinates].route = route;
                      if (
                        global.GEODISC_UI_COMMUTE_OFFER_WALKING_ROUTE_DISPLAY_EUCLIDEAN
                      ) {
                        global.WALKING_ROUTES[
                          routeCoordinates
                        ].route.routes[0].geometry.coordinates = [
                          booking.geometry.coordinates,
                          vehicle.geometry.coordinates
                        ];
                      }
                      incSerialNumber();
                    });
                  },
                  (reason) => {
                    METHOD('walkingRoutesSourceSelector:rejected', {
                      vehicleId,
                      bookingId,
                      reason
                    });
                    setImmediate(() => {
                      global.WALKING_ROUTES[routeCoordinates].error = reason;
                      incSerialNumber();
                    });
                  }
                );

                global.WALKING_ROUTES_BY_IDS[routeId] =
                  global.WALKING_ROUTES[routeCoordinates];
              }
            }

            const walkingRoute =
              global.WALKING_ROUTES[routeCoordinates] &&
              global.WALKING_ROUTES[routeCoordinates].route;
            if (walkingRoute && walkingRoute.routes && walkingRoute.routes[0]) {
              METHOD('walkingRoutesSourceSelector', {
                a: 'route',
                coordinates: walkingRoute.routes[0].geometry.coordinates
              });
              return lineString(
                walkingRoute.routes[0].geometry.coordinates,
                properties
              );
            }
            METHOD('walkingRoutesSourceSelector', {
              a: 'line',
              booking,
              bookingId,
              vehicle,
              vehicleId,
              properties
            });
            return lineString(
              [booking.geometry.coordinates, vehicle.geometry.coordinates],
              properties
            );
          });

        return [...acc, ...lines];
      }, []);

    const res = featureCollection(allLines);
    METHOD('walkingRoutesSourceSelector', { a: 'result', data: res });
    return res;
  }
);

export const nodesSourceSelector = createSelector(
  nodesSelector,
  editableBookingIdSelector,
  (nodes, editableBookingId) => {
    COUNTER('nodesSourceSelector');

    return featureCollection(
      nodes && editableBookingId
        ? nodes
            .filter(item => item.booking_uid === editableBookingId)
            .map((item, i) => ({
              ...point([item.lon, item.lat], item),
              id: i
            }))
        : []
    );
  }
);

export const editableBookingsSourceSelector = createSelector(
  editableBookingsSelector,
  activeVehicleIdsSelector,
  (editableBookings, activeVehicleIds) => {
    COUNTER('editableBookingsSourceSelector');

    if (
      !editableBookings ||
      !activeVehicleIds.length ||
      !editableBookings[activeVehicleIds[0]] ||
      !editableBookings[activeVehicleIds[0]][0]
    ) {
      return featureCollection([]);
    }

    const { bookingPickup, bookingDropoff } = editableBookings[
      activeVehicleIds
    ][0];

    return featureCollection([
      point(bookingPickup, { type: 'pickup' }),
      point(bookingDropoff, { type: 'dropoff' })
    ]);
  }
);
