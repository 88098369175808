import moment from 'moment';
import debug from 'utils/debug';

const { METHOD } = debug(
  'm:CommuteOffers:Trips:ConsolidatedTableFromCommuteOffer'
);

// eslint-disable-next-line import/prefer-default-export
export const commuteOffers$Trips$ConsolidatedTableFromCommuteOffer = (
  commuteOffer
) => {
  METHOD('Request', { commuteOffer });

  const vehicles = commuteOffer.stateless_api_request_data.vehicles;
  METHOD('vehicles', { vehicles });

  const vehiclesWithTripNames = vehicles.map((vehicle, index) => ({
    ...vehicle,
    $tripName: `Trip #${index + 1}`
  }));

  const resultTable = vehiclesWithTripNames.reduce(
    (resultTableMemo, vehicle) => {
      const route = commuteOffer.result.vehicles[vehicle.agent_id];
      METHOD('resultTable:route', { vehicle, route });

      if (!route) {
        return resultTableMemo;
      }

      const routeStops0 = route.reduce(
        (routeStopsMemo, node) => {
          const { lastStop } = routeStopsMemo;

          const booking =
            commuteOffer.stateless_api_request_data.bookings[node.booking_uid];

          if (!routeStopsMemo.lastStop || lastStop.stop_id !== node.stop_id) {
            const {
              service_time,
              slack,
              stop_id,
              scheduled_ts,
              location_code,
              location_name,
              lon,
              lat
            } = node;
            const newStops = routeStopsMemo.lastStop
              ? [...routeStopsMemo.stops, routeStopsMemo.lastStop]
              : [];
            return {
              stops: newStops,
              lastStop: {
                nodes: [node],
                pickup_passengers:
                  node.node_type === 'pickup' ? [booking.name || node.uid] : [],
                dropoff_passengers:
                  node.node_type === 'dropoff'
                    ? [booking.name || node.uid]
                    : [],
                service_time,
                slack,
                stop_id,
                scheduled_ts,
                location_code,
                location_name,
                lon,
                lat
              }
            };
          }

          const {
            nodes,
            service_time,
            slack,
            pickup_passengers,
            dropoff_passengers,
            stop_id,
            scheduled_ts,
            location_code,
            location_name,
            lon,
            lat
          } = routeStopsMemo.lastStop;

          return {
            ...routeStopsMemo,
            lastStop: {
              ...routeStopsMemo.lastStop,
              nodes: [...nodes, node],
              service_time: service_time + node.service_time,
              slack: slack + node.slack,
              pickup_passengers:
                node.node_type === 'pickup'
                  ? [...pickup_passengers, booking.name || node.uid]
                  : pickup_passengers,
              dropoff_passengers:
                node.node_type === 'dropoff_passengers'
                  ? [...dropoff_passengers, booking.name || node.uid]
                  : dropoff_passengers,
              stop_id,
              scheduled_ts,
              location_code,
              location_name,
              lon,
              lat
            }
          };
        },
        { stops: [], lastNode: null }
      );
      METHOD('routeStops:routeStops0', {
        vehicle,
        route,
        routeStops0
      });

      const routeStops = routeStops0.lastStop
        ? [...routeStops0.stops, routeStops0.lastStop]
        : [];

      const routeSource = routeStops;

      const tripRecords = routeSource.map((node) => {
        METHOD('resultTable:node', {
          vehicle,
          route,
          node
        });

        return [
          vehicle.$tripName,
          moment(node.scheduled_ts)
            .tz(global.GEODISC_TIMEZONE)
            .format('HH:mm'),
          node.location_name || '',
          node.pickup_passengers.join(', '),
          node.dropoff_passengers.join(', ')
        ];
      });
      METHOD('resultTable:tripRecords', { vehicle, route, tripRecords });

      return [...resultTableMemo, ...tripRecords];
    },
    []
  );

  METHOD('Success', {
    commuteOffer,
    resultTable
  });

  return resultTable;
};
