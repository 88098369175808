import React, { useState, useEffect } from 'react';
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  ModalButton
} from 'baseui/modal';

import debug from 'utils/debug';

const { METHOD } = debug('c:ModalWindows:InfoMessage');

export default (props) => {
  METHOD('InfoMessage', props);

  const [isOpen, setIsOpen] = useState(false);
  const [title, setTitle] = useState('Info');
  const [message, setMessage] = useState('');

  useEffect(() => {
    window.openInfoMessage = (text, opts) => {
      const options = opts || {};
      setMessage(text);
      setTitle(options.title || 'Info');
      setIsOpen(true);
    };
    window.closeInfoMessage = () => {
      setIsOpen(false);
    };
    return () => {
      window.infoMessage = null;
    };
  });

  function onClose() {
    setIsOpen(false);
  }

  return (
    <React.Fragment>
      <Modal onClose={onClose} isOpen={isOpen}>
        <ModalHeader>{title}</ModalHeader>
        <ModalBody>{message}</ModalBody>
        <ModalFooter>
          <ModalButton onClick={onClose}>Okay</ModalButton>
        </ModalFooter>
      </Modal>
    </React.Fragment>
  );
};
