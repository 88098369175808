import React from 'react';
import BackButton from './BackButton';
import Container from './Container';

const Header = ({ fetchSimulationDataStop, clearSimulation, progress }) => {
  const onClick = () => {
    clearSimulation();
    if (progress < 1) {
      fetchSimulationDataStop();
    }
  };

  return (
    <Container onClick={onClick}>
      <BackButton to='/simulations'>Back to the list</BackButton>
    </Container>
  );
};

export default Header;
