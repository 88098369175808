import debug from 'utils/debug';

import { emptyCommuteOfferJSON } from 'utils/CommuteOffer/defaults';

const { METHOD } = debug('m:CommuteOffer:MergeCommuteOffer');

// eslint-disable-next-line import/prefer-default-export
export const commuteOffer$MergeCommuteOffers = (commuteOffers, opts) => {
  METHOD('Request', {
    commuteOffers
  });

  const options = opts || {};

  const mergeBase = options.mergeBase || JSON.parse(emptyCommuteOfferJSON);

  const mergedOffer = commuteOffers.reduce((dst, src) => {
    METHOD('Merge', { dst, src });

    return {
      ...dst,
      stateless_api_request_data: {
        ...dst.stateless_api_request_data,
        bookings: {
          ...dst.stateless_api_request_data.bookings,
          ...src.stateless_api_request_data.bookings
        },
        nodes: [
          ...dst.stateless_api_request_data.nodes,
          ...src.stateless_api_request_data.nodes
        ],
        vehicles: [
          ...dst.stateless_api_request_data.vehicles,
          ...src.stateless_api_request_data.vehicles
        ],
        engine_settings: {
          ...dst.stateless_api_request_data.engine_settings,
          ...src.stateless_api_request_data.engine_settings
        }
      },
      result: {
        ...dst.result,
        vehicles: {
          ...dst.result.vehicles,
          ...src.result.vehicles
        },
        assigned_bookings: [
          ...dst.result.assigned_bookings,
          ...src.result.assigned_bookings
        ],
        rejected_bookings: [
          ...dst.result.rejected_bookings,
          ...src.result.rejected_bookings
        ]
      }
    };
  }, mergeBase);
  METHOD('Success', { mergedOffer });

  return mergedOffer;
};
