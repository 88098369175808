import { connect } from 'react-redux';
import createImmutableSelector from 'create-immutable-selector';

import { editableBookingsSelector } from 'modules/commuteOffer/selectors';
import {
  clonedVehicle,
  deleteVehicle,
  addPointToRoute,
  addStopToRoute,
  setVehicleStartTime,
  recalculateVehicleTime
} from 'modules/commuteOffer/actions';
import { filteredStopsSelector } from 'modules/entities/selectors';

import {
  activeVehicleIdsSelector,
  activeRouteStopSelector,
  addPointModeSelector,
  addStopModeSelector,
  stopSearchQuerySelector,
  editableVehicleTimeSelector
} from 'modules/ui/selectors';
import {
  setActiveVehicleId,
  cleanActiveVehicleId,
  setEditableVehicleId,
  cleanActiveRouteStop,
  setAddPointMode,
  cleanAddPointMode,
  setAddStopMode,
  setStopSearchQuery,
  cleanAddStopMode,
  toggleEditableTimeMode
} from 'modules/ui/actions';

import Vehicle from './Vehicle';

const mapStateToProps = createImmutableSelector(
  editableBookingsSelector,
  activeVehicleIdsSelector,
  activeRouteStopSelector,
  addPointModeSelector,
  addStopModeSelector,
  filteredStopsSelector,
  stopSearchQuerySelector,
  editableVehicleTimeSelector,
  (
    editableBooking,
    activeVehicleIds,
    activeRouteStop,
    pointEditing,
    addStopMode,
    filteredStops,
    stopSearchQuery,
    isEditableVehicleTime
  ) => ({
    editableBooking,
    activeVehicleIds,
    activeRouteStop,
    pointEditing,
    addStopMode,
    filteredStops,
    stopSearchQuery,
    isEditableVehicleTime
  })
);

const mapDispatchToProps = {
  setActiveVehicleId,
  cleanActiveVehicleId,
  setEditableVehicleId,
  clonedVehicle,
  deleteVehicle,
  addPointToRoute,
  cleanActiveRouteStop,
  setAddPointMode,
  cleanAddPointMode,
  setAddStopMode,
  cleanAddStopMode,
  setStopSearchQuery,
  addStopToRoute,
  setVehicleStartTime,
  recalculateVehicleTime,
  toggleEditableTimeMode
};

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps
);

export default withConnect(Vehicle);
