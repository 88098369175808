const getBackgroundColor = (isSelected, isFocused) => {
  if (isFocused) {
    return '#f6f7f9';
  }

  if (isSelected) {
    return '#e9eaec';
  }

  return 'white';
};

export default {
  control: styles => ({
    ...styles,
    height: '40px',
    width: '100%',
    outline: 'none',
    border: 'none',
    fontSize: '17px',
    color: 'rgb(54, 61, 74)',
    paddingLeft: '20px',
    borderRadius: '10px',
    backgroundColor: '#e9eaec',
    boxShadow: 'none'
  }),
  option: (provided, { isSelected, isFocused }) => ({
    ...provided,
    border: 'none',
    margin: 10,
    color: 'black',
    width: 'calc(100% - 20px)',
    borderRadius: 5,
    backgroundColor: getBackgroundColor(isSelected, isFocused),
    ':active': {
      backgroundColor: '#e9eaec'
    }
  })
};
