import React from 'react';
import { components } from 'react-select';

const Option = props => (
  <components.Option {...props}>
    <div>{props.data.label}</div>
    <div>{props.data.count}</div>
  </components.Option>
);

export default Option;
