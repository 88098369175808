import React, { PureComponent } from 'react';
import LinkButton from './LinkButton';

class FileInput extends PureComponent {
  filesRef = React.createRef();

  onClickHandler = () => {
    this.filesRef.current.click();
  };

  render() {
    return (
      <React.Fragment>
        <input
          ref={this.filesRef}
          type='file'
          id='files'
          value=''
          style={{ display: 'none' }}
          onChange={this.props.onChange}
        />
        <LinkButton onClick={this.onClickHandler}>browse files</LinkButton>
      </React.Fragment>
    );
  }
}

export default FileInput;
