import debug from 'utils/debug';

const { METHOD } = debug('u:file');

export const validateCustomFile = (file, type, ext) => {
  METHOD('validateCustomFile', { file, type, ext });

  if (file.type === type) {
    return true;
  }

  if (!file.type || file.type === '') {
    const extension = file.name
      .split('.')
      .pop()
      .toLowerCase();
    if (extension === ext) {
      return true;
    }
  }

  return null;
};

export const validateCsvFile = file =>
  validateCustomFile(file, 'text/csv', 'csv');
export const validateJsonFile = file =>
  validateCustomFile(file, 'application/json', 'json');

export const exportFile = (data, filename) => {
  const exportedFilename = filename || 'export.zip';

  const blob = new Blob([data]);
  if (navigator.msSaveBlob) {
    // IE 10+
    navigator.msSaveBlob(blob, exportedFilename);
  } else {
    const link = document.createElement('a');
    if (link.download !== undefined) {
      // feature detection
      // Browsers that support HTML5 download attribute
      const url = URL.createObjectURL(blob);
      link.setAttribute('href', url);
      link.setAttribute('download', exportedFilename);
      link.style.visibility = 'hidden';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  }
};

export const exportGeofence = (data, fileTitle) => {
  const exportedFilename = `${fileTitle}.geojson` || 'export.geojson';

  const blob = new Blob([data], { type: 'application/geo+json' });
  if (navigator.msSaveBlob) {
    // IE 10+
    navigator.msSaveBlob(blob, exportedFilename);
  } else {
    const link = document.createElement('a');
    if (link.download !== undefined) {
      // feature detection
      // Browsers that support HTML5 download attribute
      const url = URL.createObjectURL(blob);
      link.setAttribute('href', url);
      link.setAttribute('download', exportedFilename);
      link.style.visibility = 'hidden';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  }
};
