import React from 'react';
import debug from 'utils/debug';

import Row from './Row';
import Container from './Container';

const { METHOD } = debug('p:CommuteOffers:Table:Body');

const Body = ({ list, ...props }) => {
  METHOD('render', { list, ...props });

  return (
    <Container>
      {list.map(value => (
        <Row key={value.id} commuteOffer={value} {...props} />
      ))}
    </Container>
  );
};

export default Body;
