import turfBbox from '@turf/bbox';
import geoViewport from '@mapbox/geo-viewport';
import debug from 'utils/debug';

const { EVENT, TRACE } = debug('utils:maps');

export const getViewportFromGeometry = (geometry) => {
  EVENT('getViewportFromGeometry');
  TRACE($ => $('Geometry:', geometry));

  const bbox = turfBbox(geometry);
  const { center, zoom } = geoViewport.viewport(bbox, [300, 200]);
  const [longitude, latitude] = center;

  return {
    zoom,
    latitude,
    longitude
  };
};

export default getViewportFromGeometry;
