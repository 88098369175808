// commuteOffer
export const TOGGLE_BOOKINGS_HIDDEN = '@ui/TOGGLE_BOOKINGS_HIDDEN';
export const toggleBookingsHidden = () => ({ type: TOGGLE_BOOKINGS_HIDDEN });

export const SET_ACTIVE_VEHICLE_ID = '@@ui/SET_ACTIVE_VEHICLE_ID';
export const setActiveVehicleId = id => ({
  type: SET_ACTIVE_VEHICLE_ID,
  payload: id
});

export const CLEAN_ACTIVE_VEHICLE_ID = '@@ui/CLEAN_ACTIVE_VEHICLE_ID';
export const cleanActiveVehicleId = id => ({
  type: CLEAN_ACTIVE_VEHICLE_ID,
  payload: id
});

export const CLEAN_ALL_ACTIVE_VEHICLE_IDS = '@@ui/CLEAN_ACTIVE_VEHICLE_IDS';
export const cleanAllActiveVehicleId = () => ({
  type: CLEAN_ALL_ACTIVE_VEHICLE_IDS
});

export const SET_ACTIVE_ROUTE_STOP = '@@ui/SET_ACTIVE_ROUTE_STOP';
export const setActiveRouteStop = (id, uid) => ({
  type: SET_ACTIVE_ROUTE_STOP,
  payload: { id, uid }
});

export const CLEAN_ACTIVE_ROUTE_STOP = '@@ui/CLEAN_ACTIVE_ROUTE_STOP';
export const cleanActiveRouteStop = () => ({
  type: CLEAN_ACTIVE_ROUTE_STOP
});

export const SET_ACTIVE_BOOKING_ID = '@@ui/SET_ACTIVE_BOOKING_ID';
export const setActiveBookingId = id => ({
  type: SET_ACTIVE_BOOKING_ID,
  payload: id
});

export const CLEAN_ACTIVE_BOOKING_ID = '@@ui/CLEAN_ACTIVE_BOOKING_ID';
export const cleanActiveBookingId = () => ({
  type: CLEAN_ACTIVE_BOOKING_ID
});

export const SET_EDITABLE_BOOKING_ID = '@@ui/SET_EDITABLE_BOOKING_ID';
export const setEditableBookingId = id => ({
  type: SET_EDITABLE_BOOKING_ID,
  payload: id
});

export const CLEAN_EDITABLE_BOOKING_ID = '@@ui/CLEAN_EDITABLE_BOOKING_ID';
export const cleanEditableBookingId = () => ({
  type: CLEAN_EDITABLE_BOOKING_ID
});

export const SET_EDITABLE_VEHICLE_ID = '@@ui/SET_EDITABLE_VEHICLE_ID';
export const setEditableVehicleId = id => ({
  type: SET_EDITABLE_VEHICLE_ID,
  payload: id
});

export const CLEAR_EDITABLE_VEHICLE_ID = '@@ui/CLEAR_EDITABLE_VEHICLE_ID';
export const clearEditableVehicleId = () => ({
  type: CLEAR_EDITABLE_VEHICLE_ID
});

export const TOGGLE_ACTIVE_STOPS_TYPE = '@@ui/TOGGLE_ACTIVE_STOPS_TYPE';
export const toggleActiveStopsType = id => ({
  type: TOGGLE_ACTIVE_STOPS_TYPE,
  payload: id
});

export const CLEAR_ACTIVE_STOPS_TYPE = '@@ui/CLEAR_ACTIVE_STOPS_TYPE';
export const clearActiveStopsType = () => ({
  type: CLEAR_ACTIVE_STOPS_TYPE
});

export const SEARCH_QUERY_CHANGED = '@@ui/SEARCH_QUERY_CHANGED';
export const changeSearchQuery = value => ({
  type: SEARCH_QUERY_CHANGED,
  payload: value
});

export const BOOKINGS_FILTER_CHANGED = '@@ui/BOOKINGS_FILTER_CHANGED';
export const changeBookingsFilter = value => ({
  type: BOOKINGS_FILTER_CHANGED,
  payload: value
});

export const VEHICLES_FILTER_CHANGED = '@@ui/VEHICLES_FILTER_CHANGED';
export const changeVehiclesFilter = value => ({
  type: VEHICLES_FILTER_CHANGED,
  payload: value
});

export const TOGGLE_ROUTING_ENGINE = '@@ui/TOGGLE_ROUTING_ENGINE';
export const toggleRoutingEngine = id => ({
  type: TOGGLE_ROUTING_ENGINE,
  payload: id
});

export const TOGGLE_WALKING_ROUTING_ENGINE =
  '@@ui/TOGGLE_WALKING_ROUTING_ENGINE';
export const toggleWalkingRoutingEngine = id => ({
  type: TOGGLE_WALKING_ROUTING_ENGINE,
  payload: id
});

export const SET_ADD_POINT_MODE = '@@ui/SET_ADD_POINT_MODE';
export const setAddPointMode = vehicleId => ({
  type: SET_ADD_POINT_MODE,
  payload: vehicleId
});

export const CLEAN_ADD_POINT_MODE = '@@ui/CLEAN_ADD_POINT_MODE';
export const cleanAddPointMode = () => ({
  type: CLEAN_ADD_POINT_MODE
});

export const TOGGLE_EDITABLE_TIME_MODE = '@@ui/TOGGLE_EDITABLE_TIME_MODE';
export const toggleEditableTimeMode = () => ({
  type: TOGGLE_EDITABLE_TIME_MODE
});

export const CLEAN_COMMUTE_OFFER_UI = '@@ui/CLEAN_COMMUTE_OFFER_UI';
export const cleanCommuteOfferUi = () => ({
  type: CLEAN_COMMUTE_OFFER_UI
});

export const SET_DRAGGABLE_POINT = '@@ui/SET_DRAGGABLE_POINT';
export const setDraggablePoint = draggablePoint => ({
  type: SET_DRAGGABLE_POINT,
  payload: draggablePoint
});

export const SET_IS_CHANGED = '@@ui/SET_IS_CHANGED';
export const setIsChanged = value => ({
  type: SET_IS_CHANGED,
  payload: value
});

export const SET_IS_SAVING = '@@ui/SET_IS_SAVING';
export const setIsSaving = value => ({
  type: SET_IS_SAVING,
  payload: value
});

export const REMOVE_DRAGGABLE_POINT = '@@ui/REMOVE_DRAGGABLE_POINT';
export const removeDraggablePoint = () => ({ type: REMOVE_DRAGGABLE_POINT });

export const SET_ADD_STOP_MODE = '@@ui/SET_ADD_STOP_MODE';
export const setAddStopMode = vehicleId => ({
  type: SET_ADD_STOP_MODE,
  payload: vehicleId
});

export const CLEAN_ADD_STOP_MODE = '@@ui/CLEAN_ADD_STOP_MODE';
export const cleanAddStopMode = () => ({
  type: CLEAN_ADD_STOP_MODE
});

export const SET_STOP_SEARCH_QUERY = '@@ui/SET_STOP_SEARCH_QUERY';
export const setStopSearchQuery = text => ({
  type: SET_STOP_SEARCH_QUERY,
  payload: text
});

export const CLEAN_STOP_SEARCH_QUERY = '@@ui/CLEAN_STOP_SEARCH_QUERY';
export const cleanStopSearchQuery = () => ({
  type: CLEAN_STOP_SEARCH_QUERY
});

// datasets
export const CHANGE_DATASETS_SEARCH = '@@ui/CHANGE_DATASETS_SEARCH';
export const changeDatasetsSearch = text => ({
  type: CHANGE_DATASETS_SEARCH,
  payload: text
});

export const CHANGE_DATASETS_ORDERING = '@@ui/CHANGE_DATASETS_ORDERING';
export const changeDatasetsOrdering = id => ({
  type: CHANGE_DATASETS_ORDERING,
  payload: id
});

export const UPDATE_EDITABLE_DATASET = '@@ui/UPDATE_EDITABLE_DATASET';
export const updateEditableDataset = (property, value) => ({
  type: UPDATE_EDITABLE_DATASET,
  payload: { property, value }
});

export const TOGGLE_SELECTED_DATASET = '@@ui/TOGGLE_SELECTED_DATASET';
export const toggleSelectedDatasets = id => ({
  type: TOGGLE_SELECTED_DATASET,
  payload: id
});

export const CLEAR_SELECTED_DATASETS = '@@ui/CLEAR_SELECTED_DATASETS';
export const clearSelectedDatasets = () => ({
  type: CLEAR_SELECTED_DATASETS
});

export const CHANGE_COMMUTE_OFFERS_ORDERING =
  '@@ui/CHANGE_COMMUTE_OFFERS_ORDERING';
export const changeCommuteOffersOrdering = id => ({
  type: CHANGE_COMMUTE_OFFERS_ORDERING,
  payload: id
});

// dataset
export const LAYER_PANEL_CHANGE = '@@ui/LAYER_PANEL_CHANGE';
export const changeLayerPanel = (paramId, value) => ({
  type: LAYER_PANEL_CHANGE,
  payload: { paramId, value }
});

export const LAYER_TOGGLE = '@@ui/LAYER_TOGGLE';
export const toggleLayer = layerId => ({
  type: LAYER_TOGGLE,
  payload: layerId
});

export const CHANGE_DATASET_TIME = '@@ui/CHANGE_DATASET_TIME';
export const changeDatasetTime = time => ({
  type: CHANGE_DATASET_TIME,
  payload: time
});

export const SET_EDITABLE_DATASET = '@@ui/SET_EDITABLE_DATASET';
export const setEditableDataset = dataset => ({
  type: SET_EDITABLE_DATASET,
  payload: dataset
});

export const DATASET_TOGGLE_ALLDAY = '@@ui/DATASET_TOGGLE_ALLDAY';
export const datasetToggleAllDay = () => ({ type: DATASET_TOGGLE_ALLDAY });

// dataset play
export const DATASET_PLAY = '@@ui/DATASET_PLAY';
export const datasetPlay = () => ({ type: DATASET_PLAY });

export const DATASET_STOP = '@@ui/DATASET_STOP';
export const datasetStop = () => ({ type: DATASET_STOP });

export const DATASET_NEXT = '@@ui/DATASET_NEXT';

// geofences
export const TOGGLE_VISIBILITY_GEOFENCE = '@@ui/TOGGLE_VISIBILITY_GEOFENCE';
export const toggleVisibilityGeofence = id => ({
  type: TOGGLE_VISIBILITY_GEOFENCE,
  payload: id
});

export const SET_EDITABLE_GEOFENCE = '@@ui/SET_EDITABLE_GEOFENCE';
export const setEditableGeofence = geofence => ({
  type: SET_EDITABLE_GEOFENCE,
  payload: geofence
});

export const UPDATE_EDITABLE_GEOFENCE = '@@ui/UPDATE_EDITABLE_GEOFENCE';
export const updateEditableGeofence = (property, value) => ({
  type: UPDATE_EDITABLE_GEOFENCE,
  payload: { property, value }
});

export const CHANGE_GEOFENCES_SEARCH = '@@ui/CHANGE_GEOFENCES_SEARCH';
export const changeGeofencesSearch = text => ({
  type: CHANGE_GEOFENCES_SEARCH,
  payload: text
});

export const CHANGE_GEOFENCES_HOVER = '@@ui/CHANGE_GEOFENCES_HOVER';
export const changeGeofencesHover = id => ({
  type: CHANGE_GEOFENCES_HOVER,
  payload: id
});

// simulations
export const CHANGE_SIMULATIONS_ORDERING = '@@ui/CHANGE_SIMULATIONS_ORDERING';
export const changeSimulationsOrdering = id => ({
  type: CHANGE_SIMULATIONS_ORDERING,
  payload: id
});

export const CHANGE_SIMULATIONS_SEARCH = '@@ui/CHANGE_SIMULATIONS_SEARCH';
export const changeSimulationsSearch = text => ({
  type: CHANGE_SIMULATIONS_SEARCH,
  payload: text
});

export const TOGGLE_SELECTED_SIMULATION = '@@ui/TOGGLE_SELECTED_SIMULATION';
export const toggleSelectedSimulations = simulation => ({
  type: TOGGLE_SELECTED_SIMULATION,
  payload: simulation
});

export const CLEAR_SELECTED_SIMULATIONS = '@@ui/CLEAR_SELECTED_SIMULATIONS';
export const clearSelectedSimulations = () => ({
  type: CLEAR_SELECTED_SIMULATIONS
});

export const TOGGLE_COMPARE_MOD_SIMULATIONS =
  '@@ui/TOGGLE_COMPARE_MOD_SIMULATIONS';
export const toggleCompareMod = () => ({
  type: TOGGLE_COMPARE_MOD_SIMULATIONS
});

export const TOGGLE_SELECTED_COMMUTE_OFFERS =
  '@@ui/TOGGLE_SELECTED_COMMUTE_OFFERS';
export const toggleSelectedCommuteOffers = commuteOffer => ({
  type: TOGGLE_SELECTED_COMMUTE_OFFERS,
  payload: commuteOffer
});

export const CLEAR_SELECTED_COMMUTE_OFFERS =
  '@@ui/CLEAR_SELECTED_COMMUTE_OFFERS';
export const clearSelectedCommuteOffers = () => ({
  type: CLEAR_SELECTED_COMMUTE_OFFERS
});

export const OPEN_MODAL_WINDOW = '@@ui/OPEN_MODAL_WINDOW';
export const openPopup = (id, opts) => ({
  type: OPEN_MODAL_WINDOW,
  payload: { id, opts }
});

export const CLOSE_MODAL_WINDOW = '@@ui/CLOSE_MODAL_WINDOW';
export const closePopup = () => ({ type: CLOSE_MODAL_WINDOW });

export const MODAL_WINDOW_SET_PROGRESS = '@@ui/MODAL_WINDOW_SET_PROGRESS';
export const setProgress = (status, progress) => ({
  type: MODAL_WINDOW_SET_PROGRESS,
  payload: { status, progress }
});
