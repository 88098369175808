import { connect } from 'react-redux';
import createImmutableSelector from 'create-immutable-selector';

import { clearSelectedSimulations, toggleCompareMod } from 'modules/ui/actions';

import { countSelector } from 'modules/simulations/selectors';
import { selectedSimulationIdsSelector } from 'modules/ui/selectors';

import Panel from './Panel';

const mapStateToProps = createImmutableSelector(
  countSelector,
  selectedSimulationIdsSelector,
  (count, selectedIds) => ({
    count,
    selectedIds
  })
);

const mapDispatchToProps = {
  clearSelectedSimulations,
  toggleCompareMod
};

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps
);

export default withConnect(Panel);
