import Immutable from 'immutable';
import { push } from 'connected-react-router';
import { call, put, select, takeLatest } from 'redux-saga/effects';
import { api$User as api } from 'api';
import debug from 'utils/debug';
import * as actions from './actions';
import * as datasetsActions from '../datasets/actions';
import * as geofencesActions from '../geofences/actions';
import * as simulationsActions from '../simulations/actions';
import * as mapsActions from '../maps/actions';

import { currentProjectSelector } from './selectors';

const { DEBUG } = debug('m:user:saga');

function* authorization({ payload }) {
  try {
    const { username, isAuto } = payload;

    const userData = yield call(api.getUser, username);
    DEBUG('User:', userData);

    localStorage.setItem(`${global.GEODISC_VERSION.ver}:login`, username);

    let tokenData = { token: '0' };
    if (window.GEODISC_API_WEBSOCKET_AUTHORIZATION_ENABLED) {
      tokenData = yield call(api.getToken);
      DEBUG('Token:', tokenData);
    }

    yield put({
      type: actions.AUTHORIZATION_SUCCESS,
      payload: {
        user: Immutable.fromJS(userData.objects[0]),
        token: Immutable.fromJS(tokenData)
      }
    });

    // redirect
    if (!isAuto) {
      yield put(
        push(
          global.GEODISC_LOCATION_SEARCH_OPTS.get('next') ||
            window.GEODISC_UI_DEFAULT_ROUTE ||
            '/datasets'
        )
      );
    }
  } catch (error) {
    localStorage.removeItem(`${global.GEODISC_VERSION.ver}:login`);
    localStorage.removeItem(`${global.GEODISC_VERSION.ver}:token`);

    yield put({ type: actions.AUTHORIZATION_FAILURE, payload: error });
  }
}

function* fetchProjects() {
  try {
    const data = yield call(api.getProjects);
    yield put({
      type: actions.PROJECTS_FETCH_SUCCESS,
      payload: Immutable.fromJS(data.objects)
    });
  } catch (error) {
    yield put({ type: actions.PROJECTS_FETCH_FAILURE, payload: error });
  }
}

function* changeProject() {
  yield put({ type: datasetsActions.CLEAR_LIST });
  yield put({ type: geofencesActions.CLEAR_LIST });
  yield put({ type: simulationsActions.CLEAR_LIST });

  const project = yield select(currentProjectSelector);
  const latitude = project.get('lat');
  const longitude = project.get('lon');
  yield put(mapsActions.changeViewportAllMaps(latitude, longitude));
}

function* Saga() {
  yield takeLatest(actions.AUTHORIZATION_REQUEST, authorization);
  yield takeLatest(actions.PROJECTS_FETCH_REQUEST, fetchProjects);
  yield takeLatest(actions.AUTHORIZATION_SUCCESS, fetchProjects);
  yield takeLatest(actions.CURRENT_PROJECT_CHANGE, changeProject);
}

export default Saga;
