import moment from 'moment';
import debug from 'utils/debug';

const { METHOD } = debug('m:CommuteOffer:GanttScheduleTimeline');

const areRowTimesSimilar = (a, b) => {
  return Math.abs(a - b) < 1000;
};

const compareRowTimes = (a, b) =>
  areRowTimesSimilar(a.$time.start, b.$time.start)
    ? b.$time.end - a.$time.end
    : a.$time.start - b.$time.start;

// eslint-disable-next-line import/prefer-default-export
export const commuteOffer$GanttScheduleTimeline = (commuteOffer, timezone) => {
  METHOD('Request', {
    commuteOffer
  });

  const timezoneDiff =
    moment
      .tz('9:00 AM', 'LT', moment.tz.guess() || global.GEODISC_TIMEZONE)
      .toDate()
      .getTime() -
    moment
      .tz('9:00 AM', 'LT', timezone)
      .toDate()
      .getTime();

  // https://github.com/neuronetio/react-gantt-schedule-timeline-calendar

  let lastId = 0;

  const vehiclesWithTimeInfo = commuteOffer.stateless_api_request_data.vehicles
    .filter((vehicle) => {
      const $route = commuteOffer.result.vehicles[vehicle.agent_id];
      return $route && $route.length;
    })
    .map((vehicle) => {
      const $route = commuteOffer.result.vehicles[vehicle.agent_id];
      const $time = {
        start:
          moment($route[0].scheduled_ts)
            .tz(global.GEODISC_TIMEZONE)
            .toDate()
            .getTime() + timezoneDiff,
        end:
          moment($route[$route.length - 1].scheduled_ts)
            .tz(global.GEODISC_TIMEZONE)
            .toDate()
            .getTime() + timezoneDiff
      };
      return {
        ...vehicle,
        $route,
        $time
      };
    })
    .sort((a, b) => compareRowTimes(a, b));
  METHOD('vehiclesWithTimeInfo', {
    vehiclesWithTimeInfo
  });

  const vehiclesWithId = vehiclesWithTimeInfo.map((vehicle) => {
    lastId += 1;
    const vehicleRowId = `${lastId}`;
    return {
      ...vehicle,
      $id: vehicleRowId
    };
  });

  const bookingsWithTimeInfo = commuteOffer.result.assigned_bookings
    .map((booking) => {
      return {
        ...booking,
        ...commuteOffer.stateless_api_request_data.bookings[booking.uid],
        $time: {
          start:
            moment(booking.scheduled_pickup_time)
              .tz(global.GEODISC_TIMEZONE)
              .toDate()
              .getTime() + timezoneDiff,
          end:
            moment(booking.scheduled_dropoff_time)
              .tz(global.GEODISC_TIMEZONE)
              .toDate()
              .getTime() + timezoneDiff
        }
      };
    })
    .sort((a, b) => compareRowTimes(a, b));

  const bookingsWithId = bookingsWithTimeInfo.map((booking) => {
    lastId += 1;
    const bookingRowId = `${lastId}`;
    return {
      ...booking,
      ...commuteOffer.stateless_api_request_data.bookings[booking.uid],
      $id: bookingRowId
    };
  });

  const vehicles = vehiclesWithId
    .map((vehicle) => {
      const myBookings = bookingsWithId.filter(
        booking => booking.assigned_vehicle_id === vehicle.agent_id
      );
      return {
        ...vehicle,
        $route: commuteOffer.result.vehicles[vehicle.agent_id],
        $bookings: myBookings,
        $rows: myBookings.reduce((memo, booking) => {
          return {
            ...memo,
            [booking.$id]: {
              id: booking.$id,
              parentId: vehicle.$id,
              label: booking.name || booking.uid,
              expanded: false
            }
          };
        }, {})
      };
    })
    .filter(x => x.$route.length > 0);
  METHOD('vehicles', {
    vehicles
  });

  const rows = vehicles.reduce(
    (memo, vehicle) => ({
      ...memo,
      ...vehicle.$rows,
      [vehicle.$id]: {
        id: vehicle.$id,
        label: vehicle.agent_id,
        expanded: false
      }
    }),
    {}
  );
  METHOD('rows', {
    rows
  });

  const timeBounds = vehicles.reduce(
    (memo, vehicle) => {
      const { start, end } = vehicle.$time;
      return {
        ...memo,
        from: memo.from ? Math.min(memo.from, start) : start,
        to: memo.to ? Math.max(memo.to, end) : end
      };
    },
    { zoom: 13 }
  );

  const items = vehicles.reduce((memo, vehicle) => {
    const bookingItems = vehicle.$bookings.reduce(
      (bookingMemo, booking) => ({
        ...bookingMemo,
        [`${booking.$id}`]: {
          id: booking.$id,
          rowId: booking.$id,
          label: booking.name || booking.uid,
          style: { background: 'rgb(0, 119, 192)' },
          time: {
            start:
              moment(booking.scheduled_pickup_time)
                .tz(global.GEODISC_TIMEZONE)
                .toDate()
                .getTime() + timezoneDiff,
            end:
              moment(booking.scheduled_dropoff_time)
                .tz(global.GEODISC_TIMEZONE)
                .toDate()
                .getTime() + timezoneDiff
          },
          $vehicle: vehicle,
          $booking: booking
        }
      }),
      {}
    );
    return {
      ...memo,
      ...bookingItems,
      [`${vehicle.$id}`]: {
        id: vehicle.$id,
        rowId: vehicle.$id,
        label: vehicle.agent_id,
        time: {
          start:
            moment(vehicle.$route[0].scheduled_ts)
              .tz(global.GEODISC_TIMEZONE)
              .toDate()
              .getTime() + timezoneDiff,
          end:
            moment(vehicle.$route[vehicle.$route.length - 1].scheduled_ts)
              .tz(global.GEODISC_TIMEZONE)
              .toDate()
              .getTime() + timezoneDiff
        },
        $vehicle: vehicle
      }
    };
  }, {});
  METHOD('items', {
    items
  });

  const config = {
    list: {
      rows,
      columns: {
        data: {
          id: {
            id: 'id',
            data: 'id',
            width: 50,
            header: {
              content: 'ID'
            }
          },
          label: {
            id: 'label',
            data: 'label',
            width: 200,
            expander: true,
            header: {
              content: 'Label'
            }
          }
        }
      }
    },
    chart: {
      items,
      time: {
        ...timeBounds,
        from: timeBounds.from - 1000 * 60 * 60 * 3,
        to: timeBounds.to + 1000 * 60 * 60 * 3
      }
    }
  };

  METHOD('Success', {
    config
  });

  return config;
};

global.commuteOffer$GanttScheduleTimeline = commuteOffer$GanttScheduleTimeline;
