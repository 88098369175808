import React, { PureComponent } from 'react';
import moment from 'moment';
import debug from 'utils/debug';

import { getColorByKey } from 'utils/simulations';

import Vehicle from './Vehicle';
import Title from './Title';
import Route from './Route';
import Time from './Time';
import Stops from './Stops';
import RouteLine from './RouteLine';
import Container from './Container';
import Info from './Info';
import Button from './Button';
import Main from './Main';

const { TRACE } = debug('p:Simulation:LeftPanels:Bookings:Booking');

class Booking extends PureComponent {
  onClickBooking = () => {
    this.props.setActiveBookingId(this.props.booking.uid);
    this.props.flyToSimulationActiveBooking(this.props.booking.uid);
  };

  onFindInRoute = () => {
    const booking = this.props.booking;
    this.props.setActiveVehicleId(booking.assigned_vehicle_id);
    if (booking.$routeStopId) {
      this.props.setActiveRouteStop(booking.$routeStopId);
      this.props.flyToSimulationActiveStop(booking.$routeStopId);
    }
  };

  render() {
    const { booking, isDragging, activeBookingId } = this.props;

    const isActive = false;
    // const isActive = activeBookingId === booking.uid;

    if (activeBookingId === booking.uid) {
      TRACE($ => $(`Booking ${activeBookingId} is active`));
    }

    const vehicle_id = booking.assigned_vehicle_id;
    const opacity = isDragging ? 0.4 : 1;

    const actual_pickup_time = booking.actual_pickup_time
      ? moment(booking.actual_pickup_time)
          .tz(global.GEODISC_TIMEZONE)
          .format('HH:mm')
      : '--:--';
    const actual_dropoff_time = booking.actual_dropoff_time
      ? moment(booking.actual_dropoff_time)
          .tz(global.GEODISC_TIMEZONE)
          .format('HH:mm')
      : '--:--';
    const color = getColorByKey(vehicle_id);

    return (
      <Container
        id={`booking_${booking.uid}`}
        style={{ opacity }}
        isActive={isActive}
      >
        <Main onClick={this.onClickBooking}>
          <Vehicle color={color}>
            {vehicle_id ? `Vehicle #${vehicle_id}` : 'Unassigned'}
          </Vehicle>
          <Title>{`Booking #${booking.uid}`}</Title>
          <Route>
            <Time>
              <div>{actual_pickup_time}</div>
              <div>{actual_dropoff_time}</div>
            </Time>
            <RouteLine />
            <Stops>
              <div>{booking.pickup_location_name}</div>
              <div>{booking.dropoff_location_name}</div>
            </Stops>
          </Route>
        </Main>
        {isActive ? (
          <Info>
            {booking.assigned_vehicle_id &&
              booking.scheduled_pickup_stop_id && (
                <Button onClick={this.onFindInRoute}>Find in route</Button>
              )}
            <Button onClick={this.props.cleanActiveBookingId}>Close</Button>
          </Info>
        ) : null}
      </Container>
    );
  }
}

export default Booking;
