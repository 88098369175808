import Immutable from 'immutable';
import {
  call,
  select,
  put,
  takeLatest,
  takeEvery,
  take
} from 'redux-saga/effects';
import debug from 'utils/debug';
import { api$Dataset as api } from 'api';
import { exportFile } from 'utils/file';
import * as actions from './actions';
import * as uiActions from '../ui/actions';
// import * as userActions from '../user/actions';

import { routePageSelector } from '../router/selectors';
import {
  datasetsOrderingSelector,
  datasetsSearchSelector
} from '../ui/selectors';
import { currentProjectIdSelector } from '../user/selectors';

const { METHOD } = debug('m:commuteOffer:saga');

function* fetchDatasets() {
  METHOD('fetchDatasets');

  try {
    const page = yield select(routePageSelector);
    const ordering = yield select(datasetsOrderingSelector);
    const search = yield select(datasetsSearchSelector);
    const projectId = yield select(currentProjectIdSelector);

    const orderingParam = `${ordering.get('sort') ? '' : '-'}${ordering.get(
      'id'
    )}`;

    const data = yield call(
      api.getDatasets,
      page,
      orderingParam,
      search,
      projectId
    );
    yield put({
      type: actions.DATASETS_FETCH_SUCCESS,
      payload: Immutable.fromJS(data)
    });
  } catch (error) {
    yield put({ type: actions.DATASETS_FETCH_FAILURE, payload: error });
  }
}

function* fetchDataset({ payload }) {
  METHOD('fetchDataset');

  try {
    const id = payload;
    const data = yield call(api.getDataset, id);
    yield put({
      type: actions.DATASET_FETCH_SUCCESS,
      payload: Immutable.fromJS(data)
    });
  } catch (error) {
    yield put({ type: actions.DATASET_FETCH_FAILURE, payload: error });
  }
}

function* fetchDatasetCsv({ payload }) {
  METHOD('fetchDatasetCsv');

  try {
    const id = payload;
    const data = yield call(api.getDatasetCsv, id);

    yield put({ type: actions.CSV_FETCH_SUCCESS, payload: data });
  } catch (error) {
    yield put({ type: actions.CSV_FETCH_FAILURE, payload: error });
  }
}

function* downloadCSV({ payload }) {
  METHOD('downloadCSV');

  try {
    const ids = payload;
    const data = yield call(api.downloadDatasetCsv, ids);

    yield call(exportFile, data, `dataset_${ids.join('-')}.zip`);
    yield put({ type: actions.CSV_DOWNLOAD_SUCCESS });
  } catch (error) {
    yield put({ type: actions.CSV_DOWNLOAD_FAILURE, payload: error });
  }
}

function* addDataset({ payload }) {
  METHOD('addDataset');

  try {
    const { body, file } = payload;
    const { id } = yield call(api.addDataset, body);

    yield put(actions.uploadDatasetRequest(id, file));
    yield take(actions.UPLOAD_DATASET_SUCCESS);

    yield put({ type: actions.ADD_DATASET_SUCCESS });
    yield put(actions.fetchDatasets());
    yield put(uiActions.closePopup());
  } catch (error) {
    yield put({ type: actions.ADD_DATASET_FAILURE, payload: error });
  }
}

// Watch for an upload request and then
// defer to another saga to perform the actual upload
// Upload the specified file
export function* uploadDataset({ payload }) {
  METHOD('uploadDataset');

  const { id, file } = payload;

  const channel = yield call(api.uploadDatasetChannel, id, file);

  while (true) {
    const { progress = 0, err, success } = yield take(channel);
    if (err) {
      yield put(actions.uploadDatasetFailure(file, err));
      return;
    }
    if (success) {
      yield put(actions.uploadDatasetSuccess(file));
      return;
    }
    yield put(actions.uploadDatasetProgress(file, progress));
  }
}

function* deleteDataset({ payload }) {
  METHOD('deleteDataset');

  try {
    const id = payload;
    yield call(api.deleteDataset, id);
    yield put({ type: actions.DELETE_DATASET_SUCCESS });
    yield put(actions.fetchDatasets());
  } catch (error) {
    yield put({ type: actions.DELETE_DATASET_FAILURE, payload: error });
  }
}

function* updateDataset({ payload: { id, body } }) {
  METHOD('updateDataset');

  try {
    const data = yield call(api.updateDataset, id, body);
    yield put({
      type: actions.UPDATE_DATASET_SUCCESS,
      payload: Immutable.fromJS(data)
    });
  } catch (error) {
    yield put({ type: actions.UPDATE_DATASET_FAILURE, payload: error });
  }
}

function* saveDataset({ payload: { id, body } }) {
  METHOD('saveDataset');

  yield put(actions.updateDataset(id, body));

  yield take(actions.UPDATE_DATASET_SUCCESS);

  yield put({
    type: uiActions.SET_EDITABLE_DATASET,
    payload: null
  });
}

function* Saga() {
  yield takeLatest(actions.DATASETS_FETCH_REQUEST, fetchDatasets);
  yield takeLatest(actions.DATASET_FETCH_REQUEST, fetchDataset);
  yield takeLatest(actions.CSV_FETCH_REQUEST, fetchDatasetCsv);
  yield takeLatest(actions.ADD_DATASET_REQUEST, addDataset);
  yield takeLatest(actions.DELETE_DATASET_REQUEST, deleteDataset);
  yield takeEvery(actions.UPLOAD_DATASET_REQUEST, uploadDataset);
  yield takeLatest(actions.UPDATE_DATASET_REQUEST, updateDataset);
  yield takeLatest(actions.SAVE_DATASET, saveDataset);
  yield takeLatest(actions.CSV_DOWNLOAD_REQUEST, downloadCSV);
}

export default Saga;
