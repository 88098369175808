export const getLayers = hoverId => [
  {
    id: 'geofences',
    type: 'fill',
    source: 'geofences',
    paint: {
      'fill-color': ['get', 'color'],
      'fill-opacity': 0.5
    }
  },
  {
    id: 'geofences-hover',
    type: 'fill',
    source: 'geofences',
    paint: {
      'fill-color': ['get', 'color'],
      'fill-opacity': 1
    },
    filter: ['==', 'key', hoverId]
  }
];

export const getSource = geofences => ({
  type: 'FeatureCollection',
  features: geofences
    ? geofences.map(geofence => ({
        type: 'Feature',
        properties: {
          id: geofence.id,
          key: geofence.key,
          color: geofence.color
        },
        geometry: geofence.geometry
      }))
    : []
});

export const getGeojson = coordinates => ({
  type: 'FeatureCollection',
  features: [
    {
      type: 'Feature',
      properties: {},
      geometry: {
        type: 'Polygon',
        coordinates
      }
    }
  ]
});
