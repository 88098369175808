import React from 'react';
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  ModalButton
} from 'baseui/modal';

import debug from 'utils/debug';
import Table from './Table';

const { METHOD } = debug('c:ModalWindows:DataTableViewer');

export default (props) => {
  METHOD('DataTableViewer', props);

  const [isOpen, setIsOpen] = React.useState(false);
  const [headers, setHeaders] = React.useState([]);
  const [content, setContent] = React.useState([]);
  const [title, setTitle] = React.useState('Info');
  const [config, setConfig] = React.useState({});

  React.useEffect(() => {
    METHOD('Update', { props });

    window.openDataTableViewer = ($headers, $content, opts) => {
      METHOD('openDataTableViewer', { $headers, $content, opts });

      const options = opts || {};

      const $title = options.title || 'Source';

      if (!$headers) {
        global.openInfoMessage('Nothing to show', { title: $title });
        return;
      }

      setTitle($title);
      setHeaders($headers);
      setContent($content);
      setConfig(options);
      setIsOpen(true);
    };

    window.closeDataTableViewer = () => {
      METHOD('closeDataTableViewer', { isOpen, config });
      setIsOpen(false);
    };

    return () => {
      METHOD('Cleanup', { isOpen, config });
      window.closeDataTableViewer = null;
    };
  });

  function onClose() {
    METHOD('onClose', { isOpen, config });

    setIsOpen(false);
  }

  return (
    <React.Fragment>
      <Modal
        onClose={onClose}
        isOpen={isOpen}
        overrides={{
          Dialog: {
            style: {
              width: '90vw',
              height: '90vh',
              display: 'flex',
              flexDirection: 'column'
            }
          }
        }}
      >
        <ModalHeader>{title}</ModalHeader>
        <ModalBody style={{ flex: '1 1 0' }}>
          <Table columns={headers} rows={content} />
        </ModalBody>
        <ModalFooter>
          <ModalButton onClick={onClose}>Close</ModalButton>
        </ModalFooter>
      </Modal>
    </React.Fragment>
  );
};
