import React from 'react';
import Container from './Container';
import Border from './Border';
import Title from './Title';
import Description from './Description';

const Header = (props) => {
  const { color, name, description, isVisible, onChange, isEditable } = props;

  const handlerOnChange = (e) => {
    const { id, value } = e.target;
    onChange(id, value);
  };

  return (
    <Container isVisible={isVisible}>
      <Border color={color} />
      <Title
        id='name'
        value={name}
        onChange={handlerOnChange}
        isEditable={isEditable}
        placeholder='name'
      />
      {(description || isEditable) && (
        <Description
          id='description'
          value={description}
          onChange={handlerOnChange}
          isEditable={isEditable}
          placeholder='description'
        />
      )}
    </Container>
  );
};

export default Header;
