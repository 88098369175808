export const CHANGE_VIEWPORT_COMMUTE_OFFER =
  '@@map_commuteOffer/CHANGE_VIEWPORT';
export const changeViewportCommuteOffer = viewport => ({
  type: CHANGE_VIEWPORT_COMMUTE_OFFER,
  payload: viewport
});

export const CHANGE_VIEWPORT_DATASET = '@@map_dataset/CHANGE_VIEWPORT';
export const changeViewportDataset = viewport => ({
  type: CHANGE_VIEWPORT_DATASET,
  payload: viewport
});

export const CHANGE_VIEWPORT_SIMULATION = '@@map_simulations/CHANGE_VIEWPORT';
export const changeViewportSimulation = viewport => ({
  type: CHANGE_VIEWPORT_SIMULATION,
  payload: viewport
});

export const CHANGE_VIEWPORT_GEOFENCES = '@@map_geofences/CHANGE_VIEWPORT';
export const changeViewportGeofences = viewport => ({
  type: CHANGE_VIEWPORT_GEOFENCES,
  payload: viewport
});

export const CHANGE_VIEWPORT_NEW_GEOFENCE =
  '@@map_new_geofence/CHANGE_VIEWPORT';
export const changeViewportNewGeofence = viewport => ({
  type: CHANGE_VIEWPORT_NEW_GEOFENCE,
  payload: viewport
});

export const CHANGE_VIEWPORT_ALL_MAPS = '@@maps/CHANGE_VIEWPORT_ALL_MAPS';
export const changeViewportAllMaps = (latitude, longitude) => ({
  type: CHANGE_VIEWPORT_ALL_MAPS,
  payload: { latitude, longitude }
});

export const NODES_LAYER_CLICK = '@@map_commuteOffer/NODES_LAYER_CLICK';
export const onClickNodesLayer = feature => ({
  type: NODES_LAYER_CLICK,
  payload: feature
});

export const VEHICLES_LAYER_CLICK = '@@map_commuteOffer/VEHICLES_LAYER_CLICK';
export const onClickVehiclesLayer = feature => ({
  type: VEHICLES_LAYER_CLICK,
  payload: feature
});

export const BOOKING_LAYER_CLICK = '@@map_commuteOffer/BOOKING_LAYER_CLICK';
export const onClickBookingLayer = feature => ({
  type: BOOKING_LAYER_CLICK,
  payload: feature
});

export const STOPS_LAYER_CLICK = '@@map_commuteOffer/STOPS_LAYER_CLICK';
export const onClickStopsLayer = (event, feature) => ({
  type: STOPS_LAYER_CLICK,
  payload: { event, feature }
});

export const FLY_TO_COMMUTE_OFFER_VEHICLE =
  '@@map_commuteOffer/FLY_TO_COMMUTE_OFFER_VEHICLE';
export const flyToCommuteOfferVehicle = id => ({
  type: FLY_TO_COMMUTE_OFFER_VEHICLE,
  payload: id
});

export const FLY_TO_COMMUTE_OFFER_ACTIVE_STOP =
  '@@map_commuteOffer/FLY_TO_COMMUTE_OFFER_ACTIVE_STOP';
export const flyToCommuteOfferActiveStop = id => ({
  type: FLY_TO_COMMUTE_OFFER_ACTIVE_STOP,
  payload: id
});

export const FLY_TO_COMMUTE_OFFER_ACTIVE_BOOKING =
  '@@map_commuteOffer/FLY_TO_COMMUTE_OFFER_ACTIVE_BOOKING';
export const flyToCommuteOfferActiveBooking = id => ({
  type: FLY_TO_COMMUTE_OFFER_ACTIVE_BOOKING,
  payload: id
});

export const FLY_TO_SIMULATION_VEHICLE =
  '@@map_Simulation/FLY_TO_SIMULATION_VEHICLE';
export const flyToSimulationVehicle = id => ({
  type: FLY_TO_SIMULATION_VEHICLE,
  payload: id
});

export const FLY_TO_SIMULATION_ACTIVE_STOP =
  '@@map_Simulation/FLY_TO_SIMULATION_ACTIVE_STOP';
export const flyToSimulationActiveStop = id => ({
  type: FLY_TO_SIMULATION_ACTIVE_STOP,
  payload: id
});

export const FLY_TO_SIMULATION_ACTIVE_BOOKING =
  '@@map_Simulation/FLY_TO_SIMULATION_ACTIVE_BOOKING';
export const flyToSimulationActiveBooking = id => ({
  type: FLY_TO_SIMULATION_ACTIVE_BOOKING,
  payload: id
});
