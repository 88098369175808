import weekdays from 'utils/weekdays';

// eslint-disable-next-line import/prefer-default-export
export const commuteSchedule$PassengersTableHeaders = () => [
  ...[
    {
      kind: 'STRING',
      name: 'name',
      label: 'Name',
      width: 'minmax(350px, max-content)'
    },
    {
      kind: 'STRING',
      label: 'Postal code',
      name: 'zip_code',
      width: '120px'
    },
    {
      kind: 'STRING',
      label: 'Going to the Eldercare center at',
      name: 'morning_dropoff_ts',
      width: 'minmax(150px, max-content)'
    },
    {
      kind: 'STRING',
      label: 'Leaving the Eldercare center at',
      name: 'evening_pickup_ts',
      width: 'minmax(150px, max-content)'
    },
    {
      kind: 'BOOLEAN',
      label: 'Mon',
      name: 'mon',
      width: '60px'
    },
    {
      kind: 'BOOLEAN',
      label: 'Tue',
      name: 'tue',
      width: '60px'
    },
    {
      kind: 'BOOLEAN',
      label: 'Wed',
      name: 'wed',
      width: '60px'
    },
    {
      kind: 'BOOLEAN',
      label: 'Thu',
      name: 'thu',
      width: '60px'
    },
    {
      kind: 'BOOLEAN',
      label: 'Fri',
      name: 'fri',
      width: '60px'
    },
    {
      kind: 'BOOLEAN',
      label: 'Sat',
      name: 'sat',
      width: '60px'
    },
    {
      kind: 'BOOLEAN',
      label: 'Sun',
      name: 'sun',
      width: '60px'
    }
  ],
  ...Object.keys(weekdays).reduce((memo, weekday) => {
    return [
      ...memo,
      ...[
        {
          kind: 'STRING',
          label: `${weekdays[weekday].name} morning service time`,
          name: `${weekday}_morning_service_time`,
          width: 'minmax(150px, max-content)'
        },
        {
          kind: 'STRING',
          label: `${weekdays[weekday].name} morning service duration`,
          name: `${weekday}_morning_service_duration`,
          width: 'minmax(150px, max-content)'
        },
        {
          kind: 'STRING',
          label: `${weekdays[weekday].name} evening service time`,
          name: `${weekday}_evening_service_time`,
          width: 'minmax(150px, max-content)'
        },
        {
          kind: 'STRING',
          label: `${weekdays[weekday].name} evening service duration`,
          name: `${weekday}_morning_service_duration`,
          width: 'minmax(200px, max-content)'
        }
      ]
    ];
  }, []),
  ...[
    {
      kind: 'STRING',
      label: 'Country',
      name: 'country',
      width: '150px'
    },
    {
      kind: 'STRING',
      label: 'City',
      name: 'city',
      width: '150px'
    },
    {
      kind: 'NUMERICAL',
      label: 'Longitude',
      name: 'lon',
      width: '120px'
    },
    {
      kind: 'NUMERICAL',
      label: 'Latitude',
      name: 'lat',
      width: '120px'
    }
  ]
];

global.commuteSchedule$PassengersTableHeaders = commuteSchedule$PassengersTableHeaders;
