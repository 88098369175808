import React, { PureComponent } from 'react';
import debug from 'utils/debug';

import ModalWindow from 'components/ModalWindow';
import Map from 'components/MapCommuteOfferEditor';
import Header from 'components/Header';
import Panels from './Panels';
import StopsButtons from './StopsButtons';
import RoutingButtons from './RoutingButtons';
import WalkingRoutingButtons from './WalkingRoutingButtons';
import TopButtons from './TopButtons';

const { METHOD } = debug('p:CommuteOffer');

class CommuteOffer extends PureComponent {
  state = {
    serialNumber: 0
  };

  componentDidMount() {
    METHOD('componentDidMount');

    this.timerId = setInterval(() => {
      // METHOD('Timer', { state: this.state });

      const { serialNumber } = this.state;

      if (serialNumber !== global.WALKING_ROUTES_SN) {
        METHOD('SerialNumber:Changed', {
          state: this.state,
          serialNumber: global.WALKING_ROUTES_SN
        });
        this.setState({ serialNumber: global.WALKING_ROUTES_SN });

        const { incSerialNumber } = this.props;

        incSerialNumber();
      }
    }, 1000);
  }

  componentWillUnmount() {
    METHOD('componentWillUnmount');

    clearInterval(this.timerId);
  }

  render() {
    METHOD('render', { props: this.props });

    return (
      <React.Fragment>
        <Map />
        <Panels />
        <StopsButtons />
        <RoutingButtons />
        <WalkingRoutingButtons />
        <TopButtons />
        {global.COMMUTE_OFFER_HEADER_ENABLED && (
          <Header
            hasBackground
            isShadow
            isProjectSelectionDisabled
            projectLabelTarget='/commuteoffers'
          />
        )}
        <ModalWindow />
      </React.Fragment>
    );
  }
}

export default CommuteOffer;
