import React from 'react';
import { Dropdown } from '@urbica/ui-kit';

import Container from './Container';
import Content from './Content';
import Item from './Item';
import ProjectLabel from './ProjectLabel';

const ProjectMenu = (props) => {
  const {
    currentProject,
    projects,
    changeCurrentProject,
    isProjectSelectionDisabled,
    projectLabelTarget
  } = props;

  const currentProjectName = currentProject
    ? currentProject.get('name')
    : 'loading...';

  return (
    <React.Fragment>
      {isProjectSelectionDisabled && (
        <ProjectLabel to={projectLabelTarget}>
          {currentProjectName}
        </ProjectLabel>
      )}
      {!isProjectSelectionDisabled && (
        <Dropdown
          renderHandler={({ toggle, toggledOn }) => (
            <Container isActive={toggledOn} onClick={toggle}>
              {currentProjectName}
            </Container>
          )}
          renderContent={({ toggle }) => (
            <Content onClick={toggle}>
              {projects.map((project) => {
                const onClick = () => {
                  changeCurrentProject(project);
                };

                return (
                  <Item key={project.get('id')} onClick={onClick}>
                    {project.get('name')}
                  </Item>
                );
              })}
            </Content>
          )}
        />
      )}
    </React.Fragment>
  );
};

export default ProjectMenu;
