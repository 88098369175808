import React, { PureComponent } from 'react';
import moment from 'moment';
import debug from 'utils/debug';

import { DragSource } from 'react-dnd';

import Menu from 'components/Menu';
import MenuButton from './MenuButton';

import Vehicle from './Vehicle';
import Title from './Title';
import Caption from './Caption';
import Route from './Route';
import Time from './Time';
import Stops from './Stops';
import RouteLine from './RouteLine';
import Container from './Container';
import Main from './Main';
import DropoffLocationTime from './DropoffLocationTime';
import PickupLocationTime from './PickupLocationTime';
import LocationName from './LocationName';

const { METHOD } = debug('p:CommuteOffer:Panels:Bookings:Booking');

class Booking extends PureComponent {
  onFindInRoute = (nodeType) => {
    METHOD('onFindInRoute', { props: this.props, nodeType });

    this.props.setActiveVehicleId(this.props.booking.assigned_vehicle_id);
    if (this.props.booking.$routeStops) {
      const routeStop = this.props.booking.$routeStops.find(
        node => node.node_type === nodeType
      );
      if (routeStop) {
        this.props.setActiveRouteStop(routeStop.id);
        this.props.flyToCommuteOfferActiveStop(routeStop.id);
      }
    }
  };

  onFindPickupNode = () => {
    return this.onFindInRoute('pickup');
  };

  onFindDropoffNode = () => {
    return this.onFindInRoute('dropoff');
  };

  render() {
    METHOD('render', { props: this.props });

    const { booking, isDragging, connectDragSource } = this.props;

    if (!booking) {
      return null;
    }

    const isActive = false;
    const vehicle_id = booking.assigned_vehicle_id;
    const opacity = isDragging ? 0.4 : 1;

    const pickupNode =
      booking.$routeStops &&
      booking.$routeStops.find(stop => stop.node_type === 'pickup');
    const dropoffNode =
      booking.$routeStops &&
      booking.$routeStops.find(stop => stop.node_type === 'dropoff');

    return (
      <Container
        id={`booking_${booking.uid}`}
        ref={connectDragSource}
        style={{ opacity }}
        isActive={isActive}
      >
        <Main>
          <Vehicle color={booking.color}>
            <Caption>
              Vehicle
              <br />
              {vehicle_id || 'Unassigned'}
            </Caption>
            <Menu>
              {booking.assigned_vehicle_id &&
                booking.scheduled_pickup_stop_id && (
                  <MenuButton onClick={this.onFindPickupNode}>
                    Find pickup node
                  </MenuButton>
                )}
              {booking.assigned_vehicle_id &&
                booking.scheduled_dropoff_stop_id && (
                  <MenuButton onClick={this.onFindDropoffNode}>
                    Find dropoff node
                  </MenuButton>
                )}
            </Menu>
          </Vehicle>
          {booking.name ? (
            <Title>{booking.name}</Title>
          ) : (
            <Title>
              Booking
              <br />
              {booking.uid}
            </Title>
          )}
          <Route>
            <Time>
              <PickupLocationTime>
                {pickupNode
                  ? moment(pickupNode.scheduled_ts)
                      .tz(global.GEODISC_TIMEZONE)
                      .format('HH:mm')
                  : booking.scheduled_pickup_time}
              </PickupLocationTime>
              <DropoffLocationTime>
                {dropoffNode
                  ? moment(dropoffNode.scheduled_ts)
                      .tz(global.GEODISC_TIMEZONE)
                      .format('HH:mm')
                  : booking.scheduled_dropoff_time}
              </DropoffLocationTime>
            </Time>
            <RouteLine />
            <Stops>
              <LocationName>
                {pickupNode
                  ? pickupNode.location_name
                  : booking.scheduled_pickup_stop_name}
              </LocationName>
              <LocationName>
                {dropoffNode
                  ? dropoffNode.location_name
                  : booking.scheduled_dropoff_stop_name}
              </LocationName>
            </Stops>
          </Route>
        </Main>
      </Container>
    );
  }
}

export default DragSource(
  'booking',
  {
    beginDrag: props => ({ ...props.booking }),
    endDrag(props, monitor) {
      const item = monitor.getItem();
      const dropResult = monitor.getDropResult();

      if (dropResult) {
        if (item.assigned_vehicle_id) {
          // eslint-disable-next-line no-alert
          alert('First unassigned the booking from the vehicles');
          props.setActiveVehicleId(item.assigned_vehicle_id);
          props.setActiveRouteStop(item.scheduled_pickup_stop_id);
          return;
        }

        if (dropResult.isHaveEditable) {
          // eslint-disable-next-line no-alert
          alert('Finish editing booking!');
          return;
        }

        props.addBookingToRoute(item.uid, dropResult.agent_id);
        props.setActiveVehicleId(dropResult.agent_id);

        if (!item.scheduled_pickup_stop_id) {
          props.setEditableBookingId(item.uid);
        }
      }
    }
  },
  (connect, monitor) => ({
    connectDragSource: connect.dragSource(),
    isDragging: monitor.isDragging()
  })
)(Booking);
