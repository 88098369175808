import React from 'react';
import Container from './Container';
import ProgressBar from './ProgressBar';

const getStatus = (progress, status) => {
  const message = status || 'Processing';

  if (progress === 1) {
    return `${message}...`;
  }

  return `${message} (${Math.round(progress * 100)}%)`;
};

export default ({ progress, status }) => (
  <Container>
    {getStatus(progress, status)}
    <ProgressBar progress={progress} />
  </Container>
);
