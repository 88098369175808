import moment from 'moment';
import debug from 'utils/debug';
import weekdays from 'utils/weekdays';

const { METHOD } = debug('m:CommuteSchedule:PassengersTableFromOffer');

const formatBoolean = v => (v ? 'Yes' : '');

const formatTime = (v) => {
  const text = moment.tz(v, 'LT', global.GEODISC_TIMEZONE).format('HH:mm');
  return text !== 'Invalid date' ? text : '';
};

const formatDuration = (v) => {
  const text = moment.duration(v, 'milliseconds').humanize();
  return text !== 'Invalid date' && text !== 'a few seconds' ? text : '';
};

// eslint-disable-next-line import/prefer-default-export
export const commuteSchedule$PassengersTableFromOffer = (commuteOffer) => {
  METHOD('Request', { commuteOffer });

  const schedule = commuteOffer.stateless_api_request_data.inbound.schedule;
  METHOD('schedule', { schedule });

  const fixedSchedule =
    commuteOffer.stateless_api_request_data.inbound.fixed_schedule;
  METHOD('fixedSchedule', { fixedSchedule });

  const trips = commuteOffer.stateless_api_request_data.inbound.trips;
  METHOD('trips', { trips });

  const resultTable = Object.keys(schedule).map((id) => {
    METHOD('resultTable:Item', { id, schedule });

    const p = schedule[id];

    return [
      ...[
        p.name,
        p.zip_code,
        formatTime(p.morning_dropoff_ts),
        formatTime(p.evening_pickup_ts)
      ],
      ...Object.keys(weekdays).map(weekday => formatBoolean(p[weekday])),
      ...Object.keys(weekdays).reduce((memo, weekday) => {
        const tripsSchedule = trips[weekday].schedule[id];

        const scheduled_morning_pickup_ts = tripsSchedule
          ? tripsSchedule.morning_pickup_ts
          : '';
        const scheduled_evening_dropoff_ts = tripsSchedule
          ? tripsSchedule.evening_dropoff_ts
          : '';

        const morning_pickup_ts =
          fixedSchedule && fixedSchedule[id]
            ? fixedSchedule[id].morning_pickup_ts
            : scheduled_morning_pickup_ts;

        const evening_dropoff_ts =
          fixedSchedule && fixedSchedule[id]
            ? fixedSchedule[id].evening_dropoff_ts
            : scheduled_evening_dropoff_ts;

        const morning_service_duration =
          p[weekday] && morning_pickup_ts !== ''
            ? moment
                .tz(p.morning_dropoff_ts, 'LT', 'UTC')
                .diff(moment.tz(morning_pickup_ts, 'LT', 'UTC'))
            : '';

        const evening_service_duration =
          p[weekday] && evening_dropoff_ts !== ''
            ? moment
                .tz(evening_dropoff_ts, 'LT', 'UTC')
                .diff(moment.tz(p.evening_pickup_ts, 'LT', 'UTC'))
            : '';

        return [
          ...memo,
          ...[
            formatTime(morning_pickup_ts),
            formatDuration(morning_service_duration),
            formatTime(evening_dropoff_ts),
            formatDuration(evening_service_duration)
          ]
        ];
      }, []),
      ...[p.country, p.city, p.lon, p.lat]
    ];
  });
  METHOD('Success', {
    commuteOffer,
    resultTable
  });

  return resultTable;
};
