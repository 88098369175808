import SuperCluster from 'supercluster';

export function getGeoJSON(data, getPosition) {
  return data
    .map(d => ({
      type: 'Point',
      properties: {
        id: d.id,
        data: d,
        points: [d],
        point_count: d.weight ? parseInt(d.weight, 10) : 1,
        point_count_abbreviated: '1'
      },
      geometry: {
        coordinates: getPosition(d)
      }
    }))
    .filter(d => d.geometry.coordinates.every(Number.isFinite));
}

export const getCluster = ({ clusterRadius, geoJSON }) =>
  new SuperCluster({
    maxZoom: 20,
    radius: clusterRadius,
    map: props => ({ point_count: props.point_count }),
    reduce: (acc, props) => {
      acc.point_count += props.point_count;
    }
  }).load(geoJSON);

const metersPerPixel = (zoomLevel) => {
  const earthCircumference = 40075017;
  const latitudeRadians = Math.PI / 180;
  return (
    (earthCircumference * Math.cos(latitudeRadians)) / 2 ** (zoomLevel + 8) / 2
  );
};

export const getScale = (data, maxRadius, zoom) => {
  const maxValue = data.reduce((acc, item) => {
    const count = item.properties.cluster && item.properties.point_count;
    if (count > acc) {
      return count;
    }

    return acc;
  }, 0);

  return (maxRadius * metersPerPixel(Math.round(zoom))) / maxValue;
};

export const getClusteredArcs = async (
  clusterPickup,
  clusterDropoff,
  clusterDataPickup,
  clusterDataDropoff
) => {
  const featureIdInClustersDropoff = await clusterDataDropoff.reduce(
    (acc, value) => {
      if (value.id) {
        const features = clusterDropoff.getLeaves(value.id, Infinity);

        return features.reduce((innerAcc, { properties }) => {
          const newInnerAcc = innerAcc;

          newInnerAcc[properties.id] = {
            id: value.id,
            geometry: value.geometry.coordinates
          };
          return newInnerAcc;
        }, acc);
      }
      return acc;
    },
    {}
  );

  const arcs = await clusterDataPickup.reduce((acc, value) => {
    if (value.id) {
      clusterPickup.getLeaves(value.id, Infinity).forEach((feature) => {
        const dropoffCluster =
          featureIdInClustersDropoff[feature.properties.id];
        if (!dropoffCluster) return null;

        const arcId = `${value.id}-${dropoffCluster.id}`;
        if (acc[arcId]) {
          acc[arcId].weight += 1;
        } else {
          acc[arcId] = {
            coordinatesDropoff: dropoffCluster.geometry,
            coordinatesPickup: value.geometry.coordinates,
            weight: 1
          };
        }
        return acc;
      });
    }
    return acc;
  }, {});

  const arcsData = await Object.keys(arcs).map(key => arcs[key]);
  return arcsData;
};
