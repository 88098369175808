export default {
  agent_id: 'veh_new',
  assigned_nodes: {},
  capacity: {
    passengers: 10,
    stop: 10
  },
  completed_nodes: [],
  lat: 0,
  lon: 0,
  partial_route: {},
  routing_engine: {
    make_depot_zero: true,
    road_network: 'bus',
    routing_engine_name: 'valhalla',
    speed: null,
    time_factor: 1,
    url: 'http://192.168.1.14:9010'
  },
  vehicle_cost: null
};
