import React from 'react';
import { withRouter } from 'react-router-dom';

import Container from 'components/Menu';
import { urls } from 'config';

import MenuButton from './MenuButton';
import MenuLink from './MenuLink';

const Menu = (props) => {
  const { dataset, setEditableDataset, deleteDataset, history } = props;
  const id = dataset.get('id');

  const onDelete = () => {
    deleteDataset(id);
    history.push('/datasets');
  };

  return (
    <Container>
      <MenuLink href={urls.datasetCsv(id)}>Download CSV</MenuLink>
      <MenuButton onClick={() => setEditableDataset(dataset)}>Edit</MenuButton>
      <MenuButton onClick={onDelete}>Delete</MenuButton>
    </Container>
  );
};

export default withRouter(Menu);
