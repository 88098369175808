import React from 'react';
import Label from './Label';
import Input from './Input';
import Container from './Container';

export default ({ label, value, ...otherProps }) => (
  <Container>
    <Label>{label}</Label>
    <Input {...otherProps} value={value || ''} autoComplete='off' />
  </Container>
);
