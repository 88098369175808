// @flow
import { buffers, eventChannel, END } from 'redux-saga';

import { csvToJson } from '../utils/csv';
import { cutIntoHours } from '../utils/datasets';

import { fetchData } from './net';
import { getHeaders, getBasicAuth } from './headers';
import { urls, pagination } from '../config';

export const getDatasets = async (
  page: number,
  orderingParam: boolean,
  search: string,
  projectId: number
) => {
  try {
    const offset = pagination.datasets * (page - 1);
    const response = await fetchData(
      urls.datasetsList(offset, orderingParam, search, projectId),
      {
        headers: getHeaders()
      }
    );

    if (!response.ok) {
      throw new Error(response.status);
    }

    const responseJSON: DatasetsResponse = await response.json();
    return responseJSON;
  } catch (error) {
    throw error;
  }
};

export const getDataset = async (id: number) => {
  try {
    const response = await fetchData(urls.dataset(id), {
      headers: getHeaders()
    });
    if (!response.ok) {
      throw new Error(response.status);
    }

    const responseJSON = await response.json();
    return responseJSON;
  } catch (error) {
    throw error;
  }
};

export const getDatasetCsv = async (id: number) => {
  try {
    const response = await fetchData(urls.datasetCsv(id), {
      headers: getHeaders()
    });
    if (!response.ok) {
      throw new Error(response.status);
    }

    const csv = await response.text();
    const json = await csvToJson(csv);
    if (!json[0].pickup_time || json[0].customer_id) {
      return {
        '0': json
      };
    }

    const data = await cutIntoHours(json);

    return data;
  } catch (error) {
    throw error;
  }
};

export const downloadDatasetCsv = async (ids: number) => {
  try {
    const response = await fetchData(urls.datasetZip(ids), {
      headers: new Headers({
        Authorization: getBasicAuth()
      })
    });

    if (!response.ok) {
      throw new Error(response.status);
    }

    return response.blob();
  } catch (error) {
    throw error;
  }
};

export const uploadDatasetChannel = (id: number, file: File) =>
  eventChannel((emitter) => {
    const xhr = new XMLHttpRequest();

    const onProgress = (e: ProgressEvent) => {
      if (e.lengthComputable) {
        const progress = e.loaded / e.total;
        emitter({ progress });
      }
    };
    const onFailure = () => {
      emitter({ err: new Error('Upload failed') });
      emitter(END);
    };
    xhr.upload.addEventListener('progress', onProgress);
    xhr.upload.addEventListener('error', onFailure);
    xhr.upload.addEventListener('abort', onFailure);
    xhr.onreadystatechange = () => {
      const { readyState, status } = xhr;
      if (readyState === 4) {
        if (status === 201) {
          emitter({ success: true });
          emitter(END);
        } else {
          onFailure();
        }
      }
    };
    xhr.open('PUT', urls.datasetCsv(id), true);
    xhr.setRequestHeader('Authorization', getBasicAuth());
    xhr.setRequestHeader('Content-Type', 'text/csv');
    xhr.setRequestHeader('Content-Disposition', `filename="${file.name}"`);
    xhr.send(file);

    return () => {
      xhr.upload.removeEventListener('progress', onProgress);
      xhr.upload.removeEventListener('error', onFailure);
      xhr.upload.removeEventListener('abort', onFailure);
      xhr.onreadystatechange = () => {};
      xhr.abort();
    };
  }, buffers.sliding(2));

export const addDataset = async (body: any) => {
  try {
    const response = await fetchData(urls.datasets, {
      method: 'POST',
      headers: getHeaders(),
      body
    });

    if (!response.ok) {
      throw new Error(response.status);
    }

    const responseJSON: DatasetsResponse = await response.json();

    return responseJSON;
  } catch (error) {
    throw error;
  }
};

export const deleteDataset = async (id: number) => {
  try {
    const response = await fetchData(urls.dataset(id), {
      method: 'DELETE',
      headers: getHeaders()
    });
    if (!response.ok) {
      throw new Error(response.status);
    }

    return null;
  } catch (error) {
    throw error;
  }
};

export const updateDataset = async (id: number, body: any) => {
  const url = urls.dataset(id);

  try {
    const response = await fetchData(url, {
      method: 'PATCH',
      headers: getHeaders(),
      body
    });

    if (!response.ok) {
      throw new Error(response.status);
    }

    const responseJSON: DatasetsResponse = await response.json();

    return responseJSON;
  } catch (error) {
    throw error;
  }
};
