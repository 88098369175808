import { fromJS, Map } from 'immutable';
import defaultVehicle from 'config/defaultVehicle';
import { diffCommuteOffers } from 'utils/CommuteOffer';
import { registerSelectors } from 'reselect-tools';

import debug from 'utils/debug';
import * as actions from './actions';
import * as uiActions from '../ui/actions';

import * as selectors from './selectors';

registerSelectors(selectors);

const { DEBUG, TRACE, METHOD, REDUX_EVENT_HANDLER } = debug(
  'm:commuteOffer:index'
);

export const initialState = fromJS({
  serialNumber: 0,
  list: null,
  currentOffer: null,
  count: null,
  routes: {},
  currentLayer: null
});

const showCommuteOfferUpdates = (type, o, n) => {
  console.log(`${type}: Calculating...`); // eslint-disable-line
  console.log(diffCommuteOffers(type, o, n)); // eslint-disable-line
};

const updateCurrentOffer = (state, reason, isDiffEnabled, fn) => {
  METHOD('updateCurrentOffer', { reason });

  return state.update('currentOffer', (currentOffer) => {
    METHOD('updateCurrentOffer:Update', { currentOffer });

    if (currentOffer.result.readOnly) {
      global.openInfoMessage('This layer is read only', { title: 'Error' });
      return currentOffer;
    }
    // const currentChangelog = currentOffer.result.changelog || [];
    const newOffer = fn(currentOffer);
    if (global.GEODISC_DEBUG_UPDATED_JSONDIFF_ENABLED && isDiffEnabled) {
      showCommuteOfferUpdates(reason.type, state.get('currentOffer'), newOffer);
    }
    return {
      ...newOffer,
      result: {
        ...newOffer.result,
        changelog: []
        // changelog: [...currentChangelog, reason]
      }
    };
  });
};

const reducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case actions.COMMUTE_OFFER_IMPORT_BOOKINGS_REQUEST: {
      REDUX_EVENT_HANDLER(type, payload);

      return state;
    }
    case actions.COMMUTE_OFFER_IMPORT_BOOKINGS_PROGRESS: {
      REDUX_EVENT_HANDLER(type, payload);

      return state;
    }
    case actions.COMMUTE_OFFER_IMPORT_BOOKINGS_SUCCESS: {
      REDUX_EVENT_HANDLER(type, payload);

      const { newOffer } = payload;

      console.log(global.METHODS.sort()); // eslint-disable-line

      return state.set('currentOffer', newOffer);
    }
    case actions.COMMUTE_OFFER_IMPORT_BOOKINGS_FAILURE: {
      REDUX_EVENT_HANDLER(type, payload);

      console.log(global.METHODS.sort()); // eslint-disable-line

      return state;
    }
    case actions.COMMUTE_OFFER_STATELESS_NODE_SCHEDULER_REQUEST: {
      REDUX_EVENT_HANDLER(type, payload);

      return state;
    }
    case actions.COMMUTE_OFFER_STATELESS_NODE_SCHEDULER_SUCCESS: {
      REDUX_EVENT_HANDLER(type, payload);

      const data = payload;

      return state.setIn(['currentOffer', 'result'], data);
    }
    case actions.COMMUTE_OFFER_STATELESS_NODE_SCHEDULER_FAILURE: {
      REDUX_EVENT_HANDLER(type, payload);

      return state;
    }
    case actions.COMMUTE_OFFER_SHOW_VEHICLE_UPDATES: {
      REDUX_EVENT_HANDLER(type, payload);

      return state;
    }
    case actions.INC_SERIAL_NUMBER: {
      REDUX_EVENT_HANDLER(type, payload);

      const serialNumber = state.get('serialNumber');
      return state.set('serialNumber', serialNumber + 1);
    }
    case actions.COMMUTE_OFFER_ADD_VEHICLE_REQUEST: {
      REDUX_EVENT_HANDLER(type, payload);

      return state;
    }
    case actions.COMMUTE_OFFER_ADD_VEHICLE_SUCCESS: {
      REDUX_EVENT_HANDLER(type, payload);

      const newOffer = payload;

      if (global.GEODISC_DEBUG_CHANGED_JSONDIFF_ENABLED) {
        showCommuteOfferUpdates(type, state.get('originalOffer'), newOffer);
      }
      return state.set('currentOffer', newOffer);
    }
    case actions.COMMUTE_OFFER_ADD_VEHICLE_FAILURE: {
      REDUX_EVENT_HANDLER(type, payload);

      return state;
    }
    case actions.COMMUTE_OFFER_EDIT_VEHICLE_REQUEST: {
      REDUX_EVENT_HANDLER(type, payload);

      return state;
    }
    case actions.COMMUTE_OFFER_EDIT_VEHICLE_SUCCESS: {
      REDUX_EVENT_HANDLER(type, payload);

      const newOffer = payload;

      if (global.GEODISC_DEBUG_CHANGED_JSONDIFF_ENABLED) {
        showCommuteOfferUpdates(type, state.get('originalOffer'), newOffer);
      }

      return state.set('currentOffer', newOffer);
    }
    case actions.COMMUTE_OFFER_EDIT_VEHICLE_FAILURE: {
      REDUX_EVENT_HANDLER(type, payload);

      return state;
    }
    case uiActions.SET_IS_CHANGED: {
      REDUX_EVENT_HANDLER(type, payload);

      if (global.GEODISC_DEBUG_CHANGED_JSONDIFF_ENABLED) {
        showCommuteOfferUpdates(
          type,
          state.get('originalOffer'),
          state.get('currentOffer')
        );
      }

      return state;
    }
    case uiActions.SET_IS_SAVING: {
      REDUX_EVENT_HANDLER(type, payload);

      const isSaving = payload;

      if (!isSaving) {
        return state.set(
          'originalOffer',
          JSON.parse(JSON.stringify(state.get('currentOffer')))
        );
      }
      return state;
    }
    case actions.COMMUTE_OFFER_FETCH_SUCCESS: {
      REDUX_EVENT_HANDLER(type, payload);

      const data = payload;

      return state
        .set('currentLayer', null)
        .set('currentOffer', data)
        .set('originalOffer', JSON.parse(JSON.stringify(data)));
    }
    case actions.ROUTE_FETCH_REQUEST: {
      REDUX_EVENT_HANDLER(type, payload);

      return state;
    }
    case actions.ROUTE_FETCH_SUCCESS: {
      REDUX_EVENT_HANDLER(type, payload);

      const { data, agent_id } = payload;

      return state
        .setIn(
          ['currentOffer', 'result', 'routes', agent_id],
          data.routes && data.routes.length && data.routes[0]
        )
        .setIn(['routes', agent_id], data);
    }
    case actions.ROUTE_FETCH_FAILURE: {
      REDUX_EVENT_HANDLER(type, payload);

      return state;
    }
    case actions.COMMUTE_OFFERS_FETCH_SUCCESS: {
      REDUX_EVENT_HANDLER(type, payload);

      const data = payload;

      return state
        .set('list', data.objects)
        .set('count', data.meta.total_count);
    }
    case actions.CLEAR_COMMUTE_OFFER: {
      REDUX_EVENT_HANDLER(type, payload);

      global.WALKING_ROUTES = {};
      global.WALKING_ROUTES_BY_IDS = {};

      return state
        .set('originalOffer', null)
        .set('currentOffer', null)
        .set('routes', Map({}));
    }
    case actions.SET_RESULT_VEHICLES: {
      REDUX_EVENT_HANDLER(type, payload);

      const { vehicleId, nodes } = payload;

      return updateCurrentOffer(
        state,
        { type, payload },
        false,
        currentOffer => ({
          ...currentOffer,
          result: {
            ...currentOffer.result,
            vehicles: {
              ...currentOffer.result.vehicles,
              [vehicleId]: nodes
            }
          }
        })
      );
    }
    case actions.SET_VEHICLE_START_TIME: {
      REDUX_EVENT_HANDLER(type, payload);

      const { vehicleId, time } = payload;

      return updateCurrentOffer(
        state,
        { type, payload },
        true,
        (currentOffer) => {
          const node = {
            ...currentOffer.result.vehicles[vehicleId][0],
            scheduled_ts: time
          };

          return {
            ...currentOffer,
            result: {
              ...currentOffer.result,
              vehicles: {
                ...currentOffer.result.vehicles,
                [vehicleId]: [
                  node,
                  ...currentOffer.result.vehicles[vehicleId].slice(1)
                ]
              }
            }
          };
        }
      );
    }
    case actions.REMOVE_BOOKING_FROM_ROUTE: {
      REDUX_EVENT_HANDLER(type, payload);

      const id = payload;

      return updateCurrentOffer(
        state,
        { type, payload },
        true,
        (currentOffer) => {
          const {
            assigned_bookings,
            rejected_bookings,
            vehicles
          } = currentOffer.result;
          const assignedBookingIdx = assigned_bookings.findIndex(
            item => item.uid === id
          );
          const currentVehicleId =
            assigned_bookings[assignedBookingIdx].assigned_vehicle_id;

          TRACE($ => $('Booking:', assigned_bookings[assignedBookingIdx]));

          return {
            ...currentOffer,
            result: {
              assigned_bookings: assigned_bookings
                .slice(0, assignedBookingIdx)
                .concat(
                  assigned_bookings.slice(
                    assignedBookingIdx + 1,
                    assigned_bookings.length
                  )
                ),
              rejected_bookings: [
                ...rejected_bookings,
                {
                  ...assigned_bookings[assignedBookingIdx],
                  assigned_vehicle_id: undefined
                }
              ],
              vehicles: {
                ...vehicles,
                [currentVehicleId]: vehicles[currentVehicleId].filter(
                  vehicle => vehicle.booking_uid !== id
                )
              }
            }
          };
        }
      );
    }
    case actions.SET_POINT_TO_ROUTE: {
      REDUX_EVENT_HANDLER(type, payload);

      const { lon, lat, vehicleId, route, position } = payload;

      return updateCurrentOffer(
        state,
        { type, payload },
        true,
        (currentOffer) => {
          TRACE($ => $('Current Offer:', currentOffer));

          const { vehicles } = currentOffer.result;

          const point = {
            uid: `${Math.random()}`,
            lat,
            lon,
            node_type: 'point',
            stop_id: null,
            location_name: null,
            service_time: 0,
            scheduled_ts: null,
            booking_uid: null,
            location_code: null
          };

          const firstPartRoute = route.slice(0, position);
          const lastPartRoute = route.slice(position);
          TRACE($ => $('Route Parts:', [firstPartRoute, lastPartRoute]));

          const newVehicle = [
            ...firstPartRoute.reduce(
              (acc, item) =>
                acc.concat(item.bookings.map(booking => booking.node)),
              []
            ),
            point,
            ...lastPartRoute.reduce(
              (acc, item) =>
                acc.concat(item.bookings.map(booking => booking.node)),
              []
            )
          ];

          // currentOffer.stateless_api_request_data.nodes.push(point);

          const res = {
            ...currentOffer,
            result: {
              ...currentOffer.result,
              vehicles: {
                ...vehicles,
                [vehicleId]: newVehicle
              }
            }
          };

          TRACE($ => $('Result:', res));
          return res;
        }
      );
    }
    case actions.SET_STOP_TO_ROUTE: {
      REDUX_EVENT_HANDLER(type, payload);

      const { feature, vehicleId, route } = payload;

      return updateCurrentOffer(
        state,
        { type, payload },
        true,
        (currentOffer) => {
          const { vehicles } = currentOffer.result;
          const { coordinates } = feature.geometry;
          const { properties } = feature;

          const stop = {
            uid: `${Math.random()}`,
            lat: coordinates[1],
            lon: coordinates[0],
            stop_id: String(properties.id),
            location_code: properties.code,
            location_name: `${properties.name} #${properties.code}`,
            node_type: 'stop',
            service_time: 0,
            scheduled_ts: null,
            booking_uid: null
          };

          const newVehicle = [
            ...route.reduce(
              (acc, item) =>
                acc.concat(item.bookings.map(booking => booking.node)),
              []
            ),
            stop
          ];

          return {
            ...currentOffer,
            result: {
              ...currentOffer.result,
              vehicles: {
                ...vehicles,
                [vehicleId]: newVehicle
              }
            }
          };
        }
      );
    }
    case actions.CHANGE_ROUTE_POINT: {
      REDUX_EVENT_HANDLER(type, payload);

      const { lon, lat, id, vehicleId } = payload;

      return updateCurrentOffer(
        state,
        { type, payload },
        false,
        (currentOffer) => {
          const { vehicles } = currentOffer.result;

          const currentVehicle = vehicles[vehicleId];
          TRACE($ => $('Route:', currentVehicle));

          const pointIndex = currentVehicle.findIndex(
            node => node.uid === id
          );
          TRACE($ => $('Point Index:', pointIndex));

          TRACE($ => $('Old Point:', currentVehicle[pointIndex]));
          currentVehicle[pointIndex] = {
            ...currentVehicle[pointIndex],
            lat,
            lon
          };
          TRACE($ => $('New Point:', currentVehicle[pointIndex]));

          return {
            ...currentOffer,
            result: {
              ...currentOffer.result,
              vehicles: {
                ...vehicles,
                [vehicleId]: currentVehicle
              }
            }
          };
        }
      );
    }
    case actions.CHANGE_ROUTE_EMPTY_STOP: {
      REDUX_EVENT_HANDLER(type, payload);

      const { id, stop, vehicleId } = payload;

      return updateCurrentOffer(
        state,
        { type, payload },
        true,
        (currentOffer) => {
          const { vehicles } = currentOffer.result;

          const currentVehicle = vehicles[vehicleId];
          const pointIndex = currentVehicle.findIndex(
            node => node.uid === id
          );

          currentVehicle[pointIndex] = {
            ...currentVehicle[pointIndex],
            ...stop
          };

          return {
            ...currentOffer,
            result: {
              ...currentOffer.result,
              vehicles: {
                ...vehicles,
                [vehicleId]: [...currentVehicle]
              }
            }
          };
        }
      );
    }
    case actions.DELETE_ROUTE_POINT: {
      REDUX_EVENT_HANDLER(type, payload);

      const { id, vehicleId } = payload;

      return updateCurrentOffer(
        state,
        { type, payload },
        true,
        (currentOffer) => {
          const { vehicles } = currentOffer.result;

          const currentVehicle = vehicles[vehicleId];
          const pointIndex = currentVehicle.findIndex(
            node => node.uid === id
          );

          currentVehicle.splice(pointIndex, 1);

          return {
            ...currentOffer,
            result: {
              ...currentOffer.result,
              vehicles: {
                ...vehicles,
                [vehicleId]: currentVehicle
              }
            }
          };
        }
      );
    }
    case actions.ADD_BOOKING_TO_ROUTE: {
      REDUX_EVENT_HANDLER(type, payload);

      const { bookingId, vehicleId } = payload;

      return updateCurrentOffer(
        state,
        { type, payload },
        true,
        (currentOffer) => {
          const {
            assigned_bookings,
            rejected_bookings,
            vehicles
          } = currentOffer.result;

          const rejectedBookingIdx = rejected_bookings.findIndex(
            item => item.uid === bookingId
          );
          const currentBooking = rejected_bookings[rejectedBookingIdx];
          TRACE($ =>
            $(
              'Booking:',
              currentBooking,
              `(Index ${rejectedBookingIdx}, UUID "${bookingId}")`
            ));

          const { nodes } = currentOffer.stateless_api_request_data;

          // Search for nodes
          const pickUpNode = currentBooking.scheduled_pickup_stop_id
            ? nodes.find(
                item =>
                  item.stop_id === currentBooking.scheduled_pickup_stop_id &&
                  item.booking_uid === currentBooking.uid
              )
            : {};
          if (!pickUpNode) {
            // eslint-disable-next-line
            console.log('Error:', 'Pickup node not found.', {
              scheduled_pickup_stop_id: currentBooking.scheduled_pickup_stop_id,
              booking_uid: currentBooking.uid,
              nodes
            });
          }

          const dropoffNode = currentBooking.scheduled_dropoff_stop_id
            ? nodes.find(
                item =>
                  item.stop_id === currentBooking.scheduled_dropoff_stop_id &&
                  item.booking_uid === currentBooking.uid
              )
            : {};
          if (!dropoffNode) {
            // eslint-disable-next-line
            console.log('Error:', 'Dropoff node not found.', {
              scheduled_dropoff_stop_id:
                currentBooking.scheduled_dropoff_stop_id,
              booking_uid: currentBooking.uid,
              nodes
            });
          }
          const vehicle = vehicles[vehicleId] || [];

          const result = {
            assigned_bookings: [
              ...assigned_bookings,
              {
                ...rejected_bookings[rejectedBookingIdx],
                assigned_vehicle_id: vehicleId
              }
            ],
            rejected_bookings: rejected_bookings
              .slice(0, rejectedBookingIdx)
              .concat(
                rejected_bookings.slice(
                  rejectedBookingIdx + 1,
                  rejected_bookings.length
                )
              )
          };

          const pickUpIndex =
            pickUpNode && pickUpNode.stop_id
              ? vehicle.findIndex(item => pickUpNode.stop_id === item.stop_id)
              : -1;
          const dropoffIndex =
            dropoffNode && dropoffNode.stop_id
              ? vehicle.findIndex(
                  item => dropoffNode.stop_id === item.stop_id
                )
              : -1;

          const newPickUpNode = {
            ...pickUpNode,
            booking_uid: currentBooking.uid,
            node_type: 'pickup',
            scheduled_ts: currentBooking.scheduled_pickup_time,
            stop_id: currentBooking.scheduled_pickup_stop_id
          };

          const newDropOffNode = {
            ...dropoffNode,
            booking_uid: currentBooking.uid,
            node_type: 'dropoff',
            scheduled_ts: currentBooking.scheduled_dropoff_time,
            stop_id: currentBooking.scheduled_dropoff_stop_id
          };

          if (
            pickUpIndex !== -1 &&
            dropoffIndex !== -1 &&
            pickUpIndex < dropoffIndex
          ) {
            return {
              ...currentOffer,
              result: {
                ...result,
                vehicles: {
                  ...vehicles,
                  [vehicleId]: vehicle
                    .slice(0, pickUpIndex)
                    .concat([newPickUpNode])
                    .concat(vehicle.slice(pickUpIndex, dropoffIndex))
                    .concat([newDropOffNode])
                    .concat(vehicle.slice(dropoffIndex))
                }
              }
            };
          }

          return {
            ...currentOffer,
            result: {
              ...result,
              vehicles: {
                ...vehicles,
                [vehicleId]: [...vehicle, newPickUpNode, newDropOffNode]
              }
            }
          };
        }
      );
    }
    case actions.SET_BOOKING_NODE: {
      REDUX_EVENT_HANDLER(type, payload);

      const nodes = payload;

      // eslint-disable-next-line arrow-parens
      return updateCurrentOffer(
        state,
        { type, payload },
        true,
        currentOffer => ({
          ...currentOffer,
          result: nodes.reduce((acc, node) => {
            const { booking_uid, stop_id, node_type, open_time_ts } = node;
            const { assigned_bookings } = acc;

            const assignedBookingIdx = assigned_bookings.findIndex(
              item => item.uid === booking_uid
            );
            const currentVehicleId =
              assigned_bookings[assignedBookingIdx].assigned_vehicle_id;

            assigned_bookings[assignedBookingIdx] = {
              ...assigned_bookings[assignedBookingIdx],
              [`scheduled_${node_type}_stop_id`]: stop_id,
              [`scheduled_${node_type}_time`]: open_time_ts
            };

            const index = acc.vehicles[currentVehicleId].findIndex(
              item =>
                item.booking_uid === booking_uid && item.node_type === node_type
            );

            const newVehicle = {
              booking_uid: node.booking_uid,
              lat: node.lat,
              lon: node.lon,
              location_code: node.location_code,
              location_name: node.location_name,
              node_type: node.node_type,
              service_time: node.service_time,
              stop_id: node.stop_id,
              uid: node.uid,
              scheduled_ts: node.open_time_ts
            };

            if (index === -1) {
              return {
                ...acc,
                assigned_bookings,
                vehicles: {
                  ...acc.vehicles,
                  [currentVehicleId]: [
                    ...acc.vehicles[currentVehicleId],
                    newVehicle
                  ]
                }
              };
            }

            DEBUG('Coordinates:', node.lon, node.lat);

            return {
              ...acc,
              assigned_bookings: [...assigned_bookings],
              vehicles: {
                ...acc.vehicles,
                [currentVehicleId]: acc.vehicles[currentVehicleId].map(
                  (vehicle) => {
                    const isChanged =
                      vehicle.booking_uid === booking_uid &&
                      vehicle.node_type === node_type;

                    if (isChanged) {
                      return {
                        ...vehicle,
                        ...newVehicle
                      };
                    }

                    return vehicle;
                  }
                )
              }
            };
          }, currentOffer.result)
        })
      );
    }
    case actions.ADD_NODE: {
      REDUX_EVENT_HANDLER(type, payload);

      const newNodes = payload;

      return updateCurrentOffer(
        state,
        { type, payload },
        true,
        (currentOffer) => {
          const { nodes } = currentOffer.stateless_api_request_data;

          return {
            ...currentOffer,
            stateless_api_request_data: {
              ...currentOffer.stateless_api_request_data,
              nodes: [...nodes, ...newNodes]
            }
          };
        }
      );
    }
    case actions.REMOVE_BOOKING_NODE: {
      REDUX_EVENT_HANDLER(type, payload);

      const { bookingId, nodeType } = payload;

      return updateCurrentOffer(
        state,
        { type, payload },
        true,
        (currentOffer) => {
          const {
            assigned_bookings,
            rejected_bookings,
            vehicles
          } = currentOffer.result;
          const assignedBookingIdx = assigned_bookings.findIndex(
            item => item.uid === bookingId
          );
          const currentVehicleId =
            assigned_bookings[assignedBookingIdx].assigned_vehicle_id;

          TRACE($ => $('Booking:', assigned_bookings[assignedBookingIdx]));

          assigned_bookings[assignedBookingIdx] = {
            ...assigned_bookings[assignedBookingIdx],
            [`scheduled_${nodeType}_stop_id`]: null,
            [`scheduled_${nodeType}_time`]: null
          };

          return {
            ...currentOffer,
            result: {
              assigned_bookings,
              rejected_bookings,
              vehicles: {
                ...vehicles,
                [currentVehicleId]: vehicles[currentVehicleId].map(
                  (vehicle) => {
                    const isChanged =
                      vehicle.booking_uid === bookingId &&
                      vehicle.node_type === nodeType;

                    if (isChanged) {
                      return {
                        booking_uid: bookingId,
                        node_type: nodeType
                      };
                    }

                    return vehicle;
                  }
                )
              }
            }
          };
        }
      );
    }
    case actions.UPDATE_VEHICLE: {
      REDUX_EVENT_HANDLER(type, payload);

      const { vehicleId, vehicle } = payload;

      return updateCurrentOffer(
        state,
        { type, payload },
        true,
        (currentOffer) => {
          const { stateless_api_request_data, result } = currentOffer;

          const newRequest = {
            ...stateless_api_request_data,
            vehicles: stateless_api_request_data.vehicles.map((item) => {
              if (item.agent_id === vehicleId) {
                return vehicle;
              }

              return item;
            })
          };

          if (vehicleId === vehicle.agent_id) {
            return {
              ...currentOffer,
              stateless_api_request_data: newRequest
            };
          }

          const isExisting = stateless_api_request_data.vehicles.find(
            item => item.agent_id === vehicle.agent_id
          );

          if (isExisting) {
            return currentOffer;
          }

          const updatedVehicles = {
            ...result.vehicles,
            [vehicle.agent_id]: result.vehicles[vehicleId]
          };

          delete updatedVehicles[vehicleId];

          return {
            ...currentOffer,
            result: {
              ...result,
              assigned_bookings: result.assigned_bookings.map((booking) => {
                if (booking.assigned_vehicle_id === vehicleId) {
                  return {
                    ...booking,
                    assigned_vehicle_id: vehicle.agent_id
                  };
                }

                return booking;
              }),
              vehicles: updatedVehicles
            },
            stateless_api_request_data: newRequest
          };
        }
      );
    }
    case actions.CLONE_VEHICLE: {
      REDUX_EVENT_HANDLER(type, payload);

      const vehicle = payload;

      return updateCurrentOffer(
        state,
        { type, payload },
        true,
        (currentOffer) => {
          const { stateless_api_request_data, result } = currentOffer;

          return {
            ...currentOffer,
            result: {
              ...result,
              vehicles: {
                ...result.vehicles,
                veh_new: []
              }
            },
            stateless_api_request_data: {
              ...stateless_api_request_data,
              vehicles: [
                ...stateless_api_request_data.vehicles,
                {
                  ...vehicle,
                  agent_id: 'veh_new'
                }
              ]
            }
          };
        }
      );
    }
    case actions.DELETE_VEHICLE: {
      REDUX_EVENT_HANDLER(type, payload);

      const vehicleId = payload;

      return updateCurrentOffer(
        state,
        { type, payload },
        true,
        (currentOffer) => {
          const { stateless_api_request_data, result } = currentOffer;

          const requestVehicles = stateless_api_request_data.vehicles;

          const newResultVehicles = result.vehicles;

          delete newResultVehicles[vehicleId];

          const requestVehIdx = requestVehicles.findIndex(
            item => item.agent_id === vehicleId
          );

          return {
            ...currentOffer,
            result: {
              ...result,
              vehicles: newResultVehicles
            },
            stateless_api_request_data: {
              ...stateless_api_request_data,
              vehicles: requestVehicles
                .slice(0, requestVehIdx)
                .concat(
                  requestVehicles.slice(
                    requestVehIdx + 1,
                    requestVehicles.length
                  )
                )
            }
          };
        }
      );
    }
    case actions.NEW_VEHICLE: {
      REDUX_EVENT_HANDLER(type, payload);

      return updateCurrentOffer(
        state,
        { type, payload },
        true,
        (currentOffer) => {
          const { stateless_api_request_data, result } = currentOffer;
          const vehicles = stateless_api_request_data.vehicles || [];

          return {
            ...currentOffer,
            result: {
              ...result,
              vehicles: {
                ...result.vehicles,
                veh_new: []
              }
            },
            stateless_api_request_data: {
              ...stateless_api_request_data,
              vehicles: [...vehicles, defaultVehicle]
            }
          };
        }
      );
    }
    case actions.CHANGE_ROUTE_ORDER: {
      REDUX_EVENT_HANDLER(type, payload);

      const { currentIndex, newIndex, route, vehicleId } = payload;

      if (currentIndex === newIndex) {
        return state;
      }

      return updateCurrentOffer(
        state,
        { type, payload },
        true,
        (currentOffer) => {
          const { vehicles } = currentOffer.result;

          const newRoute = [...route];

          newRoute.splice(newIndex, 1, route[currentIndex]);
          newRoute.splice(currentIndex, 1, route[newIndex]);

          return {
            ...currentOffer,
            result: {
              ...currentOffer.result,
              vehicles: {
                ...vehicles,
                [vehicleId]: newRoute.reduce((acc, item) => {
                  if (!item) {
                    return acc;
                  }

                  const { bookings } = item;
                  const nodes = bookings.map(booking => booking.node);

                  return acc.concat(nodes);
                }, [])
              }
            }
          };
        }
      );
    }
    case actions.ADD_COMMUTE_OFFER_REQUEST: {
      REDUX_EVENT_HANDLER(type, payload);
      return state;
    }
    case actions.ADD_COMMUTE_OFFER_SUCCESS: {
      REDUX_EVENT_HANDLER(type, payload);
      return state;
    }
    case actions.ADD_COMMUTE_OFFER_FAILURE: {
      REDUX_EVENT_HANDLER(type, payload);
      return state;
    }
    case actions.DUPLICATE_COMMUTE_OFFER_REQUEST: {
      REDUX_EVENT_HANDLER(type, payload);
      return state;
    }
    case actions.DUPLICATE_COMMUTE_OFFER_SUCCESS: {
      REDUX_EVENT_HANDLER(type, payload);
      return state;
    }
    case actions.DUPLICATE_COMMUTE_OFFER_FAILURE: {
      REDUX_EVENT_HANDLER(type, payload);
      return state;
    }
    default:
      return state;
  }
};

export default reducer;
