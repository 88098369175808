import React, { PureComponent } from 'react';
import debug from 'utils/debug';

import Progress from './Progress';

const { METHOD } = debug('c:ModalWindow:ProgressWindow:Window');

class ModalWindow extends PureComponent {
  state = {};

  render() {
    METHOD('render', { props: this.props });

    const { progress, status } = this.props;

    return <Progress progress={progress} status={status} />;
  }
}

export default ModalWindow;
