import debug from 'utils/debug';
import { urls } from '../config';
import { fetchData } from './net';
import { getHeaders } from './headers';

const { METHOD } = debug('api:commuteOffer');

function sleep(ms) {
  return new Promise(resolve => setTimeout(resolve, ms));
}

export const buildingByZipCode = async (country, city, zipCode) => {
  METHOD('buildingByZipCode:Request', { country, city, zipCode });

  try {
    const response = await fetchData(
      urls.buildingByZipCode(country, city, zipCode),
      {
        headers: getHeaders()
      }
    );

    if (!response.ok) {
      throw new Error(response.status);
    }

    const result = await response.json();

    METHOD('buildingByZipCode:Success', {
      zipCode,
      result
    });
    return { country, city, zipCode, result };
  } catch (error) {
    METHOD('buildingByZipCode:Failure', { country, city, zipCode, error });
    throw error;
  }
};

export const nearestStop = async (lon, lat, stopType) => {
  METHOD('nearestStop:Request', { lon, lat, stopType });

  try {
    const response = await fetchData(urls.nearestStop(lon, lat, stopType), {
      headers: getHeaders()
    });

    if (!response.ok) {
      throw new Error(response.status);
    }

    const result = await response.json();

    METHOD('nearestStop:Success', {
      lon,
      lat,
      stopType,
      result
    });
    return { lon, lat, stopType, result };
  } catch (error) {
    METHOD('nearestStop:Failure', { lon, lat, stopType, error });
    throw error;
  }
};

export const findStopsByCoordinates = async (coordinates, opts) => {
  METHOD('findStopsByCoordinates:Request', { coordinates, opts });

  const options = opts || {};

  const ignoreErrors = options.ignoreErrors || false;
  const setProgress = options.setProgress || (async () => {});

  setProgress(0);

  const nearestStopRequestsResolved = [];
  const nearestStopRequestsRejected = [];

  const nearestStopRequests = coordinates.map((item) => {
    const lon = item[0];
    const lat = item[1];
    const stopType = 'building';
    return nearestStop(lon, lat, stopType).then(
      (result) => {
        nearestStopRequestsResolved.push({ lon, lat, stopType, result });
      },
      (error) => {
        nearestStopRequestsRejected.push({ lon, lat, stopType, error });
      }
    );
  });
  METHOD('findStopsByCoordinates:nearestStopRequests', { nearestStopRequests });

  // eslint-disable-next-line no-constant-condition
  while (true) {
    const progress =
      (nearestStopRequestsResolved.length +
        nearestStopRequestsRejected.length) /
      nearestStopRequests.length;
    METHOD('findStopsByCoordinates:nearestStopRequests:Progress', {
      nearestStopRequestsResolved,
      nearestStopRequestsRejected,
      nearestStopRequests,
      progress
    });

    // eslint-disable-next-line no-await-in-loop
    await setProgress(progress);

    if (
      nearestStopRequestsResolved.length +
        nearestStopRequestsRejected.length ===
      nearestStopRequests.length
    ) {
      break;
    }
    // eslint-disable-next-line no-await-in-loop
    await sleep(500);
  }

  METHOD('findStopsByCoordinates:nearestStopRequests:Resolved', {
    nearestStopRequestsResolved
  });
  METHOD('findStopsByCoordinates:nearestStopRequests:Rejected', {
    nearestStopRequestsRejected
  });

  if (!ignoreErrors) {
    if (nearestStopRequestsRejected.length) {
      throw new Error(
        `Failed to find nearest stops for ${nearestStopRequestsRejected.length} points`
      );
    }
  }

  const stopsByCoordinates = nearestStopRequestsResolved.reduce((acc, stop) => {
    const { lat, lon, stopType } = stop;
    const ident = JSON.stringify({ lon, lat, stopType });
    acc[ident] = stop.result;
    return acc;
  }, {});
  METHOD('findStopsByCoordinates:Success', { stopsByCoordinates });

  return stopsByCoordinates;
};
