import React, { memo } from 'react';
import Input from './Input';
import Container from './Container';
import search from './search.svg';

const Search = memo(({ text, clearList, onChange, dataFetch, ...props }) => {
  const onChangeHandler = (e) => {
    onChange(e.target.value);
  };

  const onKeyUp = (e) => {
    e.preventDefault();
    // Number 13 is the "Enter" key on the keyboard
    if (dataFetch && e.keyCode === 13) {
      // Trigger the button element with a click
      clearList();
      dataFetch();
    }
  };

  return (
    <Container style={props.style}>
      <img src={search} alt='search' />
      <Input
        type='text'
        onChange={onChangeHandler}
        onKeyUp={onKeyUp}
        {...props}
      />
    </Container>
  );
});

export default Search;
