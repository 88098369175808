import React from 'react';
import { Formik } from 'formik';

import Input from 'components/Input';
import ResetButton from 'components/ResetButton';
import SubmitButton from 'components/SubmitButton';
import InputSelectTags from 'components/InputSelectTags';

import Table from './Table';
import Row from './Row';
import ButtonsRow from './ButtonsRow';

const Preview = ({ file, data, onReset, uploadData, projectUrl }) => (
  <Formik
    initialValues={{
      name: file.name,
      description: '',
      tags: [],
      project: projectUrl
    }}
    onSubmit={(values) => {
      const { tags, ...otherValues } = values;

      const body = JSON.stringify({
        tags: tags.map(item => item.value),
        ...otherValues
      });

      uploadData(body);
    }}
  >
    {(props) => {
      const {
        values,
        handleChange,
        handleSubmit,
        setFieldValue,
        setFieldTouched,
        handleBlur
      } = props;

      return (
        <form onSubmit={handleSubmit} autoComplete='off'>
          <Row>
            <Input
              name='name'
              type='text'
              label='Name'
              value={values.name}
              onChange={handleChange}
              handleBlur={handleBlur}
              autoComplete='off'
            />
          </Row>
          <Row>
            <Input
              name='description'
              type='text'
              label='Description'
              value={values.description}
              onChange={handleChange}
            />
            <InputSelectTags
              name='tags'
              label='Tags'
              isMulti
              value={values.tags}
              onChange={setFieldValue}
              onBlur={setFieldTouched}
            />
          </Row>
          <Table data={data} size={file.size} />
          <ButtonsRow>
            <ResetButton onClick={onReset}>Choose a different file</ResetButton>
            <SubmitButton type='submit'>Create new dataset</SubmitButton>
          </ButtonsRow>
        </form>
      );
    }}
  </Formik>
);

export default Preview;
