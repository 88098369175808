import { connect } from 'react-redux';
import createImmutableSelector from 'create-immutable-selector';

import {
  activeVehicleIdsSelector,
  activeRouteStopSelector,
  addPointModeSelector
} from 'modules/ui/selectors';

import {
  setActiveRouteStop,
  cleanActiveRouteStop,
  setAddPointMode,
  cleanAddPointMode
} from 'modules/ui/actions';

import {
  changeRouteOrder,
  deleteRoutePoint
} from 'modules/commuteOffer/actions';

import { flyToSimulationActiveStop } from 'modules/maps/actions';

import Route from './Route';

const mapStateToProps = createImmutableSelector(
  activeVehicleIdsSelector,
  activeRouteStopSelector,
  addPointModeSelector,
  (activeVehicleIds, activeRouteStop, pointEditing) => ({
    activeVehicleIds,
    activeRouteStop,
    pointEditing
  })
);

const mapDispatchToProps = {
  setActiveRouteStop,
  cleanActiveRouteStop,
  flyToSimulationActiveStop,
  changeRouteOrder,
  setAddPointMode,
  cleanAddPointMode,
  deleteRoutePoint
};

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps
);

export default withConnect(Route);
