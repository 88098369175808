import { Map } from 'immutable';

import debug from 'utils/debug';
import * as actions from './actions';

const { REDUX_EVENT_HANDLER2, REDUX_EVENT_HANDLER } = debug('m:ui:user');

export const initialState = Map({
  projects: [],
  currentProject: null
});

const reducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case actions.AUTHORIZATION_SUCCESS: {
      REDUX_EVENT_HANDLER(type, payload);

      const { user } = payload;

      return state.merge(user);
    }
    case actions.PROJECTS_FETCH_SUCCESS: {
      return REDUX_EVENT_HANDLER2(type, payload, state, ({ $METHOD }) => {
        REDUX_EVENT_HANDLER(type, payload);

        const projects = payload;

        const projectName =
          global.GEODISC_LOCATION_HASH_OPTS.get('project') ||
          localStorage.getItem('user:currentProjectName');
        $METHOD('projectName', { projectName });

        if (!state.currentProject && projects.size) {
          const project = projectName
            ? projects.find(x => x.get('name') === projectName)
            : projects.get(0);
          return state.set('projects', projects).set('currentProject', project);
        }

        return state.set('projects', projects);
      });
    }
    case actions.CURRENT_PROJECT_CHANGE: {
      REDUX_EVENT_HANDLER(type, payload);

      const project = payload;

      const projectName = project.get('name');

      if (global.GEODISC_LOCATION_HASH_OPTS.get('project')) {
        global.GEODISC_LOCATION_HASH_OPTS.set('project', projectName);
        // window.location.hash = '#';
      }

      localStorage.setItem('user:currentProjectName', projectName);

      return state.set('currentProject', project);
    }
    case actions.DELETE_USER: {
      REDUX_EVENT_HANDLER(type, payload);

      localStorage.removeItem(`${global.GEODISC_VERSION.ver}:login`);
      localStorage.removeItem(`${global.GEODISC_VERSION.ver}:token`);

      return initialState;
    }
    default:
      return state;
  }
};

export default reducer;
