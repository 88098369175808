import 'normalize.css';
import 'mapbox-gl/dist/mapbox-gl.css';
import '@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css';
import { createGlobalStyle } from 'styled-components';

export default createGlobalStyle`
  * {
    box-sizing: border-box;
  }
  
  scrollbar-width: none;

  &::-webkit-scrollbar {
    width: 0;
    height: 0;
  }

  &::-webkit-scrollbar-track {
    -webkit-box-shadow: none;
    border-radius: 0;
  }

  &::-webkit-scrollbar-thumb {
    border: transparent 1px solid;
    background-color: transparent;
  }

  html {
    overflow: -moz-scrollbars-none;
  }

  body {
    font-family: "PT Sans Caption", Arial, sans-serif;
    color: #c5cede;

    margin: 0;
    height: 100%;
    overflow: hidden;
    background: rgb(54, 61, 74);

    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
`;
