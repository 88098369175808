import React from 'react';
import { getTimeInMinutes } from 'utils/simulations';
import CheckBox from 'components/CheckBox';

import Container from './Container';
import RowContainer from './RowContainer';
import Link from './Link';
import Column from './Column';
import LeftColumn from './LeftColumn';
import RightColumn from './RightColumn';
import MenuButton from './MenuButtom';
import Status from './Status';

const Row = ({ simulation, selectedIds, ...props }) => {
  const date = new Date(simulation.get('created_at'));
  const isDeleted = simulation.get('deleted');
  const bookmarked = simulation.get('bookmarked');
  const state = isDeleted ? 'deleted' : simulation.get('state');
  const isActive = state === 'completed';
  const transfer_rate = simulation.get('transfer_rate');
  const id = simulation.get('id');

  const onSelected = () => {
    props.toggleSelectedSimulations(simulation);
  };

  const exportSimulation = () => {
    window.open(
      `${window.GEODISC_API_URL}/api/v2/simulation/${id}/export`,
      '_blank'
    );
  };

  const deleteSimulation = () => {
    props.updateSimulation(id, JSON.stringify({ deleted: true }));
  };

  const onClickStar = () => {
    const body = JSON.stringify({ bookmarked: !bookmarked });
    props.updateSimulation(id, body);
  };

  return (
    <Container isActive={isActive}>
      <LeftColumn style={{ paddingLeft: 20 }}>
        <CheckBox
          isActive={selectedIds.includes(id)}
          onClick={() => onSelected(id)}
          style={{ marginRight: 20 }}
        />
      </LeftColumn>
      <RowContainer>
        <Link to={`/simulation/${simulation.get('id')}`}>
          <Column width='70px'>{simulation.get('id')}</Column>
          <Column width='200px'>{simulation.get('name')}</Column>
          <Column width='150px'>
            <Status
              state={state}
              onClickStar={onClickStar}
              bookmarked={bookmarked}
            />
          </Column>
          <Column style={{ paddingLeft: 20 }} width='200px'>
            {simulation.get('dataset_name')}
          </Column>
          <Column width='200px'>{simulation.get('geofence_name')}</Column>
          <Column>{simulation.get('number_of_vehicles')}</Column>
          <Column>
            {getTimeInMinutes(simulation.get('min_waiting_time'))}
          </Column>
          <Column>
            {getTimeInMinutes(simulation.get('max_waiting_time'))}
          </Column>
          <Column>{simulation.get('bookings_count')}</Column>
          <Column>{simulation.get('vehicle_capacity')}</Column>
          <Column width='150px'>
            {getTimeInMinutes(simulation.get('max_additional_journey_time'))}
          </Column>
          <Column width='120px'>
            {getTimeInMinutes(simulation.get('min_advance_booking_window'))}
          </Column>
          <Column width='150px'>
            {getTimeInMinutes(simulation.get('max_advance_booking_window'))}
          </Column>
          <Column>{date.toDateString()}</Column>
          <Column>{transfer_rate && transfer_rate.toFixed(0)}</Column>
        </Link>
        <RightColumn>
          <MenuButton
            exportSimulation={exportSimulation}
            deleteSimulation={deleteSimulation}
          />
        </RightColumn>
      </RowContainer>
    </Container>
  );
};

export default Row;
