import React, { memo } from 'react';
import logo from 'assets/SWAT-Icon-Orange.png';

import Container from './Container';

import ImgLogo from './ImgLogo';

const Logo = () => (
  <Container>
    <ImgLogo src={logo} alt='GADP' />
  </Container>
);

export default memo(Logo);
