import { connect } from 'react-redux';
import createImmutableSelector from 'create-immutable-selector';

import {
  filteredBookingsSelector,
  activeBookingIndexSelector
} from 'modules/commuteOffer/selectors';
import { isBookingsHiddenSelector } from 'modules/ui/selectors';

import { toggleBookingsHidden } from 'modules/ui/actions';

import Bookings from './Bookings';

const mapStateToProps = createImmutableSelector(
  filteredBookingsSelector,
  isBookingsHiddenSelector,
  activeBookingIndexSelector,
  (bookings, isHidden, activeBookingIndex) => ({
    bookings,
    isHidden,
    activeBookingIndex
  })
);

const mapDispatchToProps = {
  toggleBookingsHidden
};

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps
);

export default withConnect(Bookings);
