import React from 'react';
import CSV from 'comma-separated-values';
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  ModalButton
} from 'baseui/modal';
import { exportFile } from 'utils/file';

import debug from 'utils/debug';
import Table from './Table';

const { METHOD } = debug('c:ModalWindows:TableViewer');

export default (props) => {
  METHOD('TableViewer', props);

  const [isOpen, setIsOpen] = React.useState(false);
  const [headers, setHeaders] = React.useState([]);
  const [content, setContent] = React.useState([]);
  const [title, setTitle] = React.useState('Info');
  const [fileName, setFileName] = React.useState();
  const [config, setConfig] = React.useState({});

  React.useEffect(() => {
    METHOD('Update', { props });

    window.openTableViewer = ($headers, $content, opts) => {
      METHOD('openTableViewer', { $headers, $content, opts });

      const options = opts || {};

      const $title = options.title || 'Source';
      const $fileName = options.fileName;

      if (!$headers || !$content) {
        global.openInfoMessage('Nothing to show', { title: $title });
        return;
      }

      if (Array.isArray($content) && $content.length === 0) {
        global.openInfoMessage('Nothing to show', { title: $title });
      }

      setTitle($title);
      setFileName($fileName);
      setHeaders($headers);
      setContent($content);
      setConfig(options);
      setIsOpen(true);
    };

    window.closeTableViewer = () => {
      METHOD('closeTableViewer', { isOpen, config });
      setIsOpen(false);
    };

    return () => {
      METHOD('Cleanup', { isOpen, config });
      window.closeTableViewer = null;
    };
  });

  function onClose() {
    METHOD('onClose', { isOpen, config });

    setIsOpen(false);
  }

  function onExport() {
    METHOD('onExport', { isOpen, config });

    const csvHeader = [headers.map(column => column.name)];

    const csvData = [...csvHeader, ...content];
    METHOD('onExport:csvData', { csvData });

    const csv = new CSV(csvData);
    const csvBody = csv.encode();

    // global.openSourceEditor(csvBody, { title: 'Source' });
    exportFile(csvBody, fileName);
  }

  return (
    <React.Fragment>
      <Modal
        onClose={onClose}
        isOpen={isOpen}
        overrides={{
          Dialog: {
            style: {
              width: '90vw',
              height: '90vh',
              display: 'flex',
              flexDirection: 'column'
            }
          }
        }}
      >
        <ModalHeader>{title}</ModalHeader>
        <ModalBody style={{ flex: '1 1 0' }}>
          <Table headers={headers} content={content} />
        </ModalBody>
        <ModalFooter>
          {fileName && <ModalButton onClick={onExport}>Export</ModalButton>}
          <ModalButton onClick={onClose}>Close</ModalButton>
        </ModalFooter>
      </Modal>
    </React.Fragment>
  );
};
