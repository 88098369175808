import React from 'react';
import Container from './Container';

const Error = ({ error, waiting }) => {
  const message = error === '401' ? 'Authorization failed' : error;
  return (
    <Container isWaiting={waiting}>
      {waiting && 'waiting...'}
      {message}
    </Container>
  );
};

export default Error;
