import React, { PureComponent } from 'react';

import debug from 'utils/debug';
import Container from './Container';
import Stop from './Stop';

const { DEBUG, EVENT, TRACE } = debug('p:Simulation:LeftPanels:Vehicles:Route');

class Route extends PureComponent {
  moveCard = (dragIndex, hoverIndex) => {
    const { route, vehicleId } = this.props;
    this.props.changeRouteOrder(dragIndex, hoverIndex, route, vehicleId);
  };

  render() {
    EVENT('render');

    const {
      vehicleId,
      route,
      color,
      activeRouteStop,
      cleanActiveRouteStop,
      setActiveRouteStop,
      removeBookingFromRoute,
      flyToSimulationActiveStop,
      isHaveEditable,
      togglePointEditing,
      deleteRoutePoint,
      pointEditing,
      activeVehicleIds
    } = this.props;

    if (!route.length) {
      return <Container>No assigned bookings</Container>;
    }

    return (
      <Container>
        {route.map((stop, i) => {
          const isActive = stop.id === activeRouteStop;

          const onClick = () => {
            DEBUG('onClick()');
            if (isActive) {
              cleanActiveRouteStop();
            } else {
              TRACE($ => $('Stop:', stop));
              setActiveRouteStop(stop.id, stop.uid);
              flyToSimulationActiveStop(stop.id);
            }
          };

          return (
            <Stop
              isActive={isActive}
              stop={stop}
              index={i}
              id={i}
              key={i}
              color={color}
              vehicleId={vehicleId}
              removeBookingFromRoute={removeBookingFromRoute}
              isHaveEditable={isHaveEditable}
              onClick={onClick}
              moveCard={this.moveCard}
              togglePointEditing={togglePointEditing}
              pointEditing={pointEditing}
              deleteRoutePoint={deleteRoutePoint}
              activeVehicleIds={activeVehicleIds}
              cleanActiveRouteStop={cleanActiveRouteStop}
            />
          );
        })}
      </Container>
    );
  }
}

export default Route;
