import React, { PureComponent } from 'react';
import debug from 'utils/debug';

import CSV from 'comma-separated-values';

import DropZone from 'components/DropZone';
// import { validateCsvFile } from 'utils/file';

import Preview from './Preview';
import Progress from './Progress';
import Error from './Error';

const { METHOD } = debug('c:ModalWindow:ImportBookings:ImportBookings');

class ImportBookings extends PureComponent {
  state = {
    data: null,
    file: null,
    error: null
  };

  readFile = (file) => {
    METHOD('readFile', file);

    const reader = new FileReader();

    reader.onloadend = (e) => {
      if (e.target.readyState === FileReader.DONE) {
        METHOD('readFile:Done', e.target.result);

        const data = new CSV(e.target.result).parse();
        METHOD('readFile:Data', { data });

        this.setState({ data });
      }
    };

    const blob = file.slice(0, 1024 ** 3);
    reader.readAsBinaryString(blob);
  };

  handleFileSelect = (e) => {
    METHOD('handleFileSelect', e);

    const file = e.target.files[0]; // FileList object

    // if (validateCsvFile(file)) {
    this.setState({ file, error: null });
    this.readFile(file);
    // } else {
    //   this.setState({ error: 'Oops! Invalid format' });
    // }
  };

  handleDropFileSelect = (e) => {
    METHOD('handleDropFileSelect', e);

    e.stopPropagation();
    e.preventDefault();
    const file = e.dataTransfer.files[0];

    // if (validateCsvFile(file)) {
    this.setState({ file, error: null });
    this.readFile(file);
    // } else {
    //   this.setState({ error: 'Oops! Invalid format' });
    // }
  };

  handleDragOver = (e) => {
    METHOD('handleDragOver', e);

    e.stopPropagation();
    e.preventDefault();
    e.dataTransfer.dropEffect = 'copy';
  };

  uploadData = (params, opts) => {
    global.METHODS.reset();

    METHOD('uploadData', { params, opts });

    const { currentOffer } = this.props;
    const { data } = this.state;

    this.props.commuteOfferImportBookingsRequest(
      currentOffer,
      data,
      params,
      opts
    );
  };

  clearData = () => {
    METHOD('clearData');

    this.setState({ file: null, data: null });
  };

  render() {
    METHOD('render', { props: this.props });

    if (!(window.File && window.FileReader && window.FileList && window.Blob)) {
      return <div>The File APIs are not fully supported in this browser.</div>;
    }

    const { data, file, error } = this.state;
    const { progress, status, projectUrl } = this.props;
    const apiError = this.props.error;
    const isError = error || apiError;

    if (progress) {
      return <Progress progress={progress} status={status} />;
    }

    if (data && !isError) {
      return (
        <Preview
          data={data}
          file={file}
          setProgress={this.props.commuteOfferImportBookingsProgress}
          uploadData={this.uploadData}
          onReset={this.clearData}
          projectUrl={projectUrl}
        />
      );
    }

    return (
      <React.Fragment>
        <DropZone
          onDragOver={this.handleDragOver}
          onDrop={this.handleDropFileSelect}
          onChange={this.handleFileSelect}
        />
        {isError && <Error>{`${error || apiError}. Try again.`}</Error>}
      </React.Fragment>
    );
  }
}

export default ImportBookings;
