// @flow

import createImmutableSelector from 'create-immutable-selector';
import { connect } from 'react-redux';
import {
  vehiclesFilteredBySearchSelector,
  bookingsFilteredBySearchSelector
} from 'modules/commuteOffer/selectors';
import {
  changeSearchQuery,
  setActiveVehicleId,
  setActiveBookingId
} from 'modules/ui/actions';

import {
  flyToSimulationActiveBooking,
  flyToSimulationVehicle
} from 'modules/maps/actions';

import Search from './Search';

const mapStateToProps = createImmutableSelector(
  vehiclesFilteredBySearchSelector,
  bookingsFilteredBySearchSelector,
  (vehicles, bookings) => ({ vehicles, bookings })
);
const mapDispatchToProps = {
  changeSearchQuery,
  setActiveVehicleId,
  setActiveBookingId,
  flyToSimulationActiveBooking,
  flyToSimulationVehicle
};

const withConnect = connect(
  mapStateToProps,
  mapDispatchToProps
);

export default withConnect(Search);
