import { getContrastRandomColor } from 'utils/color';

import {
  GEOFENCES_FETCH_SUCCESS,
  ADD_GEOFENCE_SUCCESS,
  UPDATE_GEOFENCE_SUCCESS,
  DELETE_GEOFENCE_SUCCESS,
  CLEAR_LIST
} from './actions';

export const initialState = null;

const reducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case GEOFENCES_FETCH_SUCCESS: {
      const data = payload.objects;

      return data.map(value => ({
        ...value,
        color: getContrastRandomColor('#23282e')
      }));
    }
    case ADD_GEOFENCE_SUCCESS: {
      const data = payload;
      const id = payload.id;
      data.color = getContrastRandomColor('#23282e');

      if (id) {
        return [...state, data];
      }

      return [...state];
    }
    case UPDATE_GEOFENCE_SUCCESS: {
      const data = payload;
      data.color = getContrastRandomColor('#23282e');
      const { id } = data;
      const index = state.findIndex(item => item.id === id);
      const newState = state;
      newState[index] = data;

      return [...newState];
    }
    case DELETE_GEOFENCE_SUCCESS: {
      const key = payload;
      const index = state.findIndex(item => item.key === key);
      state.splice(index, 1);
      return [...state];
    }
    case CLEAR_LIST: {
      return [];
    }
    default:
      return state;
  }
};

export default reducer;
