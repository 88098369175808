import React from 'react';
import debug from 'utils/debug';

import { commuteSchedule$FixedScheduleGenerators } from 'utils/CommuteSchedule';

import { Formik } from 'formik';
import moment from 'moment';

import Input from 'components/Input';
import InputSelect from 'components/InputSelect';
import DateTimePicker from 'components/DateTimePicker';

import ResetButton from 'components/ResetButton';
import SubmitButton from 'components/SubmitButton';

import Row from './Row';
import ButtonsRow from './ButtonsRow';

const { METHOD } = debug(
  'c:ModalWindow:CommuteScheduleImportPassengers:Preview:index'
);

const handleKeyDown = (event) => {
  if (event.keyCode === 13) {
    event.preventDefault();
    return false;
  }
  return true;
};

const calculationTimeValues = [
  // eslint-disable-next-line prefer-spread
  ...Array.apply(null, Array(17)).map((x, index) => {
    const value = 1000 * 60 * (index + 1);
    return {
      label: `${moment
        .duration(Math.round(value + 1), 'milliseconds')
        .humanize()}`,
      value
    };
  }),
  { label: '10 seconds', value: 10000 }
];

const Preview = ({
  file,
  data,
  setProgress,
  onReset,
  uploadData,
  projectUrl
}) => {
  METHOD('Preview', {
    file,
    data,
    setProgress,
    onReset,
    uploadData,
    projectUrl
  });

  const now = new Date();
  now.setHours(0, 0, 0, 0);

  const initialValues = {
    name: file.name,
    from_date: now,
    till_date: now,
    ignore_errors: { label: 'No', value: false },
    clear_existing_data: { label: 'Yes', value: true },
    human_readable_uids: { label: 'Yes', value: true },
    node_scheduler_mode: { label: 'Enabled', value: true },
    areCalculationGroupsEnabled: { label: 'Disabled', value: false },
    areMutuallyExclusiveGroupsEnabled: { label: 'Disabled', value: false },
    areSpecialDemandsEnabled: { label: 'Disabled', value: false },
    fixedScheduleGeneratorType: {
      label: 'Sample day with all passengers',
      value: 'Sample day with all passengers'
    },
    unassigned_only: { label: 'One vehicle for each booking', value: false },
    source_file_timezone: {
      label: 'Singapore',
      value: 'Singapore'
    },
    time_limit_ms: { label: '10 seconds', value: 10000 },
    max_slack: '86400',
    max_slack_fs: '300',
    max_slack_ph: '86400',
    service_time: '30',
    vehicle_passenger: '8',
    vehicle_wheelchair: '4',
    project: projectUrl
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={(values) => {
        METHOD('onSubmit', values);
        uploadData(values, { setProgress });
      }}
    >
      {(props) => {
        const {
          values,
          handleChange,
          handleSubmit,
          setFieldValue,
          setFieldTouched,
          handleBlur
        } = props;

        return (
          <form onSubmit={handleSubmit} autoComplete='off'>
            {false && (
              <Row>
                <Input
                  name='name'
                  type='text'
                  label='Name'
                  value={values.name}
                  onChange={handleChange}
                  handleBlur={handleBlur}
                  onKeyDown={handleKeyDown}
                  autoComplete='off'
                />
              </Row>
            )}
            <Row>
              <InputSelect
                id='source_file_timezone'
                name='source_file_timezone'
                label='Source file timezone'
                options={moment.tz
                  .names()
                  .map(tz => ({ value: tz, label: tz }))}
                defaultValue={values.source_file_timezone}
                onKeyDown={handleKeyDown}
                onChange={setFieldValue}
                onBlur={setFieldTouched}
              />
            </Row>
            <Row>
              <DateTimePicker
                name='from_date'
                label='Start date'
                format='MMMM D, YYYY'
                time={false}
                value={values.from_date}
                onKeyDown={handleKeyDown}
                onChange={setFieldValue}
              />
              <DateTimePicker
                name='till_date'
                label='End date'
                format='MMMM D, YYYY'
                time={false}
                value={values.till_date}
                onKeyDown={handleKeyDown}
                onChange={setFieldValue}
              />
            </Row>
            {false && (
              <Row>
                <InputSelect
                  id='clear_existing_data'
                  name='clear_existing_data'
                  label='Clear existing data'
                  options={[
                    { label: 'Yes', value: true },
                    { label: 'No', value: false }
                  ]}
                  defaultValue={values.clear_existing_data}
                  onKeyDown={handleKeyDown}
                  onChange={setFieldValue}
                  onBlur={setFieldTouched}
                />
              </Row>
            )}
            <Row>
              <InputSelect
                id='ignore_errors'
                name='ignore_errors'
                label='Ignore records with errors'
                options={[
                  { label: 'Yes', value: true },
                  { label: 'No', value: false }
                ]}
                defaultValue={values.ignore_errors}
                onKeyDown={handleKeyDown}
                onChange={setFieldValue}
                onBlur={setFieldTouched}
              />
            </Row>
            <Row>
              <InputSelect
                id='human_readable_uids'
                name='human_readable_uids'
                label='Generate human readable identifiers'
                options={[
                  { label: 'Yes', value: true },
                  { label: 'No', value: false }
                ]}
                defaultValue={values.human_readable_uids}
                onKeyDown={handleKeyDown}
                onChange={setFieldValue}
                onBlur={setFieldTouched}
              />
            </Row>
            <Row>
              <InputSelect
                id='areCalculationGroupsEnabled'
                name='areCalculationGroupsEnabled'
                label='Calculation groups'
                options={[
                  { label: 'Enabled', value: true },
                  { label: 'Disabled', value: false }
                ]}
                defaultValue={values.areCalculationGroupsEnabled}
                onKeyDown={handleKeyDown}
                onChange={setFieldValue}
                onBlur={setFieldTouched}
              />
              <InputSelect
                id='areMutuallyExclusiveGroupsEnabled'
                name='areMutuallyExclusiveGroupsEnabled'
                label='Mutually exclusive groups'
                options={[
                  { label: 'Enabled', value: true },
                  { label: 'Disabled', value: false }
                ]}
                defaultValue={values.areMutuallyExclusiveGroupsEnabled}
                onKeyDown={handleKeyDown}
                onChange={setFieldValue}
                onBlur={setFieldTouched}
              />
              <InputSelect
                id='areSpecialDemandsEnabled'
                name='areSpecialDemandsEnabled'
                label='Special demands'
                options={[
                  { label: 'Enabled', value: true },
                  { label: 'Disabled', value: false }
                ]}
                defaultValue={values.areSpecialDemandsEnabled}
                onKeyDown={handleKeyDown}
                onChange={setFieldValue}
                onBlur={setFieldTouched}
              />
            </Row>
            <Row>
              <InputSelect
                id='fixedScheduleGeneratorType'
                name='fixedScheduleGeneratorType'
                label='Fixed schedule generation strategy'
                options={Object.keys(
                  commuteSchedule$FixedScheduleGenerators
                ).map(name => ({
                  label: name,
                  value: name
                }))}
                defaultValue={values.fixedScheduleGeneratorType}
                onKeyDown={handleKeyDown}
                onChange={setFieldValue}
                onBlur={setFieldTouched}
              />
            </Row>
            {false && (
              <Row>
                <InputSelect
                  id='unassigned_only'
                  name='unassigned_only'
                  label='Vehicles generation strategy'
                  options={[
                    {
                      label: 'One vehicle for each mutually exclusive group',
                      value: true
                    },
                    { label: 'Ove vehicle for each booking', value: false }
                  ]}
                  defaultValue={values.unassigned_only}
                  onKeyDown={handleKeyDown}
                  onChange={setFieldValue}
                  onBlur={setFieldTouched}
                />
              </Row>
            )}
            {false && (
              <Row>
                <Input
                  name='max_slack_ph'
                  label='Maximum slack time for physical vehicles, in seconds'
                  value={values.max_slack_ph}
                  onChange={handleChange}
                  handleBlur={handleBlur}
                  onKeyDown={handleKeyDown}
                  autoComplete='off'
                />
              </Row>
            )}
            <Row>
              <Input
                name='service_time'
                label='Service duration, in seconds'
                value={values.service_time}
                onChange={handleChange}
                handleBlur={handleBlur}
                onKeyDown={handleKeyDown}
                autoComplete='off'
              />
              <Input
                name='max_slack'
                label='Maximum slack time, in seconds'
                value={values.max_slack}
                onChange={handleChange}
                handleBlur={handleBlur}
                onKeyDown={handleKeyDown}
                autoComplete='off'
              />
            </Row>
            <Row>
              <Input
                name='vehicle_passenger'
                label='Vehicle capacity'
                value={values.vehicle_passenger}
                onChange={handleChange}
                handleBlur={handleBlur}
                onKeyDown={handleKeyDown}
                autoComplete='off'
              />
              <Input
                name='vehicle_wheelchair'
                label='Wheelchair passengers'
                value={values.vehicle_wheelchair}
                onChange={handleChange}
                handleBlur={handleBlur}
                onKeyDown={handleKeyDown}
                autoComplete='off'
              />
            </Row>
            {false && (
              <Row>
                <InputSelect
                  id='node_scheduler_mode'
                  name='node_scheduler_mode'
                  label='Nodes scheduler'
                  options={[
                    { label: 'Enabled', value: true },
                    { label: 'Disabled', value: false }
                  ]}
                  defaultValue={values.node_scheduler_mode}
                  onKeyDown={handleKeyDown}
                  onChange={setFieldValue}
                  onBlur={setFieldTouched}
                />
              </Row>
            )}
            <Row>
              <InputSelect
                id='time_limit_ms'
                name='time_limit_ms'
                label='Calculation time limit'
                options={calculationTimeValues}
                defaultValue={values.time_limit_ms}
                onKeyDown={handleKeyDown}
                onChange={setFieldValue}
                onBlur={setFieldTouched}
              />
            </Row>
            <ButtonsRow>
              <ResetButton onClick={onReset}>
                Choose a different file
              </ResetButton>
              <SubmitButton type='submit'>Import bookings</SubmitButton>
            </ButtonsRow>
          </form>
        );
      }}
    </Formik>
  );
};

export default Preview;
