import createImmutableSelector from 'create-immutable-selector';
import debug from 'utils/debug';

const { COUNTER } = debug('m:ui:selectors');

export const uiSelector = state => {
  COUNTER('uiSelector');
  return state.get('ui');
};

// COMMUTE_OFFER
export const commuteOfferUiSelector = createImmutableSelector(
  uiSelector,
  ui => {
    COUNTER('commuteOfferUiSelector');
    return ui.get('commuteOffer');
  }
);

export const commuteOffersOrderingSelector = createImmutableSelector(
  commuteOfferUiSelector,
  commuteOffer => commuteOffer.get('ordering')
);

export const isBookingsHiddenSelector = createImmutableSelector(
  commuteOfferUiSelector,
  commuteOffer => commuteOffer.get('isBookingsHidden')
);

export const isChangedSelector = createImmutableSelector(
  commuteOfferUiSelector,
  commuteOffer => commuteOffer.get('isChanged')
);

export const isSavingSelector = createImmutableSelector(
  commuteOfferUiSelector,
  commuteOffer => commuteOffer.get('isSaving')
);

export const addStopModeSelector = createImmutableSelector(
  commuteOfferUiSelector,
  commuteOffer => commuteOffer.get('addStopMode')
);

export const editableVehicleTimeSelector = createImmutableSelector(
  commuteOfferUiSelector,
  commuteOffer => commuteOffer.get('editableVehicleTime')
);

export const stopSearchQuerySelector = createImmutableSelector(
  commuteOfferUiSelector,
  commuteOffer => commuteOffer.get('stopSearchQuery')
);

export const activeVehicleIdsSelector = createImmutableSelector(
  commuteOfferUiSelector,
  commuteOffer => {
    COUNTER('activeVehicleIdsSelector');
    return global.GEODISC_MAP_ALWAYS_SHOW_ONLY_ACTIVE_ROUTES
      ? commuteOffer
          .get('activeVehicleIds')
          .push('DEADBEEF')
          .toJS()
      : commuteOffer.get('activeVehicleIds').toJS();
  }
);

export const activeRouteStopSelector = createImmutableSelector(
  commuteOfferUiSelector,
  commuteOffer => commuteOffer.get('activeRouteStopId')
);

export const activeRouteStopUidSelector = createImmutableSelector(
  commuteOfferUiSelector,
  commuteOffer => commuteOffer.get('activeRouteStopUid')
);

export const activeBookingIdSelector = createImmutableSelector(
  commuteOfferUiSelector,
  commuteOffer => commuteOffer.get('activeBookingId')
);

export const editableBookingIdSelector = createImmutableSelector(
  commuteOfferUiSelector,
  commuteOffer => commuteOffer.get('editableBookingId')
);

export const draggablePointSelector = createImmutableSelector(
  commuteOfferUiSelector,
  commuteOffer => commuteOffer.get('draggablePoint')
);

export const editableVehicleIdSelector = createImmutableSelector(
  commuteOfferUiSelector,
  commuteOffer => commuteOffer.get('editableVehicleId')
);

export const busStopsVisibleSelector = createImmutableSelector(
  commuteOfferUiSelector,
  commuteOffer => commuteOffer.get('busStopsTypeVisible')
);

export const searchQuerySelector = createImmutableSelector(
  commuteOfferUiSelector,
  commuteOffer => commuteOffer.get('searchQuery')
);

export const bookingsFilterSelector = createImmutableSelector(
  commuteOfferUiSelector,
  commuteOffer => commuteOffer.get('bookingsFilter')
);

export const vehiclesFilterSelector = createImmutableSelector(
  commuteOfferUiSelector,
  commuteOffer => commuteOffer.get('vehiclesFilter')
);

export const routingEngineSelector = createImmutableSelector(
  commuteOfferUiSelector,
  commuteOffer => commuteOffer.get('routingEngine')
);

export const walkingRoutingEngineSelector = createImmutableSelector(
  commuteOfferUiSelector,
  () => global.GEODISC_WALKING_ROUTING_ENGINE
);

export const addPointModeSelector = createImmutableSelector(
  commuteOfferUiSelector,
  commuteOffer => commuteOffer.get('addPointMode')
);

export const selectedCommuteOffersSelector = createImmutableSelector(
  commuteOfferUiSelector,
  commuteOffer => commuteOffer.get('selected')
);

export const selectedCommuteOfferIdsSelector = createImmutableSelector(
  selectedCommuteOffersSelector,
  commuteOffers =>
    commuteOffers && commuteOffers.map(simulation => simulation.get('id'))
);

export const commuteOffersErrorSelector = createImmutableSelector(
  commuteOfferUiSelector,
  commuteOffer => commuteOffer.get('fetchError')
);

// DATASETS
export const datasetsUiSelector = createImmutableSelector(uiSelector, ui =>
  ui.get('datasets')
);

export const datasetsOrderingSelector = createImmutableSelector(
  datasetsUiSelector,
  datasetsUi => datasetsUi.get('ordering')
);

export const datasetsSearchSelector = createImmutableSelector(
  datasetsUiSelector,
  datasetsUi => datasetsUi.get('search')
);

export const datasetsErrorSelector = createImmutableSelector(
  datasetsUiSelector,
  datasetsUi => datasetsUi.get('fetchError')
);

export const selectedIdsDatasetsSelector = createImmutableSelector(
  datasetsUiSelector,
  datasetsUi => datasetsUi.get('selectedIds')
);

// DATASET
export const datasetUiSelector = createImmutableSelector(uiSelector, ui =>
  ui.get('dataset')
);

export const layerPanelSelector = createImmutableSelector(
  datasetUiSelector,
  dataset => dataset.get('layerPanel')
);

export const datasetTimeSelector = createImmutableSelector(
  datasetUiSelector,
  dataset => dataset.get('time')
);

export const activeLayerSelector = createImmutableSelector(
  layerPanelSelector,
  layerPanel => layerPanel.get('activeLayer')
);

export const layerStyleSelector = createImmutableSelector(
  layerPanelSelector,
  activeLayerSelector,
  (layerPanel, activeLayer) => layerPanel.get(activeLayer)
);

export const datasetErrorSelector = createImmutableSelector(
  datasetUiSelector,
  datasetUi => datasetUi.get('fetchError')
);

export const csvErrorSelector = createImmutableSelector(
  datasetUiSelector,
  datasetUi => datasetUi.get('fetchCsvError')
);

export const editableDatasetSelector = createImmutableSelector(
  datasetUiSelector,
  datasetUi => datasetUi.get('editable')
);

export const datasetRunningSelector = createImmutableSelector(
  datasetUiSelector,
  datasetUi => datasetUi.get('running')
);

export const datasetAlldaySelector = createImmutableSelector(
  datasetUiSelector,
  datasetUi => datasetUi.get('allDay')
);

// GEOFENCES
export const geofencesUiSelector = createImmutableSelector(uiSelector, ui =>
  ui.get('geofences')
);

export const hiddenGeofencesSelector = createImmutableSelector(
  geofencesUiSelector,
  geofencesUi => geofencesUi.get('hidden')
);

export const editableGeofenceSelector = createImmutableSelector(
  geofencesUiSelector,
  geofencesUi => geofencesUi.get('editable')
);

export const geofencesSearchSelector = createImmutableSelector(
  geofencesUiSelector,
  geofencesUi => geofencesUi.get('search')
);

export const geofencesHoverSelector = createImmutableSelector(
  geofencesUiSelector,
  geofencesUi => geofencesUi.get('hoverId')
);

export const geofencesErrorSelector = createImmutableSelector(
  geofencesUiSelector,
  geofencesUi => geofencesUi.get('fetchError')
);

// SIMULATIONS
export const simulationsUiSelector = createImmutableSelector(uiSelector, ui =>
  ui.get('simulations')
);

export const simulationsOrderingSelector = createImmutableSelector(
  simulationsUiSelector,
  simulationsUi => simulationsUi.get('ordering')
);

export const simulationsSearchSelector = createImmutableSelector(
  simulationsUiSelector,
  simulationsUi => simulationsUi.get('search')
);

export const selectedSimulationsSelector = createImmutableSelector(
  simulationsUiSelector,
  simulationsUi => simulationsUi.get('selected')
);

export const selectedSimulationIdsSelector = createImmutableSelector(
  selectedSimulationsSelector,
  simulations => simulations.map(simulation => simulation.get('id'))
);

export const compareModSelector = createImmutableSelector(
  simulationsUiSelector,
  simulationsUi => simulationsUi.get('compareMode')
);

export const simulationsErrorSelector = createImmutableSelector(
  simulationsUiSelector,
  simulationsUi => simulationsUi.get('fetchError')
);

// newDataset
export const newDatasetSelector = createImmutableSelector(uiSelector, ui =>
  ui.get('newDataset')
);

export const uploadProgressSelector = createImmutableSelector(
  newDatasetSelector,
  newDataset => newDataset.get('progress')
);

export const uploadStatusSelector = createImmutableSelector(
  newDatasetSelector,
  newDataset => newDataset.get('status')
);

export const newDatasetErrorSelector = createImmutableSelector(
  newDatasetSelector,
  newDataset => newDataset.get('error')
);

// importBookings
export const importBookingsSelector = createImmutableSelector(uiSelector, ui =>
  ui.get('importBookings')
);

export const importBookingsProgressSelector = createImmutableSelector(
  importBookingsSelector,
  importBookings => importBookings.get('progress')
);

export const importBookingsStatusSelector = createImmutableSelector(
  importBookingsSelector,
  importBookings => importBookings.get('status')
);

export const importBookingsErrorSelector = createImmutableSelector(
  importBookingsSelector,
  importBookings => importBookings.get('error')
);

// progressWindow
export const progressWindowSelector = createImmutableSelector(uiSelector, ui =>
  ui.get('progressWindow')
);

export const progressWindowProgressSelector = createImmutableSelector(
  importBookingsSelector,
  importBookings => importBookings.get('progress')
);

export const progressWindowStatusSelector = createImmutableSelector(
  importBookingsSelector,
  importBookings => importBookings.get('status')
);

// modalWindow

export const modalWindowSelector = createImmutableSelector(uiSelector, ui =>
  ui.get('modalWindow')
);

export const loginStateSelector = createImmutableSelector(uiSelector, ui =>
  ui.get('login')
);
