import React, { PureComponent } from 'react';
import { List as VirtualizedList, AutoSizer } from 'react-virtualized';
import { memoHumanize } from 'utils/CommuteOffer';
import PanelContainer from '../PanelContainer';
import Header from './Header';
import Booking from './Booking';

import PanelList from '../PanelList';

class Bookings extends PureComponent {
  componentDidUpdate() {
    this.theList.forceUpdateGrid();
  }

  renderBooking = ({ index, key, style }) => {
    const { bookings, stops } = this.props;

    return (
      <div key={key} style={style}>
        <Booking booking={memoHumanize(bookings[index], stops)} />
      </div>
    );
  };

  render() {
    const {
      bookings,
      isHidden,
      toggleBookingsHidden,
      activeBookingIndex
    } = this.props;

    return (
      <PanelContainer
        isHidden={isHidden}
        onClick={isHidden ? toggleBookingsHidden : null}
      >
        <Header
          key='header'
          size={bookings.length}
          isHidden={isHidden}
          toggleBookingsHidden={toggleBookingsHidden}
        />
        <PanelList key='list' isHidden={isHidden}>
          <AutoSizer>
            {({ height, width }) => (
              <VirtualizedList
                // eslint-disable-next-line
                ref={(ref) => (this.theList = ref)}
                width={width}
                height={height}
                rowHeight={170}
                rowRenderer={this.renderBooking}
                scrollToIndex={activeBookingIndex}
                rowCount={bookings.length}
                overscanRowCount={10}
              />
            )}
          </AutoSizer>
        </PanelList>
      </PanelContainer>
    );
  }
}

export default Bookings;
