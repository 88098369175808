import Immutable from 'immutable';
import moment from 'moment';
import {
  delay,
  call,
  select,
  put,
  takeLatest,
  takeEvery,
  take,
  fork,
  cancel
} from 'redux-saga/effects';
import { api$Simulation as api, api$Geofence } from 'api';

import { getColorByKey } from 'utils/simulations';
import debug from 'utils/debug';
import * as actions from './actions';
import * as uiActions from '../ui/actions';
import { routePageSelector, routeIdSelector } from '../router/selectors';
import {
  simulationsOrderingSelector,
  simulationsSearchSelector,
  modalWindowSelector
} from '../ui/selectors';
import {
  simulationSelector,
  minMaxTimeSelector,
  simulationTimeSelector,
  simulationSpeedSelector,
  simulationRunningSelector
} from './selectors';
import { currentProjectIdSelector } from '../user/selectors';

const { DEBUG, METHOD } = debug('m:simulations:saga');

function* fetchSimulations() {
  METHOD('fetchSimulations');

  try {
    const page = yield select(routePageSelector);
    const ordering = yield select(simulationsOrderingSelector);
    const search = yield select(simulationsSearchSelector);
    const projectId = yield select(currentProjectIdSelector);

    const orderingParam = `${ordering.get('sort') ? '' : '-'}${ordering.get(
      'id'
    )}`;

    const data = yield call(
      api.getSimulations,
      page,
      orderingParam,
      search,
      projectId
    );

    yield put({
      type: actions.SIMULATIONS_FETCH_SUCCESS,
      payload: Immutable.fromJS(data)
    });
  } catch (error) {
    yield put({ type: actions.SIMULATIONS_FETCH_FAILURE, payload: error });
  }
}

function* fetchSimulation({ payload }) {
  METHOD('fetchSimulation', payload);

  try {
    const id = payload;

    const data = yield call(api.getSimulation, id);

    const dataGeofence = yield call(
      api$Geofence.getGeofenceFilteredById,
      data.geofence_id
    );
    const { geometry } = dataGeofence.objects[0];
    data.geometry = geometry;

    const bookings = yield call(api.getBookingsForSimulation, id);
    const vehicles = yield call(api.getVehiclesForSimulation, id);
    const nodes = yield call(api.getNodesForSimulation, id);

    const nodesByBookingUID = nodes.objects.reduce((acc, item) => {
      acc[item.booking_uid] = item;
      return acc;
    }, {});
    DEBUG('Nodes:', nodesByBookingUID);

    yield put({
      type: actions.SIMULATION_FETCH_SUCCESS,
      payload: {
        data,
        bookings: bookings.objects.reduce((acc, booking) => {
          const node = nodesByBookingUID[booking.uid];
          const assigned_vehicle_id =
            node &&
            (() => {
              const re = /\/.*\/([0-9]*)$/;
              return parseInt(node.assigned_vehicle.match(re)[1], 10);
            })();
          acc.push({
            ...booking,
            assigned_vehicle_id,
            color: getColorByKey(assigned_vehicle_id)
          });
          return acc;
        }, []),
        vehicles: vehicles.objects.reduce((acc, vehicle) => {
          acc.push({
            ...vehicle,
            activeColor: getColorByKey(vehicle.id)
          });
          return acc;
        }, []),
        nodes: nodes.objects
      }
    });
  } catch ($error) {
    METHOD('fetchSimulation:Error', { ...payload, $error });
    yield put({ type: actions.SIMULATION_FETCH_FAILURE, payload: $error });
  }
}

function* fetchSimulationData({ payload }) {
  METHOD('fetchSimulationData', payload);

  try {
    let { simulation } = payload;
    DEBUG('Old Simulation:', simulation);

    const { id, offset, limit } = payload;

    const newSimulation =
      (simulation && simulation.isLive) || !simulation
        ? yield call(api.getSimulation, id)
        : simulation;
    DEBUG('New Simulation:', newSimulation);

    simulation = simulation || newSimulation;

    if (!newSimulation.geometry) {
      if (!simulation.geometry) {
        const dataGeofence = yield call(
          api$Geofence.getGeofenceFilteredById,
          newSimulation.geofence_id
        );
        const { geometry } = dataGeofence.objects[0];
        newSimulation.geometry = geometry;
      } else {
        newSimulation.geometry = simulation.geometry;
      }
    }

    DEBUG('State:', simulation.state, '=>', newSimulation.state);
    DEBUG('Live:', simulation.isLive, '=>', newSimulation.isLive);

    if (newSimulation.useVehicleLog) {
      const { meta, objects } = yield call(api.getData, id, offset, limit);
      // meta.total_count = 3000;

      objects.forEach((item) => {
        // eslint-disable-next-line no-param-reassign
        item.current_sim_ts_unix = Math.ceil(
          moment(item.current_sim_ts).unix()
        );
      });

      let progress = meta.offset ? meta.offset / meta.total_count : 0;
      if (newSimulation.isLive) {
        progress = 1;
      }

      yield put({
        type: actions.DATA_FETCH_SUCCESS,
        payload: {
          meta,
          objects,
          simulation: newSimulation,
          progress:
            meta.total_count <= meta.offset + objects.length ? 1 : progress
        }
      });
      if (
        meta.total_count <= meta.offset + objects.length &&
        !newSimulation.isLive
      ) {
        yield put({ type: actions.DATA_FETCH_FINISH });
      }
    } else {
      yield put({ type: actions.DATA_FETCH_FINISH });
    }
  } catch (error) {
    yield put({ type: actions.DATA_FETCH_FAILURE, payload: error });
  }
}

// let offset = 0;
function* fetchSimulationDataRun(id) {
  METHOD('fetchSimulationDataRun:Request', { id });

  const tasks = window.GEODISC_API_SIMULATION_CONCURRENT_REQUEST_COUNT;
  const limit = 1000;

  yield put(actions.fetchSimulation(id));

  yield take(actions.SIMULATION_FETCH_SUCCESS);

  const simulation = yield select(simulationSelector);
  DEBUG('Simulation:', simulation);

  const { meta } = yield call(api.getData, id, 0, 1);

  let loaders = [];

  for (let offset = 0; offset < meta.total_count; offset += limit) {
    loaders.push(
      api.getData(id, offset, limit).catch((e) => {
        METHOD('fetchSimulationDataRun:Error', { id, $error: e.message });
        return { error: e, meta: { offset }, objects: [] };
      })
    );
    if (loaders.length === tasks) {
      const data = yield Promise.all(loaders);
      const vehicles = data.reduce((acc, item) => {
        return [...acc, ...item.objects];
      }, []);
      METHOD('fetchSimulationDataRun:Success', { id, vehicles });
      yield put(
        actions.fetchSimulationDataSuccess(
          simulation,
          meta,
          vehicles,
          (global.SIMULATION_DATA_RAW.length + vehicles.length) /
            meta.total_count
        )
      );
      loaders = [];
    }
  }

  if (loaders.length > 0) {
    const data = yield Promise.all(loaders);
    const vehicles = data.reduce((acc, item) => {
      return [...acc, ...item.objects];
    }, []);
    METHOD('fetchSimulationDataRun:Success', { id, vehicles });
    yield put(
      actions.fetchSimulationDataSuccess(
        simulation,
        meta,
        vehicles,
        (global.SIMULATION_DATA_RAW.length + vehicles.length) / meta.total_count
      )
    );
  }

  yield put(actions.fetchSimulationDataSuccess(simulation, meta, [], 1));
}

let simulationDataTask;

function* fetchSimulationDataStart({ payload }) {
  METHOD('fetchSimulationDataStart', payload);

  const id = payload;
  // offset = 0;
  simulationDataTask = yield fork(fetchSimulationDataRun, id);
}

function* fetchSimulationDataStop() {
  METHOD('fetchSimulationDataStop');

  if (simulationDataTask) {
    yield cancel(simulationDataTask);
  }
}

function* addSimulation({ payload }) {
  METHOD('addSimulation', payload);

  try {
    const { body } = payload;
    const modalWindow = yield select(modalWindowSelector);

    const { id } = yield call(api.addSimulations, body);

    yield call(api.launchSimulation, id);

    const simulation = yield call(api.getSimulation, id);

    yield put({
      type: actions.ADD_SIMULATION_SUCCESS,
      payload: Immutable.fromJS(simulation)
    });

    if (modalWindow) {
      yield put(uiActions.closePopup());
    }
  } catch (error) {
    yield put({ type: actions.ADD_SIMULATION_FAILURE, payload: error });
  }
}

function* updateSimulation({ payload }) {
  METHOD('updateSimulation', payload);

  try {
    const isSimulationPage = yield select(routeIdSelector);
    const { id, body } = payload;
    yield call(api.updateSimulation, id, body);

    yield put({ type: actions.UPDATE_SIMULATION_SUCCESS });

    if (isSimulationPage) {
      yield put(actions.fetchSimulation(id));
    } else {
      yield put(actions.fetchSimulations());
    }
  } catch (error) {
    yield put({ type: actions.UPDATE_SIMULATION_FAILURE, payload: error });
  }
}

function* deleteSimulation({ payload }) {
  METHOD('deleteSimulation', payload);

  try {
    const id = payload;
    yield call(api.deleteSimulation, id);
    yield put({
      type: actions.DELETE_SIMULATION_SUCCESS,
      payload: id
    });
    yield put(actions.fetchSimulations());
  } catch (error) {
    yield put({ type: actions.DELETE_SIMULATION_FAILURE, payload: error });
  }
}

function* websocketConnected() {
  METHOD('websocketConnected');
  yield;
}

function* websocketDisconnected() {
  METHOD('websocketDisconnected');
  yield;
}

function* simulationEvent({ payload }) {
  METHOD('simulationEvent', payload);
  yield;
}

// Player

function* simulationRun() {
  METHOD('simulationRun');

  let running = true;

  while (running) {
    const { maxTime } = yield select(minMaxTimeSelector);
    const time = yield select(simulationTimeSelector);
    const speed = yield select(simulationSpeedSelector);
    running = yield select(simulationRunningSelector);
    const nextTime = time + speed * 10;

    yield delay(1000);
    yield put({
      type: actions.SIMULATION_NEXT,
      payload: maxTime < nextTime ? maxTime : nextTime
    });
  }

  yield put({
    type: actions.SIMULATION_STOP
  });
}

let simulationTask;
function* simulationPlay() {
  METHOD('simulationPlay');

  if (simulationTask) {
    yield cancel(simulationTask);
  }

  simulationTask = yield fork(simulationRun);
}

function* simulationStop() {
  METHOD('simulationStop');

  if (simulationTask) {
    yield cancel(simulationTask);
  }
}

async function setSimulationLiveTime() {
  METHOD('setSimulationLiveTime');
}

function* Saga() {
  yield takeLatest(actions.SIMULATION_PLAY, simulationPlay);
  yield takeLatest(actions.SIMULATION_STOP, simulationStop);
  yield takeLatest(actions.SIMULATIONS_FETCH_REQUEST, fetchSimulations);
  yield takeLatest(actions.SIMULATION_FETCH_REQUEST, fetchSimulation);
  yield takeLatest(actions.DELETE_SIMULATION_REQUEST, deleteSimulation);
  yield takeLatest(actions.UPDATE_SIMULATION_REQUEST, updateSimulation);
  yield takeEvery(actions.ADD_SIMULATION_REQUEST, addSimulation);
  yield takeLatest(actions.ADD_SIMULATION_SUCCESS, fetchSimulations);
  yield takeLatest(actions.DATA_FETCH_REQUEST, fetchSimulationData);
  yield takeLatest(actions.DATA_FETCH_START, fetchSimulationDataStart);
  yield takeLatest(actions.DATA_FETCH_STOP, fetchSimulationDataStop);
  yield takeLatest(actions.DATA_FETCH_SUCCESS, setSimulationLiveTime);
  yield takeLatest(actions.DATA_FETCH_FINISH, fetchSimulationDataStop);
  yield takeEvery(actions.WEBSOCKET_CONNECTED, websocketConnected);
  yield takeEvery(actions.WEBSOCKET_DISCONNECTED, websocketDisconnected);
  yield takeEvery(actions.SIMULATION_EVENT, simulationEvent);
}

export default Saga;
