import moment from 'moment';
import memoizeOne from 'memoize-one';
import debug from 'utils/debug';
import deepmerge from 'deepmerge';
import parseColor from 'parse-color';
import { diffString } from 'json-diff';
import { crc32 } from 'utils/crc32';

import {
  defaultVehicleRoutingServer,
  defaultVehicleRoutingEngine,
  emptyCommuteOfferJSON
} from './defaults';

const { METHOD, PROFILER, MESSAGE } = debug('utils:commuteOffer');

export const getRoute = (vehicle, nodes, vehicleId) => {
  METHOD('getRoute', { vehicle, nodes, vehicleId });

  const res = PROFILER('getRoute', () =>
    vehicle.reduce((acc, node) => {
      const { booking_uid, uid, stop_id, ...routeItem } = node;

      if (!uid) {
        return acc;
      }

      const nodeByUID = nodes[uid];

      if (!nodeByUID && node.node_type !== 'point') {
        MESSAGE('getRoute', {
          message: `Error: The result vehicle contains a ${node.node_type} node that cannot be found the request nodes list`, // eslint-disable-line
          vehicle: vehicleId,
          node
        });
      }

      const pax = nodeByUID
        ? Math.abs(nodeByUID.demand.passenger || nodeByUID.demand.passengers)
        : 0;

      const newStop = {
        ...routeItem,
        id: `${vehicleId}-${0}`,
        position: 0,
        stop_id,
        uid,
        pax,
        bookings: [{ id: booking_uid || uid, pax, node }],
        bookingsById: {
          [booking_uid || uid]: { pax, node }
        }
      };

      // if no stops, add first
      if (!acc.length) {
        return [newStop];
      }

      const lastIndex = acc.length - 1;
      const isDifferentStop =
        acc[lastIndex].stop_id !== stop_id ||
        acc[lastIndex].node_type !== routeItem.node_type;

      if (
        acc[lastIndex].stop_id === stop_id &&
        acc[lastIndex].node_type === 'stop' &&
        routeItem.node_type !== 'point' &&
        routeItem.node_type !== 'stop'
      ) {
        acc[lastIndex] = { ...newStop, id: acc[lastIndex].id };
        return acc;
      }

      if (
        acc[lastIndex].stop_id === stop_id &&
        routeItem.node_type === 'stop' &&
        acc[lastIndex].node_type !== 'point' &&
        acc[lastIndex].node_type !== 'stop'
      ) {
        return acc;
      }

      // if newStop
      if (isDifferentStop || routeItem.node_type === 'point') {
        return [
          ...acc,
          {
            ...newStop,
            id: `${vehicleId}-${acc[lastIndex].position + 1}`,
            position: acc[lastIndex].position + 1
          }
        ];
      }

      const lastStop = acc[lastIndex];

      if (lastStop.bookingsById[booking_uid]) {
        return acc;
      }

      // if same stop
      return [
        ...acc.slice(0, -1),
        {
          ...lastStop,
          pax: lastStop.pax + 1,
          bookings: [...lastStop.bookings, { id: booking_uid, pax, node }],
          bookingsById: {
            ...lastStop.bookingsById,
            [booking_uid]: { pax, node }
          }
        }
      ];
    }, []));

  return res;
};

export const getBookingType = (isActiveVehicle, isActive, isAssigned) => {
  if (isAssigned) {
    if (isActive) {
      return 'active';
    }

    if (isActiveVehicle) {
      return 'activeVehicle';
    }

    return 'assigned';
  }

  if (isActive) {
    return 'unassignedActive';
  }

  return 'unassigned';
};

export const getWalkingRouteType = (
  isActive,
  isActiveStop,
  isActiveBooking
) => {
  if (isActive) {
    if (isActiveBooking) {
      return 'activeBooking';
    }

    if (isActiveStop) {
      return 'activeStop';
    }

    return 'active';
  }

  return 'inactive';
};

export const getCurbMode = curb => (curb ? 'curb' : 'unrestricted');

export const getRouteCoordinates = (route, approaches, curb) => {
  METHOD('getRouteCoordinates', { route, approaches, curb });

  if (approaches) {
    return `${route
      .map(stop => `${stop.lon},${stop.lat}`)
      .join(';')}?approaches=${route
      .map((stop) => {
        return typeof stop.curb !== 'undefined'
          ? getCurbMode(stop.curb)
          : getCurbMode(curb);
      })
      .join('%3B')}`;
  }

  return `${route.map(stop => `${stop.lon},${stop.lat}`).join(';')}?`;
};

export const humanize = item => ({
  ...item,
  scheduled_pickup_time: item.scheduled_pickup_time
    ? moment(item.scheduled_pickup_time)
        .tz(global.GEODISC_TIMEZONE)
        .format('HH:mm')
    : '--:--',
  scheduled_dropoff_time: item.scheduled_dropoff_time
    ? moment(item.scheduled_dropoff_time)
        .tz(global.GEODISC_TIMEZONE)
        .format('HH:mm')
    : '--:--',
  scheduled_pickup_stop_name: item.pickup_location_name
    ? item.pickup_location_name
    : '---',
  scheduled_dropoff_stop_name: item.dropoff_location_name
    ? item.dropoff_location_name
    : '---'
});

export const memoHumanize = memoizeOne(humanize);

const normalizeVehicleColor = (vehicle) => {
  const { agent_id } = vehicle;

  if (vehicle.vehicle_color) {
    const { vehicle_color } = vehicle;

    if (typeof vehicle_color === 'number') {
      return `hsl(${Math.round(vehicle_color % 360)}, 100%, 70%)`;
    }
    if (typeof vehicle_color === 'string') {
      const color = parseColor(vehicle_color);
      return `hsl(${color.hsl[0]}, 100%, 70%)`;
    }
  }

  return `hsl(${Math.round(crc32(agent_id) % 360)}, 100%, 70%)`;
};

export const validateCommuteOffer = async (commuteOffer, opts) => {
  METHOD('validateCommuteOffer:Enter', { commuteOffer });

  const options = opts || {};

  const timezone = options.tz || global.GEODISC_TIMEZONE || 'UTC';

  const { result, stateless_api_request_data } = commuteOffer;

  const readOnly = result.readOnly || false;

  // eslint-disable-next-line
  const { changelog } = result;

  const stateless_api_request_data__vehicles = stateless_api_request_data.vehicles.map(
    (vehicle) => {
      const vehicle_color = normalizeVehicleColor(vehicle);
      return {
        ...vehicle,
        vehicle_color,
        routing_engine: {
          ...defaultVehicleRoutingEngine,
          ...(vehicle.routing_engine || {}),
          ...defaultVehicleRoutingServer
        },
        start_time:
          vehicle.start_time &&
          moment(vehicle.start_time)
            .tz(timezone)
            .format(),
        end_time:
          vehicle.end_time &&
          moment(vehicle.end_time)
            .tz(timezone)
            .format(),
        color: undefined,
        activeColor: undefined
      };
    }
  );
  METHOD('validateCommuteOffer:stateless_api_request_data__vehicles', {
    stateless_api_request_data__vehicles
  });

  const stateless_api_request_data__nodes = stateless_api_request_data.nodes.map(
    node => ({
      ...node,
      open_time_ts:
        node.open_time_ts &&
        moment(node.open_time_ts)
          .tz(timezone)
          .format(),
      close_time_ts:
        node.close_time_ts &&
        moment(node.close_time_ts)
          .tz(timezone)
          .format(),
      close_time_ts_dynamic:
        node.close_time_ts_dynamic &&
        moment(node.close_time_ts_dynamic)
          .tz(timezone)
          .format()
    })
  );
  METHOD('validateCommuteOffer:stateless_api_request_data__nodes', {
    stateless_api_request_data__nodes
  });

  const result__vehicles = Object.keys(result.vehicles).reduce(
    (acc, vehicleId) => {
      const nodes = result.vehicles[vehicleId];
      acc[vehicleId] = nodes.map(node => ({
        ...node,
        calculated_scheduled_ts:
          node.calculated_scheduled_ts &&
          moment(node.calculated_scheduled_ts)
            .tz(timezone)
            .format(),
        scheduled_ts:
          node.scheduled_ts &&
          moment(node.scheduled_ts)
            .tz(timezone)
            .format(),
        matrix_timestamp:
          node.matrix_timestamp &&
          moment(node.matrix_timestamp)
            .tz(timezone)
            .format()
      }));
      return acc;
    },
    {}
  );
  METHOD('validateCommuteOffer:result__vehicles', { result__vehicles });

  const processBookingsList = bookings =>
    bookings.map((booking) => {
      return {
        ...booking,
        scheduled_pickup_time:
          booking.scheduled_pickup_time &&
          moment(booking.scheduled_pickup_time)
            .tz(timezone)
            .format(),
        scheduled_dropoff_time:
          booking.scheduled_dropoff_time &&
          moment(booking.scheduled_dropoff_time)
            .tz(timezone)
            .format()
      };
    }, {});

  const result__assigned_bookings = processBookingsList(
    result.assigned_bookings
  );
  METHOD('validateCommuteOffer:result__assigned_bookings', {
    result__assigned_bookings
  });

  const result__rejected_bookings = processBookingsList(
    result.rejected_bookings
  );
  METHOD('validateCommuteOffer:result__rejected_bookings', {
    result__rejected_bookings
  });

  const stateless_api_request_data__bookings = result.assigned_bookings.reduce(
    (memo, booking) => {
      return !stateless_api_request_data.bookings[booking.uid]
        ? {
            ...memo,
            [booking.uid]: {
              uid: booking.uid,
              pickup_time: null,
              dropoff_time: null,
              pickup_location_lat: null,
              pickup_location_name: null,
              dropoff_location_lat: null,
              dropoff_location_name: null,
              $isPhantom: true
            }
          }
        : memo;
    },
    {}
  );
  METHOD('validateCommuteOffer:stateless_api_request_data__bookings', {
    stateless_api_request_data__bookings
  });

  const res = {
    ...commuteOffer,
    stateless_api_request_data: {
      ...stateless_api_request_data,
      nodes: stateless_api_request_data__nodes,
      vehicles: stateless_api_request_data__vehicles,
      bookings: {
        ...stateless_api_request_data.bookings,
        ...stateless_api_request_data__bookings
      }
    },
    result: {
      ...result,
      vehicles: result__vehicles,
      assigned_bookings: result__assigned_bookings,
      rejected_bookings: result__rejected_bookings,
      changelog: [],
      readOnly
    }
  };

  METHOD('validateCommuteOffer:Result', { commuteOffer, result: res });
  return res;
};

export const normalizeCommuteOffer = async (data, opts) =>
  validateCommuteOffer(
    deepmerge.all([
      JSON.parse(emptyCommuteOfferJSON),
      data,
      {
        stateless_api_request_data: {
          engine_settings: {
            calculation_parameters: {
              calculations_mode: 'async'
            }
          }
        }
      }
    ]),
    opts
  );

export const filterCommuteOfferByVehicle = (commuteOffer, id) => {
  METHOD('filterCommuteOfferByVehicle:Enter', { commuteOffer });

  const { result, stateless_api_request_data } = commuteOffer;

  const readOnly = stateless_api_request_data.readOnly || false;

  const { vehicles, assigned_bookings } = result;

  const filteredAssignedBookings = assigned_bookings.filter(
    booking => booking.assigned_vehicle_id === id
  );
  METHOD('filterCommuteOfferByVehicle:filteredAssignedBookings', {
    filteredAssignedBookings
  });

  const filteredVehicles = stateless_api_request_data.vehicles.filter(
    vehicle => vehicle.agent_id === id
  );
  METHOD('filterCommuteOfferByVehicle:filteredVehicles', { filteredVehicles });

  const vehicleRoute = result.routes && result.routes[id];
  METHOD('filterCommuteOfferByVehicle:vehicleRoute', { vehicleRoute });

  const filteredRoutes = vehicleRoute ? { [id]: vehicleRoute } : {};
  METHOD('filterCommuteOfferByVehicle:filteredRoutes', { filteredRoutes });

  const newRoutes = {};

  const res = {
    ...commuteOffer,
    stateless_api_request_data: {
      vehicles: filteredVehicles
    },
    result: {
      assigned_bookings: filteredAssignedBookings,
      vehicles: {
        [id]: vehicles[id]
      },
      routes: newRoutes,
      readOnly
    }
  };

  METHOD('filterCommuteOfferByVehicle:Result', { commuteOffer, result: res });
  return res;
};

export const diffCommuteOffers = (type, o, n, opts) => {
  METHOD('diffCommuteOffers:Enter', { type, o, n, opts });

  const normalize = (commuteOffer) => {
    METHOD('diffCommuteOffers:normalize:Enter', { commuteOffer });

    const { result, ...commuteOfferProps } = commuteOffer;

    // eslint-disable-next-line
    const { changelog, routes, ...resultProps } = result;

    const res = {
      ...commuteOfferProps,
      stateless_api_request_data: {
        ...commuteOfferProps.stateless_api_request_data,
        inbound: {
          schedule: [],
          offers: {}
        }
      },
      result: {
        ...resultProps,
        changelog: undefined
      },
      current_time: undefined
    };

    METHOD('diffCommuteOffers:normalize:Result', { commuteOffer, result: res });
    return res;
  };

  return diffString(normalize(o), normalize(n), opts);
};
