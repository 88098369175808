import styled from 'styled-components';

export default styled.div`
  position: relative;
  user-select: none;
  padding: 15px;
  margin: 0 0 8px 0;
  border-radius: 10px;
  font-size: 12px;
  // min-height: 200px;

  // change background colour if dragging
  background: ${({ isActive }) =>
    isActive ? 'rgb(40, 45, 55)' : 'rgb(40, 45, 55)'};
  box-shadow: 0 0 10px 0 rgba(43, 48, 58, 0.1);
  cursor: move;
  z-index: ${({ isActive }) => (isActive ? 1 : 'auto')};

  &:hover {
    background: rgb(40, 45, 55);
  }
`;
